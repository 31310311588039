import { createContext, useCallback, useState } from "react";
import useLogin from "../../Autenticacion/Hooks/useLogin";
import { ObtenerListadoPonderacionService } from "../Services/ObtenerListadoPonderacionService";
import { useRegistroTDR } from "../../../Hooks/useRegistroTDR";

const PonderacionContext = createContext();

const PonderacionContextProvider = ({children}) => {
	const [visible, setVisible] = useState(false);
	const [renderizarTabla, setRenderizarTabla] = useState(false);
	const [indicator, setIndicator] = useState({});
	const [listado, setListado] = useState([]);
	const {usuario} = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();

	async function postObtenerPonderaciones() {
		const resp = await ObtenerListadoPonderacionService(usuario.id);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					let filtrarNivel1 =[];
					let filtrarNivel2 =[];
					let filtrarNivel3 =[];
					let filtrarNivel4 =[];
					let filtrarNivel5 =[];
					let filtrarNivel6 =[];
					let agrupacion1 =[];
					let agrupacion2 =[];
					let agrupacion3 =[];
					let agrupacion4 =[];
					let agrupacion5 =[];
					let agrupacion6 =[];

					filtrarNivel1.push(resp.data.ponderaciones.filter(y=>y.nivelServicioPadreId === null));
					filtrarNivel1.forEach(nivel1 =>{
						agrupacion2 = [];
						filtrarNivel2 = resp.data.ponderaciones.filter(y=>y.nivelServicioPadreId === nivel1[0].nivelServicioId);
						filtrarNivel2.forEach(nivel2 =>{
							agrupacion3 = [];
							filtrarNivel3 =resp.data.ponderaciones.filter(y=>y.nivelServicioPadreId === nivel2.nivelServicioId);
							filtrarNivel3.forEach(nivel3 =>{
								agrupacion4 = [];
								filtrarNivel4 =resp.data.ponderaciones.filter(y=>y.nivelServicioPadreId === nivel3.nivelServicioId);
								filtrarNivel4.forEach(nivel4 =>{
									agrupacion5 = [];
									filtrarNivel5 =resp.data.ponderaciones.filter(y=>y.nivelServicioPadreId === nivel4.nivelServicioId);
									filtrarNivel5.forEach(nivel5 =>{
										agrupacion6 = [];
										filtrarNivel6 =resp.data.ponderaciones.filter(y=>y.nivelServicioPadreId === nivel5.nivelServicioId);
										filtrarNivel6.forEach(nivel6 =>{
											agrupacion6.push({detalle: nivel5, hijos: []})
										});
										if(agrupacion6.length > 0){
											agrupacion5.push({detalle: nivel5, hijos: agrupacion6});
										}else{
											agrupacion5.push({detalle: nivel5, hijos: filtrarNivel6[0]});
										}
									});
									if(agrupacion5.length > 0){
										agrupacion4.push({detalle: nivel4, hijos: agrupacion5});
									}else{
										agrupacion4.push({detalle: nivel4, hijos: filtrarNivel5[0]});
									}
								});
								if(agrupacion4.length > 0){
									agrupacion3.push({detalle: nivel3, hijos: agrupacion4});
								}else{
									agrupacion3.push({detalle: nivel3, hijos: filtrarNivel4[0]});
								}
							});
							if(agrupacion3.length > 0){
								agrupacion2.push({detalle: nivel2, hijos: agrupacion3});
							}else{
								agrupacion2.push({detalle: nivel2, hijos: filtrarNivel3[0]});
							}
						});
						if(agrupacion2.length > 0){
							agrupacion1.push({detalle: nivel1[0], hijos: agrupacion2});
						}else{
							agrupacion1.push({detalle: nivel1[0], hijos: filtrarNivel2[0]});
						}
					});
					setListado(agrupacion1);

				}
			}
		}
	}
	const getListadoPonderacion = useCallback(
		function () {
			const startTime = new Date();
			const fetchData = async () => {
				await Promise.all([
					postObtenerPonderaciones()
				]);
				const endTime = new Date();
				const tdrId = 63;
				crearRegistroTDR(startTime, endTime, usuario, tdrId);
			};

			fetchData();
		},
		[]
	);
	const value = {
		listado,
		visible,
		indicator,
		renderizarTabla,
		getListadoPonderacion,
		getDataIndicator: (data, esNivelServicio, grupo) => {
			return setIndicator({...data, esNivelServicio: esNivelServicio, grupo: grupo});
		},
		toggleModal: () => {
			return setVisible(!visible)
		},
		toggleRenderizar: () => {
			return setRenderizarTabla(!renderizarTabla)
		}
	}
	return (
		<PonderacionContext.Provider value={value}>
			{children}
		</PonderacionContext.Provider>
	)
}
export {PonderacionContext, PonderacionContextProvider};
