import Exports from "../../../Components/Exports/Exports";
import moment from 'moment';
import { usePagination, useSortBy, useTable } from 'react-table';
import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import notfound from '../../../Assets/images/il-empty.svg';
import Button from "../../../Components/Button/Button";
import useLogin from '../../Autenticacion/Hooks/useLogin';
import RangeDatePicker from '../../../Components/RangeDatePicker/RangeDatePicker';
import SelectCheckField from '../../../Components/SelectCheckField/SelectCheckField';
import PaginationComponent from '../../../Components/PaginationTableServer/PaginationTableServer';
import { EnumFiltroTabBusquedaHallazgo } from "../../../Utils/Enumeradores/ComunEnum";
import validations from "../../../Utils/Validations/Validations";
import { useRegistroTDR } from "../../../Hooks/useRegistroTDR";

const TablaHallazgo = ({ handleCloseFiltros, listadoEstado, listadoTipoHallazgo, columns, cambioTab, setCambioTab, data, fetchData, pageCount: controlledPageCount, currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy, setPagination, handlerModal, tab, esRenderizar, setEsRenderizarTablaHallazgo }) => {
	const {
		getTableProps
		, getTableBodyProps
		, headerGroups
		, prepareRow
		, page
		, pageCount
		, gotoPage
		, setSortBy
		, state: { pageIndex, pageSize, sortBy }
	} = useTable(
		{
			columns
			, data
			, initialState: { pageIndex: currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy }
			, manualPagination: true
			, pageCount: controlledPageCount
			, autoResetPage: false
			, autoResetSortBy: false
			, manualSortBy: true
		},
		useSortBy,
		usePagination
	);

	const mostrarModal = (e, row) => {
		e.preventDefault();
		handlerModal(row);
	}

	const { usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const listaAreaNegocioSeleccion = usuario ? usuario.listaAreaNegocio ? usuario.listaAreaNegocio.map(x => { return { value: x.id.toString(), label: x.descripcion } }) : [] : [];
	const [initialValues, setInitialValues] = useState();

	useEffect(() => {
		handleCloseFiltros();
		if (tab === EnumFiltroTabBusquedaHallazgo.Pendientes) {
			setInitialValues({
				business: listaAreaNegocioSeleccion.map(x => { return x.value.toString() }),
				periodo2: [moment().subtract(1, 'M').toDate(), new Date()],
				tipoHallazgo: listadoTipoHallazgo.map(x => { return x.value.toString() })
			})
		} else {
			setInitialValues({
				business: listaAreaNegocioSeleccion.map(x => { return x.value.toString() }),
				periodo2: [moment().subtract(1, 'M').toDate(), new Date()],
				estado: listadoEstado.map(x => { return x.value.toString() }),
				tipoHallazgo: listadoTipoHallazgo.map(x => { return x.value.toString() })
			})
		}
	}, [])

	const [valoresFormularioEnvio, setValoresFormularioEnvio] = useState({
		paginacion: {
			numeroPagina: pageIndex
			, cantidadRegistros: pageSize
			, orderByAscending: false
			, ordenadoPor: "fechaCreacion"
		},
		datosBusqueda: {
			idUsuario: usuario.id
			, idPerfilContexto: usuario.perfilContexto.id
			, areasNegocioId: listaAreaNegocioSeleccion.map(x => { return Number(x.value) })
			, estado: listadoEstado.map(x => { return Number(x.value) })
			, fechaInicio: moment().subtract(1, 'M').format()
			, fechaTermino: moment().format()
			, exportacion: false
			, tipoHallazgo: listadoTipoHallazgo.map(x => { return Number(x.value) })
			, idFiltro: tab
		}
	});

	useEffect(() => {
		if ((valoresFormularioEnvio.datosBusqueda.estado.length === 0 || valoresFormularioEnvio.datosBusqueda.tipoHallazgo.length === 0) || esRenderizar || cambioTab) {
			if (listadoEstado.length > 0 && listadoTipoHallazgo.length > 0) {
				setValoresFormularioEnvio({
					paginacion: {
						numeroPagina: pageIndex
						, cantidadRegistros: pageSize
						, orderByAscending: false
						, ordenadoPor: "fechaCreacion"
					},
					datosBusqueda: {
						idUsuario: usuario.id
						, idPerfilContexto: usuario.perfilContexto.id
						, areasNegocioId: listaAreaNegocioSeleccion.map(x => { return Number(x.value) })
						, estado: listadoEstado.map(x => { return Number(x.value) })
						, fechaInicio: moment().subtract(1, 'M').format()
						, fechaTermino: moment().format()
						, exportacion: false
						, tipoHallazgo: listadoTipoHallazgo.map(x => { return Number(x.value) })
						, idFiltro: tab
					}
				});
				if (tab === EnumFiltroTabBusquedaHallazgo.Pendientes) {
					setInitialValues({
						business: listaAreaNegocioSeleccion.map(x => { return x.value.toString() }),
						periodo2: [moment().subtract(1, 'M').toDate(), new Date()],
						tipoHallazgo: listadoTipoHallazgo.map(x => { return x.value.toString() })
					})
				} else {
					setInitialValues({
						business: listaAreaNegocioSeleccion.map(x => { return x.value.toString() }),
						periodo2: [moment().subtract(1, 'M').toDate(), new Date()],
						estado: listadoEstado.map(x => { return x.value.toString() }),
						tipoHallazgo: listadoTipoHallazgo.map(x => { return x.value.toString() })
					})
				}
				setPagination({
					pageIndex: 0,
					pageSize: pageSize,
					sortBy: []
				});
				setSortBy([]);
				gotoPage(0);
				if (esRenderizar) {
					setEsRenderizarTablaHallazgo(false);
				}
				if (cambioTab) {
					setCambioTab(false);
				}
			}
		} else {
			fetchData({ formulario: valoresFormularioEnvio, pageIndex, sortBy });
			setPagination(values => ({
				...values
				, sortBy: sortBy
			}));
		}
	}, [pageIndex, sortBy, listadoEstado, listadoTipoHallazgo, esRenderizar, cambioTab])

	const descargar = () => {
		fetchData({ formulario: valoresFormularioEnvio, pageIndex: pageIndex, sortBy, exportacion: true });
	};

	function BuscadorHallazgos(values) {
		const startTime = new Date();
		let formEnvio = valoresFormularioEnvio;
		/* Se setean valores del paginado para la primera busqueda (resetea la tabla) */
		formEnvio.paginacion.numeroPagina = 0;
		formEnvio.paginacion.orderByAscending = false;
		formEnvio.paginacion.ordenadoPor = "fechaCreacion";
		/* Se setean los valores de los campos que ingresa el usuario */
		formEnvio.datosBusqueda.areasNegocioId = values.business.map(Number);
		formEnvio.datosBusqueda.fechaInicio = values.periodo2[0];
		formEnvio.datosBusqueda.fechaTermino = values.periodo2[1];
		formEnvio.datosBusqueda.estado = (tab === EnumFiltroTabBusquedaHallazgo.Pendientes) ? listadoEstado.map(x => { return Number(x.value) }) : values.estado.map(x => { return Number(x) });
		formEnvio.datosBusqueda.tipoHallazgo = values.tipoHallazgo.map(x => { return Number(x) });
		const fetchData = async () => {
			await Promise.all([
				setValoresFormularioEnvio(formEnvio)
			]);
			const endTime = new Date();
			const tdrId = 38;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
		setPagination({
			pageIndex: formEnvio.paginacion.numeroPagina,
			pageSize: formEnvio.paginacion.cantidadRegistros,
			sortBy: []
		});
		setSortBy([]);
		gotoPage(0);
	};

	return (
		<div>
			<div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-5 gap-3">
				<div className="filters order-1 order-lg-0">
					<Formik
						enableReinitialize
						initialValues={initialValues}
						validate={validations}
						onSubmit={(values) => {
							BuscadorHallazgos(values);
						}}
					>
						{({ values, setFieldValue, setFieldTouched, }) => (
							<Form className="form mb-0">
								<div className="tools order-1 order-lg-0 align-items-start">
									<SelectCheckField
										name="business"
										label='Área de negocio'
										options={listaAreaNegocioSeleccion}
										onChange={setFieldValue}
										onBlur={setFieldTouched}
										isMulti={true}
									/>

									<RangeDatePicker
										startMaxDate={new Date()}
										startMinDate={null}
										endMaxDate={new Date()}
										endMinDate={null}
										name="periodo2"
										onChange={setFieldValue}
										onBlur={setFieldTouched}
									/>
									{
										tab === EnumFiltroTabBusquedaHallazgo.Todos &&
										<SelectCheckField
											name="estado"
											label='Estado'
											options={listadoEstado}
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											isMulti={true}
										/>
									}
									<SelectCheckField
										name="tipoHallazgo"
										label='Tipo de Hallazgo'
										options={listadoTipoHallazgo}
										onChange={setFieldValue}
										onBlur={setFieldTouched}
										isMulti={true}
									/>
									<Button type="submit" design="btn-primary">Buscar</Button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
				{
					data.length > 0 &&
					<div className="exports order-0 order-lg-1">
						<Exports descargar={() => descargar()} />
					</div>
				}
			</div>
			<div className="table-responsive brd-top">
				<table className="table" {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									data.length > 0 ?
										<th {...column.getHeaderProps(column.getSortByToggleProps(), { className: column.className })}>
											<span className="d-flex align-items-center gap-1">
												{column.render('Header')}
												<>
													{column.isSorted && data.length > 0 ?
														(column.isSortedDesc ?
															<i className="ico-chevron-down"></i>
															:
															<i className="ico-chevron-up"></i>
														) :
														<>
															<i className="ico-sort fs-7"></i>
														</>
													}
												</>
											</span>
										</th>
										:
										<th {...column.getHeaderProps()}
										>
											<span className="d-flex align-items-center gap-1">
												{column.render('Header')}
											</span>
										</th>
								))}
								<th className="text-center">Detalle</th>
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{
							// eslint-disable-next-line no-mixed-operators
							page.length > 0 && page.map((row) => {
								prepareRow(row)
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps({
												className: cell.column.className
											})}>
												{cell.render('Cell')}
											</td>
										})}
										<td className="text-center">
											<button className="link text-pewter-blue fs-4" onClick={(e) => mostrarModal(e, row.original)}>
												<i className="ico-search"></i>
											</button>
										</td>
									</tr>
								)
								// eslint-disable-next-line no-mixed-operators
							}) || <tr><td colSpan={headerGroups[0].headers.length}>
								<div className="d-flex justify-content-center align-items-center flex-column gap-2 py-5">
									<img className="mb-3" src={notfound} alt="" />
									<p className='mb-0 text-primary'>No se encuentran resultados de tu búsqueda.</p>
								</div>
							</td></tr>
						}
					</tbody>
				</table>
			</div>
			{
				data.length > 0 &&
				<PaginationComponent
					pagesCountServer={pageCount}
					currentPage={pageIndex}
					setCurrentPage={gotoPage}
					alwaysShown={false}
					setPagination={setPagination}
				/>
			}
		</div>
	)
}

export default TablaHallazgo;
