import { useEffect, useState } from "react"
import ModalEstadoUsuario from "./ModalEstadoUsuario"


const SwitchInCell = (props) => {
	const [show, setShow] = useState(false);
	const [estado, setStatus] = useState(props.usuario.estado)
	const [deshabilitado, setDeshabilitado] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const controlEstadoUsuario = (e) => {
		 	handleShow()
	}
	const handlerCambioEstadoUsuario =()=>{
	}
	function validarDeshabilitado(){
		if(props.usuario.estadoAutorizacion===1){
			setDeshabilitado(true);
		}
	}
	function handlerRenderizarListado(){
		props.renderizar();
	}

	useEffect(() => {
		//FALTA AGREGAR VALIDACION DE QUE SI LA AUTORIZACION ESTA APROBADA PERO EL USUARIO NO HA SETEADO LA CONTRASEÑA(FALTA ESE CAMPO PARA LA VALIDACION) EL CHECK DEBERIA ESTAR DESHABILITADO
		validarDeshabilitado();
	}, [props]);
	return(
		<>

		<div className="form-check form-switch d-flex justify-content-center align-items-center p-0">
			<input
				className="form-check-input mx-0"
				type="checkbox"
				role="switch"
				id={`auth-${props.usuario.id}`}
				checked={estado}
				disabled = {deshabilitado}
				onChange={(e) =>controlEstadoUsuario(e)}
			/>
		</div>
		<ModalEstadoUsuario
			show={show}
			onHide={handleClose}
			usuario={props.usuario}
			handlerCambioEstadoUsuario={handlerCambioEstadoUsuario}
			handlerRenderizarListado={handlerRenderizarListado}
		/>
		</>
	)
}

export default SwitchInCell;
