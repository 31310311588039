import instance, { customConfig }  from "../../../Utils/Interceptors/Interceptor";

const ActualizaUsuarioRespaldoService= async (values, idUsuario) => {
    try {   
        const resultado = await instance.Backend.post("/Usuario/ActualizaUsuarioRespaldo",{
            usuarioRespaldoId: values.usuarioRespaldoId,
            fechaInicio: values.fechaInicio,
            fechaFin: values.fechaFin,
            idUsuarioConsulta: idUsuario
        }, customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export {ActualizaUsuarioRespaldoService};