const NotificacionLeidaCelda = ({ ...props }) => {
	const { leido, tipoNotificacionDescripcion } = props.data; 
	return (
		<div className="d-flex align-items-center gap-3">
			<span className={`dot dot-md ${!leido ? 'bg-secondary' : null}`}></span>
			{!leido ? 
			<b>{tipoNotificacionDescripcion}</b>
            :
			<span>{tipoNotificacionDescripcion}</span>
			}
		</div>
		
	)
}
export default NotificacionLeidaCelda;
