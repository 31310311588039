import { useEffect, useState } from 'react';
import { CrearRegistroTDRService } from '../Services/CrearRegistroTDRService';
import { BusquedaIpUsuarioService } from '../Services/ObtenerIpUsuario';

export function useRegistroTDR() {
    const [registroTDRData, setRegistroTDRData] = useState(null);

    async function crearRegistroTDR(startTime, endTime, usuario, tdrId) {
			const ips = window.sessionStorage.getItem('ip');
			const tiempoTranscurridoSegundos = (endTime - startTime) / 1000;
			const tiempoReal = parseFloat(tiempoTranscurridoSegundos.toFixed(2));
			const resp = await BusquedaIpUsuarioService();
		

        if(ips.includes(resp.data.ipRequest)){
					const timeZoneOffset = startTime.getTimezoneOffset() * 60000;
					const localStartTime = new Date(startTime.getTime() - timeZoneOffset);
					const localEndTime = new Date(endTime.getTime() - timeZoneOffset);

					let variables = {
							"operaciontdr_ID": tdrId,
							"tiempoReal": tiempoReal,
							"fechaHoraInicioRegistro": localStartTime.toISOString(),
							"fechaHoraFinRegistro": localEndTime.toISOString(),
							"idUsuario": usuario.id
					}
					CrearRegistroTDRService(variables);
					setRegistroTDRData(variables);
				}
    }

    return {
        registroTDRData,
        crearRegistroTDR
    };
}
