import Card from "../../../../Components/Card/Card";

const Pivote = ({...props}) => {
	const {link,title,text,icon,color} = props;
	return (
		<Card className="p-4"
			link={link}
		>
			<div className="d-flex flex-column align-items-start">
				<figure className="box-icon" style={{backgroundColor:color}}>
					<i className={icon}></i>
				</figure>
				<h6 className="fs-5 mb-2" style={{ whiteSpace: 'pre-line'}}>{title}</h6>
				<span className="fs-6 text-pewter-blue">{text}</span>
			</div>
		</Card>
	)
}
export default Pivote;
