import { useEffect, useRef, useState } from "react";
import SelectCheckField from '../../../Components/SelectCheckField/SelectCheckField';
import { Formik, Form, } from "formik";
import Badge from "../../../Components/Badge/Badge";
import Button from "../../../Components/Button/Button";
import ChartsColumnsBars from "../../../Components/ChartsColumnsBars/ChartsColumnsBars";
import CardEvaluacion from "../Components/CardEvaluacion";
import ModalAsignacionCdoTrimestre from "../Components/ModalAsignacionCdoTrimestre";
import { ObtenerListaAnos } from "../Services/ObtenerListaAnos";
import { BuscarIndiceServicioPrestado } from "../Services/BuscarIndiceServicioPrestado";
import useLogin from "../../Autenticacion/Hooks/useLogin";
import ModalAsignado from "../Components/ModalAsignado";
import { Link } from "react-router-dom";
import { EnumPathRutas } from "../../../Utils/Enumeradores/ComunEnum";
import { useRegistroTDR } from "../../../Hooks/useRegistroTDR";

const IndicadoresIspPage = () => {
	const [listadoAnos, setListadoAnos] = useState([]);
	const [listadoMeses, setListadoMeses] = useState([]);
	const [indice, setIndice] = useState(null);
	const {usuario} = useLogin();
	const [AnoSeleccion, setAnoSeleccionado] = useState([]);
	const formikref = useRef();
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [show2, setShow2] = useState(false);
	const handleClose2 = () => setShow2(false);
	const [CDOasignado, setCDOasignado] = useState({});
	const handleShow2 = () => setShow2(true);
	const handleShow = () => setShow(true);
	const [ mostrar, setMostrar] = useState(false);
	const [ trimeste, setTrimeste] = useState(false);
	const [ AnoInicial, setAnoInicial] = useState((new Date()).getFullYear());
	const { crearRegistroTDR } = useRegistroTDR();
	const [chartFindings, setchartFindings] = useState({
		series: [
			{
				name: 'Valor',
				data: listadoMeses
			},
		],
	})

	const AgregarCDO = () => {
		setMostrar(false);
		handleShow(true);
	}
	const modalMostrar = (datosMostrar, trimestre) => {
		if(datosMostrar){
			setTrimeste(trimestre);
			setMostrar(true);
			handleShow(true);
	}
		else{
			handleShow(false);
		}
	}

	useEffect(() => {
		const startTime = new Date();
		const valuesIndice = {
			idUsuario: usuario.id,
			idPerfilContexto: usuario.perfilContexto.id,
			anoConsulta: AnoInicial
		}

		const fetchData = async () => {
			await Promise.all([
				ObtenerAnos(),
				getIndice(valuesIndice)
			]);
			const endTime = new Date();
			const tdrId = 12;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
	}, [])

	const AnoSeleccionado = (y) => {
		const startTime = new Date();
		const valuesIndice = {
			idUsuario: usuario.id,
			idPerfilContexto: usuario.perfilContexto.id,
			anoConsulta: y
		}
		const fetchData = async () => {
			await Promise.all([
				getIndice(valuesIndice),
				setAnoSeleccionado(y)
			]);
			const endTime = new Date();
			const tdrId = 13;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
	}

	const ObtenerAnos = async() =>{
		const resp = await ObtenerListaAnos()
		if (resp) {
			if (resp.status === 200) {
				let listado = [];
        resp.data.map((value) => { listado.push({ value: value, label: value }) });
        setListadoAnos(listado);
			}
		}
	}

	async function getIndice(values) {
		const resp = await BuscarIndiceServicioPrestado(values);
		if (resp) {
			if (resp.status === 200) {
				setIndice(resp.data);
				let lista = [];
					resp.data.indicadorNSC.map((value) => { lista.push({ x: value.mes, y: value.valor, fillColor: value.color}) });
					setchartFindings({...chartFindings, series:[
						{
							name: 'Valor',
							data: lista
						},
					]});
			}
		}
	}

	return (
		<>
			<div className="container-fluid h-100">
				<div className="row mb-4">
					<div className="col-12">
						<div className="breadcrumbs">
							<ul>
								<li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
								<li><a href="" onClick={(e)=> {e.preventDefault(); window.location.reload(false);}}>Indicadores</a></li>
								<li><a href="">ISP</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div className="row" style={{ height: 'calc(100% - 20px)' }}>
					<div className="col-12">
						<div className="bg-white rounded p-4 d-flex flex-column h-100">
							<div className="d-flex align-items-center justify-content-between flex-wrap gap-3 mb-4">
								<h2 className="fs-4">Indice Servicio Prestado</h2>
								<Formik
									enableReinitialize
									initialValues={{ years: AnoInicial }}
									onSubmit={values => {
									}}
									innerRef = {formikref}
								>{({ setFieldValue, setFieldTouched
								}) => (
									<Form className="form mb-0">
										<SelectCheckField
											name="years"
											options={listadoAnos}
											onChange={(x,y) => {setFieldValue(x, y); AnoSeleccionado(y);}}
											onBlur={setFieldTouched}
											isMulti={false}
										/>
									</Form>
								)}
								</Formik>
							</div>
							<div className='border rounded p-4 h-100'>
								<div className="d-flex align-items-center justify-content-between flex-wrap gap-3 mb-4">
									<Badge design="gray" >{indice ? indice.ano:""}</Badge>
									{indice ? usuario.perfilContexto.id	=== 2 ? indice.trimestre.filter(x => x.cdoId === null && x.nivelcumplimientoId !== null).sort(x => x.cdoId).length >= 1 ?
									<Button type="button" design="btn-primary btn-sm" onClick={(e)=> AgregarCDO(true)}>Asignar CDO</Button>
								: <Button type="button" design="btn-primary btn-sm" disabled >Asignar CDO</Button>
									: null
									: null
								}
								</div>
								<div className='d-flex justify-content-between align-items-center flex-wrap gap-3 mb-4'>
									<div className='d-flex flex-column text-pewter-blue'>
										<span className='fs-5'>FDO anual acumulado</span>
										<b className='fs-3 lh-1'>{indice ? indice.fdoAnualAcumulado:""}</b>
									</div>
									<div className='d-flex justify-content-between gap-3 flex-wrap'>
										{indice ? indice.trimestre[0] && indice.trimestre[0].cdo !== null ?
											<CardEvaluacion
											title={indice.trimestre[0].descripcionTrimestre}
											design={indice.trimestre[0].color}
											value={indice.trimestre[0].isp}
											cdo={"CDO" + ' ' + indice.trimestre[0].cdo}
											handleClick={(e)=> modalMostrar(true, 1)}
										/>
										:
										<CardEvaluacion
										title="ISP 1er Trimestre"
										design="#00CC33"
										value="0"
										cdo="-"
										className="px-4"
										/>
										:
										<CardEvaluacion
										title="ISP 1er Trimestre"
										design="#00CC33"
										value="0"
										cdo="  -  "
										className="px-4"
										/>
										}
										{indice ? indice.trimestre[1] && indice.trimestre[1].cdo !== null  ?
											<CardEvaluacion
											title={indice.trimestre[1].descripcionTrimestre}
											design={indice.trimestre[1].color}
											value={indice.trimestre[1].isp}
											cdo={"CDO" + ' ' + indice.trimestre[1].cdo}
											handleClick={(e)=> modalMostrar(true, 2)}
										/>
										:
										<CardEvaluacion
										title="ISP 2do Trimestre"
										design="#00CC33"
										value="0"
										cdo="-"
										className="px-4"
										/>
										:
										<CardEvaluacion
										title="ISP 2do Trimestre"
										design="#00CC33"
										value="0"
										cdo="-"
										className="px-4"
										/>
										}
										{indice ? indice.trimestre[2] && indice.trimestre[2].cdo !== null ?
											<CardEvaluacion
											title={indice.trimestre[2].descripcionTrimestre}
											design={indice.trimestre[2].color}
											value={indice.trimestre[2].isp}
											cdo={"CDO" + ' ' + indice.trimestre[2].cdo}
											handleClick={(e)=> modalMostrar(true, 3)}
										/>
										:
										<CardEvaluacion
										title="ISP 3er Trimestre"
										design="#00CC33"
										value="0"
										cdo="-"
										className="px-4"
										/>
										:
										<CardEvaluacion
										title="ISP 3er Trimestre"
										design="#00CC33"
										value="0"
										cdo="-"
										className="px-4"
										/>
										}
										{indice ? indice.trimestre[3] && indice.trimestre[3].cdo !== null ?
											<CardEvaluacion
											title={indice.trimestre[3].descripcionTrimestre}
											design={indice.trimestre[3].color}
											value={indice.trimestre[3].isp}
											cdo={"CDO" + ' ' + indice.trimestre[3].cdo}
											handleClick={(e)=> modalMostrar(true, 4)}
										/>
										:
										<CardEvaluacion
										title="ISP 4to Trimestre"
										design="#00CC33"
										value="0"
										cdo="-"
										className="px-4"
										/>
										:
										<CardEvaluacion
										title="ISP 4to Trimestre"
										design="#00CC33"
										value="0"
										cdo="-"
										className="px-4"
										/>
										}
									</div>
								</div>
								<h3 className='fs-4 text-pewter-blue'>NSC</h3>

								<ChartsColumnsBars
									dataChart={chartFindings}
								/>

							</div>
						</div>
					</div>
				</div>
			</div>
			{
				show?
			<ModalAsignacionCdoTrimestre
				show={show}
				onHide={handleClose}
				setcdoasignado = {{setCDOasignado}}
				showmodalasignado = {{handleShow2}}
				anoseleccion = {AnoSeleccion.length === 0 ? AnoInicial : AnoSeleccion}
				asignaciontrimestre={mostrar.toString()}
				trimestre = {trimeste.toString()}
			/>
		:null
		}
			{
				show2?
				<ModalAsignado
					show={show2}
					onHide={handleClose2}
					cdoasignado = {CDOasignado}
				/>
				:null
			}

		</>
	)
}

export default IndicadoresIspPage
