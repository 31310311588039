import SelectColumnFilter from "../../../Components/Table/Filters/Components/SelectColumnFilter";
import { dateStartFilterFn, dateEndFilterFn, DateColumnFilter } from "../../../Components/Table/Filters/Components/DateColumnFilter";
import StateInCell from "../../../Components/Table/Filters/Components/StateInCell";
import moment from "moment";
import EtapaCelda from "../Components/EtapaCelda";
import Avatar from "../../../Components/Avatar/Avatar";
import { EnumHallazgoOrigen } from "../../../Utils/Enumeradores/ComunEnum";

export const COLUMNS = [
	{
		Header: 'Id',
		accessor: 'idHallazgo',
		Cell: ({ row }) =>
			<span className="status">
				<span className="dot" style={{ backgroundColor: row.original.colorAlerta }}></span>
				{row.original.hallazgoId}
			</span>
	},
	{
		Header: 'Origen',
		accessor: 'origenDescripcion',
		Cell: ({ row }) =>
			<span className={`badge justify-content-center ${row.original.origen === EnumHallazgoOrigen.SIC_NS ? 'bg-sicns' : 'bg-sice'}`}>{row.original.origenDescripcion}</span>
	},
	{
		Header: 'Hallazgo',
		accessor: 'hallazgoDescripcion',
	},
	{
		Header: 'Área de negocio',
		accessor: 'areaNegocio',
	},
	{
		Header: 'Fecha registro',
		accessor: 'fechaCreacion',
		Cell: ({ row }) =>
		moment(row.original.fechaCreacion).format('DD-MM-YYYY')
	},
	{
		Header: 'Fecha Límite',
		accessor: 'fechaLimite',
		Cell: ({ row }) =>
			<div className="">
				<span>{moment(row.original.fechaLimiteCorreccion).format('DD-MM-YYYY')} </span>
				{row.original.solicitudProrroga === 1 ?
					<i className="ico ico-time"></i> :
					row.original.solicitudProrroga === 2 ?
						<i className="ico ico-time text-success"></i> :
						row.original.solicitudProrroga === 3 ?
							<i className="ico ico-time text-danger"></i> : ''
				}
			</div>
	},
	{
		Header: 'Tipo de Hallazgo',
		accessor: 'tipoHallazgo',
	},
	{
		Header: 'Etapa',
		accessor: 'etapa',
		Cell: ({ row }) =>
			<EtapaCelda etapa={row.original.etapa} etapaDescripcion={row.original.etapaDescripcion} hallazgoId={row.original.hallazgoId} ></EtapaCelda>
	},
	{
		Header: 'Estado',
		accessor: 'estado',
		className: 'text-center',
		Cell: ({ row }) =>
		<StateInCell estado={row.original.estado} estadoDescripcion={row.original.estadoDescripcion} hallazgoId={row.original.hallazgoId} solicitudProrroga={row.original.solicitudProrroga} cantidadIteraciones={row.original.cantidadIteraciones}>
		</StateInCell>,

	},
	{
		Header: 'Responsable',
		accessor: 'Responsable',
		Cell: ({ row }) =>
			<Avatar data={{ rutaImagen: row.original.rutaAvatar, nombreCompleto: row.original.usuarioAsignadoNombre, apellidoPaterno: row.original.usuarioAsignadoApellidoPaterno, apellidoMaterno: row.original.usuarioAsignadoApellidoMaterno, listaPerfiles: "" }} esMostrarNombreYperfil={true} esMostrarNombreCompletoYperfil={false}></Avatar>
	},
]
