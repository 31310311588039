const SelloTiempo = ({...props}) => {
	const selloTiempo = props.data;
	const leido = props.leido;
	return (
		<div className="d-flex align-items-center">
				{!leido ?
			<b className="text-nowrap">{selloTiempo}</b>
            :
			<span className="text-nowrap">{selloTiempo}</span>
			}

		</div>
	)
}
export default SelloTiempo;
