import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const SolicitudModificarEstadoAutorizacionService = async (values) => {
    try {
        const resultado = await instance.Backend.post("/Autorizacion/SolicitudModificarEstadoAutorizacion", {
            usuarioContextoId: values.usuarioContextoId,
            usuarioId: values.id,
            estadoAutorizacion: values.estadoAutorizacion,
            movimiento: values.movimiento,
        }, customConfig);
        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { SolicitudModificarEstadoAutorizacionService };