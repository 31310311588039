import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { useField } from "formik";
registerLocale('es', es);

const Rangepicker = ({ maxDate,minDate, ...props }) => {
	const { name } = props;
	const [field, meta] = useField(props);
	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;
	const handleSelectDate = (date) => {
		setDateRange(date)
		props.onChange(name, date);
	}
	const CustomInput = React.forwardRef((props, ref) => {
		return (
			<input
				className={`form-control input-range ${meta.touched && meta.error ? 'is-invalid' : ''}`}
				onClick={props.onClick}
				value={props.value}
				type="text"
				readOnly={true}
				ref={ref}
				placeholder='Desde - Hasta'
			/>
		)

	})
	return (
		<div className='form-floating'>
			<DatePicker
				{...field}
				{...props}
				customInput={<CustomInput />}
				className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
				locale='es'
				dateFormat='dd-MM-yyyy'
				startDate={startDate}
				endDate={endDate}
				maxDate={maxDate}
				minDate={minDate}
				monthsShown={2}
				selectsRange={true}
				focusSelectedMonth={false}
				disabledKeyboardNavigation
				popperClassName='twoMonths'
				wrapperClassName='rangePicker'
				placeholderText='Desde - Hasta'
				onChange={(update) => {
					handleSelectDate(update);
				}}

			/>
			{meta.touched && meta.error ?
				<small className="message">{meta.error}</small> : null}
		</div>
	)
}

export default Rangepicker;
