/* LA VISIBILIDAD DE LOS DATOS SE ENCUENTRA EN LA LOGICA DE LA TABLA YA QUE ES UNA MATRIZ NO SE PUDO MOSTRAR ACA,
DE IGUAL FORMA ESTO SIRVE PARA OBTENER LOS HEADER DE LA TABLA Y LA LOGICA DEL SORTBY CON EL ACCESSOR PARA LA TABLA PAGINADA MANUAL */

export function useColumns() {
	const COLUMNS = [
		{
			Header: 'Id',
			accessor: 'eventoConcesionId',
		},
		{
			Header: 'Descripcion',
			accessor: 'descripcion',
		},

		{
			Header: 'Valor',
			accessor: 'valor',
			headerClassName: 'justify-content-center',
		},
		{
			Header: 'Fecha/Hora de creacion',
			accessor: 'fechaHoraCreacion',
			headerClassName: 'justify-content-center',
		},
		{
			Header: 'Estado',
			accessor: 'estado',
			headerClassName: 'justify-content-center',
			disabledFilters: true,
		},
		{
			Header: 'Detalle',
			disabledFilters: true,
			disabledSortBy: true,
			headerClassName: 'justify-content-center',
		},
		{
			Header: 'Autorizar',
			disabledFilters: true,
			disabledSortBy: true,
			headerClassName: 'justify-content-end',
		}
	];
	return { COLUMNS }
}
