import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../Components/Button/Button';
import Exports from '../../../Components/Exports/Exports';
import { Columns } from '../Utils/ColumnsDisponibilidad';
import { Form, Formik } from 'formik';
import { EnumPathRutas, EnumTablaPaginacion, EnumDisponibilidadMeses } from '../../../Utils/Enumeradores/ComunEnum';
import validations from '../../../Utils/Validations/Validations';
import TablaDisponibilidad from '../Components/TablaDisponibilidad';
import { useComun } from '../../../Hooks/useComun';
import Progreso from '../Components/Progreso';
import SelectCheckField from "../../../Components/SelectCheckField/SelectCheckField";
import { BusquedaDisponibilidad } from '../Services/BusquedaDisponibilidad';
import useLogin from '../../Autenticacion/Hooks/useLogin';


const DisponibilidadPages = () => {
	const { usuario } = useLogin();
	const { ultimoPeriodo, listaPeriodo, periodo, ObtenerPeriodoDisponibilidades} = useComun();
	const [tipoMonitoreoData, setTipoMonitoreo] = useState([1,2,3])
	const [esDescargar, setEsDescargar] = useState(false);
	const [datosBusqueda, setDatosBusqueda] = useState({ mes: ultimoPeriodo.mes , ano: ultimoPeriodo.ano, tipoMonitoreo: tipoMonitoreoData });
	const [respuesta, setRespuesta] = useState()
	const [periodoDisponibilidad, setPeriodoDisponibilidad] = useState(null);
	const columns = useMemo(() => Columns, []);
	const formikRef = useRef();
	const tipoMonitoreo = [
        { value: 1, label: "Mantenimiento Programado Aprobado" },
        { value: 2, label: "Monitoreo Normal" },
        { value: 3, label: "Mantenimiento Programado No Aprobado" }
    ]; 
	
	const [data, setData] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [{ pageIndex, pageSize, sortBy }, setPagination] =
		useState({
			pageIndex: 0,
			pageSize: EnumTablaPaginacion.CantidadPaginacion,
			sortBy: []
		});
	const [initialValues, setInitialValues] = useState({
		mes: ultimoPeriodo.mes,
		ano: ultimoPeriodo.ano,
		tipo_monitoreo: tipoMonitoreo.map(x => Number(x.value)),
	})

	useEffect(() => {
		setDatosBusqueda({
			mes: ultimoPeriodo.mes,
			ano: ultimoPeriodo.ano,
			tipoMonitoreo: tipoMonitoreoData,
		});
	
		if (listaPeriodo.length > 0) {
			const formattedMes = ultimoPeriodo.mes.toString().padStart(1, '0');
			const periodoValue = `${ultimoPeriodo.ano}-${formattedMes}`;
			const periodoActual = listaPeriodo.find(x => x.value.includes(periodoValue));
			if (periodoActual) {
				setInitialValues({ periodo_cerrado: periodoActual.value, 
					tipo_monitoreo: tipoMonitoreoData
				});
			}
		}
	}, [ultimoPeriodo]);

	const downloadBase64 = (base64String, mime, nombreArchivo) => {
		if (typeof base64String !== 'string') {
			return;
		}
	
		if (base64String.startsWith("data:")) {
			base64String = base64String.split(",")[1];
		}
	
		base64String = base64String.replace(/\s/g, '');
	
			const byteCharacters = atob(base64String);
			const byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			const blob = new Blob([byteArray], { type: mime });
	
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', nombreArchivo);
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);
	};
	

	useEffect(() => {
		const usuarioId = {
			idUsuario: usuario.id,
		}
        ObtenerPeriodoDisponibilidades(usuarioId);
	}, [])

	async function postBusquedaDisponibilidad(values) {
		const resp = await BusquedaDisponibilidad(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					if (values.datosBusqueda.exportacion) {
							const base64Data = resp.data.archivo
							const mime = 'text/csv';
							const nombreArchivo = "Disponibilidad.csv";
							downloadBase64(base64Data, mime, nombreArchivo);
						} else {
							setPageCount(resp.data.cantidadPaginas);
							setData(resp.data.listadoRegistros || []);
							setPeriodoDisponibilidad(resp.data.periodoDisponibilidad);
							setRespuesta(resp.data);
						}
					} 
				}
		}
	}

	const fetchData = useCallback(({ formulario, pageIndex, sortBy = [] }) => {
		formulario.paginacion.numeroPagina = pageIndex + 1;
		formulario.paginacion.ordenadoPor = sortBy.length > 0 ? sortBy[0].id : formulario.paginacion.ordenadoPor;
		formulario.paginacion.orderByAscending = sortBy.length > 0 ? (sortBy[0].desc ? false : true) : formulario.paginacion.orderByAscending;
		postBusquedaDisponibilidad(formulario);
	}, []);

	function BuscarlistadoDisponibilidad(values) {
        const mes = periodo.data.find(x=>x.mes==values.periodo_cerrado.substring(5) && x.ano == values.periodo_cerrado.substring(0,4)).mes;
		const ano = periodo.data.find(x=>x.mes==values.periodo_cerrado.substring(5) && x.ano == values.periodo_cerrado.substring(0,4)).ano;
		const tipoMonitoreo = values.tipo_monitoreo;
		setTipoMonitoreo(tipoMonitoreo);
		setDatosBusqueda({
			mes: mes
		   ,ano: ano
		   ,tipoMonitoreo: tipoMonitoreo
		});
	}

	const descargar = () => {
        setEsDescargar(true)
    };

	return (
		<div className="container-fluid">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li>
								<Link to={EnumPathRutas.Inicio}> Inicio</Link>
							</li>
							<li>
								<Link to={EnumPathRutas.Informes}>Informes</Link>
							</li>
							<li>
								<a href="#">Disponibilidad SIC-NS</a>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<div className="bg-white rounded p-4">
						<div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-5 gap-3">
							<Formik
								enableReinitialize
								initialValues={initialValues}
								innerRef={formikRef}
								validate={validations}				
								onSubmit={(values) => {
									BuscarlistadoDisponibilidad(values);
								}}
							>
								{({ values, setFieldValue, setFieldTouched }) => (
									<Form className="form mb-0" ref={formikRef}>
										<div className="tools order-1 order-lg-0 align-items-start">
											<SelectCheckField
												name="periodo_cerrado"
												label="Periodo"
												options={listaPeriodo}
												onBlur={setFieldTouched}
												onChange={setFieldValue}
												isMulti={false}
												value={initialValues.periodo_cerrado}
											/>
                                            <SelectCheckField
												name="tipo_monitoreo"
												label="Tipo de monitoreo"
												options={tipoMonitoreo}
												onBlur={setFieldTouched}
												onChange={setFieldValue}
												isMulti={true}
											/>
											<Button type="submit" design="btn-primary">
												Buscar
											</Button>
										</div>
									</Form>
								)}
							</Formik>
							<div className="exports order-0 order-lg-1">
								<Exports descargar={() => descargar()} />
							</div>
						</div>

						<div style={{ width: '100%', borderBottom: '1px solid black', marginBottom: '20px' }}></div>
						
						<div style={{ width: '100%', marginBottom:'20px' }} className="row d-flex justify-content-center align-items-center">
							<div style={{ width: '400px', height:'100px' }}>
							{periodoDisponibilidad ? (
								<Progreso
									title="Disponibilidad SIC-NS"
									percent={periodoDisponibilidad.valorCumplimiento}
									mes={EnumDisponibilidadMeses[periodoDisponibilidad.mes]}
									anio={periodoDisponibilidad.ano}
								/>
							) : (
								<Progreso
									title="Disponibilidad SIC-NS"
									percent='--'
									mes='-'
									anio='-'
								/>
							)}
							</div>
							<div className="d-flex justify-content-center align-items-center mb-3 col-md-5" style={{marginLeft: '70px'}}>
                                <table>
										<thead>
											<tr>
												<th style={{ height: '20px' }}>Resumen</th>
											</tr>
										</thead>
										<tbody>
										<tr>
											<th style={{ height: '30px', borderBottom: '1px solid black', borderTop: '1px solid black' }}>Total minutos del mes</th>
											<td style={{ height: '30px', borderBottom: '1px solid black', borderTop: '1px solid black', paddingLeft: '100px' }}>
												{respuesta?.totalMinutos ?? '-'}
											</td>
											<td style={{ height: '30px', borderBottom: '1px solid black', borderTop: '1px solid black', paddingLeft: '50px' }}>
											
											</td>
										</tr>
										<tr>
											<th style={{ height: '30px', borderBottom: '1px solid black', borderTop: '1px solid black' }}>Total minutos de indisponibilidad API</th>
											<td style={{ height: '30px', borderBottom: '1px solid black', borderTop: '1px solid black', paddingLeft: '100px' }}>
												{respuesta?.totalMinutosApi ?? '-'}
											</td>
											<td style={{ height: '30px', borderBottom: '1px solid black', borderTop: '1px solid black', paddingLeft: '50px' }}>
												{periodoDisponibilidad?.valor ?? '0'}%
											</td>
										</tr>
										<tr>
											<th style={{ height: '30px', borderBottom: '1px solid black', borderTop: '1px solid black' }}>Total minutos de indisponibilidad WEB</th>
											<td style={{ height: '30px', borderBottom: '1px solid black', borderTop: '1px solid black', paddingLeft: '100px' }}>
												{respuesta?.totalMinutosWeb  ?? '-'}
											</td>
											<td style={{ height: '30px', borderBottom: '1px solid black', borderTop: '1px solid black', paddingLeft: '50px'}}>
												{periodoDisponibilidad?.valor1 ?? '0'}%
											</td>
										</tr>
										</tbody>
                                </table>
                            </div>
						</div>
					<div style={{ width: '100%', borderBottom: '1px solid black', marginBottom: '20px' }}></div>
						<TablaDisponibilidad
							columns={columns}
							data={data}
							fetchData={fetchData}
							pageCount={pageCount}
							currentpage={pageIndex}
							pageSize={pageSize}
							sortBy={sortBy}
							setPagination={setPagination}
							datosBusqueda={datosBusqueda}
							esDescargar={esDescargar}
							setEsDescargar={setEsDescargar}
							ultimoPeriodo={ultimoPeriodo}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DisponibilidadPages;