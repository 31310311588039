import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Card from "../../../Components/Card/Card";
import Exports from "../../../Components/Exports/Exports";
import { useComun } from '../../../Hooks/useComun';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import CardIndicador from "../Components/CardIndicador";
import { ObtenerIndicadoresSerieService } from '../Services/ObtenerIndicadoresSerieService';
import { EnumModuloDesc, EnumPathRutas } from "../../../Utils/Enumeradores/ComunEnum";
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const DatamartIndicadoresPage = () => {
	const location = useLocation();
	const { crearRegistroTDR } = useRegistroTDR();
	const { usuario } = useLogin();
	const { downloadCSV } = useComun();
	const [listadoIndicadoresSerie, setListadoIndicadoresSerie] = useState([]);
	const datosPage = { siglaIndicador: location.state ? location.state.sigla : "", descripcionIndicador: location.state ? location.state.descripcion : "" };
	async function postObtenerIndicadoresSerieService(values) {
		const resp = await ObtenerIndicadoresSerieService(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					if(!values.exportacion){
						setListadoIndicadoresSerie(resp.data);
					}else{
						downloadCSV(resp.data, location.state.sigla, EnumModuloDesc.DescargaDatamart);
					}

				}
			}
		}
	}
	useEffect(() => {
		if (location.state) {
			const startTime = new Date();
			const formEnvio = {
				idUsuario: usuario.id,
				indicadorSigla: location.state.sigla,
				exportacion: false
			}

		const fetchData = async () => {
			await Promise.all([
				postObtenerIndicadoresSerieService(formEnvio)
			]);
			const endTime = new Date();
			const tdrId = 16;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
		}

	}, [])
	function descargar(tipo) {
		const formEnvio = {
			idUsuario: usuario.id,
			indicadorSigla: location.state.sigla,
			exportacion: true
		}
		postObtenerIndicadoresSerieService(formEnvio);

	}
	return (
		<div className="container-fluid">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
							<li><Link to={EnumPathRutas.Datamart}> Datamart</Link></li>
							<li><a href="#"></a></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<Card className="p-4">
						<div className="d-flex justify-content-between align-items-center mb-5 gap-3">
							<span className='d-flex flex-column'>
								<h3 className="fs-3">{datosPage ? datosPage.siglaIndicador : ""}</h3>
								<span className="text-pewter-blue d-block">{datosPage ? datosPage.descripcionIndicador : ""}</span>
							</span>
							{listadoIndicadoresSerie.length > 0 &&
								<Exports descargar={(tipo) => descargar(tipo)} />
							}

						</div>
						<div className="row flex-wrap">
							{
								listadoIndicadoresSerie.map((item, i) => {
									return (
										<div key={i} className="col-12 col-md-4 col-lg-3 mb-4">
											<CardIndicador key={i} item={item} datosIndicador={datosPage} />
										</div>
									)
								})
							}
						</div>
					</Card>
				</div>
			</div>
		</div>
	)
}

export default DatamartIndicadoresPage;
