import { useMemo, useEffect, useContext } from 'react';
import Card from "../../../Components/Card/Card";
import DATA from '../Mock/mock_data_alertas.json';
import { AlertasContext, AlertasContextProvider } from '../Context/ContextAlertas';
import ModalEditarAlertas from '../Components/ModalEditarAlertas';
import EsquemaDeAlertas from '../Components/EsquemaDeAlertas';
import ModalConfirmacion from '../Components/ModalConfirmacion';
import { Link } from 'react-router-dom';
import { EnumPathRutas } from '../../../Utils/Enumeradores/ComunEnum';

const AlertasPage = () => {
	const { renderizarTabla, getListadoAlertasPrevias, listado } = useContext(AlertasContext);
	const data = useMemo(() => listado, [listado]);
	useEffect(() => {
		getListadoAlertasPrevias();
	}, [])
	useEffect(() => {
		getListadoAlertasPrevias();
	}, [renderizarTabla])
	return (

		<div className="container-fluid">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
							<li><Link to={EnumPathRutas.Configuracion}>Configuración</Link></li>
							<li><a href="#">Administración de alertas previas</a></li>

						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<Card className="p-4">
						<EsquemaDeAlertas
							data={data}
						/>
					</Card>
				</div>
			</div>
			<ModalEditarAlertas />
			<ModalConfirmacion />
		</div>
	)
}
export default AlertasPage;
