import React, { useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Avatar = ({ esMostrarNombreYperfil = true, esMostrarNombreCompletoYperfil= true, ...props }) => {
	const [showTooltip, setShowTooltip] = useState(false);
  const onEnter = () => {
    document.body.classList.add('overflow-hidden');
    setShowTooltip(true);
  };

	const onLeave = () => {
		document.body.classList.remove('overflow-hidden');
		setShowTooltip(false);
	};
	const renderTooltip = () => (
		<Tooltip id={`tooltip-top`}>
		{
			esMostrarNombreYperfil &&
			props.data.nombreCompleto + " " + props.data.apellidoPaterno + " " + props.data.apellidoMaterno
			||
			esMostrarNombreCompletoYperfil &&
			props.data.nombres + " " + props.data.apellidoPaterno + " " + props.data.apellidoMaterno
		}
		{
			<p></p>
		}
		{
				props.data.listaPerfiles !== "" ?
					props.data.listaPerfiles
					: null
		}
	</Tooltip>
  );

	return (
		<div className="user justify-content-center">
		<OverlayTrigger
			key='top'
			placement='top'
      onEnter={onEnter}
      onExit={onLeave}
			overlay={renderTooltip()}
		>
				<span className={`avatar rounded-circle border overflow-hidden ${props.data.rutaImagen === "" || props.data.rutaImagen === null ? 'avatarIniciales' : 'text-white'}`}>
					{props.data.rutaImagen === "" || props.data.rutaImagen === null ?
						props.data.nombreCompleto.charAt(0).toUpperCase() + props.data.apellidoPaterno.charAt(0).toUpperCase() :
						<img src={props.data.rutaImagen + "?t=" + new Date().getTime()} alt="" /> /* "?t="+ new Date().getTime() prevención de caché a la URL */
					}
				</span>
		</OverlayTrigger>
		</div>
	)
}

export default Avatar;
