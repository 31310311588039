import notfound from '../../../Assets/images/il-empty.svg';

const TablaNivelesCumplimientos = (props) => {
    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = props.tableInstance;

    return (
        <table className="table" {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps(),
                            )}>
                                <span className={`d-flex align-items-center gap-1 ${column.headerClassName}`}>
                                    {column.render('Header')}
                                    <>
                                        {column.isSorted ?
                                            (column.isSortedDesc ?
                                                <i className="ico-chevron-down"></i>
                                                :
                                                <i className="ico-chevron-up"></i>
                                            ) :
                                            !column.disableSortBy ?
                                                <>
                                                    <i className="ico-sort fs-7"></i>
                                                </>
                                                : null
                                        }
                                    </>
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {
										page.length > 0 && page.map( (row) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps({ className: cell.column.className })}>
                                        {cell.render('Cell')}
                                    </td>
                                })
                                }
                            </tr>
                        )
										}) || <tr><td colSpan={headerGroups[0].headers.length}>
							<div className="d-flex justify-content-center align-items-center flex-column gap-2 py-5">
								<img className="mb-3" src={notfound} alt=""/>
								<p className='mb-0 text-primary'>No se encuentran resultados de tu búsqueda.</p>
							</div>
						</td></tr>
						}
            </tbody>
        </table>
    )
}
export default TablaNivelesCumplimientos;
