import React, { useState, useEffect, useRef } from 'react'
import { Formik, Form } from "formik";
import InputTextField from "../../../Components/InputTextField/InputTextField"
import Button from "../../../Components/Button/Button"
import TextAreaField from "../../../Components/TextAreaField/TextAreaField"
import SelectCheckField from "../../../Components/SelectCheckField/SelectCheckField"
import { useComun } from '../../../Hooks/useComun';
import RangeDatePicker from '../../../Components/RangeDatePicker/RangeDatePicker';
import validations, { onBlurComentario, sinDobleEspacio, soloNumerico, sinExtension, TamanoArchivo } from '../../../Utils/Validations/Validations';
import { useToast } from '../../../Hooks/useToast';
import moment from "moment";
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { IngresoHallazgoService } from '../Services/IngresoHallazgoService';
import InputFileField from '../../../Components/InputFileField/InputFileField';
import { EnumMensajeErrorArchivos, EnumSiglaIndicador } from '../../../Utils/Enumeradores/ComunEnum';

const FormInspectorFiscal = (props) => {
	const addToast = useToast();
	const { indicadorId, datos, indicadorSeleccionadoSigla, esServicio, esLimpiarFormulario, setEsLimpiarFormularo, renderizar, ...rest } = props;
	const { listaProblemaHallazgo, getListaProblemaHallazgo, formatoBytes } = useComun();
	const { listaGravedadHallazgo, getListaGravedadHallazgo } = useComun();
	const [problemaSeleccionado, setProblemaSeleccionado] = useState(0);
	const { user, usuario } = useLogin();
	const formikRef = useRef();
	const [initialValues, setInitialValues] = useState({
		problema: '',
		periodo: '',
		gravedad: '',
		comentarioHallazgo: '',
		fileHallazgo: ''
	});

	useEffect(() => {
		getListaGravedadHallazgo();
	}, []);

	useEffect(() => {
		if (esLimpiarFormulario) {
			formikRef.current.resetForm();
			setProblemaSeleccionado(0);
			setEsLimpiarFormularo(false);
		}
	}, [esLimpiarFormulario]);

	useEffect(() => {

		if (indicadorId) {
			getListaProblemaHallazgo(indicadorId);
		}

	}, [indicadorId]);

	useEffect(() => {
		if (indicadorSeleccionadoSigla === EnumSiglaIndicador.DPW || indicadorSeleccionadoSigla === EnumSiglaIndicador.DCT) {
			setInitialValues({
				problema: '',
				periodo: '',
				gravedad: '',
				comentarioHallazgo: '',
				fileHallazgo: '',
				minutos: ''
			});
		} else if (indicadorSeleccionadoSigla) {
			setInitialValues({
				problema: '',
				periodo: '',
				gravedad: '',
				comentarioHallazgo: '',
				fileHallazgo: ''
			});
		}
	}, [indicadorSeleccionadoSigla]);

	function changeProblema(e) {
		setProblemaSeleccionado(e);
	}

	function handlerFiles(event) {
		const respuestaExtension = sinExtension(event.target.files[0]);
		const respuestaTamano = TamanoArchivo(event.target.files[0]);
		if (formikRef.current.values.fileHallazgo.length < 2) {
			if (respuestaExtension === "" && respuestaTamano === "") {
				let nuevaLista = formikRef.current.values.fileHallazgo !== '' ? formikRef.current.values.fileHallazgo : [];
				nuevaLista.push(...Array.prototype.slice.call(event.target.files));
				formikRef.current.setFieldValue("fileHallazgo", nuevaLista);
			}
			else {
				formikRef.current.setFieldError("fileHallazgo", respuestaExtension !== "" ? respuestaExtension : respuestaTamano);
			}
		} else if (formikRef.current.values.fileHallazgo.length === 2) {
			formikRef.current.setFieldError("fileHallazgo", EnumMensajeErrorArchivos.CantidadAdjunto);
		}
	}


	function eliminarFile(posicionFila) {
		const nuevaLista = formikRef.current.values.fileHallazgo.filter((x, index) => index !== posicionFila);
		formikRef.current.setFieldValue("fileHallazgo", nuevaLista);
	}

	async function postIngresoHallazgo(values) {
		const resp = await IngresoHallazgoService(values);
		if (resp) {
			if (resp.status === 200) {
				addToast({ mostrar: true, mensaje: 'Se ha ingresado el hallazgo correctamente.', titulo: 'Confirmación', tipo: 'Success' });
				rest.onHide();
				renderizar();
			}
		}
	}

	function IngresoHallazgo(values) {
		let formData = new FormData();
		formData.append('IndicadorId', indicadorId);
		formData.append('ProblemaId', problemaSeleccionado);
		formData.append('FechaDeteccion', moment(values.periodo[0]).utc().toISOString());
		formData.append('FechaLimiteCorreccion', moment(values.periodo[1]).utc().toISOString());
		formData.append('Gravedad', values.gravedad);
		formData.append('Observacion', values.comentarioHallazgo);
		formData.append('CantidadMinutos', values.hasOwnProperty('minutos') && (indicadorSeleccionadoSigla === EnumSiglaIndicador.DPW || indicadorSeleccionadoSigla === EnumSiglaIndicador.DCT) ? values.minutos : '');
		if (values.fileHallazgo !== '') {
			values.fileHallazgo.map((value, index) => {
				formData.append("ListaArchivos", value);
			});
		}
		formData.append('UsuarioId', user.id);
		formData.append('PerfilId', usuario.perfilContexto.id);
		formData.append('EsServicio', esServicio);

		postIngresoHallazgo(formData);
	}

	return (
		<Formik
			enableReinitialize
			innerRef={formikRef}
			initialValues={initialValues}
			validate={validations}
			onSubmit={values => {
				IngresoHallazgo(values);


			}
			}
		>{({ setFieldValue, setFieldTouched, values }) => (
			<Form className="form mb-0">
				<div className='simple-select'>
					<SelectCheckField
						name="problema"
						label="Problema"
						options={listaProblemaHallazgo}
						onBlur={setFieldTouched}
						onChange={(name, value) => { changeProblema(value); setFieldValue("problema", value) }}
						isMulti={false}
						fijo={false}
					/>
				</div>
				{
					values.hasOwnProperty('minutos') && (indicadorSeleccionadoSigla === EnumSiglaIndicador.DPW || indicadorSeleccionadoSigla === EnumSiglaIndicador.DCT) ?
						<InputTextField
							name="minutos"
							label="Cantidad Minutos"
							type="text"
							placeholder="Cantidad Minutos"
							autoComplete="false"
							maxLength="7"
							onInput={(e) => { soloNumerico(e); }}
						/>
						: null
				}
				<div className='d-flex justify-content-between flex-fill gap-3'>
					<RangeDatePicker
						startMaxDate={new Date()}
						startMinDate={null}
						endMaxDate={null}
						endMinDate={new Date()}
						name="periodo"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						labels={['Fecha Detección', 'Fecha Límite']}
					/>
				</div>
				<SelectCheckField
					name="gravedad"
					label='Gravedad'
					onChange={setFieldValue}
					onBlur={setFieldTouched}
					options={listaGravedadHallazgo}
					isMulti={false}
					fijo={false}
				/>
				<TextAreaField
					maxLength="200"
					name="comentarioHallazgo"
					placeholder="Escribe tu mensaje aquí…"
					label='Escribe tu mensaje aquí…'
					onBlur={(e) => { onBlurComentario(e); setFieldValue("comentarioHallazgo", e.target.value); }}
					onInput={(e) => { sinDobleEspacio(e) }}
				/>
				<div className='mb-2'>
					<small className='text-pewter-blue mb-2'>Quedan {200 - values.comentarioHallazgo.length} caracteres disponibles</small>
				</div>
				<div className="form-floating mb-3">
					<InputFileField placeholder="Subir Archivos" multiple name="fileHallazgo" onChange={(event) => handlerFiles(event)}
						onClick={(event) => { event.target.value = null }} />
				</div>

				{values.fileHallazgo !== '' && values.fileHallazgo.length > 0 &&
					<div className='align-self-start d-flex gap-3 flex-wrap'>
						{values.fileHallazgo.map((x, i) => {
							return (
								<div key={i} className='doc bg-white-smoke rounded p-2 '>
									<div className='doc-icon'>
										<i className="ico-doc-empty fs-3"></i>
									</div>
									<div className='doc-info'>
										<b className='doc-title'>{x.name}</b>
										<span className='doc-time'>{formatoBytes(x.size)}</span>
									</div>
									<i className="ico ico-close" onClick={() => eliminarFile(i)}></i>
								</div>
							);
						})}
					</div>
				}
				<div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4">
					<Button type="button" design="btn-outline-pewter-blue" onClick={() => rest.onHide()}>Cancelar</Button>
					<Button type="submit" design="btn-primary">Enviar</Button>
				</div>
			</Form>
		)}
		</Formik>
	)
}

export default FormInspectorFiscal;
