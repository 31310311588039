import React, { useEffect, useMemo, useState } from 'react';
import TableUser from "../Components/TablaUsuario";
import Button from "../../../Components/Button/Button";
import SearchField from "../../../Components/SearchField/SearchField";
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import { useColumns } from '../Utils/Columns';
import Exports from '../../../Components/Exports/Exports';
import SwitchInCell from "../Components/SwitchInCell"
import moment from 'moment';
import ModalUser from "../Components/ModalUsuario";
import { useUsuario } from '../../../Hooks/useUsuario';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { Link } from 'react-router-dom';
import PaginationComponent from '../../../Components/PaginationTableServer/PaginationTableServer';
import { EnumPathRutas, EnumTablaPaginacion } from '../../../Utils/Enumeradores/ComunEnum';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const Users = () => {
	const [exportar, setExportar] = useState({ exportacion: false });
	const { listadoUsuario, getUsuarios } = useUsuario();
	const { usuario, setUsuario } = useLogin();
	useEffect(() => {
		getUsuarios(exportar);
	}, [])
	const renderizarListadoUsuario = () => {
		getUsuarios(exportar);
	}

	const { COLUMNS } = useColumns(renderizarListadoUsuario);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const data = useMemo(() => listadoUsuario, [listadoUsuario]);
	const columns = useMemo(() => COLUMNS, []);
	const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: EnumTablaPaginacion.CantidadPaginacion } }, useGlobalFilter, useSortBy, usePagination);
	const { state, setGlobalFilter } = tableInstance;
	const { globalFilter } = state;
	const { crearRegistroTDR } = useRegistroTDR();
	const [modalIsCrear, setModalIsCrear] = useState(true);
	const [editUser, setEditUser] = useState({});
	const [{ pageIndex, pageSize, sortBy }, setPagination] =
        React.useState({
            pageIndex: 0,
            pageSize: EnumTablaPaginacion.CantidadPaginacion,
            sortBy: []
        });
	function handleShow(isCrear) {
		setShow(true);
		setModalIsCrear(isCrear);
	}

	function handlerModal(user) {
		const startTime = new Date();
		const fetchData = async () => {
			await Promise.all([
				setEditUser(user),
				handleShow(false),
			]);
			const endTime = new Date();
			const tdrId = 69;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
	}

	const descargar = (tipo) => {
		getUsuarios({ exportacion: true });
	};

	useEffect(() => {
		listadoUsuario.map(x => {
			if (x.id === usuario.id) {
				setUsuario({ ...usuario, rutaImagen: x.rutaImagen });
			}
		});
	}, [listadoUsuario]);
	return (

		<div className="container-fluid h-100">
			<div className="row h-100">
				<div className="col-12 mb-4">
					<div className="breadcrumbs">
						<ul>
							<li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
							<li><Link to={EnumPathRutas.Configuracion}>Configuración</Link></li>
							<li><a href="#">Administración de usuarios</a></li>
						</ul>
					</div>
				</div>
				<div className="col-12" style={{ height: 'calc(100% - 38px)' }}>
					<div className="bg-white rounded p-4 d-flex flex-column h-md-full">
						<div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-4 gap-3">
							<div className="tools order-1 order-lg-0 align-items-start w-100">
								<SearchField
									filter={globalFilter}
									setFilter={setGlobalFilter}
								/>
								<Button design="btn-primary px-4 ms-md-auto" onClick={() => handleShow(true)}>
									<i className="ico-user-add me-2"></i>
									<span>Nuevo Usuario</span>
								</Button>
							</div>
							<div className="exports order-0 order-lg-1">
								<Exports
									descargar={(tipo) => descargar(tipo)}
								/>
							</div>
						</div>
						<div className="table-responsive">
							<TableUser
								tableInstance={tableInstance}
								handlerModal={handlerModal}
							/>
						</div>
						<PaginationComponent
							pagesCountServer={tableInstance.pageCount}
							currentPage={tableInstance.state.pageIndex}
							setCurrentPage={tableInstance.gotoPage}
							alwaysShown={false}
							setPagination={setPagination}
						/>
					</div>
				</div>
			</div>
			<ModalUser
				show={show}
				onHide={handleClose}
				isCrear={modalIsCrear}
				editUser={editUser}
				renderizar={renderizarListadoUsuario}
			/>
		</div>
	)
}
export default Users;
