import Exports from "../../../Components/Exports/Exports";

export const COLUMNS = [
	{
		Header: "Nombre Operación",
		accessor: "nombre",
		classNameSpanTh: "text-start",
		classNameCell: "text-start",
		Width: "20%",
	},
	{
		Header: "Tipo Operación",
		accessor: "descripcion",
		classNameSpanTh: "text-center d-flex align-items-center gap-1 text-wrap",
		classNameCell: "text-center",
		Width: "10%",
	},
	{
		Header: "Fecha Registro",
		accessor: "fechaHoraCreacionString",
		classNameSpanTh: "text-center d-flex align-items-center gap-1 text-wrap",
		classNameCell: "text-center",
		Width: "20%",
	},
	{
		Header: "Valor máximo de tiempo acordado para la operación(seg)",
		accessor: "tiempoAcordado",
		classNameSpanTh: "text-center d-flex align-items-center gap-1 text-wrap",
		classNameCell: "text-center",
		disableSortBy: true,
		Width: "15%",
	},
	{
		Header: "Tiempo real requerido por la operación(Seg.)",
		accessor: "tiempoReal",
		classNameSpanTh: "text-center d-flex align-items-center gap-1 text-wrap",
		classNameCell: "text-center",
		disableSortBy: true,
		Width: "15%",
	},
	{
		Header: "Tiempo promedio requerido para operaciones del mismo tipo(Seg.)",
		accessor: "tiempoPromedio",
		classNameSpanTh: "text-center d-flex align-items-center gap-1 text-wrap",
		classNameCell: "text-center",
		disableSortBy: true,
		Width: "15%",
	},
	{
		Header: "Porcentaje de operaciones de cada tipo que superaron el tiempo máximo",
		accessor: "porcentajeSobreMaximo",
		classNameSpanTh: "text-center d-flex align-items-center gap-1 text-wrap",
		classNameCell: "text-center",
		disableSortBy: true,
		Width: "15%",
	},
];
