import { useCallback, useState } from 'react'
import { useComun } from '../../../Hooks/useComun';
import { EnumModuloDesc } from '../../../Utils/Enumeradores/ComunEnum';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import{ObtenerUsuarioRespaldoService} from '../Services/ObtenerUsuarioRespaldoService'
import{ObtenerUsuariosNoAsignadosRespaldoService} from '../Services/ObtenerUsuariosNoAsignadosRespaldoService'
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

export function useBackup(props) {

	const [listadoUsuario, setListadoUsuario] = useState([]);
	const [listadoUsuarioSeleccionableRespaldo, setListadoUsuarioSeleccionableRespaldo] = useState([]);
	const { downloadCSV } = useComun();
	const {usuario} = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();

	async function postObtenerUsuarioRespaldo(descargar) {
		const startTime = new Date();
		const resp = await ObtenerUsuarioRespaldoService(descargar, usuario.id);
		if (resp) {
			if (resp.status === 200) {
				if (!descargar.exportacion) {
					if (resp.data) {
						const fetchData = async () => {
							await Promise.all([
								setListadoUsuario(resp.data),
							]);
							const endTime = new Date();
							const tdrId = 84;
							crearRegistroTDR(startTime, endTime, usuario, tdrId);
						};

						fetchData();
					}
				} else {
					if (resp.data) {
						const fetchData = async () => {
							await Promise.all([
								downloadCSV(resp.data, "UsuariosReemplazo", EnumModuloDesc.DescargaAsignarBackup),
							]);
							const endTime = new Date();
							const tdrId = 85;
							crearRegistroTDR(startTime, endTime, usuario, tdrId);
						};

						fetchData();

					}
				}
			}
		}
	}
	async function postObtenerUsuariosNoAsignadosRespaldo(id) {

        const resp = await ObtenerUsuariosNoAsignadosRespaldoService(id);
        if (resp) {
            if (resp.status === 200) {
                if (resp.data) {
                    const listado = resp.data.map(item => { return { value: item.id, label: item.nombres + " " + item.apellidoPaterno + " " + item.apellidoMaterno } });
                    setListadoUsuarioSeleccionableRespaldo(listado);
                }
            }
        }
    }

	const getUsuariosBackup = useCallback(
		function (descargar) {
			postObtenerUsuarioRespaldo(descargar);
		},
		[]
	);
	const getListaUsuariosRespaldos = useCallback(
        function (id) {
            postObtenerUsuariosNoAsignadosRespaldo(id);
        },
        []
    );


	return { listadoUsuario,listadoUsuarioSeleccionableRespaldo, getUsuariosBackup,getListaUsuariosRespaldos,setListadoUsuarioSeleccionableRespaldo }
}
