import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const BuscadorAutorizacionesService = async (values) => {
    try {
        const resultado = await instance.Backend.post("/Autorizacion/BuscadorAutorizaciones"
        , values
        , customConfig);
        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { BuscadorAutorizacionesService };