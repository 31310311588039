import { useField } from "formik";

const InputFileField = ({ ...props }) => {
	const [field, meta] = useField(props);
	const {value, ...rest} = field; //Esto es para ignorar el value.. ya que se necesita el objeto del file
	return (
		<div className="form-floating">
			<div className={`file-upload form-control ${meta.touched && meta.error ? 'is-invalid' : null}`} data-text={(field.value) ? (field.value.name) ? field.value.name : props.placeholder : props.placeholder} >
				<input
					id={`field-${props.name}`}
					type="file"
					className={`file-upload-field ${meta.touched && meta.error ? 'is-invalid' : null}`}
					{...rest}
					{...props}
				/>
			</div>
			{meta.touched && meta.error ?
				<small className="message">{meta.error}</small> : null}
		</div>

	)
}
export default InputFileField;
