import { useEffect, useState } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import PaginationComponent from "../../../Components/PaginationTableServer/PaginationTableServer";
import useLogin from "../../Autenticacion/Hooks/useLogin";
import notfound from '../../../Assets/images/il-empty.svg';
import moment from "moment";


const TablaHallazgoIndicador = ({ columns, data, fetchData, pageCount: controlledPageCount, currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy, setPagination, handlerModal, datosIndicador, esDescargar, setEsDescargar, tab, periodo, peticionBuscar, setPeticionBuscar }) => {
	const {
		getTableProps
		, getTableBodyProps
		, headerGroups
		, prepareRow
		, page
		, pageCount
		, gotoPage
		, setSortBy
		, state: { pageIndex, pageSize, sortBy }
	} = useTable(
		{
			columns
			, data
			, initialState: { pageIndex: currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy }
			, manualPagination: true
			, pageCount: controlledPageCount
			, autoResetPage: false
			, autoResetSortBy: false
			, manualSortBy: true
		},
		useSortBy
		, usePagination
	)
	const { usuario } = useLogin();
	const [valoresFormularioEnvio, setValoresFormularioEnvio] = useState({
		paginacion: {
			numeroPagina: pageIndex
			, cantidadRegistros: pageSize
			, orderByAscending: false
			, ordenadoPor: ""
		},
		datosBusqueda: {
			idUsuario: usuario.id
			, nivelCumplimientoId: datosIndicador.nivelCumplimiento //location.state.indicador
			, exportacion: false
			, fechaInicio: null
			, fechaTermino: null
		}
	});

	useEffect(() => {
		if (peticionBuscar) {
			setPeticionBuscar(false);
			let valoresNuevos = valoresFormularioEnvio;
			valoresNuevos.datosBusqueda.fechaInicio = periodo ? moment(periodo[0]).format() : null
			valoresNuevos.datosBusqueda.fechaTermino = periodo ? moment(periodo[1]).format() : null
			setValoresFormularioEnvio(valoresNuevos);
			tab === "indicadores" ?
				setPagination({
					pageIndex: 0,
					pageSize: pageSize,
					sortBy: []
				})
				:
				setPagination({
					pageIndexHallazgo: 0,
					pageSizeHallazgo: pageSize,
					sortByHallazgo: []
				})

			setSortBy([]);
			gotoPage(0);
		} else {
			fetchData({ formulario: valoresFormularioEnvio, pageIndex, sortBy });
			/* GUARDAMOS EL SORTBY EN EL USESTATE DE PAGINATION QUE SE ENCUENTRA EN LA PAGE PARA QUE NO SE PIERDA EL ORDENAMIENTO */
			tab === "indicadores" ?
				setPagination(values => ({
					...values
					, sortBy: []
				}))
				:
				setPagination(values => ({
					...values
					, sortByHallazgo: []
				}))
		}

	}, [pageIndex, sortBy, periodo])

	function mostrarModal(e, row) {
		e.preventDefault();
		handlerModal(row);
	}
	useEffect(() => {
		if (esDescargar) {
			fetchData({ formulario: valoresFormularioEnvio, pageIndex: pageIndex, sortBy, exportacion: true });
			setEsDescargar(false);
		}
	}, [esDescargar])

	return (
		<>
			<div className="table-responsive">
				<table className="table" {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									data.length > 0 ?
										<th {...column.getHeaderProps(column.getSortByToggleProps(), { className: column.className })}
										>
											<span className="d-flex align-items-center gap-1">
												{column.render('Header')}
												<>
													{column.isSorted && data.length > 0 ?
														(column.isSortedDesc ?
															<i className="ico-chevron-down"></i>
															:
															<i className="ico-chevron-up"></i>
														) :
														<>
															<i className="ico-sort fs-7"></i>
														</>
													}
												</>
											</span>
										</th>
										:
										<th {...column.getHeaderProps()}
										>
											<span className="d-flex align-items-center gap-1">
												{column.render('Header')}
											</span>
										</th>
								))}
								{tab === "indicadores" ?
									<th className="text-center">Detalle</th>
									: <th className="text-center"></th>}

							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{
							page.length > 0 && page.map((row) => {
								prepareRow(row)
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps({
												className: cell.column.className
											})}>
												{cell.render('Cell')}
											</td>
										})}
										<td className="text-center">
											<button className="link text-pewter-blue fs-4"
												onClick={(e) => mostrarModal(e, row.original)}>
												{tab === "indicadores" ?
													<i className="ico-search"></i>
													:
													<i className="ico-chevron-right"></i>
												}
											</button>
										</td>
									</tr>
								)
							}) || <tr><td colSpan={headerGroups[0].headers.length + 1}>
								<div className="d-flex justify-content-center align-items-center flex-column gap-2 py-5">
									<img className="mb-3" src={notfound} alt="" />
									<p className='mb-0 text-primary'>No se encuentran resultados de tu búsqueda.</p>
								</div>
							</td></tr>
						}
					</tbody>
				</table>
			</div>
			{
				data.length > 0 &&
				<PaginationComponent
					pagesCountServer={pageCount}
					currentPage={pageIndex}
					setCurrentPage={gotoPage}
					alwaysShown={true}
					setPagination={setPagination}
				/>

			}
		</>

	)
}

export default TablaHallazgoIndicador;

