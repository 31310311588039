import instance, { customConfig } from "../Utils/Interceptors/Interceptor";

const CrearRegistroTDRService = async (values) => {
    try {
        return await instance.Backend.post("/Informe/CreaRegistroTdr", values, customConfig);

    } catch (error) {

        console.log(error);

    }

}
export { CrearRegistroTDRService };
