import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import SelectCheckField from '../../../Components/SelectCheckField/SelectCheckField';
import Button from '../../../Components/Button/Button';
import Select from 'react-select';
import FormAsesoriaFiscal from './FormAsesoriaFiscal';
import FormInspectorFiscal from './FormInspectorFiscal';
import { Formik, Form, } from "formik";
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { useComun } from '../../../Hooks/useComun';
import { useHallazgo } from '../Hooks/useHallazgo';
import validations from '../../../Utils/Validations/Validations';
import { useIndicadorHallazgo } from '../Hooks/useIndicadorHallazgo';

const ModalAgregarHallazgo = (props) => {
	const { renderizar, numeroContrato, ...rest} = props;
    const { listadoIndicadoresIngresoHallazgo, obtenerListaIndicadoresIngresoHallazgo } = useComun();
    const { getListadoExigencia } = useHallazgo();
	const { getListaIndicadoresHallazgo } = useIndicadorHallazgo();
	const [esServicio, setEsServicio] = useState(null);
	const [indicadorSeleccionado, setIndicadorSeleccionado] = useState(null);
	const [indicadorSeleccionadoSigla, setIndicadorSeleccionadoSigla] = useState('');
    const [listadoProblema, setListadoProblema] = useState([]);
    const [listadoElemento, setListadoElemento] = useState([]);
	const [esLimpiarFormulario, setEsLimpiarFormularo] = useState(false);
    const { user, usuario } = useLogin();
    const [data, setData] = React.useState([]);
    const [options, setOptions] = React.useState([]);

	const [initialValues, setInitialValues] = useState({
		indicador: ''
	});

    const [form, setForm] = useState(null);
    useEffect(() => {
        obtenerListaIndicadoresIngresoHallazgo();
		getListaIndicadoresHallazgo();
        }, [])

	useEffect(() => {
		setEsServicio(null);
		}, [rest.show])

	function changeUnidad(e) {
		setIndicadorSeleccionado(e.value);
		setEsServicio(e.esServicio);
		setIndicadorSeleccionadoSigla(e.sigla);
		setEsLimpiarFormularo(true);
	}

	return (
		<Modal
		{...rest}
		size="md"
		aria-labelledby="contained-modal-title-vcenter"
		centered
		className=''
		>
			<Modal.Header closeButton className='pt-4 px-4 gap-2'>
			<h5 className="modal-title">
				<span>Ingresar Hallazgo</span>
			</h5>
			</Modal.Header>
			<Modal.Body className='p-4 gap-4 d-flex flex-column'>
			<div className="form-floating">
				<span className='mb-2 d-block fw-bold text-pewter-blue'>Nº Contrato {numeroContrato}</span>
					<div className='simple-select'>
						<Select 
							options={listadoIndicadoresIngresoHallazgo} 
							onChange={(e) => changeUnidad(e)} 
							classNamePrefix="selector" 
							placeholder="Indicador" 
							noOptionsMessage={() => "Sin opciones"}
						/>
					</div>
			</div>
			{esServicio === true ?
			<FormInspectorFiscal
					indicadorId={indicadorSeleccionado}
					indicadorSeleccionadoSigla = {indicadorSeleccionadoSigla}
					esServicio = {esServicio}
					onHide={rest.onHide}
					renderizar={renderizar}
					esLimpiarFormulario={esLimpiarFormulario}
					setEsLimpiarFormularo={setEsLimpiarFormularo}
					/>
			:esServicio === false ? 
			<FormAsesoriaFiscal
				indicadorId={indicadorSeleccionado}
				indicadorSeleccionadoSigla = {indicadorSeleccionadoSigla}
				esServicio = {esServicio}
				onHide={rest.onHide}
				renderizar={renderizar}
				esLimpiarFormulario={esLimpiarFormulario}
				setEsLimpiarFormularo={setEsLimpiarFormularo}
				setEsServicio={setEsServicio}
				/> :
				null
			}
			</Modal.Body>
		</Modal>
	)
}

export default ModalAgregarHallazgo;
