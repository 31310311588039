import { Link } from "react-router-dom";

const Card = ({title,link,className,children}) => {
	return (
		<div className={`card h-100 ${className}`}>
			{title ?
				<div className="card__header mb-3">
					<h2>{title}</h2>
					{link ?
						<Link to={`/${link}`} className="btn btn--outline btn--icon btn--pewter-blue">
							<i className="ico-chevron-right"></i>
						</Link> :
						null
					}
				</div>
				: null
			}
			{link && !title ?
				<Link to={`/${link}`} className="btn btn--outline btn--icon btn--pewter-blue align-self-end">
					<i className="ico-chevron-right"></i>
				</Link>
			: null }
			<div className="card__body">
				{children}
			</div>
		</div>
	)
}

export default Card;
