import instance, { customConfig }  from "../../../Utils/Interceptors/Interceptor";


async function ObtenerUsuariosNoAsignadosRespaldoService(id){

    try {

        const resultado = await instance.Backend.post("/Usuario/ObtenerUsuariosNoAsignadosRespaldo",{
            idUsuario:id
        }, customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export {ObtenerUsuariosNoAsignadosRespaldoService};