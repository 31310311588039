import { useState, useEffect } from "react";
import ModalConfirmacion from './ModalConfirmacion';
const EstadoEnCelda = (props) => {
    const {renderizar, ...rest} = props;
    const [estado, setStatus] = useState(props.alarma.estado === "Activo" ? true : false);  
	const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    
	const btnCambiarEstado = async () =>{
	 	setShow(true);	
	}
    useEffect(() => {
		setStatus(props.alarma.estado === "Activo" ? true : false) 
	}, [props]);  

    return (
        <>

            <div className="form-check form-switch d-flex justify-content-center align-items-center p-0">
                <input
                    className="form-check-input mx-0"
                    type="checkbox"
                    role="switch"
                    id={`auth-${props.alarma.alarmaListaId}`}
                    checked={estado}
                    onChange={() => btnCambiarEstado()}
                />
                
            <ModalConfirmacion
				show={show}			
                alarmaListaId={props.alarma.alarmaListaId}
				onHide={handleClose}
				estado={estado}
				renderizar={renderizar}
			/>
            </div>
        </>
    )
}

export default EstadoEnCelda;
