import Exports from "../../../Components/Exports/Exports";

export function useColumnasNivelCumplimiento(props) {
	const COLUMNS = [
		{
			Header: "Período de tiempo",
			accessor: "descripcion",
			className: "text-start",
		},
		{
			Header: "Descargar informe",
			id: "exports",
			headerClassName: "justify-content-end",
			className: "text-end",
			disabledFilters: true,
			disableSortBy: true,
			accessor: (row) => row,
			Cell: ({ row }) => (
				<div className="exports order-0 order-lg-1">
					<Exports
						mostrarPdf={true}
						descargar={(tipo) => props.mostrarModal(tipo, row.original)}
					/>
				</div>
			),
		},
	];
	return { COLUMNS };
}
