import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ActualizaObjetarEvento = async (values) => {
    try {
        const resultado = await instance.Backend.post("/Bitacora/ActualizaObjetarEvento",values, customConfig);

        return resultado

    } catch (error) {
        
        console.log(error);

    }

}
export { ActualizaObjetarEvento };