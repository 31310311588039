import { useField } from "formik";

const TextAreaField = ({label, type, placeholder, autoComplete, ...props}) => {
	const [field, meta] = useField(props);
	return (
		<div className="form-floating">
			<textarea
				className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}` }
				id={`field-${props.name}`}
				placeholder={placeholder}
				autoComplete={autoComplete}
				{...field}
				{...props}
				/>
			<label htmlFor={`field-${props.name}`}>{label}</label>
			{meta.touched && meta.error ?
				<small className="message">{meta.error}</small> : null}
		</div>
	)
}

export default TextAreaField;
