import React, {  } from 'react';
import Modal from 'react-bootstrap/Modal';

const ModalBimDetalle = (props) => {
	const {respdatadetalle, ...rest} = props;
	return (
		<Modal
			{...props}
			size="md"
			centered
			backdropClassName="backdrop-dark"
			className='modal--bg-gray'
		>
			<Modal.Header closeButton>
				<h5 className="modal-title">Detalle</h5>
			</Modal.Header>
			<Modal.Body className='pt-3'>
				<div className='bg-white rounded p-3'>
					{respdatadetalle.map((x, i) => 
						<ul key={i} className='list list--def'>
							<li className='list__item'>
								<span>Id Activo</span>
								<b>{x.tipoActivoId}</b>
							</li>
							<li className='list__item'>
								<span>Descripción Activo</span>
								<b>{x.descripcionTipo}</b>
							</li>
							<li className='list__item'>
								<span>Id Eje</span>
								<b>{x.codigoEje}</b>
							</li>
							<li className='list__item'>
								<span>Descripción Macroubicación</span>
								<b>{x.descripcionMacroubicacion}</b>
							</li>
							<li className='list__item'>
								<span>Id BIM</span>
								<b>{x.idBIM}</b>
							</li>
							<li className='list__item'>
								<span>Id SICE</span>
								<b>{x.idSICE}</b>
							</li>
							<li className='list__item'>
								<span>Descripción</span>
								<b>{x.descripcionElemento}</b>
							</li>
							<li className='list__item'>
								<span>Km inicial</span>
								<b>{x.kmInicial}</b>
							</li>
							<li className='list__item'>
								<span>Km Final</span>
								<b>{x.kmFinal}</b>
							</li>
							<li className='list__item'>
								<span>Calzada</span>
								<b>{x.calzada}</b>
							</li>
							<li className='list__item'>
								<span>Id Lado Elemento</span>
								<b>{x.elementoId}</b>
							</li>
							<li className='list__item'>
								<span>Sector</span>
								<b>{x.sector}</b>
							</li>
						</ul>
					)}
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default ModalBimDetalle;
