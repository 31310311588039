import instance, { customConfig }  from "../../../Utils/Interceptors/Interceptor";

const ObtenerUsuarioRespaldoService = async (exportacion, idUsuario) => {
    try {

        const resultado = await instance.Backend.post("/Usuario/ObtenerUsuariosRespaldos",
        {exportacion: exportacion.exportacion,
            idUsuario: idUsuario
        }, customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export {ObtenerUsuarioRespaldoService};
