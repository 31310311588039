import Modal from 'react-bootstrap/Modal';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Fragment, useEffect, useState } from 'react';
import { SeguimientoHallazgoService } from '../Services/SeguimientoHallazgoService';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import moment from 'moment';
import { EnumEstadoHallazgo } from '../../../Utils/Enumeradores/ComunEnum';

const ModalEstadoHallazgo = (props) => {
	const { datosModal, ...rest } = props;
	const { usuario } = useLogin();
	const [dataSeguimiento, setDataSeguimiento] = useState([]);
	async function postSeguimientoHallazgo(values) {
		const resp = await SeguimientoHallazgoService(values);
		if (resp) {
			if (resp.status === 200) {
				setDataSeguimiento(resp.data.seguimiento);
			}
		}
	}
	useEffect(() => {
		if (props.show) {
			const formEnvio = {
				idUsuario: usuario.id,
				idPerfilContexto: usuario.perfilContexto.id,
				hallazgoId: datosModal.hallazgoId //7294
			}
			postSeguimientoHallazgo(formEnvio);
		}

	}, [props.show])
	return (
		<Modal
			{...rest}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className='modal--bg-gray'
		>
			<Modal.Header
				closeButton
				className='p-4 px-md-5 pt-md-5'
			>
				<h5 className="modal-title">Seguimiento</h5>
			</Modal.Header>
			<Modal.Body
				className='p-4 px-md-5 pb-md-5'
			>
				<Swiper
					modules={[Navigation, Pagination, Scrollbar, A11y]}
					slidesPerView={1}
					scrollbar={{ draggable: true }}
					pagination={{ clickable: true }}
				>
					{dataSeguimiento.map((resp, index) => {
						return (
							<SwiperSlide key={index}>
								<div className="steps">
									{resp.map((item, i) => {
										return (
											<div key={i} className="steps__item is-ready">
												<div className="steps__indicator">
													{item.etapaActual ?
														<span className="steps__icon">
															<i className="ico-check"></i>
														</span>
														:
														<span className="steps__icon">{item.etapa}</span>
													}


												</div>
												<div className="steps__summary">
													<span>{item.etapaDescripcion}</span>
													{item.fechaEtapa !== null &&
														<span><b>{moment(item.fechaEtapa).format('DD-MM-YYYY')}</b> {moment(item.fechaEtapa).format('HH:mm')}</span>
													}
													{item.estadoEtapa.map((itemEstado, indexEstado) =>
														<Fragment key={indexEstado + '_estado'}>
															<span>
																<span className={`badge badge--monochromatic
															${itemEstado.estado === EnumEstadoHallazgo.Nuevo ? 'bg-secondary' : ''}
															${itemEstado.estado === EnumEstadoHallazgo.EnProceso ? 'bg-primary' : ''}
															${itemEstado.estado === EnumEstadoHallazgo.Rechazado ? 'bg-danger' : ''}
															${itemEstado.estado === EnumEstadoHallazgo.Aprobado ? 'bg-success' : ''}
															${itemEstado.estado === EnumEstadoHallazgo.Preingreso ? 'bg-primary' : ''}
															${itemEstado.estado === EnumEstadoHallazgo.Cerrado ? 'bg-disabled' : ''}
															${itemEstado.estado === EnumEstadoHallazgo.Resuelto ? 'bg-success' : ''}
															${itemEstado.estado === EnumEstadoHallazgo.Objetado ? 'bg-danger' : ''}
															${itemEstado.estado === EnumEstadoHallazgo.Validacion ? 'bg-primary' : ''}
														`}>{itemEstado.estadoDescripcion}</span>
															</span>
															{itemEstado.fechaEstado !== null &&
																<span><b>{moment(itemEstado.fechaEstado).format('DD-MM-YYYY')}</b> {moment(itemEstado.fechaEstado).format('HH:mm')}</span>
															}

														</Fragment>
													)}

												</div>
											</div>
										)
									})}

								</div>
							</SwiperSlide>
						)
					})}
					{}
				</Swiper>
			</Modal.Body>
		</Modal >
	)
}
export default ModalEstadoHallazgo;
