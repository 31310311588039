import { useMemo, useState, useEffect } from 'react';
import Exports from "../../../Components/Exports/Exports";
import SearchField from "../../../Components/SearchField/SearchField";
import Button from "../../../Components/Button/Button";
import DATA from '../Mock/mock_data_distribution_list.json';
import { COLUMNS } from '../Utils/Columns';
import { useColumns } from '../Utils/Columns';
import {useTable, useSortBy, usePagination, useGlobalFilter, useFilters} from 'react-table';
import TablaListaDeDistribucion from '../Components/TablaListaDeDistribucion';
import ModalAgregarListaDeDistribucion from '../Components/ModalAgregarListaDeDistribucion';
import { useAlarmaLista } from '../Hooks/UseAlarmaLista';
import {EnumTablaPaginacion} from '../../../Utils/Enumeradores/ComunEnum'
const ListaDeDistribucionPage = () => {
	const { getListadoAlarmaLista, listadoAlarmasLista } = useAlarmaLista();

	useEffect(() => {
		getListadoAlarmaLista({ exportacion: false });
	}, [])

	const renderizarAlarmaLista = () => {
		getListadoAlarmaLista({ exportacion: false });
	};
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const { COLUMNS } = useColumns(renderizarAlarmaLista);
	const columns = useMemo(()=> COLUMNS, []);
	const data = useMemo(() => listadoAlarmasLista?.listaAlarmaDistribucion, [listadoAlarmasLista?.listaAlarmaDistribucion]);
	const tableInstance = useTable(
		{
			columns,
			data,
			initialState: {pageIndex: 0, pageSize: EnumTablaPaginacion.CantidadPaginacion},
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	return <>
		<TablaListaDeDistribucion
			tableInstance={tableInstance}
			renderizar = {renderizarAlarmaLista}
			getListadoAlarmaLista = {getListadoAlarmaLista}
			listadoAlarmasLista = {listadoAlarmasLista}
		/>
	</>
}
export default ListaDeDistribucionPage;
