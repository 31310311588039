export const COLUMNS = [
	{
		Header: 'Id',
		accessor: 'bitacoraDetalleId',
	},
	{
		Header: 'Usuario',
		accessor: 'nombreCompleto',
		Cell: ({ row }) =>
			<div className="user">
					<span className={`avatar rounded-circle border overflow-hidden ${row.original.rutaImagen === "" || row.original.rutaImagen === null ? 'avatarIniciales' : 'text-white'}`}>
						{row.original.rutaImagen === "" || row.original.rutaImagen === null ?
							row.original.nombreCompleto.charAt(0).toUpperCase() + row.original.apellidoPaterno.charAt(0).toUpperCase() :
							<img src={row.original.rutaImagen} alt="" />
						}
					</span>
					<div className="user__ide">
						<span className="text-nowrap">{row.original.nombreCompleto}</span>
							{row.original.listaPerfiles !== "" ?
							<small className="text-pewter-blue">{row.original.listaPerfiles}</small> : null}
					</div>
				</div>

	},
	{
		Header: 'Fecha Conexión',
		accessor: 'fechaInicio',
	},
];
