import notfound from '../../../Assets/images/il-empty.svg';
import React, { useEffect, useState } from 'react';
import { usePagination, useSortBy, useTable, useGlobalFilter, useFilters } from 'react-table';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import Button from "../../../Components/Button/Button";
import Exports from '../../../Components/Exports/Exports';
import {EnumTablaPaginacion} from '../../../Utils/Enumeradores/ComunEnum'
import moment from 'moment';
import RangeDatePicker from '../../../Components/RangeDatePicker/RangeDatePicker';
import { Form, Formik } from 'formik';
import SearchField from '../../../Components/SearchField/SearchField';
import PaginationComponent from '../../../Components/PaginationTableServer/PaginationTableServer';
import validations from '../../../Utils/Validations/Validations';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const BitacoraBimTable = ({ columns, data, fetchData, pageCount: controlledPageCount, currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy, setPagination, handlerModal }) => {
	const {
		getTableProps
		, getTableBodyProps
		, headerGroups
		, prepareRow
		, page
		, pageCount
		, gotoPage
		, setSortBy
		, state: { pageIndex, pageSize, sortBy }
	} = useTable(
		{
			columns
			, data
			, initialState: { pageIndex: currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy }
			, manualPagination: true
			, pageCount: controlledPageCount
			, autoResetPage: false
			, autoResetSortBy: false
			, manualSortBy: true
		},
		useSortBy,
		usePagination
	);

	const { usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const [initialValues, setInitialValues] = useState({
		periodo2: [moment().subtract(1, 'M').toDate(), new Date()]
	});
	const [valoresFormularioEnvio, setValoresFormularioEnvio] = useState({
		paginacion: {
			numeroPagina: pageIndex
			, cantidadRegistros: pageSize
			, orderByAscending: false
			, ordenadoPor: "idBIM"
		},
		datosBusqueda: {
			idUsuario: usuario.id
			, fechaInicio: moment().subtract(1, 'M').format()
			, fechaFin: moment().format()
			, exportacion: false
		}
	});
	useEffect(() => {
		fetchData({ formulario: valoresFormularioEnvio, pageIndex, sortBy });
		/* GUARDAMOS EL SORTBY EN EL USESTATE DE PAGINATION QUE SE ENCUENTRA EN ALARMASPAGE PARA QUE NO SE PIERDA EL ORDENAMIENTO */
		setPagination(values => ({
			...values
			, sortBy: sortBy
		}));
	}, [pageIndex, sortBy])

	const descargar = () => {
		fetchData({ formulario: valoresFormularioEnvio, pageIndex: pageIndex, sortBy, exportacion: true });
	};

	function BuscarActividadBim(values) {
		const startTime = new Date();
		let formEnvio = valoresFormularioEnvio;
		/* Se setean valores del paginado para la primera busqueda (resetea la tabla) */
		formEnvio.paginacion.numeroPagina = 0;
		formEnvio.paginacion.orderByAscending = false;
		formEnvio.paginacion.ordenadoPor = "fechaCreacion";
		/* Se setean los valores de los campos que ingresa el usuario */
		formEnvio.datosBusqueda.fechaInicio = values.periodo2[0];
		formEnvio.datosBusqueda.fechaFin = values.periodo2[1];
		const fetchData = async () => {
			await Promise.all([
				setValoresFormularioEnvio(formEnvio)
			]);
			const endTime = new Date();
			const tdrId = 29;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
		setPagination({
			pageIndex: formEnvio.paginacion.numeroPagina,
			pageSize: formEnvio.paginacion.cantidadRegistros,
			sortBy: []
		});
		setSortBy([]);
		gotoPage(0);
	};

	const mostrarModal = (e, row) => {
		e.preventDefault();
		handlerModal(row);
	}
	return (
		<div>
			<div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-5 gap-3">
								<Formik
									enableReinitialize
									initialValues={initialValues}
									validate={validations}
									onSubmit={(values) => {
										BuscarActividadBim(values);
									}}
								>
									{({ values, setFieldValue, setFieldTouched, }) => (
										<Form className="form mb-0">
											<div className="tools order-1 order-lg-0 align-items-start">
											<RangeDatePicker
												startMaxDate={new Date()}
												startMinDate={null}
												endMaxDate={new Date()}
												endMinDate={null}
												name="periodo2"
												onChange={setFieldValue}
												onBlur={setFieldTouched}
											/>
												<Button type="submit" design="btn-primary">Buscar</Button>
											</div>
										</Form>
									)}
								</Formik>
								{
								data.length > 0 &&
									<div className="exports order-0 order-lg-1">
									<Exports descargar={() => descargar()} />
									</div>
								}
							</div>
			<div className="table-responsive brd-top">
				<table className="table" {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									data.length > 0 ?
									<th {...column.getHeaderProps(column.getSortByToggleProps(), { className: column.className })}>
										<span className="d-flex align-items-center gap-1">
											{column.render('Header')}
											<>
												{column.isSorted ?
													(column.isSortedDesc  && data.length > 0 ?
														<i className="ico-chevron-down"></i>
														:
														<i className="ico-chevron-up"></i>
													) :
													<>
														<i className="ico-sort fs-7"></i>
													</>
												}
											</>
										</span>
									</th>
									:
									<th {...column.getHeaderProps()}
									>
										<span className="d-flex align-items-center gap-1">
											{column.render('Header')}
										</span>
									</th>
								))}
								<th className="text-center">Detalle</th>
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{
							page.length > 0 && page.map( (row) => {
								prepareRow(row)
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps({
												className: cell.column.className
											})}>
												{cell.render('Cell')}
											</td>
										})}
										<td className="text-center">
											<button className="link text-pewter-blue fs-4" onClick={(e) => mostrarModal(e, row.original)}>
												<i className="ico-search"></i>
											</button>
										</td>
									</tr>
									)
							}) || <tr><td colSpan={headerGroups[0].headers.length + 1}>
							<div className="d-flex justify-content-center align-items-center flex-column gap-2 py-5">
								<img className="mb-3" src={notfound} alt=""/>
								<p className='mb-0 text-primary'>No se encuentran resultados de tu búsqueda.</p>
							</div>
						</td></tr>
						}
					</tbody>
				</table>
			</div>
			{
				data.length > 0 &&
				<PaginationComponent
					pagesCountServer={pageCount}
					currentPage={pageIndex}
					setCurrentPage={gotoPage}
					alwaysShown={false}
					setPagination={setPagination}
				/>
			}
		</div>
	)
}

export default BitacoraBimTable;

