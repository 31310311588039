
import React from 'react';
import {Navigate, Outlet, useParams} from 'react-router-dom'
import { esBase64 } from '../../../Utils/Validations/Validations';

const  ValidateUrlBase64=(props) =>{
    const params = useParams();
    const errors = [];
    const values = Object.values(params);

    values.forEach(resp =>{
        if(!esBase64(resp)){
            errors.push(true);
        }
    });
  return errors.length === 0 ?<Outlet/> : <Navigate to="/"/> 
}

export default ValidateUrlBase64;