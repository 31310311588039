import instance from "../../../Utils/Interceptors/Interceptor";


async function ActualizarPonderacionService(values, idUsuario) {

    try {
        return await instance.Backend.post("/Indicador/ActualizaPonderacion", {
            esNivelServicio: values.esNivelServicio,
            id: values.id,
            ponderacion: values.ponderacion,
            idUsuario: idUsuario
        });

    } catch (error) {
        console.log(error);
    }

}
export { ActualizarPonderacionService };