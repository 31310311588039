import { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import useLogin from '../../Features/Autenticacion/Hooks/useLogin';
import validations from '../../Utils/Validations/Validations';
import Button from '../Button/Button';
import { Formik, Form, } from "formik";
import { useNavigate } from "react-router-dom";
import SelectCheckField from '../SelectCheckField/SelectCheckField';
import { EnumPathRutas, EnumPerfil } from '../../Utils/Enumeradores/ComunEnum';

const ModalSelectPerfil = (props) => {
	const { user, getUsuario, respaldo, RegistroRemplazo} = useLogin();
	const formikref = useRef();
	const navigate = useNavigate();
	const [UserSeleccion, setUserSeleccionado] = useState([]);
	const listUsers =  [{ value: user ? user.id: '', label: user ? user.nombres + ' ' + user.apellidoPaterno + ' ' + user.apellidoMaterno: ''}]
	const listPerfil = user ? user.listaPerfil.map(x => { return { value: x.id, label: x.descripcion } }) : [];
	const listUsersRespaldo = user ? user.listaRespaldos ? user.listaRespaldos.map(x => { return {value: x.id, label: x.nombres  + ' ' + x.apellidoPaterno + ' ' + x.apellidoMaterno, id: x.id} }) : [] : [];
	const [listRespaldoPerfil, setListaRespaldoPerfil]= useState(user ? user.listaRespaldos  ? user.listaRespaldos.map(x => x.listaPerfil.map(x => { return {value: x.id, label: x.descripcion}})) : [] : []);
	const backup = user ? user.listaRespaldos  ? user.listaRespaldos.length === 0 ? false : true : false : false;

	const UserSeleccionado = (id) => {
		setUserSeleccionado(id);
		if (user.id === id){
			setListaRespaldoPerfil(user.listaPerfil.map(x => { return { value: x.id, label: x.descripcion}}))
		}
		else {
			setListaRespaldoPerfil(user.listaRespaldos.find(x => x.id === id).listaPerfil.map(x => { return { value: x.id, label: x.descripcion}}))
		}
	}

	const listaconrespaldo = listUsers.concat(listUsersRespaldo);

	const setDefault = () => {
		formikref.current.setFieldValue("profile", '');
	}

	return (
		<Modal
			{...props}
			size="sm"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			backdrop="static"
			backdropClassName="backdrop-dark"
		>
			<Modal.Header>
				<h5 className="modal-title">
					<span>Perfil de usuario</span>
				</h5>
			</Modal.Header>
			<Modal.Body>
				<Formik
					enableReinitialize
					initialValues={{ user: user ? user.nombres: '', profile: ''}}
					validate={validations}
					onSubmit={values => {
						if(backup === true){
							if(user.id === values.user){
								RegistroRemplazo(false, 0);
							}
							else{
								RegistroRemplazo(true, values.user);
							}
							getUsuario(user.id, values.profile, () => {
								if (values.profile===EnumPerfil.Resolutor || values.profile===EnumPerfil.AsesoriaIF){
									navigate(EnumPathRutas.Hallazgos, { replace: true });
								}else{
								navigate(EnumPathRutas.Inicio, { replace: true });
							}
						});
					}
					else{
						RegistroRemplazo(false, 0);
						getUsuario(user.id, values.profile, () => {
							if (values.profile===EnumPerfil.Resolutor || values.profile===EnumPerfil.AsesoriaIF){
								navigate(EnumPathRutas.Hallazgos, { replace: true });
							}else{
								navigate(EnumPathRutas.Inicio, { replace: true });
							}
						});
					}

				}}
				innerRef = {formikref}
				>{({ setFieldValue, setFieldTouched
				}) => (
					<Form className="form mb-0">
						{}
						{backup === true ?
							<div className=' mb-3'>
							<SelectCheckField
								helpers="min-w-inherit"
								name="user"
								label="Usuario"
								options={listaconrespaldo}
								onChange={(x,y) => {setDefault(); setFieldValue(x, y);UserSeleccionado(y);}}
								onBlur={setFieldTouched}
								isMulti={false}
							/>
							</div>
						: null
						}
						{backup === true ?
						<SelectCheckField
							helpers="min-w-inherit"
							name="profile" label="Perfil"
							options={listRespaldoPerfil}
							onChange={setFieldValue}
							onBlur={setFieldTouched}
							isMulti={false}
						/>
					: <SelectCheckField
						helpers="min-w-inherit"
						name="profile" label="Perfil"
						options={listPerfil}
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						isMulti={false}
					/>}

						<div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4 mt-3">
							<Button type="submit" design="btn-primary w-100">Aceptar</Button>
						</div>
					</Form>
				)}
				</Formik>
			</Modal.Body>
		</Modal>
	)
}
export default ModalSelectPerfil;
