import { useEffect, useMemo, useState } from 'react';
import Card from '../../../Components/Card/Card';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TablaAlarmas from '../Components/TablaAlarmas';
import { useColumns } from '../Utils/Columns';
import { useTable, useSortBy, usePagination, useGlobalFilter, useFilters } from 'react-table';
import ListaDeDistribucionPage from '../../ListaDistribucion/Pages/ListaDeDistribucionPage';
import { useAlarma } from '../Hooks/useAlarmas';
import { EnumPathRutas, EnumTablaPaginacion } from '../../../Utils/Enumeradores/ComunEnum'
import { Link } from 'react-router-dom';
import { useComun } from '../../../Hooks/useComun';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { EnumModuloDesc } from "../../../Utils/Enumeradores/ComunEnum";

const AdministracionAlarmasPage = () => {
	const { getListadoAlarmasPerfil, listadoAlarmasPerfil } = useAlarma();
	const { usuario, bitacoraDetalle, registraBitacoraMovimientoUsuario } = useLogin();

	useEffect(() => {
		getListadoAlarmasPerfil({ exportacion: false });
	}, [])

	const renderizarAlarmaPerfil = () => {
		getListadoAlarmasPerfil({ exportacion: false });
	};

	const [key, setKey] = useState('asociacion');
	const { COLUMNS } = useColumns(renderizarAlarmaPerfil);
	const columns = useMemo(() => COLUMNS, []);

	useEffect(() => {
		if (key === 'asociacion') {
			registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoAdministracionAlarmasPerfil, idBitacoraDetalle: bitacoraDetalle},()=>{});
		}else if(key === 'distribucion') {
			registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoAdministracionAlarmasListas, idBitacoraDetalle: bitacoraDetalle},()=>{});
		}
	}, [key]);

	const data = useMemo(() => listadoAlarmasPerfil, [listadoAlarmasPerfil]);

	const tableInstance = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: EnumTablaPaginacion.CantidadPaginacion },
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	return (
		<div className="container-fluid">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
							<li><Link to={EnumPathRutas.Configuracion}>Configuración</Link></li>
							<li><a href="#">Administración de alarmas</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<Card className="p-4">
						<Tabs
							defaultActiveKey="asociacion"
							activeKey={key}
							transition={false}
							id="alarms"
							className="mb-3"
							onSelect={(k) => setKey(k)}
						>
							<Tab eventKey="asociacion" title="Asociar alarmas">
								{key === 'asociacion' &&
									<TablaAlarmas
										tableInstance={tableInstance}
										renderizar={renderizarAlarmaPerfil}
									/>}
							</Tab>
							<Tab eventKey="distribucion" title="Lista de distribución">
								{key === 'distribucion' &&
									<ListaDeDistribucionPage />
								}

							</Tab>
						</Tabs>
					</Card>
				</div>
			</div>
		</div>
	)
}
export default AdministracionAlarmasPage;
