import moment from "moment";
import EstadoEnCelda from "../Components/EstadoEnCelda";

export function useColumns(props){
	const COLUMNS = [
	{
		Header: 'Lista de distribución',
		accessor: 'email',
	},
	{
		Header: 'Área de negocio',
		accessor: 'areaNegocio',
	},
	{
		Header: 'Tipo de alarma',
		accessor: 'tipoAlarma',
	},
	{
		Header: 'Fecha de creación',
		accessor: 'fechaCreacion', className: 'text-center',
		Cell: ({ row }) =>{return moment(row.original.fechaCreacion).format('DD-MM-YYYY')}
	},
	{
		Header: 'Autor',
		accessor: 'nombresAutor',
		id: 'author',
		Cell: ({ row }) =>
			<div className="user">
				<span className={`avatar rounded-circle border overflow-hidden ${row.original.urlAvatarAutor === "" || row.original.urlAvatarAutor === null ? 'avatarIniciales' : 'text-white'}`}>
					{row.original.urlAvatarAutor === "" || row.original.urlAvatarAutor === null ?
						row.original.nombresAutor.charAt(0).toUpperCase() + row.original.apellidoPaternoAutor.charAt(0).toUpperCase() :
						<img src={row.original.urlAvatarAutor} alt="" />
					}
				</span>
				<div className="user__ide">
					<span className="text-nowrap">{row.original.nombresAutor + ' ' + row.original.apellidoPaternoAutor + ' ' + row.original.apellidoMaternoAutor}</span>
				</div>				
			</div> 
	},
	{
		Header: 'Estado',
		accessor: 'state',
		className: 'text-center',
		disabledFilters: true,
		Cell: (row) => {
			return <EstadoEnCelda alarma={row.row.original} renderizar={props} />
		},
	},
];
return { COLUMNS };
}