import instance, { customConfig } from "../Utils/Interceptors/Interceptor";

const ParametroObtenerPorIdService = async (value) => {
    try {
        return await instance.Backend.post("/Parametro/ObtenerPorId",{
            parametroId:value
        }, customConfig);
    } catch (error) {
        console.log(error);
    }
}
export { ParametroObtenerPorIdService };