const ExigenciaCelda = ({...props}) => {
	const {descripcion, sigla} = props.data
	return (
		<div className="d-flex align-items-start gap-2">
			{sigla !== null ? <b>{sigla}</b> : ''}
			<span>{descripcion}</span>
		</div>
	)
}
export default ExigenciaCelda;
