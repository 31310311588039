import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ContadorMedicionesManualesServices = async (idUsuario) => {
    try {
        return await instance.Backend.post("/Autorizacion/ContadorMedicionesManuales",
        {
            IdUsuario: idUsuario
        },
         customConfig
        );
    } catch (error) {
        console.log(error);
    }
}
export { ContadorMedicionesManualesServices };