import React from 'react';
import ReactApexChart from "react-apexcharts";

const ChartsColumnsBars = (props) => {
	const devices = () => { return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent); }
	const series = props.dataChart.series;
	const options = {
		chart: {
			type: 'bar',
			toolbar: {
				show: false,
			}
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '50%',
				endingShape: 'rounded',
				groupPadding: 0,
				pointPadding: 0,
			},
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		},
		xaxis: {
			group: {
				style: {
					fontSize: '10px',
					fontWeight: 700,
				},
				groups: [
					{ title: `1er ${devices() ? 'T.' : 'Trimestre'}`, cols: 3 },
					{ title: `2er ${devices() ? 'T.' : 'Trimestre'}`, cols: 3 },
					{ title: `3er ${devices() ? 'T.' : 'Trimestre'}`, cols: 3 },
					{ title: `4er ${devices() ? 'T.' : 'Trimestre'}`, cols: 3 }
				]
			}
		},
		yaxis: {
			title: {
				text: '% Porcentaje'
			},
			max: 100,
		},
		fill: {
			opacity: 1
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return val + "%";
				}
			}
		},
		annotations: {
			yaxis: [
				{
					y: 98,
					borderColor: '#E43223',
					strokeDashArray:0,
					label: {
					 }
				}
			]
		}
	}

	return (
		<div id="chart" style={{ maxHeight: 'calc(100% - 150px)' }}>
			<ReactApexChart
				options={options}
				series={series}
				type="bar"
				height="100%"
			/>
		</div>
	)
}

export default ChartsColumnsBars
