import instance from "../../../Utils/Interceptors/Interceptor";

async function ActivarCuentaService(values) {

    try {
        return await instance.Backend.post("/Usuario/ActivarCuenta", {
            idUsuario: values.id,
            tokenActivacion: values.token,
            password: values.clave
        });

    } catch (error) {
        console.log(error);
    }

}
export { ActivarCuentaService };