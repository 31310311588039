import moment from "moment";
import SelloTiempo from "../Components/SelloTiempo";
import NotificacionLeidaCelda from "../Components/NotificacionLeidaCelda";

export const ColumnasTabla = [
	{
		Header: 'Tipo de notificación',
		id: 'tipoNotificacionDescripcion',
		className:'notify',
		accessor: 'tipoNotificacionDescripcion',
		Cell : ({row}) => <NotificacionLeidaCelda data={row.original}/>
	},
	{
		Header: 'Categoría',
		accessor: 'categoriaDescripcion',
		className:'string',
		Cell : ({row}) => <div>
			{!row.original.leido ? 
			<b>{row.original.categoriaDescripcion}</b>
            :
			<span>{row.original.categoriaDescripcion}</span>
			}
		</div>

	},
	{
		Header: 'Descripción',
		accessor: 'mensaje',
		className:'string',
		Cell : ({row}) => <div>
			{!row.original.leido ? 
			<b>{row.original.mensaje}</b>
            :
			<span>{row.original.mensaje}</span>
			}
		</div>
	},
	{
		Header: 'Fecha ',
		id: 'fechaCreacion',
		accessor: 'fechaCreacion',
		Cell : ({row}) => <SelloTiempo data={moment(row.original.fechaCreacion).format('DD-MM-yyyy HH:mm:ss')} leido = {row.original.leido}/>
	},
];
