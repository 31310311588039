import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

async function ObtenerAsignacionTrimestre(values){

    try {

        const resultado = await instance.Backend.post("/Indicador/ObtenerAsignacionTrimestre",
						values
        , customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export {ObtenerAsignacionTrimestre};
