import { React, Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useComun } from "../../../Hooks/useComun";
import { useDatamart } from "../Hooks/useDatamart";
import useLogin from "../../Autenticacion/Hooks/useLogin";
import { useRegistroTDR } from "../../../Hooks/useRegistroTDR";

const EsquemaDataMart = (props) => {
	const { data } = props;
	const navigate = useNavigate();
	const [toggle, setToggle] = useState({});
	const { handleModal } = useDatamart();
	const { metodoRecursivoXNivelServicioId, ObtenerColoresNivelesServicio } = useComun();
	const { user, usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	useEffect(() => {
		const listaToggle = data.map(x => x.nivelServicioId);
		const listaReduce = listaToggle.reduce((a, v) => ({ ...a, [v]: true }), {});
		setToggle(listaReduce);
		let sigla = localStorage.getItem('sigla');

		if(sigla !== null){
			inicioFormula(sigla);
		}
	}, [data])
	const HandleExpand = (id, valor) => {
		const lista = data.filter(x => x.nivelServicioId === id);
		const resultado = metodoRecursivoXNivelServicioId(data, lista[0].nivelServicioId)
		resultado.push(lista[0]);
		const ids = resultado.map(x => x.nivelServicioId)
		const listaReduce = ids.reduce((a, v) => ({ ...a, [v]: valor }), {});
		setToggle((prevState) => ({ ...prevState, ...listaReduce }));
	}
	const HandleShow = (data) => {
		const startTime = new Date();
		const fetchData = async () => {
			await Promise.all([
				handleModal(data, true),
			]);
			const endTime = new Date();
			const tdrId = 15;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
	}
	const inicioFormula = (sigla) =>{
		const indicadores = data.find(x=>x.indicadores!==null && x.indicadores.find(y=>y.sigla===sigla))
		if(!(indicadores === null || indicadores === undefined)){
			const item = indicadores.indicadores.find(x=>x.sigla===sigla);
			handleModal(item, true);
			localStorage.removeItem('sigla');
		}
	}
	function MostrarListado(props) {
		const lista = props.listaNiveles;

		let listItems = lista.map((x, index) =>
			<Fragment key={x.sigla + index + "_padrePrincipal"}>
				{
					!x.TienePadre?
						<Fragment key={x.sigla + index + "_padre"}>
							<tr key={x.sigla + index + "_niv"}>
								<td style={{ paddingLeft: x.nivelItem !== null ? '' + (x.nivelItem * 2.5 - 2.5) + 'rem ' : null }}>
									<div className="d-flex align-items-center gap-2">
										<button className={toggle[x.nivelServicioId] ? 'btn-collapse rotate' : 'btn-collapse'} onClick={() => HandleExpand(x.nivelServicioId, !toggle[x.nivelServicioId])}>
											<i className="ico-chevron-down"></i>
										</button>
										<span className="badge badge--monochromatic bg-primary">{x.sigla}</span>
										<strong>{x.descripcion}</strong>
									</div>
								</td>
								<td className='p-3 text-center'>
									{x.formula &&
										<button className='link link--border text-pewter-blue fs-5' onClick={() => HandleShow(x)}>
											<i className="ico-info-circle"></i>
										</button>
									}

								</td>
								<td></td>
							</tr>
							{x.indicadores &&
								x.indicadores.map((item, i) =>
									<tr key={item.sigla + i + "_ind"} className={toggle[x.nivelServicioId] ? 'border-top' : ''} >
										<td colSpan='4' className="p-0" >
											<div className={toggle[x.nivelServicioId] ? 'children expanded' : 'children'}>
												<table>
													<tbody>
														<tr >
															<td style={{ paddingLeft: x.nivelItem !== null ? '' + ((x.nivelItem * 2.5 - 2.5) + 2.5) + 'rem ' : null }}>
																<div className="d-flex align-items-center gap-2">
																	<i className="indicator ico-enter"></i>
																	<span className="badge badge--monochromatic">{item.sigla}</span>
																	<span className="name">{item.descripcion}</span>
																</div>
															</td>
															<td className='p-3 text-center'>
																{item.formula &&
																	<button className='link link--border text-pewter-blue fs-5' onClick={() => HandleShow(item, false)}>
																		<i className="ico-info-circle"></i>
																	</button>
																}
															</td>
															<td>
																<button className="link link--border text-pewter-blue fs-5" onClick={() => { navigate('/datamart/indicadores', { state: { indicador: item.indicadorId, sigla: item.sigla, descripcion: item.descripcion } }) }}>
																	<i className="ico-chevron-right"></i>
																</button>
															</td>

														</tr>
													</tbody>
												</table>
											</div>
										</td>
									</tr>
								)
							}
						</Fragment>
						:
						x.TieneHijo ?
							<tr key={x.sigla + index + "_niv"} className={toggle[x.nivelServicioPadreId] ? 'border-top' : ''} >
								<td colSpan='3' className="p-0">
									<div className={toggle[x.nivelServicioPadreId] ? 'children expanded' : 'children'}>
										<table>
											<tbody>
												<tr>
													<td style={{ paddingLeft: x.nivelItem !== null ? '' + (x.nivelItem * 2.5 - 2.5) + 'rem ' : null }}>
														<div className="d-flex align-items-center gap-2">
															<button className={toggle[x.nivelServicioId] ? 'btn-collapse rotate' : 'btn-collapse'} onClick={() => HandleExpand(x.nivelServicioId, !toggle[x.nivelServicioId])}>
																<i className="ico-chevron-down"></i>
															</button>
															<span className="badge badge--monochromatic" style={ObtenerColoresNivelesServicio(x.nivelItem)}>{x.sigla}</span>
															<strong>{x.descripcion}</strong>
														</div>
													</td>
													<td className='p-3 text-center'>
														{x.formula &&
															<button className='link link--border text-pewter-blue fs-5' onClick={() => HandleShow(x)}>
																<i className="ico-info-circle"></i>
															</button>
														}

													</td>
													<td></td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
							:
							x.indicadores &&
								<>
									<tr key={x.sigla + index + "_niv"} className={toggle[x.nivelServicioPadreId] ? 'border-top' : ''} >
										<td colSpan='3' className="p-0">
											<div className={toggle[x.nivelServicioPadreId] ? 'children expanded' : 'children'}>
												<table>
													<tbody>
														<tr key={x.sigla + index + "_niv"}>
															<td style={{ paddingLeft: x.nivelItem !== null ? '' + (x.nivelItem * 2.5 - 2.5) + 'rem ' : null }}>
																<div className="d-flex align-items-center gap-2">
																	<button className={toggle[x.nivelServicioId] ? 'btn-collapse rotate' : 'btn-collapse'} onClick={() => HandleExpand(x.nivelServicioId, !toggle[x.nivelServicioId])}>
																		<i className="ico-chevron-down"></i>
																	</button>
																	<span className="badge badge--monochromatic" style={ObtenerColoresNivelesServicio(x.nivelItem)}>{x.sigla}</span>
																	<strong>{x.descripcion}</strong>
																</div>
															</td>
															<td className='p-3 text-center'>
																{x.formula &&
																	<button className='link link--border text-pewter-blue fs-5' onClick={() => HandleShow(x)}>
																		<i className="ico-info-circle"></i>
																	</button>
																}

															</td>
															<td></td>
														</tr>
														{x.indicadores.map((item, i) =>
															<tr key={item.sigla + i + "_ind"} className={toggle[x.nivelServicioId] ? 'border-top' : ''}>
																<td colSpan='4' className="p-0" >
																	<div className={toggle[x.nivelServicioId] ? 'children expanded' : 'children'}>
																		<table>
																			<tbody>
																				<tr key={item.sigla + index + "_ind"} >
																					<td style={{ paddingLeft: x.nivelItem !== null ? '' + ((x.nivelItem * 2.5 - 2.5) + 2.5) + 'rem ' : null }}>
																						<div className="d-flex align-items-center gap-2">
																							<i className="indicator ico-enter"></i>
																							<span className="badge badge--monochromatic">{item.sigla}</span>
																							<span className="name">{item.descripcion}</span>
																						</div>
																					</td>
																					<td className='p-3 text-center'>
																						{item.formula &&
																							<button className='link link--border text-pewter-blue fs-5' onClick={() => HandleShow(item, false)}>
																								<i className="ico-info-circle"></i>
																							</button>
																						}
																					</td>
																					<td>
																						<button className="link link--border text-pewter-blue fs-5" onClick={() => { navigate('/datamart/indicadores', { state: { indicador: item.indicadorId, sigla: item.sigla, descripcion: item.descripcion } }) }}>
																							<i className="ico-chevron-right"></i>
																						</button>
																					</td>

																				</tr>
																			</tbody>
																		</table>
																	</div>
																</td>
															</tr>
														)}
													</tbody>
												</table>
											</div>
										</td>
									</tr>

								</>
				}
			</Fragment>

		)
		return (
			listItems
		);

	};
	return (
		<div className="table-responsive">
			<table className="skeleton">
				<thead>
					<tr>
						<th>Indicadores</th>
						<th className="text-center px-3">Fórmula</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<MostrarListado key="listado_1" listaNiveles={data}></MostrarListado>
				</tbody>
			</table>
		</div>
	)
}
export default EsquemaDataMart;
