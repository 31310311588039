import { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, } from "formik";
import { AlertasContext } from '../Context/ContextAlertas';
import InputTextField from '../../../Components/InputTextField/InputTextField';
import Button from '../../../Components/Button/Button';
import validations from '../../../Utils/Validations/Validations';
import { useToast } from '../../../Hooks/useToast';
import { ActualizaAlertaPreviaService } from '../Services/ActualizaAlertaPreviaService';
import Context from '../../Autenticacion/Context/LoginContext';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';
import useLogin from '../../Autenticacion/Hooks/useLogin';

const ModalEditarAlertas = () => {
	const { visible, indicator, toggleModal,toggleRenderizar } = useContext(AlertasContext);
	const { user } = useContext(Context);
	const grupo = indicator.grupo;
	const handleClose = () => toggleModal(false);
	const addToast = useToast();
	const { crearRegistroTDR } = useRegistroTDR();
	const { usuario } = useLogin();

	async function postActualizaAlertaPrevia(values) {
		const startTime = new Date();
		const resp = await ActualizaAlertaPreviaService(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					const fetchData = async () => {
						await Promise.all([
							addToast({ mostrar: true, mensaje: 'Porcentaje actualizado correctamente.', titulo: 'Confirmación', tipo: 'Success' }),
							toggleRenderizar(true),
							handleClose(),
						]);
						const endTime = new Date();
						const tdrId = 93;
						crearRegistroTDR(startTime, endTime, usuario, tdrId);
					};

					fetchData();

				}
			}
		}
	}
	function validarIngresoPonderacion(ingresoPonderacion) {
		const valorTotalGrupoPonderaciones =
			grupo.filter(x => indicator.esNivelServicio ? x.detalle.nivelServicio_Id !== indicator.nivelServicio_Id : x.indicador_Id !== indicator.indicador_Id)
				.map(resp => resp.detalle ? resp.detalle.porcentajeAlertaPrevia : resp.porcentajeAlertaPrevia)
				.reduce((prev, curr) => prev + curr, 0);

		const valorTotalPonderaciones = valorTotalGrupoPonderaciones + ingresoPonderacion;
		if (valorTotalPonderaciones > 100) {
			addToast({ mostrar: true, mensaje: 'El porcentaje no debe superar el 100%', titulo: 'Información', tipo: 'Info' });
			return false;
		}
		return true;
	}
	return (
		<Modal
			show={visible}
			onHide={handleClose}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Editar ponderación</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Formik
					enableReinitialize
					initialValues={{ indicador: `${indicator.sigla}: ${indicator.descripcion}`, porcentaje: `${indicator.porcentajeAlertaPrevia}` }}
					validate={validations}
					onSubmit={values => {
						const valuesForm = {
							idUsuario: user.id,
							esNivelServicio: indicator.esNivelServicio,
							id: (indicator.esNivelServicio) ? indicator.nivelServicio_Id : indicator.indicador_Id,
							porcentajeAlertaPrevia: Number(values.porcentaje)

						}
							postActualizaAlertaPrevia(valuesForm);

					}}
				>{({
					values,
					setFieldValue,
				}) => (

					<Form className="form mb-0">
						<InputTextField
							name="indicador"
							label="Indicador"
							type="text"
							placeholder="Indicador"
							autoComplete="false"
							readOnly
						/>
						<InputTextField
							name="porcentaje"
							label="Porcentaje"
							type="text"
							placeholder="Porcentaje"
							autoComplete="false"
							maxLength="3"
							onInput={(e) => { e.target.value = e.target.value.replace(/\D/g, ''); setFieldValue("porcentaje", e.target.value.replace(/\D/g, '')); }}

						/>
						<div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4">
							<Button type="button" design="btn-outline-pewter-blue" onClick={() => handleClose()}>Cancelar</Button>
							<Button type="submit" design="btn-primary">Guardar</Button>
						</div>
					</Form>
					)}
				</Formik>
			</Modal.Body>
		</Modal>
	)
}
export default ModalEditarAlertas;
