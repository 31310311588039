import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, } from "formik";
import InputFileField from '../../../Components/InputFileField/InputFileField';
import InputRadioField from '../../../Components/InputRadioField/InputRadioField';
import TextAreaField from '../../../Components/TextAreaField/TextAreaField';
import Button from '../../../Components/Button/Button';
import { AsignarCDO } from '../Services/AsignarCDO';
import { useToast } from '../../../Hooks/useToast';
import { ObtenerTrimestreCDO } from '../Services/ObtenerTrimestreCDO';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { BuscarIndiceServicioPrestado } from '../Services/BuscarIndiceServicioPrestado';
import { ObtenerAsignacionTrimestre } from '../Services/ObtenerAsignacionTrimestre';
import { useComun } from '../../../Hooks/useComun';
import validations, { ValuesRequeridos } from '../../../Utils/Validations/Validations';



const ModalAsignacionCdoTrimestre = (props) => {
	const { renderizar, ...rest } = props;
	const addToast = useToast();
	const { usuario } = useLogin();
	const [indice, setIndice] = useState(null);
	const [asignacionTrimestreCDO, setTrimestreCDO] = useState([]);
	const [asignacion, setAsignacionTrimestre] = useState(null);
	const { downloadXUrl } = useComun();
	const [initialValues, setInitialValues] = useState({
		idUsuario: usuario.id,
		idPerfilContexto: usuario.perfilContexto.id,
		Ano: rest.anoseleccion,
		Trimestre: 0,
		NivelCumplimientoId: 0,//760,
		conducta: '',
		mensaje: '',
		fileISP: '',
		adaptacion: '',
		desempeno: ''
	});
	useEffect(() => {
		const valuesIndice = {
			idUsuario: usuario.id,
			idPerfilContexto: usuario.perfilContexto.id,
			anoConsulta: rest.anoseleccion
		}
		const valuesTrimestre = {
			idUsuario: usuario.id,
			idPerfilContexto: usuario.perfilContexto.id,
			anoConsulta: rest.anoseleccion,
			trimeste: rest.trimestre
		}
		const valuesTrimestreCDO = {
			idUsuario: usuario.id,
			idPerfilContexto: usuario.perfilContexto.id,
			anoConsulta: rest.anoseleccion
		}
		if (rest.asignaciontrimestre !== "true") {
			getTrimestreCDO(valuesTrimestreCDO);
		}
		else {
			getAsignacion(valuesTrimestre);
		}
		getIndice(valuesIndice);
	}, [])

	useEffect(() => {
		getTrimestre();
	}, [indice])

	async function getIndice(values) {
		const resp = await BuscarIndiceServicioPrestado(values);
		if (resp) {
			if (resp.status === 200) {
				setIndice(resp.data);
			}
		}
	}

	async function getTrimestreCDO(values) {
		const resp = await ObtenerTrimestreCDO(values);
		if (resp) {
			if (resp.status === 200) {
				setTrimestreCDO(resp.data);
			}
		}
	}

	async function getAsignacion(values) {
		const resp = await ObtenerAsignacionTrimestre(values);
		if (resp) {
			if (resp.status === 200) {
				setAsignacionTrimestre(resp.data);
			}
		}
	}

	const getTrimestre = () => {
		if (indice) {
			const TrimestreAsignar = indice.trimestre.filter(x => x.cdoId === null && x.nivelcumplimientoId !== null).sort(x => x.cdoId);
			setInitialValues({
				...initialValues,
				Trimestre: TrimestreAsignar.length > 0 ? TrimestreAsignar[0].numeroTrimestre : 0,
				NivelCumplimientoId: TrimestreAsignar.length > 0 ? TrimestreAsignar[0].nivelcumplimientoId : 0
			})
		}

	}



	async function postAsignarCDO(values) {
		const variable = [{
			variableId: 1,
			alto: values.adaptacion === "0" ? true : false,
			medio: values.adaptacion === "50" ? true : false,
			bajo: values.adaptacion === "100" ? true : false,
		},
		{
			variableId: 2,
			alto: values.conducta === "0" ? true : false,
			medio: values.conducta === "50" ? true : false,
			bajo: values.conducta === "100" ? true : false
		},
		{
			variableId: 3,
			alto: values.desempeno === "0" ? true : false,
			medio: values.desempeno === "50" ? true : false,
			bajo: values.desempeno === "100" ? true : false
		}];
		let formData = new FormData();
		formData.append('IdUsuario', values.idUsuario);
		formData.append('idPerfilContexto', values.idPerfilContexto);
		formData.append('Ano', values.Ano);
		formData.append('Trimestre', values.Trimestre);
		formData.append('NivelCumplimientoId', values.NivelCumplimientoId);

		variable.map((value, index) => {
			formData.append("variables[" + index + "][variableId]", Number(value.variableId));
			formData.append("variables[" + index + "][alto]", value.alto);
			formData.append("variables[" + index + "][medio]", value.medio);
			formData.append("variables[" + index + "][bajo]", value.bajo);
		});

		formData.append('Observacion', values.mensaje);
		formData.append('Documentos', values.fileISP);
		const resp = await AsignarCDO(formData);
		if (resp) {
			if (resp.status === 200) {
				rest.setcdoasignado.setCDOasignado(resp.data);
				addToast({ mostrar: true, mensaje: 'Se ha guardado correctamente.', titulo: 'Confirmación', tipo: 'Success' });
				rest.onHide();
				rest.showmodalasignado.handleShow2();
			}
		}
	}

	return (
		<Modal
			{...props}
			size="md"
			centered
			backdropClassName="backdrop-dark"
			className='modal--bg-gray'
		>
			<Modal.Header closeButton>
				<h5 className="modal-title">Asignación CDO trimestre</h5>
			</Modal.Header>
			<Modal.Body>
				<div className='bg-white rounded p-3 mb-3'>
					<ul className='list list--def'>
						{rest.asignaciontrimestre === "true" ?
							<li className='list__item'>
								<span>Trimestre</span>
								<b>{asignacion ? asignacion.trimestre : ''}</b>
							</li>
							:
							<li className='list__item'>
								<span>Trimestre</span>
								<b>{asignacionTrimestreCDO.trimestre}</b>
							</li>
						}
						{rest.asignaciontrimestre === "true" ?
							<li className='list__item'>
								<span>ISP</span>
								<b>{asignacion ? asignacion.isp : ''}</b>
							</li>
							:
							<li className='list__item'>
								<span>ISP</span>
								<b>{asignacionTrimestreCDO.isp}</b>
							</li>
						}
						{rest.asignaciontrimestre === "true" ?
							<li className='list__item'>
								<span>Rango CDO UF</span>
								<b>{asignacion ? asignacion.rangoCDOUf : ''}</b>
							</li>
							:
							<li className='list__item'>
								<span>Rango CDO UF</span>
								<b>{asignacionTrimestreCDO.rangoCDOUf}</b>
							</li>
						}
					</ul>
				</div>
				<h2 className='fs-5 mb-2 text-pewter-blue'>Variables</h2>
				<div className='bg-white rounded p-3'>
					<Formik
						enableReinitialize
						initialValues={initialValues}
						validate={validations}
						onSubmit={values => {
							postAsignarCDO(values);
						}}
					>
						{({
							values,
							handleChange,
							setFieldValue,
						}) => (
							<Form className="form mb-0">
								<div className="form-floating pb-2 border-bottom">
									<span className='fs-6 mb-2 d-flex text-pewter-blue'>1. Conducta diligente del Concesionario.</span>
									<div className='d-flex align-items-start gap-3 flex-wrap mt-3'>
										{rest.asignaciontrimestre === "true" ? asignacion ? asignacion.variables[0].alto.estado === true ?
											<InputRadioField name="conducta" value="0" label="0% Alto" checked="checked" disabled="disabled" />
											:
											<InputRadioField name="conducta" value="0" label="0% Alto" disabled="disabled" />
											:
											<InputRadioField name="conducta" value="0" label="0% Alto" orden="1" />
											:
											<InputRadioField name="conducta" value="0" label="0% Alto" orden="1" />
										}
										{rest.asignaciontrimestre === "true" ? asignacion ? asignacion.variables[0].medio.estado === true ?
											<InputRadioField name="conducta" value="50" label="50% Medio" checked="checked" disabled="disabled" />
											:
											<InputRadioField name="conducta" value="50" label="50% Medio" disabled="disabled" />
											:
											<InputRadioField name="conducta" value="50" label="50% Medio" orden="2" />
											:
											<InputRadioField name="conducta" value="50" label="50% Medio" orden="2" />
										}
										{rest.asignaciontrimestre === "true" ? asignacion ? asignacion.variables[0].bajo.estado === true ?
											<InputRadioField name="conducta" value="100" label="100% Bajo" checked="checked" disabled="disabled" />
											:
											<InputRadioField name="conducta" value="100" label="100% Bajo" disabled="disabled" />
											:
											<InputRadioField name="conducta" value="100" label="100% Bajo" orden="3" />
											:
											<InputRadioField name="conducta" value="100" label="100% Bajo" orden="3" />
										}
									</div>
								</div>
								<div className="form-floating pb-2 border-bottom">
									<span className='fs-6 mb-2 d-flex text-pewter-blue'>2. Haber adoptado las medidas de mitigación o reparación necesarias respecto al desempeño operacional insuficiente.</span>
									<div className='d-flex align-items-start gap-3 flex-wrap mt-3'>
										{rest.asignaciontrimestre === "true" ? asignacion ? asignacion.variables[1].alto.estado === true ?
											<InputRadioField name="adaptacion" value="0" label="0% Alto" checked="checked" disabled="disabled" />
											:
											<InputRadioField name="adaptacion" value="0" label="0% Alto" disabled="disabled" />
											:
											<InputRadioField name="adaptacion" value="0" label="0% Alto" orden="1" />
											:
											<InputRadioField name="adaptacion" value="0" label="0% Alto" orden="1" />
										}
										{rest.asignaciontrimestre === "true" ? asignacion ? asignacion.variables[1].medio.estado === true ?
											<InputRadioField name="adaptacion" value="50" label="50% Medio" checked="checked" disabled="disabled" />
											:
											<InputRadioField name="adaptacion" value="50" label="50% Medio" disabled="disabled" />
											:
											<InputRadioField name="adaptacion" value="50" label="50% Medio" orden="2" />
											:
											<InputRadioField name="adaptacion" value="50" label="50% Medio" orden="2" />
										}
										{rest.asignaciontrimestre === "true" ? asignacion ? asignacion.variables[1].bajo.estado === true ?
											<InputRadioField name="adaptacion" value="100" label="100% Bajo" checked="checked" disabled="disabled" />
											:
											<InputRadioField name="adaptacion" value="100" label="100% Bajo" disabled="disabled" />
											:
											<InputRadioField name="adaptacion" value="100" label="100% Bajo" orden="3" />
											:
											<InputRadioField name="adaptacion" value="100" label="100% Bajo" orden="3" />
										}
									</div>
								</div>
								<div className="form-floating pb-2">
									<span className='fs-6 mb-2 d-flex text-pewter-blue'>3. Desempeño operacional insuficiente reiterado.</span>
									<div className='d-flex align-items-start gap-3 flex-wrap mt-3'>
										{rest.asignaciontrimestre === "true" ? asignacion ? asignacion.variables[2].alto.estado === true ?
											<InputRadioField name="desempeno" value="0" label="0% Alto" checked="checked" disabled="disabled" />
											:
											<InputRadioField name="desempeno" value="0" label="0% Alto" disabled="disabled" />
											:
											<InputRadioField name="desempeno" value="0" label="0% Alto" orden="1" />
											:
											<InputRadioField name="desempeno" value="0" label="0% Alto" orden="1" />
										}
										{rest.asignaciontrimestre === "true" ? asignacion ? asignacion.variables[2].medio.estado === true ?
											<InputRadioField name="desempeno" value="50" label="50% Medio" checked="checked" disabled="disabled" />
											:
											<InputRadioField name="desempeno" value="50" label="50% Medio" disabled="disabled" />
											:
											<InputRadioField name="desempeno" value="50" label="50% Medio" orden="2" />
											:
											<InputRadioField name="desempeno" value="50" label="50% Medio" orden="2" />
										}
										{rest.asignaciontrimestre === "true" ? asignacion ? asignacion.variables[2].bajo.estado === true ?
											<InputRadioField name="desempeno" value="100" label="100% Bajo" checked="checked" disabled="disabled" />
											:
											<InputRadioField name="desempeno" value="100" label="100% Bajo" disabled="disabled" />
											:
											<InputRadioField name="desempeno" value="100" label="100% Bajo" orden="3" />
											:
											<InputRadioField name="desempeno" value="100" label="100% Bajo" orden="3" />
										}
									</div>
								</div>
								<div className="form-floating pb-2 border-bottom">
									{rest.asignaciontrimestre === "true" ? asignacion ? asignacion.observacion === null ?
										null
										:
										<TextAreaField
											name="mensaje"
											value={asignacion.observacion}
											disabled
										/>
										:
										<TextAreaField
											name="mensaje"
											placeholder="Escribe tu mensaje aquí…"
											label='Escribe tu mensaje aquí…'
										/>
										:
										<TextAreaField
											name="mensaje"
											placeholder="Escribe tu mensaje aquí…"
											label='Escribe tu mensaje aquí…'
										/>
									}
								</div>
								{rest.asignaciontrimestre === "true" ? asignacion ? asignacion.documentos.length !== 0 ?
									<div className='accordion-item'>
										<div className='accordion-header'>
											<div className='d-flex gap-2 align-items-center'>
												<h2 className='fs-5 mb-2 text-pewter-blue'>Documentos</h2>
											</div>
										</div>
										<div className='accordion-body'>
											<div className='d-flex gap-4 flex-wrap'>
												<div className='doc'>
													<div className='doc-icon'>
														<i className="ico-doc-empty fs-3" onClick={() => downloadXUrl(asignacion.documentos[0].urlArchivo, asignacion.documentos[0].mimeArchivo, asignacion.documentos[0].nombreArchivo, asignacion.documentos[0].extensionArchivo)}></i>
													</div>
													<div className='doc-info'>
														<b className='doc-title' onClick={() => downloadXUrl(asignacion.documentos[0].urlArchivo, asignacion.documentos[0].mimeArchivo, asignacion.documentos[0].nombreArchivo, asignacion.documentos[0].extensionArchivo)}>{asignacion.documentos[0].nombreArchivo + asignacion.documentos[0].extensionArchivo}</b>
														<span className='doc-time' onClick={() => downloadXUrl(asignacion.documentos[0].urlArchivo, asignacion.documentos[0].mimeArchivo, asignacion.documentos[0].nombreArchivo, asignacion.documentos[0].extensionArchivo)}>{"Agregado el " + asignacion.fechaCreacion}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									:
									null
									:
									null
									:
									<div className="form-floating mb-2">
										<InputFileField name="fileISP" placeholder="Subir documento" onChange={(event) => { setFieldValue("fileISP", event.target.files[0]); }} />
									</div>
								}
								<div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4">
									{rest.asignaciontrimestre === "true" ?
										null
										:
										<Button type="button" design="btn-outline-pewter-blue" onClick={() => rest.onHide()}>Cancelar</Button>
									}
									{rest.asignaciontrimestre === "true" ?
										null
										:
										<Button type="submit" design="btn-primary">Aplicar</Button>
									}
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default ModalAsignacionCdoTrimestre
