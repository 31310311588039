import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

async function ObtenerEstadisticasHallazgoService(id,idPerfilContexto){

    try {

        const resultado = await instance.Backend.post("/Hallazgo/ObtenerEstadisticasHallazgo",{
            idUsuario: id,
            idPerfilContexto: idPerfilContexto
        }, customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export {ObtenerEstadisticasHallazgoService};