import { useEffect, useState } from 'react'
import moment from 'moment';

export function useModalBackup(props) {

	const [initialValues, setInitialValues] = useState({
			respaldo_id: '',
			usuario: '',
			backup: '',
			periodo2:''

	});
	const { isCrear, editUser, ...rest } = props;
	useEffect(() => {
		if (!isCrear) {

			setInitialValues({
				respaldo_id: editUser.usuarioRespaldoId,
				usuario: editUser.idUsuario,
				backup:editUser.idRespaldo,
				periodo2: [moment(editUser.fechaInicio).toDate(), moment(editUser.fechaFin).toDate()]

			})
		} else {
			setInitialValues({
				usuario: '',
				backup: '',
				periodo2: ''
			})
		}
	}, [isCrear, editUser])


  return { initialValues, isCrear, rest }
}
