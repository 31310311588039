import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useLocation } from 'react-router-dom';
import Button from '../../../Components/Button/Button';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { InformeNivelCumplimientoSerieService } from '../Services/InformeNivelCumplimientoSerieService';
import { ReporteEstadisticasHallazgosService } from '../Services/ReporteEstadisticasHallazgosService';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const ModalSolicitudDescarga = (props) => {
    const { datosModal, datosBusqueda, ...rest } = props;
    const location = useLocation();
		const { crearRegistroTDR } = useRegistroTDR();
    const [mensajeModal, setMensajeModal] = useState('');
    const { usuario } = useLogin();

    const postInformeNivelCumplimientoSerie = async (values) => {
				const startTime = new Date();
        const resp = await InformeNivelCumplimientoSerieService(values);
        if (resp) {
            if (resp.status === 200) {
                if (resp.data) {
										const fetchData = async () => {
											await Promise.all([
												setMensajeModal(resp.data)
											]);
											const endTime = new Date();
											if(values.formatoEcxel === true){
												const tdrId = 56;
												crearRegistroTDR(startTime, endTime, usuario, tdrId);
											}
											else if(values.formatoEcxel === false){
												const tdrId = 57;
												crearRegistroTDR(startTime, endTime, usuario, tdrId);
											}
										};
										fetchData();
                }
            }
        }
    }
    async function postReporteEstadisticasHallazgos(values) {
			const startTime = new Date();
			const resp = await ReporteEstadisticasHallazgosService(values);
			if (resp) {
				if (resp.status === 200) {
					if (resp.data) {
										const fetchData = async () => {
											await Promise.all([
												setMensajeModal(resp.data)
											]);
											const endTime = new Date();
											if(values.formatoEcxel === true){
												const tdrId = 61;
												crearRegistroTDR(startTime, endTime, usuario, tdrId);
											}
											else if(values.formatoEcxel === false){
												const tdrId = 62;
												crearRegistroTDR(startTime, endTime, usuario, tdrId);
											}
										};
										fetchData();

                }
            }
        }
    }
    useEffect(() => {
        if (props.show) {
            setMensajeModal('');
            if (location.pathname === '/informes/nivel_cumplimiento') {
                const { serieId, esFormatoEcxel } = datosModal;
                const values = {
                    idUsuario: usuario.id,
                    serieId: serieId,
                    IdPerfilContexto :usuario.perfilContexto.id,
                    formatoEcxel: esFormatoEcxel,
                }
                postInformeNivelCumplimientoSerie(values);
            } else if (location.pathname === '/informes/estadistica_hallazgos') {
                const { areaNegocioId, formatoEcxel } = datosModal;
                const { fechaInicio, fechaTermino } = datosBusqueda;
                const formEnvio = {
                    idUsuario: usuario.id,
                    idPerfilContexto: usuario.perfilContexto.id,
                    areaNegocioId: areaNegocioId,
                    fechaInicio: fechaInicio,
                    fechaTermino: fechaTermino,
                    formatoEcxel: formatoEcxel // el false indica que se descarge en excel
                }
                postReporteEstadisticasHallazgos(formEnvio);
            }

        }
    }, [props.show]);
    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className='info my-4'>
                    <h6 className='info__title'>Solicitud</h6>
                    <p className='info__text'>{mensajeModal}</p>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4">
                    <Button type="button" design="btn-primary btn--small" onClick={rest.onHide}>Aceptar</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default ModalSolicitudDescarga;
