import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

async function ObtenerIndicadoresSerieService(values) {
    try {
        return await instance.Backend.post("/Indicador/NivelCumplimientoIndicadorSerie"
            , values
            , customConfig);
    } catch (error) {
        console.log(error);
    }
}
export { ObtenerIndicadoresSerieService };