import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ActualizaParametroIndicadorExigenciaService = async (values, idUsuario) => {

    try {
        return await instance.Backend.post("/Indicador/ActualizaParametroIndicadorExigencia", {
            parametroIndicadorId: values.parametroIndicadorId,
            unidadMedidaId: values.unidadMedidaId,
            valor1: values.valor1,
            valor2: values.valor2,
            idUsuario: idUsuario
        });

    } catch (error) {
        console.log(error);
    }

}
export { ActualizaParametroIndicadorExigenciaService };

