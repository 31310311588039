import ExigenciaCell from "../Components/ExigenciaCelda";
import SwitchInCell from "../Components/EstadoEnCelda";
import FuncionesEnCelda from "../Components/FuncionesEnCelda";
import EstadoEnCelda from "../Components/EstadoEnCelda";

export function useColumns(props) {
	const COLUMNS = [
		{
			Header: 'Descripción parámetro',
			accessor: 'descripcionParametro',
			Cell: ({ row }) => {var htm={__html:row.original.descripcionParametro}; return <div dangerouslySetInnerHTML={htm} ></div>}
		},
		{
			Header: 'Valor exigido',
			accessor: data => {
				if (data.tab === 'VIA'){
					return data.reglaTO;
				}else if (data.descripcionUnidadMedida==='Cumplimiento'){
					let descripcion = '';
					if (data.valor1===1){
						descripcion = 'Cumple';
					}else{
						descripcion = 'No Cumple';
					}
					return descripcion;
				}else{
					return data.valor1;
				}
			},
			className: 'text-bold',
		},
		{
			Header: 'Unidad medida',
			accessor: 'descripcionUnidadMedida',
		},
		{
			Header: 'Estado',
			accessor: 'estado',
			className: 'text-center',
			disabledFilters: true,
			Cell: ({ row }) => <EstadoEnCelda data = {row.original}estado={row.original.estado} id={row.original.parametroIndicadorExigenciaId} renderizar={props.renderizar} />
		},
	];
	return { COLUMNS };
}
