import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ObtenerAlarmasPerfilService = async (exportacion, idUsuario) => {
    try {
        const resultado = await instance.Backend.post("/Alarma/ListadoAlarmaPerfil", 
            {exportacion: exportacion.exportacion,
             idUsuario: idUsuario
        }
        , customConfig);

        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { ObtenerAlarmasPerfilService };