import Modal from 'react-bootstrap/Modal';
import { Formik, Form, } from "formik";
import InputTextField from '../../../Components/InputTextField/InputTextField';
import Button from '../../../Components/Button/Button';
import { useComun } from '../../../Hooks/useComun';
import SelectCheckField from '../../../Components/SelectCheckField/SelectCheckField';
import { useContext, useEffect, useState } from 'react';
import validations from '../../../Utils/Validations/Validations';
import { ActualizaParametroIndicadorExigenciaService } from '../Services/ActualizaParametroIndicadorExigenciaService';
import { useToast } from '../../../Hooks/useToast';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const ModalEdicionExigencia = (props) => {
	const { descripcionParametro, valor1, valor2, unidadMedidaId, parametroIndicadorExigenciaId } = props.exigencia;
	const { listado, renderizar, valorTab, ...rest } = props;
	const { listadoUnidadMedida, getListaUnidadMedida } = useComun();
	const addToast = useToast();
	const {usuario} = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const initialValues = {
		exigencia: descripcionParametro,
		valor1: valor1,
		valor2: valor2,
		unidad: unidadMedidaId,
		reglaUnValor: 'TO <=',
		reglaDosValores: '< TO <='
	}
	const [esCumplimiento, setEsCumplimiento] = useState(false);
	const listadoValorCumplimiento = [{value:0, label:'No Cumple'},{value:1, label:'Cumple'}];
	useEffect(() => {
		getListaUnidadMedida();
	}, [])

	useEffect(() => {
		if(unidadMedidaId===16){ // 16 equivale a "cumplimiento"
			setEsCumplimiento(true);
		}else{
			setEsCumplimiento(false);
		}
	}, [unidadMedidaId])

	async function postActualizarPonderacion(values) {
		const startTime = new Date();
		const resp = await ActualizaParametroIndicadorExigenciaService(values, usuario.id);
		if (resp) {
			if (resp.status === 200) {
				const fetchData = async () => {
					await Promise.all([
						addToast({ mostrar: true, mensaje: 'Parámetro modificado correctamente.', titulo: 'Confirmación', tipo: 'Success' }),
						renderizar(),
						rest.onHide(),
					]);
					const endTime = new Date();
					const tdrId = 97;
					crearRegistroTDR(startTime, endTime, usuario, tdrId);
				};

				fetchData();
			}
		}
	}

	function changeUnidad(e, value) {
		if (value===16){ // 16 equivale a "cumplimiento"
			setEsCumplimiento(true);
		}else{
			setEsCumplimiento(false);
		}

	}

	return (
		<Modal
			{...rest}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<h5 className="modal-title">
					<span>Editar parámetro</span>
				</h5>
			</Modal.Header>
			<Modal.Body>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validate={validations}
					onSubmit={values => {
						const valuesForm = {
							parametroIndicadorId: parametroIndicadorExigenciaId,
							unidadMedidaId: Number(values.unidad),
							valor1: Number(values.valor1),
							valor2: values.valor2,
						}
						postActualizarPonderacion(valuesForm);
					}}
				>
					{({ setFieldValue, setFieldTouched }) => (
						<Form className="form mb-10">
							<span className="badge badge-special bg-gray mb-3 white-space-normal">
								<div style={{textAlign:'left'}}
								dangerouslySetInnerHTML={{__html: descripcionParametro}}
								/>
							</span>
							{valorTab!=='VIA' &&
									<>
									{!esCumplimiento ?
										<InputTextField
										name="valor1"
										label="Valor"
										type="number"
										placeholder="Valor exigido"
										autoComplete="false" /> :
										<SelectCheckField
										name="valor1"
										label='Valor'
										options={listadoValorCumplimiento}
										onChange={setFieldValue}
										onBlur={setFieldTouched}
										isMulti={false}
										closeOnSelect={true}
										fijo={false}
										/>
									}

									<SelectCheckField
										name="unidad"
										label='Unidad'
										options={listadoUnidadMedida}
										onChange= {(e, value)=> {changeUnidad(e, value); setFieldValue("unidad", value)}}
										onBlur={setFieldTouched}
										isMulti={false}
										fijo={false}
										closeOnSelect={true} /></>
							}
							{valorTab==='VIA' && valor2 == null &&
								<div className="row mb-3">
									<div className="input-group input-grid">
											<InputTextField
											name="reglaUnValor"
											label="Operador"
											type="text"
											placeholder="Valor exigido"
											autoComplete="false"
											readOnly="readOnly"
											/>
										<InputTextField
											name="valor1"
											label="Valor 1"
											type="number"
											placeholder="Valor exigido"
											autoComplete="false"
											/>
									</div>
								</div>
							}
							{valorTab==='VIA' && valor2 != null &&
								<div className="row mb-3">
									<div className="input-group input-grid">
											<InputTextField
											name="valor1"
											label="Valor 1"
											type="number"
											placeholder="Valor exigido"
											autoComplete="false"
											/>
											<InputTextField
											name="reglaDosValores"
											label="Operador"
											type="text"
											placeholder="Valor exigido"
											autoComplete="false"
											disabled="disabled"
											/>
											<InputTextField
											name="valor2"
											label="Valor 2"
											type="number"
											placeholder="Valor exigido"
											autoComplete="false"
											/>
									</div>
								</div>
							}
							{valorTab==='VIA' &&
								<div className='mb-3'>
									<SelectCheckField
										name="unidad"
										label='Unidad'
										options={listadoUnidadMedida}
										onChange={setFieldValue}
										onBlur={setFieldTouched}
										isMulti={false}
										closeOnSelect={true}
										disabled="disabled"
										fijo={false}
									/>
								</div>
							}


							{}
							<div className="d-flex flex-column flex-md-row justify-content-between align-items-stretch align-items-md-center gap-4">
								<Button type="button" design="btn-outline-pewter-blue flex-fill" onClick={rest.onHide}>Cancelar</Button>
								<Button type="submit" design="btn-primary flex-fill">Guardar</Button>
							</div>
						</Form>
					)}
				</Formik>
			</Modal.Body>
		</Modal>
	)
}

export default ModalEdicionExigencia;
