import { useEffect, useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../../../Components/Button/Button';
import { useToast } from '../../../Hooks/useToast';
import Context from '../../Autenticacion/Context/LoginContext';
import { AlertasContext } from '../Context/ContextAlertas';
import { ActualizaAlertaPreviaEstadoService } from '../Services/ActualizaAlertaPreviaEstadoService';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';
import useLogin from '../../Autenticacion/Hooks/useLogin';

const ModalConfirmacion = () => {
	const { show, indicator, toggleRenderizar, toggleModalConfirmar } = useContext(AlertasContext);
	const { user } = useContext(Context);
	const grupo = indicator.grupo;
	const handleClose = () => toggleModalConfirmar(false);
	const addToast = useToast();
	const [mensajeModal, setMensajeModal] = useState({ titulo: "", texto: "" });
	const { crearRegistroTDR } = useRegistroTDR();
	const { usuario } = useLogin();

	const btnModificarEstado = async () => {
		const startTime = new Date();
		const values = {
			idUsuario: user.id,
			esNivelServicio: indicator.esNivelServicio,
			id: (indicator.esNivelServicio) ? indicator.nivelServicio_Id : indicator.indicador_Id,
			estado: !indicator.estadoAlertaPrevia
		}
		const resp = await ActualizaAlertaPreviaEstadoService(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					const fetchData = async () => {
						await Promise.all([
							toggleRenderizar(true),
							addToast({ mostrar: true, mensaje: 'Se actualizó el estado de la alerta', titulo: 'Confirmación', tipo: 'Success' }),
						]);
						const endTime = new Date();
						const tdrId = 91;
						crearRegistroTDR(startTime, endTime, usuario, tdrId);
					};

					fetchData();
				}
			}
		}

		handleClose();
	}
	useEffect(() => {
		if (indicator.esNivelServicio === true) {
			if (indicator.estadoAlertaPrevia === false) {
				setMensajeModal({ titulo: "¿Estás seguro que deseas activar la alerta\n" + " " + indicator.descripcion + "?" });
			} else {
				setMensajeModal({ titulo: "¿Estás seguro que deseas desactivar la alerta\n" + " " + indicator.descripcion + "?" });
			}
		} else {
			if (indicator.estadoAlertaPrevia === false) {
				setMensajeModal({ titulo: "¿Estás seguro que deseas activar el indicador\n" + " " + indicator.descripcion + "?" });
			} else {
				setMensajeModal({ titulo: "¿Estás seguro que deseas desactivar el indicador\n" + " " + indicator.descripcion + "?" });
			}
		}
	}, [indicator]);
	return (
		<Modal
			show={show}
			onHide={handleClose}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				<div className='info my-4'>
					<span className='info__icon'>
						<i className='ico-user-delete'></i>
					</span>
					<h6 className='info__title'>{mensajeModal.titulo}</h6>
					<p className='info__text'>{mensajeModal.texto}</p>
				</div>
				<div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4">
					<Button type="button" design="btn-outline-pewter-blue btn--small" onClick={() => handleClose()}>Cancelar</Button>
					<Button type="button" design="btn-primary btn--small" onClick={btnModificarEstado}>Aceptar</Button>
				</div>
			</Modal.Body>
		</Modal>
	)
}
export default ModalConfirmacion;
