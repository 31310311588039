import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const BusquedaObjetarEvento = async (values) => {
    try {
        const resultado = await instance.Backend.post("/Bitacora/BusquedaBitacoraObjetarEvento",values, customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export { BusquedaObjetarEvento };