import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import InputTextField from "../../../Components/InputTextField/InputTextField";
import brandIsa from "../../../Assets/images/brand-isa.svg";
import validations from "../../../Utils/Validations/Validations";
import { recuperarClave } from "../Services/RecuperarClaveService";
import { Fragment } from "react";
import { useToast } from "../../../Hooks/useToast";

const RecoveryPassword = () => {
	const navigate = useNavigate();
	const addToast = useToast();
	async function postRecuperarClave(email) {
		const resp = await recuperarClave(email);
		if (resp) {
			if (resp.status === 200) {
				navigate("/", { replace: true });
				addToast({ mostrar: true, mensaje: 'Correo enviado', titulo: 'Confirmación', tipo: 'Success' });
			}
		}
	}
	const RecuperarPass = (values) => {
		postRecuperarClave(values.email);
	}
	return (
		<Fragment>
			<div className="container min-vh-100">
				<div className="row justify-content-center align-items-center min-vh-100">
					<div className="col-12 col-md-6 col-lg-5 col-xl-4">
						<div className="card card--login">
							<div className="card-body p-4 p-md-5">
								<img className="d-flex mb-5 mx-auto" src={brandIsa} width="187" alt="ruta loa" />
								<span className="fs-4 text-pewter-blue mb-4 d-block">Recuperar contraseña</span>
								<Formik
									initialValues={{ email: '', }}
									validate={validations}
									onSubmit={async (values, { setSubmitting, resetForm }) => {
										setSubmitting(false)
										RecuperarPass(values);
									}}
								>
									<Form className="form mb-0">
										<InputTextField
											name="email"
											label="Ingresa tu email"
											type="email"
											placeholder="hola@emai.com"
											autoComplete="false"
											maxLength="50"
										/>
										<div className="d-grid mb-4">
											<button type="submit" className="btn btn-primary">Recuperar contraseña</button>
										</div>
									</Form>
								</Formik>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>

	);
}

export default RecoveryPassword;
