import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";


const ModificarEstadosEventosConcesionXIndicadorServices = async (values) => {
    
    try {

        const resultado = await instance.Backend.post("/Autorizacion/ModificarEstadosEventosConcesion", 
            values, customConfig);
        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { ModificarEstadosEventosConcesionXIndicadorServices };