import AvatarInCell from "../../../Components/Table/Filters/Components/AvatarInCell";
import SelloTiempo from "../../../Components/SelloTiempo/SelloTiempo";

export const COLUMNS = [
	{
		Header: 'Sección',
		accessor: 'seccion',
	},
	{
		Header: 'Fecha',
		accessor: 'fechaInicio',
		className: 'text-nowrap',
		Cell: ({ row }) =>  row.original.fecha 
	},
	{
		Header: 'Hora',
		accessor: 'hora',
	},
	{
		Header: 'IP',
		accessor: 'ip',
	},
	{
		Header: 'Movimiento',
		accessor: 'movimiento',
	},
];
