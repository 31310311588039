import instance, { customConfig } from "../Utils/Interceptors/Interceptor";

const ListaIndicadoresAreaNegocioIdService = async (values, idUsuario) => {
    try {
        const resultado = await instance.Backend.post("/Indicador/ListaIndicadoresAreaNegocio",{
            areasNegocioId: values,
            usuarioId: idUsuario
        }
            , customConfig);
        
        return resultado
        
    } catch (error) {
        console.log(error);
    }
}
export { ListaIndicadoresAreaNegocioIdService };