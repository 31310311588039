const Chip = ({...props}) => {
	const {label, value, icon, state, handleClick, design} = props;
	return (
		<div className={`chip ${design ? design : ''}`} onClick={handleClick}>
			<div className="chip__info">
				<span className="chip__label">{label}</span>
				<strong className="chip__value">{value}</strong>
			</div>
			<div className={`chip__figure chip__figure--${state}`}>
				<div className={icon}></div>
			</div>
		</div>
	)
}
export default Chip;
