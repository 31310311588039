import React, {useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <div className="react-datepicker__input">
			<i className="ico-calendar"></i>
      <label onClick={props.onClick} ref={ref}>{props.value || props.placeholder}</label>
			<i className="ico-chevron-down" onClick={props.onClick}></i>
    </div>
  );
});

export function dateStartFilterFn(rows, id, filterValues) {
  const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
  if (sd) {
    return rows.filter((r) => {
      var date = r.values[id];
      var [day, month,year] = date.split("-");
      var formattedData = [year, month, day].join("-");
      const cellDate = new Date(formattedData);
			return cellDate >= sd;
    });
  } else {
    return rows;
  }
}

export function dateEndFilterFn(rows, id, filterValues) {
  const ed = filterValues[1] ? new Date(filterValues[1]) : undefined;
  if (ed) {
    return rows.filter((r) => {
      var date = r.values[id];
      var [day, month,year] = date.split("-");
      var formattedData = [year, month, day].join("-");

      const cellDate = new Date(formattedData);

			return cellDate <= ed;

    });
  } else {
    return rows;
  }
}

export function DateColumnFilter({column: {setFilter, Header}}) {

	const [startDate, setStartDate] = useState('');

	const handleSelectDate = (date) => {
		setFilter((old = []) => [old[0],date ? date : undefined]);
		setStartDate(date);
	}

  return (
		<DatePicker
			locale="es"
			selected={startDate}
			onChange={(date) => handleSelectDate(date)}
			placeholderText={Header}
			dateFormat="dd-MM-yyyy"
			customInput={<CustomInput/>}

			showYearDropdown
      dateFormatCalendar="MMMM"
      yearDropdownItemNumber={15}
      scrollableYearDropdown
		/>
  );
}
