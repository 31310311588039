import { EnumEstadoObjetarEventos } from "../../../Utils/Enumeradores/ComunEnum";

export const Columns = [
    { Header: 'Id Evento', accessor: 'eventoConcesionId' },
    { Header: 'Hora Inicio de Indisp.', accessor: 'fechaHoraInicioIndisponibilidad' },
    { Header: 'Hora Fin de Indisp.', accessor: 'fechaHoraFinIndisponibilidad' },
    { Header: 'PK Inicial', accessor: 'pkInicial' },
    { Header: 'PK Final', accessor: 'pkFinal' },
    { Header: <span  >
    Nº Pistas<br/> VIA.
  </span>, accessor: 'numeroPistas' },
    { Header: <span >
    Nº Pistas<br/> Obstruidas.
  </span>, accessor: 'numeroPistasObstruidas' },
    { Header: <span >
    Tiempo de<br/> Obstrucción(TO).
  </span>, accessor: 'tiempoObstruccion' },
    { Header: <span >
    Nivel<br/> Indisp.
  </span>, accessor: 'nivelIndisponibilidad' },
    { Header: <span >
    Factor<br/> Indisp.
  </span>, accessor: 'factorIndisponibilidadActual' }, 
    { Header:  <span >
    Factor Indisp.<br/> Solicitado
  </span>, accessor: 'factorIndisponibilidadSolicitado'
    },
    { Header: <span >
    Factor Indisp.<br/> Otorgado
  </span>, accessor: 'factorIndisponibilidadOtorgado', className: 'factor-indisp' },
    {
      Header: 'Estado', 
      accessor: 'Estado' ,
      className: 'text-center',
      disabledFilters: true,
      Cell: ({ row }) => <span
      className={`badge badge--monochromatic
        ${row.original.estado === EnumEstadoObjetarEventos[3] ? 'bg-success' : ''}
        ${row.original.estado === EnumEstadoObjetarEventos[0] ? 'bg-primary' : ''}
        ${row.original.estado === EnumEstadoObjetarEventos[1] ? 'bg-info' : ''}
        ${row.original.estado === EnumEstadoObjetarEventos[5] ? 'bg-gray' : ''}
        ${row.original.estado === EnumEstadoObjetarEventos[4] ? 'bg-danger' : ''}
        `}
        style={{
          backgroundColor: row.original.estado === EnumEstadoObjetarEventos[2] ? '#FFF1E2' : '',
          color: row.original.estado === EnumEstadoObjetarEventos[2] ? '#FF8D0E' : '',
        }}
       >
    {row.original.estado === EnumEstadoObjetarEventos[2] ? "Solicitado" : row.original.estado === EnumEstadoObjetarEventos[1] ? "Vencido" : row.original.estado === EnumEstadoObjetarEventos[3] ? "Aprobado" : row.original.estado === EnumEstadoObjetarEventos[4] ? "Rechazado" : row.original.estado === EnumEstadoObjetarEventos[0] ? "Sin Solicitud" : row.original.estado === EnumEstadoObjetarEventos[5] ? "Sin Respuesta" : ""}
  </span>
    },
    { Header: 'Fecha Objeción', accessor: 'fechaObjecion' },
    { Header: 'Fecha Respuesta', accessor: 'fechaRespuesta' }
  ];  