import { useCallback, useState } from 'react'
import { useComun } from '../../../Hooks/useComun';
import { EnumModuloDesc } from '../../../Utils/Enumeradores/ComunEnum';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { ListadoAlarmaListaService } from '../Services/ListadoAlarmaListaService';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

export function useAlarmaLista() {

    const [listadoAlarmasLista, setListadoAlarmasLista] = useState({ listaAlarmaDistribucion: [], agregarLista: false });
    const { downloadCSV } = useComun();
    const { usuario } = useLogin();
		const { crearRegistroTDR } = useRegistroTDR();

    async function postListadoAlarmaLista(exportacion) {
			const startTime = new Date();
        const resp = await ListadoAlarmaListaService(exportacion, usuario.id);
        if (resp) {
            if (resp.status === 200) {
                if (!exportacion.exportacion) {
                    if (resp.data) {
												const fetchData = async () => {
													await Promise.all([
														setListadoAlarmasLista(resp.data),
													]);
													const endTime = new Date();
													const tdrId = 77;
													crearRegistroTDR(startTime, endTime, usuario, tdrId);
												};

												fetchData();
                    }
                } else {
                    if (resp.data) {
												const fetchData = async () => {
													await Promise.all([
														downloadCSV(resp.data, 'ListaDistribucion', EnumModuloDesc.DescargaAlarmasListas),
													]);
													const endTime = new Date();
													const tdrId = 83;
													crearRegistroTDR(startTime, endTime, usuario, tdrId);
												};

												fetchData();

                    }
                }
            }
        }
    }

    const getListadoAlarmaLista = useCallback(
        function (exportacion) {
            postListadoAlarmaLista(exportacion);
        },
        []
    );

    return { listadoAlarmasLista, getListadoAlarmaLista }
}
