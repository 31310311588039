const ChipAlarma = ({...props}) => {
	const {title, time, text, value} = props;
	return (
		<div className="chip chip--standar">
			<div className="chip__info flex-fill mw-50">
				<b className="chip__title">{title}</b>
				<small className="text-start chip__label">{time}</small>
			</div>
			<div className="chip__status mw-50 flex-fill">
				<small className="chip__label text-wrap">{text}</small>
				<b>{value}</b>
			</div>
		</div>
	)
}

export default ChipAlarma;
