import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import InputTextField from "../../../Components/InputTextField/InputTextField";
import brandIsa from "../../../Assets/images/brand-isa.svg";
import validations from "../../../Utils/Validations/Validations";
import { cambioClave } from "../Services/CambioClaveService";
import { useToast } from "../../../Hooks/useToast";

const ChangePassword = () => {
	const params = useParams();
	const parametroUrl ={
		id: atob(params.id)
	}
	const navigate = useNavigate();
	const addToast = useToast();
	async function postCambioClave(values) {
		const resp = await cambioClave(values);
		if (resp) {
			if (resp.status === 200) {
				navigate("/", { replace: true });
				addToast({mostrar : true, mensaje: 'contraseña actualizada con exito.', titulo : 'Confirmación', tipo:'Success'});
			}
		}
	}
	const CambiarPass = (values) => {
		const parametros = {
			id: parametroUrl.id,
			codigo:values.code,
			clave: values.password
		}
		postCambioClave(parametros);
	}
	return (
		<div className="container min-vh-100">
			<div className="row justify-content-center align-items-center min-vh-100">
				<div className="col-12 col-md-7 col-lg-5 col-xl-4">
					<div className="card card--login">
						<div className="card-body p-4 p-md-5">
							<img className="d-flex mb-5 mx-auto" src={brandIsa} width="187" alt="ruta loa" />
							<span className="fs-4 text-pewter-blue mb-4 d-block">Recuperar contraseña</span>
							<Formik
								initialValues={{ code: '', password: '', verification: '' }}
								validate={validations}
								onSubmit={async (values, { setSubmitting, resetForm })  => {
									setSubmitting(false)
									CambiarPass(values);
								}}
							>
								{({
									values,
									setFieldValue,
								}) => (
									<Form className="form mb-0">
										<InputTextField
											name="code"
											label="Ingresa Código de verificación"
											type="text"
											placeholder="verificación"
											autoComplete="false"
											maxLength="5"
											onInput={(e) => { e.target.value = e.target.value.replace(/\D/g, ''); setFieldValue("code", e.target.value.replace(/\D/g, '')); }}
										/>
										<InputTextField
											name="password"
											label="Nueva Contraseña"
											type="password"
											placeholder="nueva"
											autoComplete="false"
											maxLength="20"
										/>
										<InputTextField
											name="verification"
											label="Confirmar Contraseña"
											type="password"
											placeholder="confirmación"
											autoComplete="false"
											maxLength="20"
										/>
										<div className="d-grid mb-4">
											<button type="submit" className="btn btn-primary">Enviar</button>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ChangePassword;
