import instance, { customConfigFormData } from "../../../Utils/Interceptors/Interceptor";



const GuardarAvatar = async (values) => {
    try {
        const resultado = await instance.Backend.post("/Usuario/SubirAvatarUsuario",
            values, customConfigFormData
        );

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export {GuardarAvatar};
