import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../../../Components/Button/Button';
import { useToast } from '../../../Hooks/useToast';
import { EnumEstadoEventoConcesion } from "../../../Utils/Enumeradores/ComunEnum";
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { ModificarEstadoEventoConcesionServices } from '../Services/ModificarEstadoEventoConcesionServices';
import { ModificarEstadosEventosConcesionXIndicadorServices } from '../Services/ModificarEstadosEventosConcesionXIndicadorServices';

const ModalEstadoAutorizacion = (props) => {
    const { renderizar, datosModal, renderizarContadorMedicion, ...rest } = props;
    const { listaIds, esIndicador, esAutorizado, datosBusquedaModificacion } = datosModal;
    const [mensajeModal, setMensajeModal] = useState({ titulo: "", texto: "" });
    const addToast = useToast();
    const { Autorizado } = EnumEstadoEventoConcesion;
    const { usuario } = useLogin();

    const btnModificarEstadoMedicion = async () => {
        const values = {
            idUsuario: usuario.id,
            eventoConcesionIds: listaIds,
            estado: esAutorizado,
        }
        
        if (esIndicador === false){
            const resp = await ModificarEstadoEventoConcesionServices(values);
            if (resp) {
                if (resp.status === 200) {
                    if (resp.data) {
                        addToast({ mostrar: true, mensaje: 'Se actualizó el estado del evento manual correctamente.', titulo: 'Confirmación', tipo: 'Success' });
                        renderizar();
                        renderizarContadorMedicion();
                    }
                }
            }
        }
        else{
            const resp = await ModificarEstadosEventosConcesionXIndicadorServices(datosBusquedaModificacion);
            if (resp) {
                if (resp.status === 200) {
                    if (resp.data) {
                        addToast({ mostrar: true, mensaje: 'Se actualizó el estado de los eventos manuales correctamente.', titulo: 'Confirmación', tipo: 'Success' });
                        renderizar();
                        renderizarContadorMedicion();
                    }
                }
            }
        }
        rest.onHide();
    }
    useEffect(() => {
        if (esIndicador === false) {
            if (esAutorizado === Autorizado) {
                setMensajeModal({ titulo: "¿Está seguro que desea aprobar este evento manual?" });
            } else {
                setMensajeModal({ titulo: "¿Está seguro que desea rechazar este evento manual?" });
            }
        } else {
            if (esAutorizado === Autorizado) {
                setMensajeModal({ titulo: "¿Está seguro que desea aprobar esta lista eventos manuales?" });
            } else {
                setMensajeModal({ titulo: "¿Está seguro que desea rechazar esta lista de eventos manuales?" });
            }
        }
    }, [props]);
    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className='info my-4'>
                    <span className='info__icon'>
                        <i className='ico-user-delete'></i>
                    </span>
                    <h6 className='info__title'>{mensajeModal.titulo}</h6>
                    <p className='info__text'>{mensajeModal.texto}</p>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4">
                    <Button type="button" design="btn-outline-pewter-blue btn--small" onClick={rest.onHide}>Cancelar</Button>
                    <Button type="button" design="btn-primary btn--small" onClick={btnModificarEstadoMedicion}>Aceptar</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default ModalEstadoAutorizacion;