import instance, { customConfigFormData } from "../../../Utils/Interceptors/Interceptor";


const IngresoProrrogaService = async (values) => {
    try {
        return await instance.Backend.post("/Hallazgo/ProrrogaIngreso",values, customConfigFormData);
    } catch (error) {
        console.log(error);
    }
}
export { IngresoProrrogaService };