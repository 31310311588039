import React, { useEffect, useMemo, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import TableUser from '../Components/TablaUsuario';
import { useColumnsAlerta } from '../Utils/Columns';
import { EnumPathRutas, EnumTablaPaginacion } from '../../../Utils/Enumeradores/ComunEnum'
import MedicionesManuales from '../Components/MedicionesManuales';
import { Link } from 'react-router-dom';
import { ContadorUsuariosPendienteAutorizacionService } from '../Services/ContadorUsuariosPendienteAutorizacionService';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { BuscadorAutorizacionesService } from '../Services/BuscadorAutorizacionesService';
import { useComun } from '../../../Hooks/useComun';
import { EnumModuloDesc } from "../../../Utils/Enumeradores/ComunEnum";
import { useContadores } from '../../../Hooks/useContadores';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const AutorizacionesPage = () => {
	const { usuario, user, bitacoraDetalle, registraBitacoraMovimientoUsuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const { actualizarContador, contador } = useContadores();
	const [contadorUsuariosPendienteAutorizacion, setContadorUsuariosPendienteAutorizacion] = useState(0);
	const { downloadCSV } = useComun();
	const [esRenderizar, setEsRenderizar] = React.useState(false);
	const [data, setData] = React.useState([]);
	const [pageCount, setPageCount] = React.useState(0);
	const [{ pageIndex, pageSize, sortBy }, setPagination] =
		React.useState({
			pageIndex: 0,
			pageSize: EnumTablaPaginacion.CantidadPaginacion,
			sortBy: []
		});
	const { contadorMediciones, getContadorMediciones } = useComun();
	const [key, setKey] = useState('usuarios');
	const [esRenderizarContadorMedicion, setEsRenderizarContadorMedicion] = React.useState(false);

	useEffect(() => {
		if (key === 'usuarios') {
			registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoAutorizacionesUsuario, idBitacoraDetalle: bitacoraDetalle},()=>{});
		}else if(key === 'manuales') {
			registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoAutorizacionesEventosManuales, idBitacoraDetalle: bitacoraDetalle},()=>{});
		}
	}, [key]);

	const renderizarListadoUsuario = () => {
		setEsRenderizar(true);
	}

	const renderizarContadorMedicion = () => {
		setEsRenderizarContadorMedicion(!esRenderizarContadorMedicion);
		actualizarContador(!contador);
	}

	const { COLUMNS } = useColumnsAlerta({ renderizar: renderizarListadoUsuario });
	const columns = useMemo(() => COLUMNS, []);

	async function postContadorUsuariosPendienteAutorizacion(usuarioId) {
		const resp = await ContadorUsuariosPendienteAutorizacionService(usuarioId);
		if (resp) {
			if (resp.status === 200) {
				setContadorUsuariosPendienteAutorizacion(resp.data);
			}
		}
	}

	async function postBuscadorAutorizaciones(values) {
		const startTime = new Date();
		const resp = await BuscadorAutorizacionesService(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					if (values.datosBusqueda.exportacion) {
						downloadCSV(resp.data, "Autorizaciones", EnumModuloDesc.DescargaAutorizacionesEventosManuales);
					} else {
						const fetchData = async () => {
							await Promise.all([
								setPageCount(resp.data.cantidadPaginas),
								setData(resp.data.listadoAutorizaciones),
							]);
							const endTime = new Date();
							const tdrId = 51;
							crearRegistroTDR(startTime, endTime, usuario, tdrId);
						};

						fetchData();
					}
				}
			} else if (resp.status === 206) {
				const fetchData = async () => {
					await Promise.all([
						setPageCount(0),
						setData([]),
					]);
					const endTime = new Date();
					const tdrId = 51;
					crearRegistroTDR(startTime, endTime, usuario, tdrId);
				};

				fetchData();
			}
		}
	}



	useEffect(() => {
		postContadorUsuariosPendienteAutorizacion(usuario.id);
	}, [esRenderizar])

	useEffect(() => {
		getContadorMediciones();
	}, [esRenderizarContadorMedicion])

	const fetchData = React.useCallback(({ formulario, pageIndex, sortBy = [], exportacion = false }) => {
		formulario.paginacion.numeroPagina = pageIndex + 1;
		formulario.paginacion.ordenadoPor = sortBy.length > 0 ? sortBy[0].id : formulario.paginacion.ordenadoPor;
		formulario.paginacion.orderByAscending = sortBy.length > 0 ? (sortBy[0].desc ? false : true) : formulario.paginacion.orderByAscending;
		formulario.datosBusqueda.exportacion = exportacion;
		postBuscadorAutorizaciones(formulario);
	}, [])

	return (
		<div className="container-fluid">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
							<li><a href="#">Autorizaciones</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="bg-white rounded p-4">
						<Tab.Container id="users" defaultActiveKey="usuarios">
							<Nav className='mb-3 nav-tabs'>
								<Nav.Item>
									<Nav.Link eventKey="usuarios" onClick={() => setKey('usuarios')}>
										Usuarios
										{contadorUsuariosPendienteAutorizacion > 0 &&
											<span className="badge">{contadorUsuariosPendienteAutorizacion}</span>
										}
									</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="manuales" onClick={() => setKey('manuales')}>
										Eventos manuales
										{contadorMediciones > 0 &&
											<span className="badge">{contadorMediciones}</span>
										}
									</Nav.Link>
								</Nav.Item>
							</Nav>
							<Tab.Content>
								<Tab.Pane eventKey="usuarios">
									<TableUser
										columns={columns}
										data={data}
										fetchData={fetchData}
										pageCount={pageCount}
										currentpage={pageIndex}
										pageSize={pageSize}
										sortBy={sortBy}
										setPagination={setPagination}
										esRenderizar={esRenderizar}
										setEsRenderizar={setEsRenderizar}
									/>
								</Tab.Pane>
								<Tab.Pane eventKey="manuales">
									{key === 'manuales' &&
										<MedicionesManuales renderizarContadorMedicion={renderizarContadorMedicion}/>
									}
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AutorizacionesPage;
