import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from "react-bootstrap/AccordionContext";
import Galeria from '../../../Components/Galeria/Galeria';
import { Form, Formik } from 'formik';
import Button from '../../../Components/Button/Button';
import TextAreaField from '../../../Components/TextAreaField/TextAreaField';
import SelectCheckField from '../../../Components/SelectCheckField/SelectCheckField';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { DetalleHallazgoService } from '../Services/DetalleHallazgoService';
import moment from 'moment';
import { useComun } from '../../../Hooks/useComun';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { EnumAccionHallazgo, EnumAccionProrroga, EnumEstadoHallazgo, EnumEstadoProrroga, EnumEtapaHallazgo, EnumEtapaProrroga, EnumHallazgoOrigen, EnumMensajeErrorArchivos, EnumModuloDesc, EnumParametro, EnumPerfil, EnumTipoArchivos } from '../../../Utils/Enumeradores/ComunEnum';
import InputFileField from '../../../Components/InputFileField/InputFileField';
import validations, { TamanoArchivo, onBlurComentario, sinDobleEspacio, sinExtension } from '../../../Utils/Validations/Validations';
import { useToast } from '../../../Hooks/useToast';
import DatePickerComponent from '../../../Components/DatePicker/DatePicker';
import { IngresoProrrogaService } from '../Services/IngresoProrrogaService';
import { ActualizacionProrrogaService } from '../Services/ActualizacionProrrogaService';
import { ActualizaHallazgoService } from '../Services/ActualizaHallazgoService';
import Avatar from '../../../Components/Avatar/Avatar';
import { ObtenerArchivoBimOperacionService } from '../Services/ObtenerArchivoBimOperacionService';

function ContextAwareToggle({ children, eventKey, callback }) {
	const { activeEventKey } = useContext(AccordionContext);
	const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

	const isCurrentEventKey = activeEventKey.indexOf(eventKey) > -1;

	return (
		<button className='accordion-toggle' type="button" onClick={decoratedOnClick}>
			{isCurrentEventKey ? <i className="ico-chevron-down"></i> : <i className="ico-chevron-up"></i>}
		</button>
	);
}

const ModalDetalleHallazgo = (props) => {
	const { usuario } = useLogin();
	const { datosModal, renderizar, ...rest } = props;
	const [tab, setTab] = useState('historial');
	const { downloadXUrl, obtenerFormatoFecha, formatoBytes, getAccionActualizaHallazgo, listaAccionActualizarHallazgo, getObtenerUsuariosPorPerfil, listaUsuarioXPerfil
		, ParametroObtenerPorId, usuarioBimOperacion, downloadCSV } = useComun();
	const [dataDetalle, setDataDetalle] = useState({});
	const [dataDetalleHallazgo, setDataDetalleHallazgo] = useState({});
	const [listaImagenes, setListaImagenes] = useState([]);
	const [listaArchivos, setListaArchivos] = useState([]);
	const [initialValues, setInitialValues] = useState({});
	const [initialValuesHallazgo, setInitialValuesHallazgo] = useState({ comentarioHallazgo: '', fileHallazgo: '' });
	const addToast = useToast();
	const [btnAccion, setBtnAccion] = useState('');
	const [btnMostrarFormulario, setBtnMostrarFormulario] = useState(false);
	const [btnMostrarFormularioHallazgo, setBtnMostrarFormularioHallazgo] = useState(false);
	const [nombreLabel, setNombreLabel] = useState("Resolutor");
	const [btnAccionHallazgo, setBtnAccionHallazgo] = useState('');
	const [btnSubmitHallazgo, setBtnSubmitHallazgo] = useState(false); //Este botón solo se ocupa en el caso que el btnAccionHallazgo sea asigna o reasigna
	const [mostrarListaPerfilesHallazgo, setMostrarListaPerfilesHallazgo] = useState(false);
	const [mostrarAfectaIndicador, setMostrarAfectaIndicador] = useState(false);
	const [esBimOperacion, setEsBimOperacion] = useState(false);
	const afectaIndicador = [
		{ value: true, label: 'Si' },
		{ value: false, label: 'No' }
	];
	async function postDetalleHallazgo(values) {
		const resp = await DetalleHallazgoService(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					setDataDetalleHallazgo(resp.data.esServicio ? resp.data.hallazgoServicio : resp.data.hallazgo)
					setDataDetalle(resp.data);
					setListaImagenes(resp.data.hallazgoImagen.map(x => {
						return ({
							largeURL: x.ruta,
							thumbnailURL: x.ruta,
							width: 567,
							height: 567,
						})
					}))
					const listaArchivosAux = resp.data.hallazgoHistorialDocumento?.concat(resp.data.hallazgoHistorialImagen, resp.data.prorrogaHistorialDocumento, resp.data.prorrogaHistorialImagen)
					setListaArchivos(listaArchivosAux);
				}
			}
		}
	}

	//ObtenerArchivoBimOperacion
	async function postObtenerArchivoBimOperacion(values, item) {
		const resp = await ObtenerArchivoBimOperacionService(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					const extensionLimpia = item.extension.replace(".", "").toLowerCase();
					const nombreLimpio = item.nombre.split(".");
					downloadCSV(resp.data, nombreLimpio[0], EnumModuloDesc.DescargaGestionHallazgos, extensionLimpia);
				}
			}
		}
	}

	function obtenerBotonesActualizacionHallazgo() {
		const envioFormAccion = {
			hallazgoId: dataDetalleHallazgo.hallazgoId,
			usuarioId: usuario.id,
			perfilId: usuario.perfilContexto.id
		}
		getAccionActualizaHallazgo(envioFormAccion);
	}
	function obtenerResolutoresOLiderDeAreaHallazgo(idPerfilUsuario, esUsuarioBimOperacion) {
		const envioFormUsuarios = {
			IdUsuarioContexto: usuario.id,
			idPerfilUsuario: idPerfilUsuario,
			AreaNegocioId: dataDetalleHallazgo.areaNegocioId,
			EsUsuarioBimOperacion: esUsuarioBimOperacion
		}
		getObtenerUsuariosPorPerfil(envioFormUsuarios);
	}
	useEffect(() => {
		if (props.show) {
			if (!dataDetalle.esServicio && initialValuesHallazgo.hasOwnProperty('responsableHallazgo')) {
				setInitialValuesHallazgo(values => ({
					...values
					, responsableHallazgo: listaUsuarioXPerfil[0].value
				}));
				setEsBimOperacion(true);
			}
		}
	}, [listaUsuarioXPerfil])
	function logicaFormularioXRolUsuario() {
		switch (usuario.perfilContexto.id) {
			case EnumPerfil.LiderArea: {
				///HALLAZGO
				if (dataDetalleHallazgo.hallazgoEtapaId === EnumEtapaHallazgo.Asignacion &&
					(dataDetalleHallazgo.hallazgoEtadoId === EnumEstadoHallazgo.EnProceso ||
						dataDetalleHallazgo.hallazgoEtadoId === EnumEstadoHallazgo.Objetado ||
						dataDetalleHallazgo.hallazgoEtadoId === EnumEstadoHallazgo.Rechazado)) {
					obtenerBotonesActualizacionHallazgo();
					obtenerResolutoresOLiderDeAreaHallazgo(EnumPerfil.Resolutor, dataDetalle.esServicio ? false : true);
					setMostrarListaPerfilesHallazgo(true);
					setInitialValuesHallazgo({ comentarioHallazgo: '', responsableHallazgo: '', fileHallazgo: '' }); //responsableHallazgo
					setBtnMostrarFormularioHallazgo(true);
				} else if ((dataDetalleHallazgo.hallazgoEtapaId === EnumEtapaHallazgo.LiderArea && dataDetalleHallazgo.hallazgoEtadoId === EnumEstadoHallazgo.EnProceso) ||
					(dataDetalleHallazgo.hallazgoEtapaId === EnumEtapaHallazgo.LiderArea && dataDetalleHallazgo.hallazgoEtadoId === EnumEstadoHallazgo.Rechazado)) {
					obtenerBotonesActualizacionHallazgo();
					setBtnMostrarFormularioHallazgo(true);
				}
				/// FIN HALLAZGO
				///PRORROGA
				setInitialValues({ comentarioProrroga: '', fileProrroga: '', fechaProrroga: '' });
				if (dataDetalle.prorrogaMayor === null) {
					setBtnMostrarFormulario(true);
				}
				///FIN PRORROGA
				break;
			}
			case EnumPerfil.LiderConcesionaria: {
				///HALLAZGO
				if (dataDetalleHallazgo.hallazgoEtapaId === EnumEtapaHallazgo.LiderConcesionaria && dataDetalleHallazgo.hallazgoEtadoId === EnumEstadoHallazgo.Rechazado) {
					obtenerBotonesActualizacionHallazgo();
					setInitialValuesHallazgo({ comentarioHallazgo: '', fileHallazgo: '' });
					setBtnMostrarFormularioHallazgo(true);
				} else if (dataDetalleHallazgo.hallazgoEtapaId === EnumEtapaHallazgo.LiderConcesionaria &&
					(dataDetalleHallazgo.hallazgoEtadoId === EnumEstadoHallazgo.EnProceso || dataDetalleHallazgo.hallazgoEtadoId === EnumEstadoHallazgo.Resuelto)) {
					obtenerBotonesActualizacionHallazgo();
					setBtnMostrarFormularioHallazgo(true);
				}
				/// FIN HALLAZGO
				///PRORROGA
				setInitialValues({ comentarioProrroga: '', fileProrroga: '' });
				if (dataDetalle.prorrogaMayor !== null) {
					if ((dataDetalle.prorrogaMayor.idEstado === EnumEstadoProrroga.EnProceso && dataDetalle.prorrogaMayor.idEtapa === EnumEtapaProrroga.ValidacionLC) ||
						(dataDetalle.prorrogaMayor.idEstado === EnumEstadoProrroga.Rechazado && dataDetalle.prorrogaMayor.idEtapa === EnumEtapaProrroga.ValidacionLC)) {
						setBtnMostrarFormulario(true);
					}
				}
				///FIN PRORROGA
				break;
			}
			case EnumPerfil.InspectorFiscal: {
				///HALLAZGO
				if (dataDetalleHallazgo.hallazgoEtapaId === EnumEtapaHallazgo.ResolucionIF && dataDetalleHallazgo.hallazgoEtadoId === EnumEstadoHallazgo.Resuelto) {
					obtenerBotonesActualizacionHallazgo();
					setMostrarAfectaIndicador(true);
					setInitialValuesHallazgo({ comentarioHallazgo: '', afectaIndicadorSinValidacion: '', fileHallazgo: '' });
					setBtnMostrarFormularioHallazgo(true);
				}
				if (dataDetalleHallazgo.hallazgoEtapaId === EnumEtapaHallazgo.Ingreso && dataDetalleHallazgo.hallazgoEtadoId === EnumEstadoHallazgo.Preingreso) {
					obtenerBotonesActualizacionHallazgo();
					setBtnMostrarFormularioHallazgo(true);
				}
				/// FIN HALLAZGO
				///PRORROGA

				if (dataDetalle.prorrogaMayor !== null) {
					if (dataDetalle.prorrogaMayor.idEstado === EnumEstadoProrroga.EnProceso && dataDetalle.prorrogaMayor.idEtapa === EnumEtapaProrroga.ValidacionIF) {
						setInitialValues({ comentarioProrroga: '', fileProrroga: '', fechaProrrogaIP: moment(dataDetalle.prorrogaMayor.fechaProrroga).toDate() });
						setBtnMostrarFormulario(true);
					}
				}
				///FIN PRORROGA
				break;
			}
			case EnumPerfil.Resolutor: {
				///HALLAZGO
				if (dataDetalleHallazgo.hallazgoEtapaId === EnumEtapaHallazgo.Resolutor &&
					(dataDetalleHallazgo.hallazgoEtadoId === EnumEstadoHallazgo.Validacion ||
						dataDetalleHallazgo.hallazgoEtadoId === EnumEstadoHallazgo.EnProceso ||
						dataDetalleHallazgo.hallazgoEtadoId === EnumEstadoHallazgo.Rechazado)) {
					obtenerBotonesActualizacionHallazgo();
					setBtnMostrarFormularioHallazgo(true);
				}
				/// FIN HALLAZGO

				break;
			}
		}
	}
	function ObtenerDetalleHallazgo() {
		const formEnvio = {
			idUsuario: usuario.id,//75,
			idPerfilContexto: usuario.perfilContexto.id,
			hallazgoId: datosModal.hallazgoId //7294
		}
		postDetalleHallazgo(formEnvio);
	}
	useEffect(() => {
		if (props.show) {
			ObtenerDetalleHallazgo();
			ParametroObtenerPorId(EnumParametro.UsuarioBimOperacion);
		} else {
			setBtnMostrarFormulario(false);
			setBtnMostrarFormularioHallazgo(false);
			setBtnAccionHallazgo('');
			setBtnSubmitHallazgo(false);
			setMostrarListaPerfilesHallazgo(false);
			setMostrarAfectaIndicador(false);
		}

	}, [props.show])

	useEffect(() => {
		if (props.show) {
			logicaFormularioXRolUsuario();
		}
	}, [dataDetalle])

	const formikRef = useRef();
	const formikHallazgoRef = useRef();

	function handlerFilesHallazgo(event) {
		const respuestaExtension = sinExtension(event.target.files[0]);
		const respuestaTamano = TamanoArchivo(event.target.files[0]);
		if (formikHallazgoRef.current.values.fileHallazgo.length < 2) {
			if (respuestaExtension === "" && respuestaTamano === "") {
				let nuevaLista = formikHallazgoRef.current.values.fileHallazgo !== '' ? formikHallazgoRef.current.values.fileHallazgo : [];
				nuevaLista.push(...Array.prototype.slice.call(event.target.files));
				formikHallazgoRef.current.setFieldValue("fileHallazgo", nuevaLista);
			}
			else {
				formikHallazgoRef.current.setFieldError("fileHallazgo", respuestaExtension !== "" ? respuestaExtension : respuestaTamano);
			}
		} else if (formikHallazgoRef.current.values.fileHallazgo.length === 2) {
			formikHallazgoRef.current.setFieldError("fileHallazgo", EnumMensajeErrorArchivos.CantidadAdjunto);
		}
	}

	function eliminarFileHallazgo(posicionFila) {
		const nuevaLista = formikHallazgoRef.current.values.fileHallazgo.filter((x, index) => index !== posicionFila);
		formikHallazgoRef.current.setFieldValue("fileHallazgo", nuevaLista);
	}

	function handlerFiles(event) {
		const respuestaExtension = sinExtension(event.target.files[0]);
		const respuestaTamano = TamanoArchivo(event.target.files[0]);
		if (formikRef.current.values.fileProrroga.length < 2) {
			if (respuestaExtension === "" && respuestaTamano === "") {
				let nuevaLista = formikRef.current.values.fileProrroga !== '' ? formikRef.current.values.fileProrroga : [];
				nuevaLista.push(...Array.prototype.slice.call(event.target.files));
				formikRef.current.setFieldValue("fileProrroga", nuevaLista);
			}
			else {
				formikRef.current.setFieldError("fileProrroga", respuestaExtension !== "" ? respuestaExtension : respuestaTamano);
			}
		} else if (formikRef.current.values.fileProrroga.length === 2) {
			formikRef.current.setFieldError("fileProrroga", EnumMensajeErrorArchivos.CantidadAdjunto);
		}
	}

	function eliminarFile(posicionFila) {
		const nuevaLista = formikRef.current.values.fileProrroga.filter((x, index) => index !== posicionFila);
		formikRef.current.setFieldValue("fileProrroga", nuevaLista);
	}

	async function postIngresoProrroga(values) {
		const resp = await IngresoProrrogaService(values);
		if (resp) {
			if (resp.status === 200) {
				addToast({ mostrar: true, mensaje: 'Se ha ingresado la prorroga correctamente.', titulo: 'Confirmación', tipo: 'Success' });
				setBtnMostrarFormulario(false);
				ObtenerDetalleHallazgo();
				renderizar();
			}
		}
	}

	async function postActualizacionProrroga(values) {
		const resp = await ActualizacionProrrogaService(values);
		if (resp) {
			if (resp.status === 200) {
				addToast({ mostrar: true, mensaje: 'Se ha actualizado la prorroga correctamente.', titulo: 'Confirmación', tipo: 'Success' });
				setBtnMostrarFormulario(false);
				ObtenerDetalleHallazgo();
				renderizar();
			}
		}
	}

	async function postActualizaHallazgo(values) {
		const resp = await ActualizaHallazgoService(values);
		if (resp) {
			if (resp.status === 200) {
				addToast({ mostrar: true, mensaje: 'Se ha actualizado el hallazgo correctamente.', titulo: 'Confirmación', tipo: 'Success' });
				setBtnMostrarFormularioHallazgo(false);
				ObtenerDetalleHallazgo();
				renderizar();
			}
		}
	}

	function IngresoProrroga(values) {
		let formData = new FormData();
		formData.append('IdUsuario', usuario.id);
		formData.append('IdPerfilContexto', usuario.perfilContexto.id);
		formData.append('HallazgoId', datosModal.hallazgoId);
		formData.append('FechaProrroga', moment(values.fechaProrroga).format('yyyy-MM-DD'));
		formData.append('Comentario', values.comentarioProrroga);
		if (values.fileProrroga !== '') {
			values.fileProrroga.map((value, index) => {
				formData.append("Documentos", value);
			});
		}

		postIngresoProrroga(formData);
	}
	function ActualizacionProrroga(values) {
		let formData = new FormData();
		formData.append('IdUsuario', usuario.id);
		formData.append('IdPerfilContexto', usuario.perfilContexto.id);
		formData.append('HallazgoId', datosModal.hallazgoId);
		formData.append('ProrrogaId', values.prorrogaId);
		formData.append('FechaProrroga', moment(values.fechaProrroga).format('yyyy-MM-DD'));
		formData.append('Accion', values.accion);
		formData.append('Comentario', values.comentarioProrroga);
		if (values.fileProrroga !== '') {
			values.fileProrroga.map((value, index) => {
				formData.append("Documentos", value);
			});
		}
		postActualizacionProrroga(formData);
	}

	function changeBtnAccionHallazgo(valueAccion, values) {
		setBtnAccionHallazgo(valueAccion);
		if (valueAccion === EnumAccionHallazgo.Aprobada && usuario.perfilContexto.id === EnumPerfil.InspectorFiscal && dataDetalleHallazgo.hallazgoEtapaId === EnumEtapaHallazgo.ResolucionIF && dataDetalleHallazgo.hallazgoEtadoId === EnumEstadoHallazgo.Resuelto) {
			setInitialValuesHallazgo({ comentarioHallazgo: values.comentarioHallazgo, afectaIndicador: values.hasOwnProperty('afectaIndicadorSinValidacion') ? values.afectaIndicadorSinValidacion : values.afectaIndicador, fileHallazgo: values.fileHallazgo });
		} else if (valueAccion === EnumAccionHallazgo.Rechazada && usuario.perfilContexto.id === EnumPerfil.InspectorFiscal && dataDetalleHallazgo.hallazgoEtapaId === EnumEtapaHallazgo.ResolucionIF && dataDetalleHallazgo.hallazgoEtadoId === EnumEstadoHallazgo.Resuelto) {
			setInitialValuesHallazgo({ comentarioHallazgo: values.comentarioHallazgo, afectaIndicadorSinValidacion: values.hasOwnProperty('afectaIndicador') ? values.afectaIndicador : values.hasOwnProperty('afectaIndicadorSinValidacion') ? values.afectaIndicadorSinValidacion : '', fileHallazgo: values.fileHallazgo });
		} else if (valueAccion === EnumAccionHallazgo.Asigna || valueAccion === EnumAccionHallazgo.Reasigna) {
			setInitialValuesHallazgo({ comentarioHallazgo: values.comentarioHallazgo, profile: values.hasOwnProperty('responsableHallazgo') ? values.responsableHallazgo : values.profile, fileHallazgo: values.fileHallazgo });
		} else {
			setInitialValuesHallazgo({ comentarioHallazgo: values.comentarioHallazgo, responsableHallazgo: values.hasOwnProperty('profile') ? values.profile : values.hasOwnProperty('responsableHallazgo') ? values.responsableHallazgo : '', fileHallazgo: values.fileHallazgo });
		}

	}

	useEffect(() => {
		if (props.show) {
			if (btnAccionHallazgo === EnumAccionHallazgo.Aprobada && usuario.perfilContexto.id === EnumPerfil.InspectorFiscal && dataDetalleHallazgo.hallazgoEtapaId === EnumEtapaHallazgo.ResolucionIF && dataDetalleHallazgo.hallazgoEtadoId === EnumEstadoHallazgo.Resuelto) {
				formikHallazgoRef.current.setTouched({ comentarioHallazgo: true, afectaIndicador: true });
				formikHallazgoRef.current.validateForm(initialValuesHallazgo);
				setBtnSubmitHallazgo(true);
			} else if (btnAccionHallazgo === EnumAccionHallazgo.Asigna || btnAccionHallazgo === EnumAccionHallazgo.Reasigna) {
				formikHallazgoRef.current.setTouched({ comentarioHallazgo: true, profile: true });
				formikHallazgoRef.current.validateForm(initialValuesHallazgo);
				setBtnSubmitHallazgo(true);
			} else if (btnAccionHallazgo !== '') {
				formikHallazgoRef.current.setTouched({ comentarioHallazgo: true });
				formikHallazgoRef.current.validateForm(initialValuesHallazgo);
				setBtnSubmitHallazgo(true);
			}
		}
	}, [initialValuesHallazgo])

	useEffect(() => {
		if (props.show && btnSubmitHallazgo) {
			if (formikHallazgoRef.current.isValid) {
				guardarHallazgo(initialValuesHallazgo);
				setBtnSubmitHallazgo(false);
			} else {
				setBtnSubmitHallazgo(false);
			}
		}
	}, [btnSubmitHallazgo])

	function guardarHallazgo(values) {
		let formData = new FormData();
		formData.append('HallazgoId', datosModal.hallazgoId);
		formData.append('UsuarioId', usuario.id);
		formData.append('PerfilId', usuario.perfilContexto.id);
		formData.append('Comentario', values.comentarioHallazgo);
		formData.append('Accion', btnAccionHallazgo);
		formData.append('AfectaIndicador', mostrarAfectaIndicador && dataDetalle.esServicio ? btnAccionHallazgo === EnumAccionHallazgo.Aprobada && values.hasOwnProperty('afectaIndicador') ? values.afectaIndicador : '' : '');
		formData.append('IdUsuarioResolutor', listaUsuarioXPerfil.length > 0 ?
			btnAccionHallazgo === EnumAccionHallazgo.Asigna || btnAccionHallazgo === EnumAccionHallazgo.Reasigna && values.hasOwnProperty('profile') ? values.profile : '' : '');
		if (values.fileHallazgo !== '') {
			values.fileHallazgo.map((value, index) => {
				formData.append("ListaArchivos", value);
			});
		} else {
			formData.append("ListaArchivos", '')
		}
		postActualizaHallazgo(formData);

	}

	function descargaArchivos(item) {
		if (item.usuarioId === usuarioBimOperacion && !item.mime.includes("image")) {
			postObtenerArchivoBimOperacion({
				idUsuario: usuario.id,
				rutaArchivo: item.ruta
			}, item);
		} else {
			downloadXUrl(item.ruta, item.mime, item.nombre, item.extension)
		}
	}

	return (
		<Modal
			{...rest}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className=''
		>
			<Modal.Header closeButton className='pt-4 px-4 gap-2'>
				<div className="state state--bg-default">
					<span className="state__indice">{dataDetalleHallazgo?.hallazgoEtapaId}</span>
					<b>{dataDetalleHallazgo?.hallazgoEtapaDescripcion}</b>
				</div>
				<small className='text-pewter-blue'>{moment(dataDetalleHallazgo?.fechaModificacionHallazgo).format('DD-MM-yyyy HH:mm:ss')}</small>
			</Modal.Header>
			<Modal.Body className='p-4 gap-4 d-flex flex-column'>
				<div className='d-flex flex-column align-items-center fs-4'>
					<b>Hallazgo {dataDetalleHallazgo?.hallazgoId}</b>
					{!dataDetalle?.esServicio &&
						<span>{dataDetalleHallazgo?.macroUbicacion}</span>
					}
					{ }
				</div>
				<Accordion defaultActiveKey={['0', '1', '2', '3']} alwaysOpen>
					<div className='accordion-item'>
						<div className='accordion-header'>
							<div className='d-flex gap-2 align-items-center'>
								<span className='text-dark fs-5 fw-semibold lh-1'>Detalle</span>
							</div>
							<ContextAwareToggle eventKey="0" />
						</div>
						<Accordion.Collapse eventKey="0">
							<div className='accordion-body'>
								<ul className='text-pewter-blue d-flex mb-3 flex-column gap-1 fs-6 word-break-all'>
									{dataDetalle?.esServicio ?
										<>
											<li><b>Líder de área</b>: {dataDetalleHallazgo?.hallazgoUsuarioAsignado}</li>
											<li><b>Fecha notificación</b>: {moment(dataDetalleHallazgo?.fechaNotificacionHallazgo).format('DD-MM-YYYY')}</li>
											<li><b>Fecha detección</b>: {moment(dataDetalleHallazgo?.fechaDeteccion).format('DD-MM-YYYY')}</li>
											<li><b>Fecha límite corrección</b>: {moment(dataDetalleHallazgo?.fechaLimiteCorreccionHallazgo).format('DD-MM-YYYY')}</li>
											<li><b>Problema</b>: {dataDetalleHallazgo?.tipoProblema}</li>
											<li><b>Observación</b>: {dataDetalleHallazgo?.hallazgoObservacion}</li>
											<li><b>Gravedad</b>: {dataDetalleHallazgo?.gravedadDescripcion}</li>
											{
												dataDetalleHallazgo.cantidadMinutos > 0 &&
												<li><b>Cantidad minutos</b>: {dataDetalleHallazgo?.cantidadMinutos}</li>
											}
										</>
										:
										<>
											{datosModal.origen === EnumHallazgoOrigen.SICE &&
												<li><b>Código SICE</b>: {dataDetalleHallazgo?.codigoSICE}</li>
											}
											<li><b>Líder de área</b>: {dataDetalleHallazgo?.hallazgoUsuarioAsignado}</li>
											<li><b>Fecha notificación</b>: {moment(dataDetalleHallazgo?.fechaNotificacionHallazgo).format('DD-MM-YYYY')}</li>
											<li><b>Fecha detección</b>: {moment(dataDetalleHallazgo?.fechaDeteccion).format('DD-MM-YYYY')}</li>
											<li><b>Fecha límite corrección</b>: {moment(dataDetalleHallazgo?.fechaLimiteCorreccionHallazgo).format('DD-MM-YYYY')}</li>
											<li><b>Problema</b>: {dataDetalleHallazgo?.tipoProblema}</li>
											<li><b>Elemento</b>: {dataDetalleHallazgo?.descripcionElemento}</li>
											<li><b>Km inicial</b>: {dataDetalleHallazgo?.kmInicio}</li>
											<li><b>Km final</b>: {dataDetalleHallazgo?.kmFinal}</li>
											<li><b>Gravedad</b>: {dataDetalleHallazgo?.gravedadDescripcion}</li>
											<li><b>Observación</b>: {dataDetalleHallazgo?.hallazgoObservacion}</li>
											{
												dataDetalleHallazgo.cantidadMinutos > 0 &&
												<li><b>Cantidad minutos</b>: {dataDetalleHallazgo?.cantidadMinutos}</li>
											}
											{
												dataDetalleHallazgo?.tipoFallaDescripcion !== '' &&
												<li><b>Tipo falla</b>: {dataDetalleHallazgo?.tipoFallaDescripcion}</li>
											}
											{
												dataDetalleHallazgo?.fiaFim !== null &&
												<li><b>Fia fim</b>: {dataDetalleHallazgo?.fiaFim + '%'}</li>
											}
											{
												dataDetalleHallazgo?.otCorreccion !== null &&
												<li><b>N° Orden de Trabajo</b>: {dataDetalleHallazgo?.otCorreccion}</li>
											}
											{ }
										</>
									}

								</ul>
								{dataDetalle?.esServicio && (dataDetalleHallazgo.hallazgoEtapaId === EnumEtapaHallazgo.ResolucionIF && dataDetalleHallazgo.hallazgoEtadoId === EnumEstadoHallazgo.Cerrado) &&
									<div className="form ms-auto me-0">
										<span className='bg-white-smoke rounded p-2 align-self-start gap-3 text-pewter-blue'>Afecta indicador: {dataDetalleHallazgo.afectaIndicador ? "Si" : "No"}</span>
									</div>
								}
							</div>
						</Accordion.Collapse>
					</div>
					<div className='accordion-item'>
						<div className='accordion-header'>
							<div className='d-flex gap-2 align-items-center'>
								<span className='text-dark fs-5 fw-semibold lh-1'>Fotografías Ubicación</span>
							</div>
							<ContextAwareToggle eventKey="1" />
						</div>
						<Accordion.Collapse eventKey="1">
							<div className='accordion-body'>
								<Galeria
									galleryID="fotografias"
									images={listaImagenes}
								/>
							</div>
						</Accordion.Collapse>
					</div>
					<div className='accordion-item'>
						<div className='accordion-header'>
							<div className='d-flex gap-2 align-items-center'>
								<span className='text-dark fs-5 fw-semibold lh-1'>Documentos</span>
							</div>
							<ContextAwareToggle eventKey="2" />
						</div>
						<Accordion.Collapse eventKey="2">
							<div className='accordion-body'>
								<div className='d-flex gap-4 flex-wrap'>
									{dataDetalle.hallazgoDocumento?.map((x, i) => {
										return (
											<div key={i} className='doc' style={{ 'flexBasis': '45%' }} onClick={() => downloadXUrl(x.ruta, x.mime, x.nombre, x.extension)}>
												<div className='doc-icon'>
													<i className="ico-doc-empty fs-3"></i>
												</div>
												<div className='doc-info'>
													<b className='doc-title'>{x.nombre}</b>
													<span className='doc-time'>Agregado por <b>{x.usuario}</b> {obtenerFormatoFecha(x.fechaCreacion)}</span>
												</div>
											</div>
										);
									})}
									{ }
								</div>
							</div>
						</Accordion.Collapse>
					</div>
					<div className='accordion-item mb-0'>
						<div className='accordion-header pb-0 d-flex justify-content-between align-items-center'>
							<ul className='accordion-tab'>
								<li className={`pb-1 ${tab === 'historial' ? 'active' : ''}`} onClick={() => setTab('historial')}><span>Historial</span></li>
								<li className={`pb-1 ${tab === 'prorroga' ? 'active' : ''}`} onClick={() => setTab('prorroga')}><span>Prórroga <span className="badge bg-pewter-blue">{dataDetalle.prorroga ? dataDetalle.prorroga.length : 0}</span></span></li>
								<li className={`pb-1 ${tab === 'archivos' ? 'active' : ''}`} onClick={() => setTab('archivos')}><span>Archivos <span className="badge bg-pewter-blue">{dataDetalle.hallazgoHistorialImagen ? dataDetalle.hallazgoHistorialImagen.length + dataDetalle.hallazgoHistorialDocumento.length + dataDetalle.prorrogaHistorialDocumento.length + dataDetalle.prorrogaHistorialImagen.length : 0}</span></span></li>
							</ul>
							<ContextAwareToggle eventKey="3" />
						</div>
						<Accordion.Collapse eventKey="3">
							<div className='accordion-body pb-0'>
								<div className='position-relative'>
									{tab === 'historial' ?
										<>
											<ul className='d-flex flex-column gap-4 my-4'>
												{dataDetalle.historialHallazgoMensajes?.map((item, i) =>
													<li key={i} className='d-flex justify-content-between gap-3'>
														<Avatar data={{ rutaImagen: item.urlAvatar, nombreCompleto: item.usuarioNombres, apellidoPaterno: item.usuarioApellidoPaterno }} esMostrarNombreYperfil={false} esMostrarNombreCompletoYperfil={false}></Avatar>
														<div className='d-flex flex-column ms-0 me-auto'>
															<span><b>{item.usuario}</b>. {item.perfil}</span>
															<small className='text-pewter-blue text-break'><b>{item.estado}</b>. {item.comentario}</small>
														</div>
														<div>
															<small className='text-pewter-blue text-nowrap'>{obtenerFormatoFecha(item.fechaCreacion)}</small>
														</div>

													</li>
												)}
											</ul>
											{btnMostrarFormularioHallazgo &&
												<Formik
													innerRef={formikHallazgoRef}
													enableReinitialize={true}
													initialValues={initialValuesHallazgo}
													validate={validations}
													onSubmit={values => {
														guardarHallazgo(values);
													}}
												>
													{({ setFieldValue, setFieldTouched, values }) => (
														<Form className="form mb-0 ms-auto me-0">
															<TextAreaField
																maxLength="200"
																name="comentarioHallazgo"
																placeholder="Escribe tu mensaje aquí…"
																label='Escribe tu mensaje aquí…'
																onBlur={(e) => { onBlurComentario(e); setFieldValue("comentarioHallazgo", e.target.value); }}
																onInput={(e) => { sinDobleEspacio(e) }}
															/>
															{ }
															<div className='mb-2'>
																<small className='text-pewter-blue mb-2'>Quedan {200 - values.comentarioHallazgo.length} caracteres disponibles</small>
															</div>

															{mostrarListaPerfilesHallazgo ?
																btnAccionHallazgo === EnumAccionHallazgo.Asigna ||
																	btnAccionHallazgo === EnumAccionHallazgo.Reasigna ?
																	<SelectCheckField
																		name="profile" label={nombreLabel}
																		options={listaUsuarioXPerfil}
																		onBlur={setFieldTouched}
																		onChange={setFieldValue}
																		isMulti={false}
																		disabled={esBimOperacion}
																		fijo={false}
																	/>
																	:
																	<SelectCheckField
																		name="responsableHallazgo" label={nombreLabel}
																		options={listaUsuarioXPerfil}
																		onBlur={setFieldTouched}
																		onChange={setFieldValue}
																		isMulti={false}
																		disabled={esBimOperacion}
																		fijo={false}
																	/>
																: null
															}
															{mostrarAfectaIndicador && dataDetalle.esServicio ?
																btnAccionHallazgo === EnumAccionHallazgo.Aprobada ?
																	<SelectCheckField
																		name="afectaIndicador"
																		label='¿Afecta indicador?'
																		options={afectaIndicador}
																		onChange={setFieldValue}
																		onBlur={setFieldTouched}
																		isMulti={false}
																		fijo={false}
																	/>
																	:
																	<SelectCheckField
																		name="afectaIndicadorSinValidacion"
																		label='¿Afecta indicador?'
																		options={afectaIndicador}
																		onChange={setFieldValue}
																		onBlur={setFieldTouched}
																		isMulti={false}
																		fijo={false}
																	/>
																: null
															}
															<div className="form-floating mb-3">
																<InputFileField placeholder="Subir Archivos" 
																multiple 
																name="fileHallazgo" 
																onChange={(event) => handlerFilesHallazgo(event)}
																onClick={(event) => { event.target.value = null }} />
															</div>
															{values.fileHallazgo !== '' && values.fileHallazgo.length > 0 &&
																<div className='align-self-start d-flex gap-3 flex-wrap'>
																	{values.fileHallazgo.map((x, i) => {
																		return (
																			<div key={i} className='doc bg-white-smoke rounded p-2'>
																				<div className='doc-icon'>
																					<i className="ico-doc-empty fs-3"></i>
																				</div>
																				<div className='doc-info'>
																					<b className='doc-title'>{x.name}</b>
																					<span className='doc-time'>{formatoBytes(x.size)}</span>
																				</div>
																				<i className="ico ico-close" onClick={() => eliminarFileHallazgo(i)}></i>
																			</div>
																		);
																	})}
																</div>
															}

															<div className="d-flex justify-content-center align-items-start gap-3">
																{listaAccionActualizarHallazgo.length > 0 &&
																	listaAccionActualizarHallazgo.map((item, i) =>
																<Fragment key={i}>
																	{item.value === EnumAccionHallazgo.Asigna ||
																		item.value === EnumAccionHallazgo.Reasigna ?

																		<Button type="button" design="btn btn-primary gap-2 d-flex align-items-center" onClick={() => changeBtnAccionHallazgo(item.value, values)}>
																			<i className='ico-user-add'></i>
																			<span>{item.label}</span>

																		</Button>
																		:
																		mostrarListaPerfilesHallazgo || (mostrarAfectaIndicador && dataDetalle.esServicio) ?
																			<Button type="button" design={`btn ${item.value === EnumAccionHallazgo.SubirCorreccion ||
																				item.value === EnumAccionHallazgo.ActualizaCorreccion ||
																				item.value === EnumAccionHallazgo.Aprobada ||
																				item.value === EnumAccionHallazgo.AprobadaSubirCorrecion ? 'btn-primary' : 'btn--pewter-blue btn--outline'}`}
																				onClick={() => changeBtnAccionHallazgo(item.value, values)}>
																				<span>{item.label}</span>
																			</Button>
																			:
																			<Button type="submit" design={`btn ${item.value === EnumAccionHallazgo.SubirCorreccion ||
																				item.value === EnumAccionHallazgo.ActualizaCorreccion ||
																				item.value === EnumAccionHallazgo.Aprobada ||
																				item.value === EnumAccionHallazgo.AprobadaSubirCorrecion ? 'btn-primary' : 'btn--pewter-blue btn--outline'}`}
																				onClick={() => setBtnAccionHallazgo(item.value)}>

																				<span>{item.label}</span>
																			</Button>
																	}

																</Fragment>
																	)}
															</div>
														</Form>
													)}
												</Formik>
											}

										</>
										: null}
									{tab === 'prorroga' ?
										<>
											{dataDetalle.prorroga &&
												dataDetalle.prorroga.length > 0 &&
												<Swiper
													modules={[Navigation, Pagination, Scrollbar, A11y]}
													slidesPerView={1}
													scrollbar={{ draggable: true }}
													pagination={{ clickable: true }}
													className="my-4"
												>
													{dataDetalle.prorroga.sort((a, b) => b.idProrroga - a.idProrroga).map((item1, indexMensaje) =>
														<SwiperSlide key={'slide_' + indexMensaje}>
															<ul key={'ul_' + indexMensaje} className='d-flex flex-column gap-4 my-4'>
																{item1.prorrogaMensajes.sort((a, b) => b.prorrogaId - a.prorrogaId).map((item, index) =>
																	<li key={'Prorroga_' + index} className='d-flex justify-content-between gap-3'>
																		<Avatar data={{ rutaImagen: item.urlAvatar, nombreCompleto: item.usuarioNombres, apellidoPaterno: item.usuarioApellidoPaterno }} esMostrarNombreYperfil={false} esMostrarNombreCompletoYperfil={false}></Avatar>
																		<div className='d-flex flex-column ms-0 me-auto'>
																			<span><b>{item.usuario}</b>. {item.perfilDescripcion}</span>
																			<small className='text-pewter-blue text-break'><b>{item.estado}</b>. {item.comentario}</small>

																			{item.documentosUsuario.length > 0 &&
																				<div className='align-self-start d-flex gap-3 flex-wrap'>
																					{item?.documentosUsuario?.map((x, i) => {
																						return (
																							<div key={'ListaArchivosPR_' + i} className='doc bg-white-smoke rounded p-2' onClick={() => downloadXUrl(x.ruta, x.mime, x.nombre, x.extension)}>
																								<div className='doc-icon'>
																									<i className="ico-doc-empty fs-3"></i>
																								</div>
																								<div className='doc-info'>
																									<b className='doc-title'>{x.nombre}</b>
																									<span className='doc-time'>{formatoBytes(x.tamano)}</span>
																								</div>
																							</div>
																						);
																					})}
																				</div>
																			}
																		</div>
																		<div>
																			<small className='text-pewter-blue text-nowrap'>{obtenerFormatoFecha(item.fechaCreacion)}</small>
																		</div>
																	</li>
																)}
															</ul>
															<div className="form mb-3 ms-auto me-0" key={indexMensaje + 'mensajeP'}>
																<span>Fecha de prórroga <span className='bg-white-smoke rounded p-2 align-self-start gap-3 text-pewter-blue'>{moment(item1.fechaProrroga).format('DD-MM-YYYY')}</span></span>
																{item1.fechaProrrogaIF !== null &&
																	<span>Fecha de prórroga aprobación <span className='bg-white-smoke rounded p-2 align-self-start gap-3 text-pewter-blue'>{moment(item1.fechaProrrogaIF).format('DD-MM-YYYY')}</span></span>
																}

															</div>
														</SwiperSlide>
													)
													}
												</Swiper>
											}

											{usuario?.perfilContexto.id === EnumPerfil.LiderArea && (dataDetalle.prorrogaMayor !== null && dataDetalle.prorrogaMayor.idEstado === EnumEstadoProrroga.Cerrada) &&
												!btnMostrarFormulario ?

												<Button type="button" design="btn btn-primary gap-2 d-flex align-items-center" onClick={() => setBtnMostrarFormulario(true)}>
													<i className='ico-user-add'></i>
													<span>Solicitar nueva prórroga</span>
												</Button>
												:
												usuario?.perfilContexto.id === EnumPerfil.LiderArea && (dataDetalle.prorrogaMayor !== null && dataDetalle.prorrogaMayor.idEstado === EnumEstadoProrroga.Cerrada) &&
													btnMostrarFormulario ?
													<>
														<Button type="button" design="btn btn-primary gap-2 d-flex align-items-center mb-3" disabled={true}>
															<i className='ico-user-add'></i>
															<span>Solicitar nueva prórroga</span>
														</Button>
													</>
													: null
											}
											{btnMostrarFormulario &&
												<Formik
													enableReinitialize
													innerRef={formikRef}
													initialValues={initialValues}
													validate={validations}
													onSubmit={(values) => {
														switch (usuario.perfilContexto.id) {
															case EnumPerfil.LiderArea: {
																IngresoProrroga(values);
																break;
															}
															case EnumPerfil.LiderConcesionaria: {

																const envioForm = {
																	prorrogaId: dataDetalle.prorrogaMayor.idProrrogaMayor,
																	fechaProrroga: dataDetalle.prorrogaMayor.fechaProrroga,
																	accion: btnAccion,
																	comentarioProrroga: values.comentarioProrroga,
																	fileProrroga: values.fileProrroga

																}
																ActualizacionProrroga(envioForm);
																break;
															}
															case EnumPerfil.InspectorFiscal: {
																const envioForm = {
																	prorrogaId: dataDetalle.prorrogaMayor.idProrrogaMayor,
																	fechaProrroga: btnAccion === EnumAccionProrroga.Rechazada ? dataDetalle.prorrogaMayor.fechaProrroga :
																		values.fechaProrrogaIP !== '' ? values.fechaProrrogaIP : dataDetalle.prorrogaMayor.fechaProrroga,
																	accion: btnAccion,
																	comentarioProrroga: values.comentarioProrroga,
																	fileProrroga: values.fileProrroga

																}
																ActualizacionProrroga(envioForm);
																break;
															}
														}


													}}
												>
													{({ setFieldValue, setFieldTouched, values }) => (
														<Form className="form mb-0 ms-auto me-0">
															<TextAreaField
																maxLength="200"
																name="comentarioProrroga"
																placeholder="Escribe tu mensaje aquí…"
																label='Escribe tu mensaje aquí…'
																onBlur={(e) => { onBlurComentario(e); setFieldValue("comentarioProrroga", e.target.value); }}
																onInput={(e) => { sinDobleEspacio(e) }}
															/>
															<div className='mb-2'>
																<small className='text-pewter-blue mb-2'>Quedan {200 - values.comentarioProrroga.length} caracteres disponibles</small>
															</div>

															<div className="form-floating mb-3">
																<InputFileField 
																placeholder="Subir Archivos" 
																multiple 
																name="fileProrroga" 
																onChange={(event) => handlerFiles(event)}
																onClick={(event) => { event.target.value = null }} 
																/>
															</div>

															{values.fileProrroga !== '' && values.fileProrroga.length > 0 &&
																<div className='rounded p-2 align-self-start d-flex gap-3 flex-wrap'>
																	{values.fileProrroga.map((x, i) => {
																		return (
																			<div key={i} className='doc bg-white-smoke '>
																				<div className='doc-icon'>
																					<i className="ico-doc-empty fs-3"></i>
																				</div>
																				<div className='doc-info'>
																					<b className='doc-title'>{x.name}</b>
																					<span className='doc-time'>{formatoBytes(x.size)}</span>
																				</div>
																				<i className="ico ico-close" onClick={() => eliminarFile(i)}></i>
																			</div>
																		);
																	})}
																</div>
															}

															{usuario?.perfilContexto.id === EnumPerfil.LiderArea ?
																<div className="d-flex justify-content-center align-items-start gap-3">
																	<div className='d-flex flex-column flex-fill dsac-datepicker'>
																		<DatePickerComponent
																			name="fechaProrroga"
																			maxDate={null}
																			minDate={moment(dataDetalleHallazgo.fechaLimiteCorreccionHallazgo).add(1, 'day').toDate()}
																			onChange={setFieldValue}
																			onBlur={setFieldTouched}
																		/>
																	</div>
																	<Button type="submit" design="btn btn-primary">
																		<span>Solicitar</span>
																	</Button>
																</div>
																:
																usuario?.perfilContexto.id === EnumPerfil.LiderConcesionaria ?
																	dataDetalle.prorrogaMayor.idEstado === EnumEstadoProrroga.Rechazado && dataDetalle.prorrogaMayor.idEtapa === EnumEtapaProrroga.ValidacionLC ?
																		<div className="d-flex justify-content-center align-items-start gap-3">
																			<Button type="submit" design="btn btn--pewter-blue btn--outline" onClick={() => setBtnAccion(EnumAccionProrroga.Cerrada)}>Cerrar solicitud</Button>
																			<Button type="submit" design="btn btn-primary gap-2 d-flex align-items-center" onClick={() => setBtnAccion(EnumAccionProrroga.Reenviada)} >
																				<span>Reenviar solicitud</span>
																			</Button>
																		</div>
																		:
																		dataDetalle.prorrogaMayor.idEstado === EnumEstadoProrroga.EnProceso && dataDetalle.prorrogaMayor.idEtapa === EnumEtapaProrroga.ValidacionLC ?
																			<div className="d-flex justify-content-center align-items-start gap-3">
																				<Button type="submit" design="btn btn--pewter-blue btn--outline" onClick={() => setBtnAccion(EnumAccionProrroga.Rechazada)}>Rechazar</Button>
																				<Button type="submit" design="btn btn-primary gap-2 d-flex align-items-center" onClick={() => setBtnAccion(EnumAccionProrroga.Aprobada)} >
																					<span>Aprobar</span>
																				</Button>
																			</div>
																			: null
																	:
																	usuario?.perfilContexto.id === EnumPerfil.InspectorFiscal ?
																		<div className="d-flex justify-content-center align-items-start gap-3">
																			<DatePickerComponent
																				name="fechaProrrogaIP"
																				maxDate={null}
																				minDate={moment(dataDetalleHallazgo.fechaLimiteCorreccionHallazgo).toDate()}
																				onChange={setFieldValue}
																				onBlur={setFieldTouched}
																			/>
																			<Button type="submit" design="btn btn--pewter-blue btn--outline" onClick={() => setBtnAccion(EnumAccionProrroga.Rechazada)}>Rechazar</Button>
																			<Button type="submit" design="btn btn-primary gap-2 d-flex align-items-center" onClick={() => setBtnAccion(EnumAccionProrroga.Aprobada)}>
																				<span>Aprobar</span>
																			</Button>
																		</div>
																		: null
															}


														</Form>
													)}
												</Formik>
											}

										</>
										: null}
									{tab === 'archivos' ?
										<>
											<div className='d-flex gap-4 flex-wrap'>
												{listaArchivos.map((x, i) => {
													return (
														<div key={'ListaArchivos_' + i} className='doc' style={{ 'flexBasis': '45%' }} onClick={() => descargaArchivos(x)}>
															<div className='doc-icon'>
																<i className="ico-doc-empty fs-3"></i>
															</div>
															<div className='doc-info'>
																<b className='doc-title'>{x.nombre}</b>
																<span className='doc-time'>Agregado por <b>{x.usuario}</b> {obtenerFormatoFecha(x.fechaCreacion)}</span>
															</div>
														</div>
													);
												})}
											</div>
										</>
										: null}
								</div>
							</div>
						</Accordion.Collapse>
					</div>
				</Accordion>
				<div>
				</div>
			</Modal.Body >
		</Modal >
	)
}
export default ModalDetalleHallazgo;