import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const InformeNivelCumplimientoSerieService = async (values) => {
    try {
        const resultado = await instance.Backend.post("/Informe/InformeNivelCumplimientoSerie",
            values, customConfig
        );
        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { InformeNivelCumplimientoSerieService };