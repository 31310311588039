import instance, { customConfig } from "../Utils/Interceptors/Interceptor";

const ListaIndicadoresPorAreaNegocioService = async (values, idUsuario) => {
    try {
        const resultado = await instance.Backend.post("/Indicador/ListaIndicadoresPorAreaNegocio",{
            listaIdAreaNegocio: values,
            idUsuario: idUsuario
        }
            , customConfig);
        
        return resultado
        
    } catch (error) {
        console.log(error);
    }
}
export { ListaIndicadoresPorAreaNegocioService };