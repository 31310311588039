import React, { useMemo, useState } from 'react';
import Card from "../../../Components/Card/Card"
import BitacoraEventosConcesionTable from "../Components/BitacoraEventosConcesionTable";
import { COLUMNS } from '../Utils/ColumnsEventosConcesion';
import { EnumModuloDesc, EnumPathRutas, EnumTablaPaginacion } from '../../../Utils/Enumeradores/ComunEnum'
import { BusquedaEventosConcesion } from '../Services/BusquedaEventosConcesion';
import { useComun } from '../../../Hooks/useComun';
import ModalDetalleEventoConcesion from '../../../Components/ModalDetalleEventoConcesion/ModalDetalleEventoConcesion';
import { Link } from 'react-router-dom';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const BitacoraEventosConcesionPage = () => {
	const { downloadCSV } = useComun();
	const columns = useMemo(() => COLUMNS, []);
	const [data, setData] = React.useState([]);
	const [datosModal, setDatosModal] = React.useState({});
	const [editUser, setEditUser] = useState({});
	const [show, setShow] = useState(false);
	const [pageCount, setPageCount] = React.useState(0);
	const { user, usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();

	const [{ pageIndex, pageSize, sortBy }, setPagination] =
		React.useState({
			pageIndex: 0,
			pageSize: EnumTablaPaginacion.CantidadPaginacion,
			sortBy: []
		});
	async function postBusquedaEventosConcesion(values) {
		const startTime = new Date();
		const resp = await BusquedaEventosConcesion(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					if (values.datosBusqueda.exportacion) {
						const fetchData = async () => {
							await Promise.all([
								downloadCSV(resp.data, "Bitácora Eventos Concesión", EnumModuloDesc.DescargaBitacoraEvento),
							]);
							const endTime = new Date();
							const tdrId = 21;
							crearRegistroTDR(startTime, endTime, usuario, tdrId);
						};

						fetchData();
					} else {
						setPageCount(resp.data.cantidadPaginas);
						setData(resp.data.listadoBitacoraEventoConcesion);
					}
				}
			} else if (resp.status === 206) {
				setPageCount(0);
				setData([]);
			}
		}
	}

	const fetchData = React.useCallback(({ formulario, pageIndex, sortBy = [], exportacion = false }) => {
		const startTime = new Date();
		formulario.paginacion.numeroPagina = pageIndex + 1;
		formulario.paginacion.ordenadoPor = sortBy.length > 0 ? sortBy[0].id : formulario.paginacion.ordenadoPor;
		formulario.paginacion.orderByAscending = sortBy.length > 0 ? (sortBy[0].desc ? false : true) : formulario.paginacion.orderByAscending;
		formulario.datosBusqueda.exportacion = exportacion;
		const fetchData = async () => {
			await Promise.all([
				postBusquedaEventosConcesion(formulario)
			]);
			const endTime = new Date();
			const tiempoTranscurridoSegundos = (endTime - startTime);
			const tdrId = 19;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
	}, []);

	function handlerModal(item) {
		const startTime = new Date();
		const fetchData = async () => {
			await Promise.all([
				setDatosModal(item),
				setShow(true),
			]);
			const endTime = new Date();
			const tdrId = 22;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
	}
	const handleClose = () => setShow(false);
	return (
		<div className="container-fluid">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
							<li><a href="" onClick={(e) => { e.preventDefault(); window.location.reload(false); }}>Bitácora</a></li>
							<li><a href="">Eventos Concesión</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<Card className="p-4">
						<BitacoraEventosConcesionTable
							columns={columns}
							data={data}
							fetchData={fetchData}
							pageCount={pageCount}
							currentpage={pageIndex}
							pageSize={pageSize}
							sortBy={sortBy}
							setPagination={setPagination}
							handlerModal={handlerModal}
						/>
					</Card>
				</div>
			</div>
			<ModalDetalleEventoConcesion
				show={show}
				onHide={handleClose}
				datosModal={datosModal}
			/>
		</div>
	)
}

export default BitacoraEventosConcesionPage;
