import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ContadorAlarmasNoLeidasService = async (values) => {
    try {
        return await instance.Backend.post("/Alarma/ContadorAlarmasNoLeidas",
            values, customConfig
        );
    } catch (error) {
        console.log(error);
    }
}
export { ContadorAlarmasNoLeidasService };