import { useCallback, useState } from 'react'
import { ContadorAlarmasNoLeidasService } from '../Features/Alarmas/Services/ContadorAlarmasNoLeidasService';
import { EstadoHallazgoService } from '../Features/Hallazgo/Services/EstadoHallazgoService';
import { ContadorNotificacionNoLeidasService } from '../Features/Notificaciones/Services/ContadorNotificacionNoLeidasService';
import { ObtenerTiposNotificacionesService } from '../Features/Notificaciones/Services/ObtenerTiposNotificacionesService';
import { ObtenerListadoPerfilAreaNegocio } from '../Features/Usuario/Services/ObtenerListPerfilesAreasNegocio';
import { ObtenerAreasNegocioIdUsuario } from '../Features/Usuario/Services/ObtenerAreasNegocioIdUsuario';
import { ObtenerListadoUsuarios } from '../Services/ObtenerListadoUsuarios';
import { ObtenerListaUnidadMedida } from '../Services/ListaUnidadMedida';
import { ListaIndicadoresPorAreaNegocioService } from '../Services/ObtenerListaIndicadoresPorAreaNegocioService';
import { ListaIndicadoresAreaNegocioIdService } from '../Services/ListaIndicadoresAreaNegocioIdService';
import { ContadorMedicionesManualesServices } from '../Features/Autorizaciones/Services/ContadorMedicionesManualesServices';
import { ContadorTotalPendientesAutorizacionService } from '../Features/Autorizaciones/Services/ContadorTotalPendientesAutorizacionService';
import moment from 'moment';
import { EnumParametro, EnumTipoArchivos , EnumDisponibilidadMeses } from '../Utils/Enumeradores/ComunEnum';
import { ObtenerListaSerieService } from '../Features/Indicadores/Services/ObtenerListaSerieService';
import { ListaIndicadoresIngresoHallazgoService } from '../Features/Hallazgo/Services/ListaIndicadoresIngresoHallazgoService';
import { ListaTipoHallazgoService } from '../Features/Hallazgo/Services/ListaTipoHallazgoService';
import { ObtenerListaIndicadorDtService } from '../Features/Indicadores/Services/ObtenerListaIndicadorDtService';
import useLogin from '../Features/Autenticacion/Hooks/useLogin';
import { ListadoProblemas } from "../Features/Hallazgo/Services/ListadoProblemasService";
import { ListadoElementos } from "../Features/Hallazgo/Services/ListadoElementosService";
import { ListadoGravedad } from "../Features/Hallazgo/Services/ListadoGravedadService";
import { ListadoTipoFallaService } from "../Features/Hallazgo/Services/ListadoTipoFallaService";
import { AccionActualizaHallazgoService } from '../Features/Hallazgo/Services/AccionActualizaHallazgoService';
import { ObtenerUsuariosPorPerfilService } from '../Services/ObtenerUsuariosPorPerfilService';
import { ParametroObtenerPorIdService } from '../Services/ParametroObtenerPorIdService';
import { ObtenerIndicadoresTdrService } from '../Features/Informes/Services/ObtenerIndicadoresTdrService';
import { useRegistroTDR } from './useRegistroTDR';
import { ListadoMacroUbicacion } from '../Features/Hallazgo/Services/ListadoMacroUbicacionService';
import { ObtenerPeriodoDisponibilidad } from '../Features/Informes/Services/ObternerPeriodoDisponibilidad';

export function useComun(props) {
	const [optionsProfile, setOptionsProfile] = useState([]);
	const [optionsBusiness, setOptionsBusiness] = useState([]);
	const [listadoUsuarioSeleccionable, setListadoUsuarioSeleccionable] = useState([]);
	const [contadorAlarmas, setContadorAlarmas] = useState(0);
	const [contadorNotificaciones, setContadorNotificaciones] = useState(0);
	const [listadoNotificacionesSeleccionable, setListadoNotificacionesSeleccionable] = useState([]);
	const [listadoUnidadMedida, setListadoUnidadMedida] = useState();
	const [listadoIndicadores, setListadoIndicadores] = useState([]);
	const [listadoIndicadoresIngresoHallazgo, setListaIndicadoresIngresoHallazgo] = useState([]);
	const [listadoTipoHallazgo, setListaTipoHallazgo] = useState([]);
	const [listadoIndicadoresId, setListadoIndicadoresId] = useState([]);
	const [contadorMediciones, setContadorMediciones] = useState();;
	const [contadorTotalPendientesAutorizacion, setContadorTotalPendientesAutorizacion] = useState(0);
	const [listaSerie, setListaSerie] = useState([]);
	const [respRegistro, setRespRegistro] = useState(false);
	const [listaIndicadorDt, setListaIndicadorDt] = useState([]);
	const [listadoEstado, setListadoEstado] = useState([]);
	const { usuario, bitacoraDetalle, registraBitacoraMovimientoUsuario } = useLogin();
	const [listaProblemaHallazgo, setListaProblemaHallazgo] = useState([]);
	const [listaMacroubicacionHallazgo, setListaMacroubicacionHallazgo] = useState([]);
	const [listaElementoHallazgo, setListaElementoHallazgo] = useState([]);
	const [listaGravedadHallazgo, setListaGravedadHallazgo] = useState([]);
	const [listaTipoFalla, setListaTipoFalla] = useState([]);
	const [listaAccionActualizarHallazgo, setListaAccionActualizarHallazgo] = useState([]);
	const [listaUsuarioXPerfil, setListaUsuarioXPerfil] = useState([]);
	const [numeroContrato, setNumeroContrato] = useState(null);
	const [usuarioBimOperacion, setUsuarioBimOperacion] = useState(null);
	const [listaIndicadoresTdr, setListaIndicadoresTdr] = useState([]);
	const [tiempoIndicadoresTdr, setTiempoListaIndicadoresTdr] = useState([]);
	const [listaTdr, setListaTdr] = useState([]);
	const { crearRegistroTDR } = useRegistroTDR();
	const [periodo, setPeriodo] = useState([]);
	const [ultimoPeriodo, setUltimoPeriodo] = useState([]);
	const [listaPeriodo, setListaPeriodo] = useState([]);
	const [obtenerIpTdr, setObtenerIpTdr] = useState(null);

	const downloadCSV = (data, nombreArchivo, modulo, tipoArchivo = EnumTipoArchivos.XLS) => {
		registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: modulo, idBitacoraDetalle: bitacoraDetalle }, () => { });
		var extension;
		var linkSource;
		var universalBOM = "\uFEFF";

		switch (tipoArchivo) {
			case EnumTipoArchivos.PDF:
				extension = 'pdf';
				linkSource = 'data:application/octet-stream;base64,' + data;
				break;
			case EnumTipoArchivos.XLS:
				extension = 'csv';
				linkSource = 'data:application/octet-stream,' + encodeURIComponent(universalBOM + atob(data));
				break;
			default:
		}
		const downloadLink = document.createElement("a");
		downloadLink.setAttribute('download', nombreArchivo + '.' + extension);
		downloadLink.setAttribute('href', linkSource);
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};

	const downloadXUrl = (url, mime, nombreArchivo, extension) => {
		fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': mime,
			}
		}).then(r => r.blob()).then((blob) => {
			const url = window.URL.createObjectURL(
				new Blob([blob]),
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`${nombreArchivo}`,
			);
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);
		});
	}

	const obtenerFormatoFecha = (fecha) => {
		if (moment(fecha).format('DD-MM-yyyy') === moment().format('DD-MM-yyyy')) {
			return "Hoy a las " + moment(fecha).format('HH:mm');
		} else if (moment(fecha).format('DD-MM-yyyy') === moment().subtract(1, 'days').format('DD-MM-yyyy')) {
			return "Ayer a las " + moment(fecha).format('HH:mm');
		} else {
			return moment(fecha).format('DD-MM-yyyy HH:mm');
		}
	}

	function ObtenerColoresNivelesServicio(id) {
		switch (id) {
			case 2: {
				return { backgroundColor: '#ECF7EF', color: '#49B461' }
			}
			case 3: {
				return { backgroundColor: '#E7F5FF', color: '#1C99FF' }
			}
			case 4: {
				return { backgroundColor: '#ECF7EF', color: '#49B461' }
			}
			case 5: {
				return { backgroundColor: '#E7F5FF', color: '#1C99FF' }
			}
			default: {
				return { backgroundColor: '#ECF7EF', color: '#49B461' }
			}
		}
	}

	function formatoBytes(bytes, decimals = 2) {
		if (!+bytes) return '0 Bytes'

		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

		const i = Math.floor(Math.log(bytes) / Math.log(k))

		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
	}
	/* Método recursivo creado para ordenar las los niveles de servicio con las dependencias de padres he hijos respectivos */
	/* listaData: lista desordenada que devuelve la api, nivelServicioPadreId: es el nivel con el que empieza la busqueda, nivelItem: para saber el orden para la vista en front */
	function metodoRecursivoXNivelServicioPadreId(listaData, nivelServicioPadreId, nivelItem) {
		nivelItem++;
		let resultadoLista = [];
		let resultadoListaHijo = [];
		const listado = listaData.filter(y => y.nivelServicioPadreId === nivelServicioPadreId);
		listado.map(x => {
			if (listaData.filter(y => y.nivelServicioId === x.nivelServicioPadreId).length > 0) {
				x.TienePadre = true;
				x.nivelItem = nivelItem;
			} else {
				x.TienePadre = false;
				x.nivelItem = 1;
			}
			if (listaData.filter(y => y.nivelServicioPadreId === x.nivelServicioId).length > 0) {
				resultadoListaHijo = metodoRecursivoXNivelServicioPadreId(listaData, x.nivelServicioId, nivelItem);
				x.TieneHijo = true;
			} else {
				x.TieneHijo = false;
			}
			resultadoLista.push(x);
			if (x.TieneHijo) {
				resultadoListaHijo.map(y => {
					resultadoLista.push(y);
				})
			}
		});

		return resultadoLista;
	}

	function ValidarListaMetodoRecursivoXNivelServicioPadreId(listaData, nivelServicioPadreId, nivelItem, resultadoFinal) {
		let resultadoLista = metodoRecursivoXNivelServicioPadreId(listaData, nivelServicioPadreId, nivelItem);
		resultadoLista.map(x => {
			resultadoFinal.push(x);
		});
		if (listaData.length > resultadoLista.length) { //VALIDA QUE DEVUELVA TODOS LOS ITEM DE LA LISTA, EN EL CASO DE QUE NO VUELVE A PASAR POR EL METODO INSERTANDO LA NUEVA LISTA
			const idsNivelesServicio = resultadoLista.map(x => x.nivelServicioId);
			const listaNoRecorrida = []
			listaData.map(x => {
				if (!idsNivelesServicio.includes(x.nivelServicioId)) {
					listaNoRecorrida.push(x);
				}
			})
			if (listaNoRecorrida.length > 0) {
				const numeroMin = listaNoRecorrida.reduce((n, m) => Math.min(n, m.nivelServicioPadreId), Number.POSITIVE_INFINITY)
				const nivelItemMax = resultadoLista.reduce((n, m) => Math.max(n, m.nivelItem), -Number.POSITIVE_INFINITY)
				ValidarListaMetodoRecursivoXNivelServicioPadreId(listaNoRecorrida, numeroMin, nivelItemMax, resultadoFinal);

			}
		}
		return resultadoFinal;
	}

	function metodoRecursivoXNivelServicioId(listaData, nivelServicioId) {
		let resultadoLista = [];
		let resultadoListaHijo = [];
		const listado = listaData.filter(y => y.nivelServicioPadreId === nivelServicioId);
		listado.map(x => {
			if (listaData.filter(y => y.nivelServicioPadreId === x.nivelServicioId).length > 0) {
				resultadoListaHijo = metodoRecursivoXNivelServicioId(listaData, x.nivelServicioId);
				x.TieneHijo = true;
			} else {
				x.TieneHijo = false;
			}
			resultadoLista.push(x);
			if (x.TieneHijo) {
				resultadoListaHijo.map(y => {
					resultadoLista.push(y);
				})
			}
		});
		return resultadoLista;
	}

	async function obtenerPerfilesAreasNegocio() {

		const resp = await ObtenerListadoPerfilAreaNegocio(usuario.id);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					let perfiles = [];
					let areaNegocio = [];
					resp.data.listaPerfiles.map((value) => perfiles.push({ value: (value.id).toString(), label: value.descripcion }));
					resp.data.listaAreaNegocio.map((value) => areaNegocio.push({ value: (value.id).toString(), label: value.descripcion }));
					setOptionsProfile(perfiles);
					setOptionsBusiness(areaNegocio);
				}
			}
		}
	}

	async function obtenerAreasNegocioIdUsuario() {

		const resp = await ObtenerAreasNegocioIdUsuario(usuario.id, usuario.perfilContexto.id);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					let perfiles = [];
					let areaNegocio = [];
					resp.data.listaPerfiles.map((value) => perfiles.push({ value: (value.id).toString(), label: value.descripcion }));
					resp.data.listaAreaNegocio.map((value) => areaNegocio.push({ value: (value.id).toString(), label: value.descripcion }));
					setOptionsProfile(perfiles);
					setOptionsBusiness(areaNegocio);
				}
			}
		}
	}

	async function postObtenerListadoUsuarioPorEstado(estado) {

		const resp = await ObtenerListadoUsuarios(estado, usuario.id);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					const listado = resp.data.map(item => { return { value: item.id, label: item.nombres + " " + item.apellidoPaterno + " " + item.apellidoMaterno } });
					setListadoUsuarioSeleccionable(listado);
				}
			}
		}
	}

	async function postContadorAlarmasNoLeidas(values) {
		const resp = await ContadorAlarmasNoLeidasService(values);
		if (resp) {
			if (resp.status === 200) {
				setContadorAlarmas(resp.data);
			}
		}
	}
	async function postObtenerListaUnidadMedida(values) {
		const startTime = new Date();
		const resp = await ObtenerListaUnidadMedida(values, usuario.id);
		if (resp) {
			if (resp.status === 200) {
				let listado = [];
				resp.data.unidadMedida.map((value) => { listado.push({ value: value.unidadMedidaId, label: value.descripcion }) });
				const fetchData = async () => {
					await Promise.all([
						setListadoUnidadMedida(listado),
					]);
					const endTime = new Date();
					const tdrId = 94;
					crearRegistroTDR(startTime, endTime, usuario, tdrId);
				};

				fetchData();
			}
		}
	}

	async function postListaIndicadoresIngresoHallazgo() {
		const resp = await ListaIndicadoresIngresoHallazgoService(usuario.id, usuario.perfilContexto.id);
		if (resp) {
			if (resp.status === 200) {
				let listado = [];
				resp.data.listaIndicadores.map((value) => {
					listado.push({
						value: value.indicadorId, label: value.sigla + ' - ' + value.descripcion, esServicio: value.esServicio
						, sigla: value.sigla
					})
				});
				setListaIndicadoresIngresoHallazgo(listado);
			}
		}
	}

	async function postListaTipoHallazgo() {
		const resp = await ListaTipoHallazgoService();
		if (resp) {
			if (resp.status === 200) {
				let listado = [];
				resp.data.map((value) => { listado.push({ value: value.id.toString(), label: value.descripcion }) });
				setListaTipoHallazgo(listado);
			}
		}
	}

	async function postContadorNotificacionNoLeidas(idUsuario, idPerfil) {
		const resp = await ContadorNotificacionNoLeidasService(idUsuario, idPerfil);
		if (resp) {
			if (resp.status === 200) {
				setContadorNotificaciones(resp.data);
			}
		}
	}

	async function postObtenerTiposNotificaciones() {
		const resp = await ObtenerTiposNotificacionesService();
		if (resp) {
			if (resp.status === 200) {
				const listado = resp.data;
				setListadoNotificacionesSeleccionable(listado.map(x => { return { value: x.tipoNotificacionId.toString(), label: x.descripcion } }));
			}
		}
	}

	async function postContadorTotalPendientesAutorizacion(usuarioId) {
		const resp = await ContadorTotalPendientesAutorizacionService(usuarioId);
		if (resp) {
			if (resp.status === 200) {
				setContadorTotalPendientesAutorizacion(resp.data);
			}
		}
	}
	async function postObtenerListadoIndicadoresPorAreaNegocio(values) {
		const resp = await ListaIndicadoresPorAreaNegocioService(values, usuario.id);
		if (resp) {
			if (resp.status === 200) {
				let listado = [];
				resp.data.map((value) => { listado.push({ value: value.indicadorId, label: value.sigla }) });

				setListadoIndicadores(listado);
			}
		}
	}

	async function postObtenerListaIndicadoresAreaNegocioId(values) {
		const resp = await ListaIndicadoresAreaNegocioIdService(values, usuario.id);
		if (resp) {
			if (resp.status === 200) {
				let listado = [];
				resp.data.listaIndicadores.map((value) => { listado.push({ value: value.indicadorId, label: value.sigla }) });
				setListadoIndicadoresId(listado);
			}
		}
	}

	async function postContadorMedicionesManuales() {
		const resp = await ContadorMedicionesManualesServices(usuario.id);
		if (resp) {
			if (resp.status === 200) {
				setContadorMediciones(resp.data);
			}
		}
	}

	async function postObtenerListaSerie(values) {
		const resp = await ObtenerListaSerieService(values);
		if (resp) {
			if (resp.status === 200) {
				let listado = [];
				resp.data.map((value) => { listado.push({ value: value.serieId, label: value.descripcion }) });
				setListaSerie(listado);
			}
		}
	}

	async function postObtenerListaIndicadorDt(values) {
		const resp = await ObtenerListaIndicadorDtService(values);
		if (resp) {
			if (resp.status === 200) {
				let listado = [];
				resp.data.map((value) => { listado.push({ value: value.indicadorId, label: value.sigla }) });
				setListaIndicadorDt(listado);
			}
		}
	}

	async function postObtenerListaIndicadoresTdr(values) {
    const resp = await ObtenerIndicadoresTdrService(values);
    if (resp) {
        if (resp.status === 200) {
            let listado = [];
            let ultimoDato = null;
            resp.data.map((value) => {
							listado.push({ value: value.fecha, label: value.nombreMes + " " + value.ano });
                if (!ultimoDato || new Date(value.fecha) > new Date(ultimoDato.fecha)) {
                    ultimoDato = value;
                }
            });
						setListaTdr(resp);
            setListaIndicadoresTdr(listado);
            if (ultimoDato) {
                setTiempoListaIndicadoresTdr({
                    valorCumplimiento: ultimoDato.valorCumplimiento,
                    nombreMes: ultimoDato.nombreMes,
                    ano: ultimoDato.ano,
					mes: ultimoDato.mes
                });
            }
        }
    }
}

	async function postEstadoHallazgo() {
		const resp = await EstadoHallazgoService();
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					let estadoHallazgo = [];
					resp.data.map((value) => estadoHallazgo.push({ value: (value.id).toString(), label: value.descripcion }));
					setListadoEstado(estadoHallazgo);
				}
			}
		}
	}

	async function postListaProblemaHallazgo(indicadorId, idMacroubicacion) {
		const resp = await ListadoProblemas(indicadorId, usuario.id, idMacroubicacion);
		if (resp) {
			if (resp.status === 200) {
				let listado = [];
				resp.data.map((value) => {
					listado.push({ value: value.problemaId, label: value.descripcion, tipoActivoId: value.tipoActivoId })
				});
				setListaProblemaHallazgo(listado);
			}
		}
	};


	async function postListaMacroubicacionHallazgo(indicadorId) {
		const resp = await ListadoMacroUbicacion(indicadorId, usuario.id);
		if (resp) {
			if (resp.status === 200) {
				let listado = [];
				resp.data.map((value) => {
					listado.push({ value: value.macroubicacionId, label: value.macroubicacionId + " - " + value.descripcion})
				});
				setListaMacroubicacionHallazgo(listado);
			}
		}
	};


	async function postListaElementoHallazgo(problemaSeleccionado, idMacroubicacion) {
		const resp = await ListadoElementos(problemaSeleccionado, idMacroubicacion, usuario.id);
		if (resp) {
			if (resp.status === 200) {
				let listado = [];
				resp.data.map((value) => {
					listado.push({
						value: value.elementoId, label: value.descripcion, macro: value.idMacroubicacion + ' - ' + value.descripcionMacroubicacion
						, idMacro: value.macroubicacionId
					})
				});
				setListaElementoHallazgo(listado);
			}
		}
	};

	async function postListaGravedadHallazgo() {
		const resp = await ListadoGravedad(usuario.id);
		if (resp) {
			if (resp.status === 200) {
				let listado = [];
				resp.data.map((value) => {
					listado.push({ value: value.id, label: value.descripcion })
				});
				setListaGravedadHallazgo(listado);
			}
		}
	};

	async function postListaTipoFalla() {
		const resp = await ListadoTipoFallaService();
		if (resp) {
			if (resp.status === 200) {
				let listado = [];
				resp.data.map((value) => { listado.push({ value: value.id, label: value.descripcion }) });
				setListaTipoFalla(listado);
			}
		}
	}

	async function postAccionActualizaHallazgo(values) {
		const resp = await AccionActualizaHallazgoService(values);
		if (resp) {
			if (resp.status === 200) {
				let listado = [];
				resp.data.sort().reverse().map((value) => { listado.push({ value: value.idTexto, label: value.descripcion }) });
				setListaAccionActualizarHallazgo(listado);
			}
		}
	}

	async function postObtenerUsuariosPorPerfil(values) {
		const resp = await ObtenerUsuariosPorPerfilService(values);
		if (resp) {
			if (resp.status === 200) {
				let listado = [];
				resp.data.map((value) => { listado.push({ value: value.id, label: value.nombres + ' ' + value.apellidoPaterno + ' ' + value.apellidoMaterno }) });
				setListaUsuarioXPerfil(listado);
			}
		}
	}

	async function postObtenerPeriodoDisponibilidad(values) {
		const resp = await ObtenerPeriodoDisponibilidad(values);
		if (resp) {
                    if (resp.status === 200) {
                        let periodosDisponibles = [];
                        let periodoActual = null;
            
                        resp.data.forEach((value) => {
							const mesNombre = EnumDisponibilidadMeses[value.mes];
                            periodosDisponibles.push({ value: `${value.ano}-${value.mes}`, label: `${mesNombre} ${value.ano}` });
                            if (!periodoActual || new Date(value.ano, value.mes - 1) > new Date(periodoActual.ano, periodoActual.mes - 1)) {
                                periodoActual = value;
                            }
                        });
            
                        setPeriodo(resp);
						setListaPeriodo(periodosDisponibles);
                        if (periodoActual) {
                            setUltimoPeriodo({
                                mes: periodoActual.mes,
                                ano: periodoActual.ano,
								mesNombre: EnumDisponibilidadMeses[periodoActual.mes]
                            });
			}}
		}
	}

	async function postParametroObtenerPorId(value) {
		const resp = await ParametroObtenerPorIdService(value);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					switch (value) {
						case EnumParametro.NumeroContrato: {
							setNumeroContrato(resp.data);
							break;
						}
						case EnumParametro.UsuarioBimOperacion: {
							setUsuarioBimOperacion(resp.data);
							break;
						}
						case EnumParametro.IpTdr:{
							setObtenerIpTdr(resp.data);
							break;
						}
						default: {
							break;
						}
					}

				}
			}
		}
	}

	const getListaTipoFalla = useCallback(
		function () {
			postListaTipoFalla();
		},
		[]
	);

	const getListaPerfilesAreasNegocio = useCallback(
		function () {
			obtenerPerfilesAreasNegocio();
		},
		[]
	);
	const getListadoAreasNegocioIdUsuario = useCallback(
		function () {
			obtenerAreasNegocioIdUsuario();
		},
		[]
	);
	const getListaUsuarioSeleccionables = useCallback(
		function (estado) {
			postObtenerListadoUsuarioPorEstado(estado);
		},
		[]
	);
	const obtenerContadorAlarmasNoLeidas = useCallback(
		function (values) {
			postContadorAlarmasNoLeidas(values);
		},
		[]
	);
	const getListaUnidadMedida = useCallback(
		function () {
			postObtenerListaUnidadMedida();
		},
		[]
	);
	const obtenerContadorNotificacionNoLeidas = useCallback(
		function (idUsuario, idPerfil) {
			postContadorNotificacionNoLeidas(idUsuario, idPerfil);
		},
		[]
	);
	const obtenerListaIndicadoresIngresoHallazgo = useCallback(
		function (values) {
			postListaIndicadoresIngresoHallazgo(values);
		},
		[]
	);
	const obtenerListaTipoHallazgo = useCallback(
		function () {
			postListaTipoHallazgo();
		},
		[]
	);
	const obtenerTiposNotificaciones = useCallback(
		function () {
			postObtenerTiposNotificaciones();
		},
		[]
	);
	const obtenerContadorTotalPendientesAutorizacion = useCallback(
		function (usuarioId) {
			postContadorTotalPendientesAutorizacion(usuarioId);
		},
		[]
	);
	const getListaIndicadores = useCallback(
		function (values) {
			postObtenerListadoIndicadoresPorAreaNegocio(values);
		},
		[]
	);
	const getListaIndicadoresId = useCallback(
		function (values) {
			postObtenerListaIndicadoresAreaNegocioId(values);
		},
		[]
	);
	const getContadorMediciones = useCallback(
		function () {
			postContadorMedicionesManuales();
		},
		[]
	);
	const ObtenerListaSerie = useCallback(
		function (values) {
			postObtenerListaSerie(values);
		},
		[]
	);
	const ObtenerListaIndicadorDt = useCallback(
		function (values) {
			postObtenerListaIndicadorDt(values);
		},
		[]
	);

	const ObtenerPeriodoDisponibilidades = useCallback(
		function (values) {
			postObtenerPeriodoDisponibilidad(values);
		},
		[]
	);

	const ObtenerListaIndicadoresTdr = useCallback(
		function (values) {
			postObtenerListaIndicadoresTdr(values);
		},
		[]
	);

	const obtieneEstadoHallazgo = useCallback(
		function () {
			postEstadoHallazgo();
		},
		[]
	);

	const getListaProblemaHallazgo = useCallback(
		function (indicadorId, idMacroubicacion) {
			postListaProblemaHallazgo(indicadorId, idMacroubicacion);
		},
		[]
	);

	const getListaMacroubicacionHallazgo = useCallback(
		function (indicadorId) {
			postListaMacroubicacionHallazgo(indicadorId);
		},
		[]
	);

	const getListaElementoHallazgo = useCallback(
		function (problemaSeleccionado, idMacroubicacion) {
			postListaElementoHallazgo(problemaSeleccionado, idMacroubicacion);
		},
		[]
	);

	const getListaGravedadHallazgo = useCallback(
		function (values) {
			postListaGravedadHallazgo(values);
		},
		[]
	);

	const getAccionActualizaHallazgo = useCallback(
		function (values) {
			postAccionActualizaHallazgo(values);
		},
		[]
	);

	const getObtenerUsuariosPorPerfil = useCallback(
		function (values) {
			postObtenerUsuariosPorPerfil(values);
		},
		[]
	);

	const ParametroObtenerPorId = useCallback(
		function (values) {
			postParametroObtenerPorId(values);
		},
		[]
	);

	return {
		optionsProfile
		, optionsBusiness
		, listadoUsuarioSeleccionable
		, contadorAlarmas
		, contadorNotificaciones
		, listadoNotificacionesSeleccionable
		, listadoUnidadMedida
		, contadorTotalPendientesAutorizacion
		, listadoIndicadores
		, listadoIndicadoresIngresoHallazgo
		, listadoTipoHallazgo
		, listadoIndicadoresId
		, contadorMediciones
		, listaSerie
		, listaIndicadorDt
		, listaIndicadoresTdr
		, tiempoIndicadoresTdr
		, listaTdr
		, listadoEstado
		, listaProblemaHallazgo
		, listaMacroubicacionHallazgo
		, listaElementoHallazgo
		, listaGravedadHallazgo
		, listaTipoFalla
		, listaAccionActualizarHallazgo
		, listaUsuarioXPerfil
		, numeroContrato
		, usuarioBimOperacion
		, obtenerIpTdr
		, periodo
		, listaPeriodo
		, ultimoPeriodo
		, downloadCSV
		, downloadXUrl
		, obtenerFormatoFecha
		, formatoBytes
		, metodoRecursivoXNivelServicioPadreId
		, ValidarListaMetodoRecursivoXNivelServicioPadreId
		, metodoRecursivoXNivelServicioId
		, getListaPerfilesAreasNegocio
		, getListadoAreasNegocioIdUsuario
		, getListaUsuarioSeleccionables
		, obtenerContadorAlarmasNoLeidas
		, obtenerContadorNotificacionNoLeidas
		, obtenerListaIndicadoresIngresoHallazgo
		, obtenerListaTipoHallazgo
		, getListaUnidadMedida
		, obtenerTiposNotificaciones
		, obtenerContadorTotalPendientesAutorizacion
		, getListaIndicadores
		, getListaIndicadoresId
		, getContadorMediciones
		, ObtenerListaSerie
		, ObtenerListaIndicadorDt
		, ObtenerListaIndicadoresTdr
		, ObtenerColoresNivelesServicio
		, obtieneEstadoHallazgo
		, getListaProblemaHallazgo
		, getListaMacroubicacionHallazgo
		, getListaElementoHallazgo
		, getListaGravedadHallazgo
		, getListaTipoFalla
		, getAccionActualizaHallazgo
		, getObtenerUsuariosPorPerfil
		, setListaElementoHallazgo
		, setListaProblemaHallazgo
		, ParametroObtenerPorId
		, setListadoIndicadoresId
		, setListadoIndicadores
		, setOptionsBusiness
		, ObtenerPeriodoDisponibilidades
	}
}

