import Exports from "../../../Components/Exports/Exports";
import { usePagination, useSortBy, useTable } from 'react-table';
import React, { useEffect, useState } from 'react';
import notfound from '../../../Assets/images/il-empty.svg';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import PaginationComponent from '../../../Components/PaginationTableServer/PaginationTableServer';

const TablaHallazgoFiltro = ({ columns, data, fetchData, pageCount: controlledPageCount, currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy, setPagination, handlerModal, tipoFiltro }) => {
	const {
		getTableProps
		, getTableBodyProps
		, headerGroups
		, prepareRow
		, page
		, pageCount
		, gotoPage
		, state: { pageIndex, pageSize, sortBy }
	} = useTable(
		{
			columns
			, data
			, initialState: { pageIndex: currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy }
			, manualPagination: true
			, pageCount: controlledPageCount
			, autoResetPage: false
			, autoResetSortBy: false
			, manualSortBy: true
		},
		useSortBy,
		usePagination
	);
	const mostrarModal = (e, row) => {
		e.preventDefault();
		handlerModal(row);
	}

	const { usuario } = useLogin();

	const [valoresFormularioEnvio, setValoresFormularioEnvio] = useState({
		paginacion: {
			numeroPagina: pageIndex
			, cantidadRegistros: pageSize
			, orderByAscending: false
			, ordenadoPor: "AreaNegocio"
		},
		datosBusqueda: {
			idUsuario: usuario.id
			, idPerfilContexto: usuario.perfilContexto.id
			, exportacion: false
			, idFiltroHallazgo: tipoFiltro
		}
	});

	useEffect(() => {
		fetchData({ formulario: valoresFormularioEnvio, pageIndex, sortBy });
		/* GUARDAMOS EL SORTBY EN EL USESTATE DE PAGINATION QUE SE ENCUENTRA EN ALARMASPAGE PARA QUE NO SE PIERDA EL ORDENAMIENTO */
		setPagination(values => ({
			...values
			, sortBy: sortBy
		}));
	}, [pageIndex, sortBy]);

	const descargar = () => {
		fetchData({ formulario: valoresFormularioEnvio, pageIndex: pageIndex, sortBy, exportacion: true });
	};

	return (
		<div>
			<div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-5 gap-3">
				<div className="filters order-1 order-lg-0">
				</div>
				{
					data.length > 0 &&
					<div className="exports order-0 order-lg-1">
						<Exports descargar={() => descargar()} />
					</div>
				}
			</div>
			<div className="table-responsive brd-top">
				<table className="table" {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									data.length > 0 ?
										<th {...column.getHeaderProps(column.getSortByToggleProps(), { className: column.className })}>
											<span className="d-flex align-items-center gap-1">
												{column.render('Header')}
												<>
													{column.isSorted && data.length > 0 ?
														(column.isSortedDesc ?
															<i className="ico-chevron-down"></i>
															:
															<i className="ico-chevron-up"></i>
														) :
														<>
															<i className="ico-sort fs-7"></i>
														</>
													}
												</>
											</span>
										</th>
										:
										<th {...column.getHeaderProps()}
										>
											<span className="d-flex align-items-center gap-1">
												{column.render('Header')}
											</span>
										</th>
								))}
								<th className="text-center">Detalle</th>
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{
							page.length > 0 && page.map((row) => {
								prepareRow(row)
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps({
												className: cell.column.className
											})}>
												{cell.render('Cell')}
											</td>
										})}
										<td className="text-center">
											<button className="link text-pewter-blue fs-4" onClick={(e) => mostrarModal(e, row.original)}>
												<i className="ico-search"></i>
											</button>
										</td>
									</tr>
								)
							}) || <tr><td colSpan={headerGroups[0].headers.length}>
								<div className="d-flex justify-content-center align-items-center flex-column gap-2 py-5">
									<img className="mb-3" src={notfound} alt="" />
									<p className='mb-0 text-primary'>No se encuentran resultados de tu búsqueda.</p>
								</div>
							</td></tr>
						}
					</tbody>
				</table>
			</div>
			{
				data.length > 0 &&
				<PaginationComponent
					pagesCountServer={pageCount}
					currentPage={pageIndex}
					setCurrentPage={gotoPage}
					alwaysShown={false}
					setPagination={setPagination}
				/>
			}
		</div>
	)
}

export default TablaHallazgoFiltro;
