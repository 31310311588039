import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const IngresoHallazgoService = async (values) => {
    try {
        const resultado = await instance.Backend.post("/Hallazgo/IngresoHallazgo",values, customConfig);

        return resultado;

    } catch (error) {

        console.log(error);

    }

}
export { IngresoHallazgoService };