import Modal from 'react-bootstrap/Modal';
import { Formik, Form, } from "formik";
import InputTextField from '../../../Components/InputTextField/InputTextField';
import Button from '../../../Components/Button/Button';
import { format } from 'rut.js';
import InputFileField from '../../../Components/InputFileField/InputFileField';
import validations from '../../../Utils/Validations/Validations';
import { useToast } from '../../../Hooks/useToast';
import { useModalUser } from '../Hooks/useModalUser';
import SelectAutocompleteField from '../../../Components/SelectAutocompleteField/SelectAutocompleteField';
import { AgregarUsuario } from '../Services/AgregarUsuarioService';
import { ModificarUsuario } from '../Services/ModificarUsuarioService';
import { useEffect, useRef, useState } from 'react';
import { useComun } from '../../../Hooks/useComun';
import SelectCheckField from '../../../Components/SelectCheckField/SelectCheckField';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const ModalUser = (props) => {
	const { renderizar, perfiles, areaNegocio, ...resto } = props;
	const formikRef = useRef();
	const { optionsBusiness, optionsProfile, getListaPerfilesAreasNegocio } = useComun();
	const [valuesFormulario, setValuesFormulario] = useState({ values: {}, listaPerfil: [] });
	const { initialValues, isCrear, rest, mostrarAreaNegocio } = useModalUser(resto, valuesFormulario);
	const addToast = useToast();
	const { usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	async function postAgregarUsuario(values) {
		const startTime = new Date();
		const resp = await AgregarUsuario(values, usuario.id);
		if (resp) {
			if (resp.status === 201) {
				const fetchData = async () => {
					await Promise.all([
						addToast({ mostrar: true, mensaje: 'Usuario guardado correctamente.', titulo: 'Confirmación', tipo: 'Success' }),
						renderizar(),
						rest.onHide(),
					]);
					const endTime = new Date();
					const tdrId = 67;
					crearRegistroTDR(startTime, endTime, usuario, tdrId);
				};

				fetchData();
			}
		}
	}
	async function postModificarUsuario(values) {
		const startTime = new Date();
		const resp = await ModificarUsuario(values, usuario.id);
		if (resp) {
			if (resp.status === 200) {
				const fetchData = async () => {
					await Promise.all([
						addToast({ mostrar: true, mensaje: 'Usuario actualizado correctamente.', titulo: 'Confirmación', tipo: 'Success' }),
						renderizar(),
						rest.onHide(),
					]);
					const endTime = new Date();
					const tdrId = 70;
					crearRegistroTDR(startTime, endTime, usuario, tdrId);
				};

				fetchData();
			}
		}
	}
	function changePerfil(e, val) {
		formikRef.current.setFieldValue("profile", val);
		setValuesFormulario({ values: formikRef.current.values, listaPerfil: val.map(Number) });
	}

	useEffect(() => {
		if (rest.show) {
			setValuesFormulario({ values: {}, listaPerfil: [] });
			getListaPerfilesAreasNegocio();
		}
	}, [rest.show])

	return (
		<Modal
			{...rest}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<h5 className="modal-title" id="modalUserLabel">
					<i className="ico-user-add me-2"></i>
					{isCrear ?
						<span>Nuevo Usuario</span>
						:
						<span>Modificar Usuario</span>
					}
				</h5>
			</Modal.Header>
			<Modal.Body>
				<Formik
					enableReinitialize
					innerRef={formikRef}
					initialValues={initialValues}
					validate={validations}
					onSubmit={values => {
						values.file = values.file === '' ? null : values.file;

						let formEnvio = values;
						formEnvio.business = values.business ? values.business : [];
						if (isCrear) {
							postAgregarUsuario(formEnvio);
						} else {
							postModificarUsuario(formEnvio);
						}

					}}
				>
					{({
						values,
						handleChange,
						setFieldValue,
						setFieldTouched,
					}) => (
						<Form className="form mb-0">
							<InputTextField
								name="rut"
								label="Rut"
								type="text"
								placeholder="11.111.111-1"
								autoComplete="true"
								maxLength="12"
								value={values.rut}
								disabled={!isCrear}
								onChange={(event) => {
									const formatted = (event.target.value === "" || event.target.value === "-") ? "" : format(event.target.value);
									setFieldValue("rut", formatted);
									handleChange(formatted);
								}}
							/>
							<InputTextField
								name="nombre"
								label="Nombre"
								type="text"
								maxLength="50"
								placeholder="Nombre"
								autoComplete="false"
							/>
							<InputTextField
								name="apellido_paterno"
								label="Apellido paterno"
								type="text"
								maxLength="50"
								placeholder="Apellido"
								autoComplete="false"
							/>
							<InputTextField
								name="apellido_materno"
								label="Apellido materno"
								type="text"
								maxLength="50"
								placeholder="Apellido materno"
								autoComplete="false"
							/>
							<InputTextField
								name="email"
								label="Correo electrónico"
								type="email"
								maxLength="50"
								placeholder="Correo electrónico"
								autoComplete="false"
							/>
							<InputTextField
								name="celular"
								label="Celular"
								type="text"
								maxLength="12"
								placeholder="Celular"
								autoComplete="false"
							/>

							<SelectCheckField name="profile" label="Perfil"
								options={optionsProfile}
								onChange={(e, val) => { changePerfil(e, val); }}
								onBlur={setFieldTouched}
								isMulti={true}
								fijo={false}
							/>
							{mostrarAreaNegocio &&
								<SelectCheckField name="business" label="Área de negocio"
									options={optionsBusiness}
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									isMulti={true}
									fijo={false}
								/>
							}

							<div className="form-floating mb-3">
								<InputFileField placeholder="Subir Imagen" name="file" onChange={(event) => { setFieldValue("file", event.target.files[0]); }} />
							</div>
							<div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4">
								<Button type="button" design="btn-outline-pewter-blue" onClick={rest.onHide}>Cancelar</Button>
								<Button type="submit" design="btn-primary">Guardar</Button>
							</div>
						</Form>
					)}
				</Formik>
			</Modal.Body>
		</Modal>
	)
}

export default ModalUser;
