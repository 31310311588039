import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const cambioClave = async (values) => {

  try {
    const resultado = await instance.Backend.post("/Usuario/RecuperarContrasenaCodigo", {
      codigo: values.codigo,
      contrasena: values.clave,
      usuarioId: values.id
    }, customConfig);

    return resultado;

  } catch (error) {
    console.log(error);
  }

}
export { cambioClave };