import { useCallback, useState, createContext } from "react";
const ContadoresContext = createContext();

export default ContadoresContext;
export function ContadoresContextProvider({ children }) {
    const [contador, setContador] = useState(false);
    const actualizarContador = useCallback(
        function (value) {
            setContador(() => value);
        },
        [setContador]
    );

    return (
        <ContadoresContext.Provider value={{actualizarContador, contador}}>
            {children}
        </ContadoresContext.Provider>
    );
}
