import Modal from 'react-bootstrap/Modal';
import React, { useMemo, useEffect, useState } from "react";
import Avatar from '../../../Components/Avatar/Avatar';
import Exports from '../../../Components/Exports/Exports';
import { ObtenerActividadUsuarioDetalle } from '../Services/ObtenerActividadUsuarioDetalle';
import { useComun } from '../../../Hooks/useComun';
import { COLUMNS } from '../Utils/ColumnsDetailUser';
import Card from "../../../Components/Card/Card";
import BitacoraDetailTable from "../Components/BitacoraDetailTable";
import { EnumTablaPaginacion } from '../../../Utils/Enumeradores/ComunEnum';
import { EnumModuloDesc } from "../../../Utils/Enumeradores/ComunEnum";
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const ModalBitacoraUser = (props) => {
	const { downloadCSV } = useComun();
	const { dataDetalle, ...rest } = props;
	const [data, setData] = React.useState([]);
	const columns = useMemo(() => COLUMNS, []);
	const [pageCount, setPageCount] = React.useState(0);
	const { usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const [{ pageIndex, pageSize, sortBy }, setPagination] =
		React.useState({
			pageIndex: 0,
			pageSize: EnumTablaPaginacion.CantidadPaginacion,
			sortBy: []
		});
	useEffect(() => {
		if(!rest.show){
			setPagination({
				pageIndex: 0,
				pageSize: EnumTablaPaginacion.CantidadPaginacion,
				sortBy: []
			});
		}
	}, [rest.show])
	async function postObtenerActividadUsuarioDetalle(values) {
		const startTime = new Date();
		const resp = await ObtenerActividadUsuarioDetalle(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					if (values.datosBusqueda.exportacion) {
						const fetchData = async () => {
							await Promise.all([
								downloadCSV(resp.data, "Bitacora Detalle", EnumModuloDesc.DescargaBitacoraUsuario)
							]);
							const endTime = new Date();
							const tdrId = 27;
							crearRegistroTDR(startTime, endTime, usuario, tdrId);
						};

						fetchData();
					} else {
						setPageCount(resp.data.cantidadPaginas);
						setData(resp.data.listadoDetalleActividadUsuario);
					}
				}
			}
		}
	}

	const [valoresFormularioEnvio, setValoresFormularioEnvio] = useState({
		paginacion: {
			numeroPagina: pageIndex
			, cantidadRegistros: pageSize
			, orderByAscending: false
			, ordenadoPor: "FechaInicio"
		},
		datosBusqueda: {
			idUsuario: dataDetalle.usuarioId
			, idBitacora: dataDetalle.bitacoraDetalleId
			, exportacion: false
		}
	});

	const descargar = () => {
		fetchData({ formulario: valoresFormularioEnvio, pageIndex: pageIndex, exportacion: true });
	};

	const fetchData = React.useCallback(({ formulario, pageIndex, sortBy = [], exportacion = false }) => {
		const startTime = new Date();
		formulario.paginacion.numeroPagina = pageIndex + 1;
		formulario.paginacion.ordenadoPor = sortBy.length > 0 ? sortBy[0].id : formulario.paginacion.ordenadoPor;
		formulario.paginacion.orderByAscending = sortBy.length > 0 ? (sortBy[0].desc ? false : true) : formulario.paginacion.orderByAscending;
		formulario.datosBusqueda.exportacion = exportacion;
		const fetchData = async () => {
			await Promise.all([
				postObtenerActividadUsuarioDetalle(formulario)
			]);
			const endTime = new Date();
			const tdrId = 26;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
	}, [])

	return (
		<Modal
			{...rest}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body className='pt-3'>
				<div className="row">
					<div className="col-12">
						<Card className="p-4">
							<BitacoraDetailTable
								columns={columns}
								data={data}
								avatar={dataDetalle}
								fetchData={fetchData}
								pageCount={pageCount}
								currentpage={pageIndex}
								pageSize={pageSize}
								sortBy={sortBy}
								setPagination={setPagination}
							/>
						</Card>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default ModalBitacoraUser;
