import React, { useEffect, useMemo, useRef, useState } from 'react';
import Exports from "../../../Components/Exports/Exports";
import Button from "../../../Components/Button/Button";
import { COLUMNS } from '../Utils/Columns';
import { Form, Formik } from 'formik';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NivelCumplimientoIndicadorSerieDetalleEstadoService } from '../Services/NivelCumplimientoIndicadorSerieDetalleEstadoService';
import { EnumModuloDesc, EnumPathRutas, EnumTablaPaginacion } from '../../../Utils/Enumeradores/ComunEnum';
import ModalDetalleEventoConcesion from '../../../Components/ModalDetalleEventoConcesion/ModalDetalleEventoConcesion';
import RangeDatePicker from '../../../Components/RangeDatePicker/RangeDatePicker';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Badge from '../../../Components/Badge/Badge';
import { useComun } from '../../../Hooks/useComun';
import TablaHallazgoIndicador from '../Components/TablaHallazgoIndicador';
import { useTablasIndicadores } from '../Hooks/useTablasIndicadores';
import { ColumnasHallazgoIndicador } from '../Utils/ColumnaHallazgoIndicador';
import validations from '../../../Utils/Validations/Validations';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';
import useLogin from '../../Autenticacion/Hooks/useLogin';

const IndicadoresCuartoNivelPage = () => {
	const [key, setKey] = useState('indicadores');
	const { crearRegistroTDR } = useRegistroTDR();
	const { user, usuario } = useLogin();
	const formikref = useRef();
	const navigate = useNavigate();
	const columns = useMemo(() => COLUMNS, []);
	const columnasHallazgoIndicador = useMemo(() => ColumnasHallazgoIndicador, []);
	const [esDescargar, setEsDescargar] = useState(false);
	const [esDescargarHallazgo, setEsDescargarHallazgo] = useState(false);
	const [data, setData] = useState([]);
	const location = useLocation();
	const [show, setShow] = useState(false);
	const [datosModal, setDatosModal] = useState({});
	const [periodo, setPeriodo] = useState(null);
	const [muestraBuscador, setMuestraBuscador] = useState(false);
	const [serieDesc, setSerieDesc] = useState("");
	const { downloadCSV } = useComun();
	const [peticionBuscar, setPeticionBuscar] = useState(false);
	const item = location.state ? location.state.item : {};
	const datosIndicador = {
		siglaIndicador: location.state ? location.state.sigla : ""
		, descripcionIndicador: location.state ? location.state.descripcion : ""
		, nivelCumplimiento: location.state ? location.state.idNivelCumplimiento : null
		, descripcionSerie: location.state ? location.state.descripcionSerie : ""
	};
	const [pageCount, setPageCount] = React.useState(0);
	const [{ pageIndex, pageSize, sortBy }, setPagination] =
		React.useState({
			pageIndex: 0,
			pageSize: EnumTablaPaginacion.CantidadPaginacion,
			sortBy: []
		});
	const [{ pageIndexHallazgo, pageSizeHallazgo, sortByHallazgo }, setPaginationHallazgo] =
		React.useState({
			pageIndexHallazgo: 0,
			pageSizeHallazgo: EnumTablaPaginacion.CantidadPaginacion,
			sortByHallazgo: []
		});
	const {
		getNivelCumplimientoHallazgoIndicadorSerieDetalle
		, dataHallazgoIndicador
		, pageCountHallazgoIndicador } = useTablasIndicadores({ siglaIndicador: ("Detalle Indicador Hallazgo " + (item.sigla ? item.sigla : '')), EnumModulo: EnumModuloDesc.DescargaIndicadores });

	async function postNivelCumplimientoIndicadorSerieDetalleEstado(values) {
		const startTime = new Date();
		const resp = await NivelCumplimientoIndicadorSerieDetalleEstadoService(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					if (values.datosBusqueda.exportacion) {
						downloadCSV(resp.data, ("Detalle Indicador " + (item.sigla ? item.sigla : '')), EnumModuloDesc.DescargaIndicadores);
					} else {
						const fetchData = async () => {
							await Promise.all([
								setMuestraBuscador(resp.data.serieId >= 5 ? true : false),
								setSerieDesc(resp.data.serieDescripcion),
								setPageCount(resp.data.cantidadPaginas),
								setData(resp.data.listadoExigencias),
							]);
							const endTime = new Date();
							const tdrId = 8;
							crearRegistroTDR(startTime, endTime, usuario, tdrId);
						};
						fetchData();
					}
				}
			} else if (resp.status === 206) {
				const fetchData = async () => {
					await Promise.all([
						setPageCount(0),
						setData([]),
					]);
					const endTime = new Date();
					const tdrId = 8;
					crearRegistroTDR(startTime, endTime, usuario, tdrId);
				};
				fetchData();
			}
		}
	}

	useEffect(() => {
		if (muestraBuscador) {
			formikref.current.setFieldValue("periodo2", '');
			formikref.current.resetForm();
		}
		key === "indicadores" ?
			setPaginationHallazgo({
				pageIndexHallazgo: 0,
				pageSizeHallazgo: EnumTablaPaginacion.CantidadPaginacion,
				sortByHallazgo: []
			})
			:
			setPagination({
				pageIndex: 0,
				pageSize: EnumTablaPaginacion.CantidadPaginacion,
				sortBy: []
			})

	}, [key])

	const fetchData = React.useCallback(({ formulario, pageIndex, sortBy = [], exportacion = false }) => {
		formulario.paginacion.numeroPagina = pageIndex + 1;
		formulario.paginacion.ordenadoPor = sortBy.length > 0 ? sortBy[0].id : formulario.paginacion.ordenadoPor;
		formulario.paginacion.orderByAscending = sortBy.length > 0 ? (sortBy[0].desc ? false : true) : formulario.paginacion.orderByAscending;
		formulario.datosBusqueda.exportacion = exportacion;
				postNivelCumplimientoIndicadorSerieDetalleEstado(formulario);
	}, []);

	const fetchDataHallazgoIndicador = React.useCallback(({ formulario, pageIndex, sortBy = [], exportacion = false }) => {
		formulario.paginacion.numeroPagina = pageIndex + 1;
		formulario.paginacion.ordenadoPor = sortBy.length > 0 ? sortBy[0].id : formulario.paginacion.ordenadoPor;
		formulario.paginacion.orderByAscending = sortBy.length > 0 ? (sortBy[0].desc ? false : true) : formulario.paginacion.orderByAscending;
		formulario.datosBusqueda.exportacion = exportacion;
				getNivelCumplimientoHallazgoIndicadorSerieDetalle(formulario);
	}, [])

	function handlerModal(item) {
		if (key === "indicadores") {
			setDatosModal(item);
			setShow(true);
		} else {
			navigate('/hallazgos', { state: { hallazgoId: item.hallazgoId } })
		}

	}
	const handleClose = () => setShow(false);

	return (
		<div className="container-fluid">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
							<li><Link to={EnumPathRutas.Indicadores}> Indicadores</Link></li>
							<li><a href="">{item.descripcion ? item.descripcion : ''}</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="bg-white rounded p-4">
						<div className="d-flex flex-column flex-xl-row justify-content-between align-items-xl-center gap-3 mb-3">
							<div className="d-flex flex-column flex-md-row align-items-start gap-3 justify-content-between w-100">
								<span className='d-flex flex-column'>
									<h3 className="fs-3">{item.sigla ? item.sigla : ''}</h3>
									<span className="text-pewter-blue d-block">{item.descripcion ? item.descripcion : ''}</span>
								</span>


								{muestraBuscador ?
									<Formik
										enableReinitialize
										innerRef={formikref}
										initialValues={{ periodo2: '' }}
										validate={validations}
										onSubmit={values => {
											setPeriodo(values.periodo2);
											setPeticionBuscar(true);
										}}
									>
										{({ setFieldValue, setFieldTouched, }) => (
											<Form className="form mb-0 me-md-3 ms-md-auto">
												<div className="tools order-1 order-lg-0 align-items-start">
													<RangeDatePicker
														startMaxDate={new Date()}
														startMinDate={null}
														endMaxDate={new Date()}
														endMinDate={null}
														name="periodo2"
														onChange={setFieldValue}
														onBlur={setFieldTouched}
													/>
													<Button type="submit" design="btn-primary">Buscar</Button>
												</div>
											</Form>
										)}
									</Formik>
									: null
								}
								{key === "indicadores" &&
									data.length > 0 &&
									<div className="exports align-self-end align-self-md-start">
										<Exports descargar={() => setEsDescargar(true)} />
									</div>
								}
								{key === "hallazgo" &&
									dataHallazgoIndicador.length > 0 &&
									<div className="exports align-self-end align-self-md-start">
										<Exports descargar={() => setEsDescargarHallazgo(true)} />
									</div>
								}
							</div>


						</div>
						<div className="d-flex flex-column flex-lg-row justify-content-between align-items-start align-items-lg-center gap-3">
							{serieDesc ?
								<Badge
									design={"secondary"}
									className="mb-3"
								>{serieDesc}</Badge>
								: null}
						</div>
						<Tabs
							defaultActiveKey="indicadores"
							activeKey={key}
							transition={false}
							id="tabs"
							onSelect={(k) => setKey(k)}
						>
							<Tab eventKey="indicadores" title="Eventos" className="p-0">
								{key === "indicadores" &&
									<TablaHallazgoIndicador
										columns={columns}
										data={data}
										fetchData={fetchData}
										pageCount={pageCount}
										currentpage={pageIndex}
										pageSize={pageSize}
										sortBy={sortBy}
										setPagination={setPagination}
										handlerModal={handlerModal}
										datosIndicador={datosIndicador}
										tab={key}
										periodo={periodo}
										peticionBuscar={peticionBuscar}
										esDescargar={esDescargar}
										setEsDescargar={setEsDescargar}
										setPeticionBuscar={setPeticionBuscar}
									/>
								}
							</Tab>
							<Tab eventKey="hallazgo" title="Hallazgo">
								{key === "hallazgo" &&
									<TablaHallazgoIndicador
										columns={columnasHallazgoIndicador}
										data={dataHallazgoIndicador}
										fetchData={fetchDataHallazgoIndicador}
										pageCount={pageCountHallazgoIndicador}
										currentpage={pageIndexHallazgo}
										pageSize={pageSizeHallazgo}
										sortBy={sortByHallazgo}
										setPagination={setPaginationHallazgo}
										handlerModal={handlerModal}
										datosIndicador={datosIndicador}
										esDescargar={esDescargarHallazgo}
										setEsDescargar={setEsDescargarHallazgo}
										tab={key}
										periodo={periodo}
										peticionBuscar={peticionBuscar}
										setPeticionBuscar={setPeticionBuscar}

									/>
								}
							</Tab>
						</Tabs>
					</div>
				</div>
			</div>
			<ModalDetalleEventoConcesion
				show={show}
				onHide={handleClose}
				datosModal={datosModal}
			/>
		</div>
	)
}
export default IndicadoresCuartoNivelPage;
