import { useCallback, useState } from 'react'
import { ObtenerListadoUsuarioConPerfilAreaNegocio } from '../Features/Usuario/Services/ObtenerListUsuarioConPerfilAreaNegocioService';
import useLogin from '../Features/Autenticacion/Hooks/useLogin';
import { GuardarAvatar } from '../Features/Usuario/Services/GuardarAvatar';
import { useComun } from './useComun';
import { EnumModuloDesc } from '../Utils/Enumeradores/ComunEnum';
import { useRegistroTDR } from './useRegistroTDR';


export function useUsuario(props) {
	const [listadoUsuario, setListadoUsuario] = useState([]);
	const { usuario, user, setUser, setUsuario } = useLogin();
	const modulos = usuario ? (usuario.listaModuloAcceso ? usuario.listaModuloAcceso : []) : [];
	const { downloadCSV } = useComun();
	const { crearRegistroTDR } = useRegistroTDR();

	async function postObtenerListadoUsuarioTabla(descargar) {
		const startTime = new Date();
		const resp = await ObtenerListadoUsuarioConPerfilAreaNegocio(descargar, usuario.id);
		if (resp) {
			if (resp.status === 200) {
				if (!descargar.exportacion) {
					if (resp.data) {
						const fetchData = async () => {
							await Promise.all([
								setListadoUsuario(resp.data)
							]);
							const endTime = new Date();
							const tdrId = 66;
							crearRegistroTDR(startTime, endTime, usuario, tdrId);
						};

						fetchData();
					}
				} else {
					if (resp.data) {
						const fetchData = async () => {
							await Promise.all([
								downloadCSV(resp.data, "Usuarios", EnumModuloDesc.DescargaAlarmasPerfil),
							]);
							const endTime = new Date();
							const tdrId = 71;
							crearRegistroTDR(startTime, endTime, usuario, tdrId);
						};

						fetchData();
					}
				}
			}
		}
	}

	async function postGuardarImagen(values) {

		const resp = await GuardarAvatar(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					setUser({ ...user, rutaImagen: resp.data });
					setUsuario({ ...usuario, rutaImagen: resp.data });
				}
			}
		}
	}
	const getUsuarios = useCallback(
		function (descargar) {
			postObtenerListadoUsuarioTabla(descargar);
		},
		[]
	);

	const guardarImagen = useCallback(
		function (values) {
			postGuardarImagen(values);
		},
		[]
	);

	return { listadoUsuario, getUsuarios, usuario, modulos, guardarImagen }
}
