import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const BuscadorEventosManualesServices = async (values) => {
    try {
        const resultado = await instance.Backend.post("/Autorizacion/BuscadorEventosManuales",
            values, customConfig
        );
        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { BuscadorEventosManualesServices };