import { useField } from "formik";

const InputCheckField = ({label, ...props}) => {
	const [field, meta] = useField({...props, type: 'checkbox'});
	return (
		<div>
			<div className="d-flex form-check text-pewter-blue mb-0">
				<input
					id={`field-${props.name}`}
					name={props.name}
					type="checkbox"
					className="form-check-input me-2"
					{...field}
					{...props}
				/>
				<label className="form-check-label fs-6" role="button" htmlFor={`field-${props.name}`}>{label}</label>
				{meta.touched && meta.error ?
					<small className="message">{meta.error}</small> : null}
			</div>
		</div>
	);
}
export default InputCheckField;
