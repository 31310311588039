import { useState } from "react"
import ModalEstadoHallazgo from "./ModalEstadoHallazgo";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import useLogin from "../../Autenticacion/Hooks/useLogin";
import { useRegistroTDR } from "../../../Hooks/useRegistroTDR";

const EtapaCelda = ({ ...props }) => {
	const { usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => {
		const startTime = new Date();
		setShow(true);

		setTimeout(() => {
			const endTime = new Date();
			const tdrId = 40;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		}, 0);
	};

	const [showTooltip, setShowTooltip] = useState(false);
  const onEnter = () => {
    document.body.classList.add('overflow-hidden');
    setShowTooltip(true);
  };

	const onLeave = () => {
		document.body.classList.remove('overflow-hidden');
		setShowTooltip(false);
	};
	const renderTooltip = () => (
		<Tooltip id={`tooltip-top`}>{props.etapaDescripcion}</Tooltip>
  );


	return (
		<>
			<div className="state justify-content-center" onClick={handleShow}>
				<OverlayTrigger
					key='top'
					placement='top'
					onEnter={onEnter}
      		onExit={onLeave}
					overlay={renderTooltip()}
				>
						<span className="state__indice">{props.etapa}</span>
				</OverlayTrigger>
			</div>
			<ModalEstadoHallazgo
				datosModal={{ hallazgoId: props.hallazgoId }}
				show={show}
				onHide={handleClose}
			/>
		</>
	)
}
export default EtapaCelda;
