import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ListadoAlarmaListaService = async (exportacion, idUsuario) => {
    try {
        const resultado = await instance.Backend.post("/Alarma/ListadoAlarmaListaDistribucion"
        , {
            exportacion: exportacion.exportacion,
            idUsuario: idUsuario
        }, customConfig);

        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { ListadoAlarmaListaService };