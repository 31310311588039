const Badge = (props) => {
	return (
		<span
		{...props}
		className={`badge bg-${props.design} ${props.className}`}>
			{props.children}
		</span>
	)
}
export default Badge;
