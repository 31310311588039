import { useEffect, useState, useCallback, useRef } from "react";
import { useMemo } from 'react';
import { Form, Formik } from 'formik';
import TablaExigencias from './TablaExigencias';
import { EnumTablaPaginacion } from '../../../Utils/Enumeradores/ComunEnum'
import SelectCheckField from '../../../Components/SelectCheckField/SelectCheckField';
import RangeDatePicker from '../../../Components/RangeDatePicker/RangeDatePicker';
import Button from '../../../Components/Button/Button';
import { useComun } from '../../../Hooks/useComun';
import moment from "moment";
import { useColumns } from '../Utils/ColumnsExigencias';
import { BuscadorEventosManualesServices } from "../Services/BuscadorEventosManualesServices";
import ModalConfirmacion from "../Components/ModalConfirmacion"
import ModalDetalleEventoConcesion from "../../../Components/ModalDetalleEventoConcesion/ModalDetalleEventoConcesion";
import validations from "../../../Utils/Validations/Validations";
import useLogin from "../../Autenticacion/Hooks/useLogin";
import { useLoading } from "../../../Hooks/useLoading";
import { useRegistroTDR } from "../../../Hooks/useRegistroTDR";

const estado = [
	{ value: 1, label: 'Aprobado' },
	{ value: 2, label: 'Rechazado' },
	{ value: 3, label: 'Pendiente' },
	{ value: 0, label: 'Todos' }

];
const MedicionesManuales = (props) => {
	const { renderizarContadorMedicion } = props;
	const { listadoIndicadores, optionsBusiness, setListadoIndicadores, getListaIndicadores, getListaPerfilesAreasNegocio } = useComun();
	const [data, setData] = useState([]);
	const { usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const addLoading = useLoading();
	const [esBuscar, setEsBuscar] = useState(true);
	const [esPeticion, setEsPeticion] = useState(true);
	const formikRef = useRef();
	const [pageCount, setPageCount] = useState(0);
	const [renderizarTabla, setRenderizarTabla] = useState(false);
	useEffect(() => {
		getListaPerfilesAreasNegocio();

	}, [])

	const [mediciones, setMediciones] = useState({

		idNegocio: optionsBusiness ? optionsBusiness.map(x => { return x.value }) : []
		, idIndicador: listadoIndicadores ? listadoIndicadores.map(x => { return x.value }) : []
		, estado: 3
		, tipoIngreso: 1
		, fechaInicio: moment().subtract(1, 'M').format()
		, fechaTermino: moment().format()

	})
	const [initialValues, setInitialValues] = useState({
		area: optionsBusiness ? optionsBusiness.map(x => { return x.value }) : '',
		indicadores: listadoIndicadores ? listadoIndicadores.map(x => { return x.value }) : '',
		estado: 3,
		periodo2: [moment(mediciones.fechaInicio).toDate(), moment(mediciones.fechaTermino).toDate()]
	})
	useEffect(() => {
		if (listadoIndicadores.length > 0 && esBuscar) {
			setInitialValues(values => ({
				...values
				, area: formikRef.current.values.area
				, indicadores: listadoIndicadores ? listadoIndicadores.map(x => { return x.value }) : '',
			}));
			setMediciones(values => ({
				...values
				, idIndicador: listadoIndicadores ? listadoIndicadores.map(x => { return x.value }) : []
				, idNegocio: optionsBusiness ? optionsBusiness.map(x => { return Number(x.value) }) : []
			}));
			setRenderizarTabla(true);
			setEsBuscar(false);
		}

	}, [listadoIndicadores])

	const [{ pageIndex, pageSize, sortBy }, setPagination] =
		useState({
			pageIndex: 0,
			pageSize: EnumTablaPaginacion.CantidadPaginacion,
			sortBy: []
		});

	const renderizarListado = () => {
		setEsRenderizar(!esRenderizar);
	}
	const { COLUMNS } = useColumns();
	const columns = useMemo(() => COLUMNS, []);
	const [esRenderizar, setEsRenderizar] = useState(false);
	async function postObtenerListadoEventosConcesion(values) {
		const startTime = new Date();
		const resp = await BuscadorEventosManualesServices(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					setPageCount(resp.data.cantidadPaginas);
					let listado = resp.data.listadoEventoManual;
					let listadoAux = [];
					listado.map((x, i) => {
						const resultado = listado.filter(y => y.idIndicador === x.idIndicador);
						if (listadoAux.filter(y => y.idIndicador === resultado[0].idIndicador).length === 0) {
							listadoAux.push({ detalle: resultado, idIndicador: resultado[0].idIndicador, siglaIndicador: resultado[0].siglaIndicador });
						};
					});
					const fetchData = async () => {
						await Promise.all([
							setData(listadoAux),
						]);
						const endTime = new Date();
						const tdrId = 53;
						crearRegistroTDR(startTime, endTime, usuario, tdrId);
					};

					fetchData();
				}
			} else if (resp.status === 206) {
				const fetchData = async () => {
					await Promise.all([
						setPageCount(0),
						setData([]),
					]);
					const endTime = new Date();
					const tdrId = 53;
					crearRegistroTDR(startTime, endTime, usuario, tdrId);
				};

				fetchData();
			}
		}
	}

	const fetchData = useCallback(({ formulario, pageIndex, sortBy = [] }) => {
		formulario.paginacion.numeroPagina = pageIndex + 1;
		formulario.paginacion.ordenadoPor = sortBy.length > 0 ? sortBy[0].id : formulario.paginacion.ordenadoPor;
		formulario.paginacion.orderByAscending = sortBy.length > 0 ? (sortBy[0].desc ? false : true) : formulario.paginacion.orderByAscending;
		postObtenerListadoEventosConcesion(formulario);
	}, [])


	useEffect(() => {

		setInitialValues(values => ({
			...values
			, area: optionsBusiness ? optionsBusiness.map(x => { return x.value.toString() }) : ''
		}));
		if (optionsBusiness.length > 0) {
			getListaIndicadores(optionsBusiness.map(x => { return x.value }));
		}
	}, [optionsBusiness])

	function BuscarMediciones(values) {
		const startTime = new Date();
		const fetchData = async () => {
			await Promise.all([
				setMediciones({

					idNegocio: values.area !== "" ? values.area.map(x => { return Number(x) }) : []
					, idIndicador: values.indicadores !== "" ? values.indicadores.map(x => { return Number(x) }) : []
					, estado: values.estado !== "" ? values.estado : 0
					, tipoIngreso: 1
					, fechaInicio: values.periodo2[0]
					, fechaTermino: values.periodo2[1]
				}),
			]);
			const endTime = new Date();
			const tdrId = 54;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
	}


	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [datosModal, setDatosModal] = useState({ listaIds: [], esIndicador: false, esAutorizado: 2, datosBusquedaModificacion: null });
	function handleShowModal(listaIds, esIndicador, esAutorizado, datosBusquedaModificacion) {
		setDatosModal({ listaIds: listaIds, esIndicador: esIndicador, esAutorizado: esAutorizado, datosBusquedaModificacion: datosBusquedaModificacion? datosBusquedaModificacion: null });
		setShow(true);
	}

	const [showEvento, setShowEvento] = useState(false);
	const handleCloseEvento = () => setShowEvento(false);
	const [datosModalEvento, setDatosModalEvento] = useState({});
	function handlerShowModalEvento(item) {
		setDatosModalEvento(item);
		setShowEvento(true);
	}

	function changeAreaNegocio(e) {
		formikRef.current.setFieldValue('area', e);
		if (e.length == 0) {
			setListadoIndicadores([]);
		}
	}

	const BusquedaIndicadorBlur = () => {
		addLoading(true);
		if (formikRef.current.values.area.length > 0) {
			getListaIndicadores(formikRef.current.values.area.map(x => { return Number(x) }));
		}
		setTimeout(() => {
			addLoading(false);
		}, "400");
	};


	return (
		<>
			<div className='d-flex gap-3 pt-3 pb-4'>
				<Formik
					enableReinitialize
					validate={validations}
					initialValues={initialValues}
					innerRef={formikRef}
					onSubmit={(values) => {

						BuscarMediciones(values);
						renderizarContadorMedicion();

					}}
				>
					{({ values, setFieldValue, setFieldTouched }) => (
						<Form className="form mb-0">
							<div className="tools order-1 order-lg-0 align-items-start">
								<SelectCheckField
									name="area"
									label="Área de negocio"
									options={optionsBusiness}
									onChange={(e, value) => { changeAreaNegocio(value) }}
									onBlur={BusquedaIndicadorBlur}
									isMulti={true}
									backdrop="static"
								/>
								<SelectCheckField
									name="indicadores"
									label='Indicadores'
									options={listadoIndicadores}
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									isMulti={true}
								/>

								<SelectCheckField
									name="estado"
									label='Estado'
									options={estado}
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									isMulti={false}
								/>
								<RangeDatePicker
									startMaxDate={new Date()}
									startMinDate={null}
									endMaxDate={new Date()}
									endMinDate={null}
									name="periodo2"
									onChange={setFieldValue}
									onBlur={setFieldTouched}
								/>

								<Button type="submit" design="btn-primary">Buscar</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
			{
				renderizarTabla &&
				<div className="table-responsive">
					<TablaExigencias
						columns={columns}
						data={data}
						fetchData={fetchData}
						pageCount={pageCount}
						currentpage={pageIndex}
						pageSize={pageSize}
						sortBy={sortBy}
						setPagination={setPagination}
						mediciones={mediciones}
						esRenderizar={esRenderizar}
						handleShowModal={handleShowModal}
						handlerShowModalEvento={handlerShowModalEvento}
					/>
				</div>
			}

			<ModalConfirmacion
				show={show}
				datosModal={datosModal}
				onHide={handleClose}
				renderizar={renderizarListado}
				renderizarContadorMedicion={renderizarContadorMedicion}
			/>
			<ModalDetalleEventoConcesion
				show={showEvento}
				onHide={handleCloseEvento}
				datosModal={datosModalEvento}
			/>

		</>
	)
}

export default MedicionesManuales
