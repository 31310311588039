import Modal from 'react-bootstrap/Modal';
import { Formik, Form, } from "formik";
import InputTextField from '../../../Components/InputTextField/InputTextField';
import Button from '../../../Components/Button/Button';
import { useComun } from '../../../Hooks/useComun';
import SelectCheckField from '../../../Components/SelectCheckField/SelectCheckField';
import { useContext, useEffect, useState } from 'react';
import validations from '../../../Utils/Validations/Validations';
import { ActualizaParametroIndicadorExigenciaService } from '../Services/ActualizaParametroIndicadorExigenciaService';
import { useToast } from '../../../Hooks/useToast';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const ModalEdicionVia = (props) => {
	const { descripcionParametro, valor1, valor2, reglaTO, unidadMedidaId, parametroIndicadorExigenciaId } = props.exigencia;
	const { renderizar, valorTab, ...rest } = props;
	const { listadoUnidadMedida, getListaUnidadMedida } = useComun();
	const addToast = useToast();
	const {usuario} = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const initialValues = {
		exigencia: descripcionParametro,
		valor1: valor1,
		valor2: valor2,
		reglaTO: reglaTO,
		unidad: unidadMedidaId }
	useEffect(() => {
		getListaUnidadMedida();
	}, [])
	async function postActualizarPonderacion(values) {
		const startTime = new Date();
		const resp = await ActualizaParametroIndicadorExigenciaService(values, usuario.id);
		if (resp) {
			if (resp.status === 200) {
				const fetchData = async () => {
					await Promise.all([
						addToast({ mostrar: true, mensaje: 'Parámetro modificado correctamente.', titulo: 'Confirmación', tipo: 'Success' }),
						renderizar(),
						rest.onHide(),
					]);
					const endTime = new Date();
					const tdrId = 97;
					crearRegistroTDR(startTime, endTime, usuario, tdrId);
				};

				fetchData();
			}
		}
	}

	return (
		<Modal
			{...rest}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<h5 className="modal-title">
					<span>Editar parámetro</span>
				</h5>
			</Modal.Header>
			<Modal.Body>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validate={validations}
					onSubmit={values => {
						const valuesForm = {
							parametroIndicadorId: parametroIndicadorExigenciaId,
							unidadMedidaId: values.unidad === unidadMedidaId ? Number(values.unidad) : values.unidad.value,
							valorExigido: Number(values.valor),
						}
						postActualizarPonderacion(valuesForm);
					}}
				>
					{({ setFieldValue, setFieldTouched }) => (
						<Form className="form mb-10">
							<span className="badge bg-gray mb-3 white-space-normal">
								<div style={{textAlign:'left'}}
								dangerouslySetInnerHTML={{__html: descripcionParametro}}
								/>
							</span>
							<Form className="form mb-10">
							<InputTextField
								name="valor1"
								label="Valor exigido"
								type="text"
								placeholder="Valor exigido"
								autoComplete="false"
							/>
							<InputTextField
								name="valor2"
								label="Valor exigido"
								type="text"
								placeholder="Valor exigido"
								autoComplete="false"
							/>
							{reglaTO && valorTab==='VIA' &&
								<InputTextField
								name="reglaTO"
								label="Operador"
								type="text"
								/>
							}
							</Form>
							<SelectCheckField
								name="unidad"
								label='Unidad'
								options={listadoUnidadMedida}
								onChange={setFieldValue}
								onBlur={setFieldTouched}
								isMulti={false}
								closeOnSelect={true}

							/>
							{}
							<div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4">
								<Button type="button" design="btn-outline-pewter-blue" onClick={rest.onHide}>Cancelar</Button>
								<Button type="submit" design="btn-primary">Guardar</Button>
							</div>
						</Form>
					)}
				</Formik>
			</Modal.Body>
		</Modal>
	)
}

export default ModalEdicionVia;
