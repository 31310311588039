import notfound from '../../../Assets/images/il-empty.svg';
import React, { useEffect, useRef, useState } from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import Button from "../../../Components/Button/Button";
import Exports from '../../../Components/Exports/Exports';
import moment from 'moment';
import { EnumEstadoObjetarEventos } from '../../../Utils/Enumeradores/ComunEnum';
import RangeDatePicker from '../../../Components/RangeDatePicker/RangeDatePicker';
import { Form, Formik } from 'formik';
import SelectCheckField from '../../../Components/SelectCheckField/SelectCheckField';
import PaginationComponent from '../../../Components/PaginationTableServer/PaginationTableServer';
import validations from '../../../Utils/Validations/Validations';

const BitacoraObjetarIndicadorTable = ({ columns, data, fetchData, pageCount: controlledPageCount, currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy, setPagination, handlerModal, test, setTest }) => {
	const {getTableProps, getTableBodyProps, headerGroups, prepareRow, page, pageCount, gotoPage, state: { pageIndex, pageSize, sortBy }
		  } = useTable
					(
						{
							columns
							, data
							, initialState: { pageIndex: currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy }
							, manualPagination: true
							, pageCount: controlledPageCount
							, autoResetPage: false
							, autoResetSortBy: false
							, manualSortBy: true
						},
						useSortBy,
						usePagination
					);

	const { usuario } = useLogin();
	const formikRef = useRef();
	
	const listaEstadoEvento = [
        { value: 0, label: "Sin Solicitud" },
        { value: 1, label: "Vencido" },
        { value: 2, label: "Solicitado" },
        { value: 3, label: "Aprobado" },
        { value: 4, label: "Rechazado" },
        { value: 5, label: "Sin respuesta DGC" }
    ];

    const [initialValues] = useState({
        periodo2: [moment().subtract(1, 'M').toDate(), new Date()],
        estado: listaEstadoEvento.map(x => Number(x.value))
    });

    const [valoresFormularioEnvio, setValoresFormularioEnvio] = useState({
        paginacion: {
            numeroPagina: pageIndex,
            cantidadRegistros: pageSize,
            orderByAscending: false,
            ordenadoPor: "idEvento"
        },
        datosBusqueda: {
            fechaInicio: moment().subtract(1, 'M').format(),
            fechaFin: moment().format(),
            exportacion: false,
            estado: listaEstadoEvento.map(x => Number(x.value)),
            idUsuario: usuario.id,
            perfilIdContexto: usuario.perfilContexto.id
        }
    });

    const descargar = () => {
        fetchData({ formulario: valoresFormularioEnvio, pageIndex, sortBy, exportacion: true });
    };

	useEffect(() => {
			fetchData({ formulario: valoresFormularioEnvio, pageIndex, sortBy });
			setPagination(values => ({
				...values
				, sortBy: sortBy
			}));
			setTest(false)
	}, [pageIndex, sortBy, test ])

    function BuscarObjetarEvento(values) {
        const fechaInicio = moment(values.periodo2[0]).format();
        const fechaFin = moment(values.periodo2[1]).format();
        const valor = {
            paginacion: {
                numeroPagina: pageIndex,
                cantidadRegistros: pageSize,
                orderByAscending: sortBy.length > 0 ? !sortBy[0].desc : valoresFormularioEnvio.paginacion.orderByAscending,
                ordenadoPor: sortBy.length > 0 ? sortBy[0].id : valoresFormularioEnvio.paginacion.ordenadoPor
            },
            datosBusqueda: {
                fechaInicio: fechaInicio,
                fechaFin: fechaFin,
                exportacion: false,
                estado: values.estado,
                idUsuario: usuario.id,
                perfilIdContexto: usuario.perfilContexto.id
            }
        };
        setValoresFormularioEnvio(valor);
        fetchData({ formulario: valor, pageIndex, sortBy });
    }

	const mostrarModal = (e, row) => {
		e.preventDefault();
		handlerModal(row);
	}

	return (
		<div>
			<div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-start mb-5 gap-3">
			<Formik
				enableReinitialize
				initialValues={initialValues}
				innerRef={formikRef}
				validate={validations}
				onSubmit={(values) => {
					BuscarObjetarEvento(values);
				}}
			>
				{({ values, setFieldValue, setFieldTouched }) => (
					<Form className="form mb-0">
					<div className="tools order-1 order-lg-0 align-items-start">
						<RangeDatePicker
						startMaxDate={new Date()}
						startMinDate={null}
						endMaxDate={new Date()}
						endMinDate={null}
						name="periodo2"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						/>
						<SelectCheckField
						name="estado"
						label='Estado'
						options={listaEstadoEvento}
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						isMulti={true}
						/>
						<Button type="submit" design="btn-primary">Buscar</Button>
					</div>
					</Form>
				)}
				</Formik>
				{
					data.length > 0 &&
					<div className="exports order-0 order-lg-1">
						<Exports descargar={() => descargar()} />
					</div>
				}
			</div>
			<div className="table-responsive brd-top">
				<table className="table" {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									data.length > 0 ?
										<th {...column.getHeaderProps(column.getSortByToggleProps(), { className: column.className })}>
											<span className="d-flex align-items-center gap-1">
												{column.render('Header')}
												<>
													{column.isSorted ?
														(column.isSortedDesc && data.length > 0 ?
															<i className="ico-chevron-down"></i>
															:
															<i className="ico-chevron-up"></i>
														) :
														<>
															<i className="ico-sort fs-7"></i>
														</>
													}
												</>
											</span>
										</th>
										:
										<th {...column.getHeaderProps()}
										>
											<span className="d-flex align-items-center gap-1">
												{column.render('Header')}
											</span>
										</th>
								))}
								<th className="text-center">Acción</th>
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{
							page.length > 0 && page.map((row) => {
								prepareRow(row)
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps({
												className: cell.column.className
											})}>
												{cell.render('Cell')}
											</td>
										})}
										<td className="text-center">
										{row.original.estado === EnumEstadoObjetarEventos[1] ? (
												<button className="link text-pewter-blue fs-4" disabled onClick={(e) => mostrarModal(e, row.original)}>
													<i className="ico-search"></i>
												</button>
											) : row.original.estado === EnumEstadoObjetarEventos[0] && (usuario.perfilContexto.id === 1 || usuario.perfilContexto.id === 2) ? (
												<button className="link text-pewter-blue fs-4" disabled onClick={(e) => mostrarModal(e, row.original)}>
													<i className="ico-search"></i>
												</button>
											) : usuario.perfilContexto.id === 1 || usuario.perfilContexto.id === 2 ? (
												<button className="link text-pewter-blue fs-4" onClick={(e) => mostrarModal(e, row.original)}>
														<i className="ico-search"></i>
												</button>
											) : row.original.estado === EnumEstadoObjetarEventos[5] || row.original.estado === EnumEstadoObjetarEventos[3] || row.original.estado === EnumEstadoObjetarEventos[4] && (usuario.perfilContexto.id === 3) ? (
												<button className="link text-pewter-blue fs-4" onClick={(e) => mostrarModal(e, row.original)}>
													<i className="ico-search"></i>
												</button>
											) : (
												<button className="link text-pewter-blue fs-4" onClick={(e) => mostrarModal(e, row.original)}>
													<i className="ico-doc-edit"></i>
												</button>
											)
										}
										</td>
									</tr>
								)
							}) || <tr><td colSpan={headerGroups[0].headers.length + 1}>
								<div className="d-flex justify-content-center align-items-center flex-column gap-2 py-5">
									<img className="mb-3" src={notfound} alt="" />
									<p className='mb-0 text-primary'>No se encuentran resultados de tu búsqueda.</p>
								</div>
							</td></tr>
						}
					</tbody>
				</table>
			</div>
			{
				data.length > 0 &&
				<PaginationComponent
					pagesCountServer={pageCount}
					currentPage={pageIndex}
					setCurrentPage={gotoPage}
					alwaysShown={false}
					setPagination={setPagination}
				/>
			}
		</div>
	)
}

export default BitacoraObjetarIndicadorTable;