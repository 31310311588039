import { useField } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import React, { useEffect, useState } from "react";

registerLocale('es', es);

const DatePickerComponent = ({ maxDate, minDate, ...props }) => {
    const { name } = props;
    const [field, meta] = useField(props);
    const { value } = field;
    const [startDate, setStartDate] = useState('');
    useEffect(() => {
        if (value) {
            setStartDate(value);
        }
    }, [value])
    const handleSelectDate = (date) => {
        setStartDate(date);
        props.onChange(name, date);
    }
    const CustomInput = React.forwardRef((props, ref) => {
        return (
            <div className={`react-datepicker__input ${meta.touched && meta.error ? 'is-invalid' : ''}`} onClick={props.onClick}>
                <i className="ico-calendar" onClick={props.onClick}></i>
                <label onClick={props.onClick} ref={ref}>{props.value || props.placeholder}</label>
                <i className="ico-chevron-down" onClick={props.onClick}></i>
            </div>
        );
    });

    return (
        <>
            <DatePicker
                {...field}
                {...props}
                locale="es"
                selected={startDate}
                maxDate={maxDate}
                minDate={minDate}
                onChange={(date) => handleSelectDate(date)}
                placeholderText={'Nueva fecha'}
                dateFormat="dd-MM-yyyy"
                customInput={<CustomInput />}

                showYearDropdown
                dateFormatCalendar="MMMM"
                yearDropdownItemNumber={15}
                scrollableYearDropdown
            />
            {meta.touched && meta.error ?
                <small className="message">{meta.error}</small> : null}
        </>
    )
}
export default DatePickerComponent;
