import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";


const DetalleHallazgoService = async (values) => {
    try {
        const resultado = await instance.Backend.post("/Hallazgo/DetalleHallazgo",values, customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export { DetalleHallazgoService };