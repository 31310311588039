import React from 'react';
import Badge from "../../../Components/Badge/Badge";

const CardEvaluacion = (props) => {
	const {title,design,value,handleClick,cdo} = props;
	return (
		<div className='border rounded p-2 text-pewter-blue d-flex flex-column justify-content-between gap-2 flex-fill' onClick={handleClick}>
			<span className='fs-7 lh-1'>{title}</span>
			<div className='d-flex justify-content-between gap-3 align-items-center'>
				<b className='fs-3 lh-1'>{value}</b>
				<span
		className={`badge ${props.className}`} style={{'backgroundColor': design}}>
			{cdo}
		</span>
			</div>
		</div>
	)
}

export default CardEvaluacion;
