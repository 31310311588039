import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
let idChart = 0;
const ColumnCharts = ({ dataChart, ...props }) => {
	const {listaData, listaNombres} = dataChart;
	const [series, setSeries] = useState(listaData);
	useEffect(() => {
		setSeries(listaData);
		idChart++;
	}, [dataChart]);
	const options = {
		chart: {
			id: idChart,
			type: 'bar',
			toolbar: {
				show: false,
			}
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '22px',
				endingShape: 'rounded',
			},
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		},
		xaxis: {
			categories: listaNombres
		},
		fill: {
			opacity: 1,
			colors: ['#FC0000', '#E65D29', '#49B461','#FF4560','#775DD0','#008FFB', '#00E396','#FEB019', '#AEB6BF', '#85C1E9', '#34495E']
		},
		tooltip: {
			y: {
			  formatter: function (val) {
				return  val + "%"
			  }
			}
		  },
		legend: {
			position: 'top',
			horizontalAlign: 'left',
			fontFamily: '"Poppins", sans-serif',
			fontSize: '14px',
			labels: {
				colors: ['#809FB8'],
			},
			markers: {
				fillColors: ['#FC0000', '#E65D29', '#49B461','#FF4560','#775DD0','#008FFB', '#00E396','#FEB019', '#AEB6BF', '#85C1E9', '#34495E'],
				radius: 17,
			}
		},
	}
	return(
		<div id="chart" className="h-100">
			<ReactApexChart
				options={options}
				series={series}
				type="bar"
				height="100%"
			/>
		</div>
	)
}
export default ColumnCharts;
