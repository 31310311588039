import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

async function ObtenerTrimestreCDO(values){

    try {

        const resultado = await instance.Backend.post("/Indicador/ObtenerTrimestreCDO", values, customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export {ObtenerTrimestreCDO};
