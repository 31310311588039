import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";



const ObtenerUsuarioPorId = async (values) => {
    try {
        const resultado = await instance.Backend.post("/Usuario/ObtenerUsuarioContexto", {
            idUsuario: values.idUsuario,
            perfilId: values.idPerfil
        },customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export {ObtenerUsuarioPorId};