import React, { useCallback } from 'react'
import { NivelCumplimientoIndicadorSerieDetalleService } from '../Services/NivelCumplimientoIndicadorSerieDetalleService';
import { EnumModuloDesc } from '../../../Utils/Enumeradores/ComunEnum';
import { useComun } from '../../../Hooks/useComun';
import { NivelCumplimientoHallazgoIndicadorSerieDetalleService } from '../Services/NivelCumplimientoHallazgoIndicadorSerieDetalleService';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

export function useTablasIndicadores(props) {
    const { downloadCSV } = useComun();
		const { crearRegistroTDR } = useRegistroTDR();
		const { user, usuario } = useLogin();
    const [data, setData] = React.useState([]);
    const [pageCount, setPageCount] = React.useState(0);
    const [dataHallazgoIndicador, setDataHallazgoIndicador] = React.useState([]);
    const [pageCountHallazgoIndicador, setPageCountHallazgoIndicador] = React.useState(0);
    async function postNivelCumplimientoIndicadorSerieDetalle(values) {
			const startTime = new Date();
        const resp = await NivelCumplimientoIndicadorSerieDetalleService(values);
        if (resp) {
            if (resp.status === 200) {
                if (resp.data) {
                    if (values.datosBusqueda.exportacion) {
                        downloadCSV(resp.data, props.siglaIndicador, EnumModuloDesc.DescargaDatamart);
                    } else {
												const fetchData = async () => {
													await Promise.all([
														setPageCount(resp.data.cantidadPaginas),
														setData(resp.data.listadoExigencias),
													]);
													const endTime = new Date();
													const tdrId = 17;
													crearRegistroTDR(startTime, endTime, usuario, tdrId);
												};
												fetchData();
                    }
                }
            } else if (resp.status === 206) {
								const fetchData = async () => {
									await Promise.all([
										setPageCount(0),
										setData([]),
									]);
									const endTime = new Date();
									const tdrId = 17;
									crearRegistroTDR(startTime, endTime, usuario, tdrId);
								};
								fetchData();
            }
        }
    }

    async function postNivelCumplimientoHallazgoIndicadorSerieDetalle(values) {
			const startTime = new Date();
        const EnumModulo = props ? props.EnumModulo ? props.EnumModulo : EnumModuloDesc.DescargaDatamart : EnumModuloDesc.DescargaDatamart;
        const resp = await NivelCumplimientoHallazgoIndicadorSerieDetalleService(values);
        if (resp) {
            if (resp.status === 200) {
                if (resp.data) {
                    if (values.datosBusqueda.exportacion) {
                        downloadCSV(resp.data, props.siglaIndicador, EnumModulo);
                    } else {
												const fetchData = async () => {
													await Promise.all([
														setPageCountHallazgoIndicador(resp.data.cantidadPaginas),
														setDataHallazgoIndicador(resp.data.hallazgo),
													]);
													const endTime = new Date();
													const tdrId = 9;
													crearRegistroTDR(startTime, endTime, usuario, tdrId);
												};
												fetchData();
                    }
                }
            } else if (resp.status === 206) {
								const fetchData = async () => {
									await Promise.all([
										setPageCountHallazgoIndicador(0),
										setDataHallazgoIndicador([]),
									]);
									const endTime = new Date();
									const tdrId = 9;
									crearRegistroTDR(startTime, endTime, usuario, tdrId);
								};
								fetchData();
            }
        }
    }

		async function postNivelCumplimientoHallazgoIndicadorSerieDetalleDatamart(values) {
			const startTime = new Date();
        const EnumModulo = props ? props.EnumModulo ? props.EnumModulo : EnumModuloDesc.DescargaDatamart : EnumModuloDesc.DescargaDatamart;
        const resp = await NivelCumplimientoHallazgoIndicadorSerieDetalleService(values);
        if (resp) {
            if (resp.status === 200) {
                if (resp.data) {
                    if (values.datosBusqueda.exportacion) {
                        downloadCSV(resp.data, props.siglaIndicador, EnumModulo);
                    } else {
												const fetchData = async () => {
													await Promise.all([
														setPageCountHallazgoIndicador(resp.data.cantidadPaginas),
														setDataHallazgoIndicador(resp.data.hallazgo),
													]);
													const endTime = new Date();
													const tdrId = 18;
													crearRegistroTDR(startTime, endTime, usuario, tdrId);
												};
												fetchData();
                    }
                }
            } else if (resp.status === 206) {
								const fetchData = async () => {
									await Promise.all([
										setPageCountHallazgoIndicador(0),
										setDataHallazgoIndicador([]),
									]);
									const endTime = new Date();
									const tdrId = 18;
									crearRegistroTDR(startTime, endTime, usuario, tdrId);
								};
								fetchData();
            }
        }
    }

    const getNivelCumplimientoIndicadorSerieDetalle = useCallback(
        function (values) {
            postNivelCumplimientoIndicadorSerieDetalle(values);
        },
        []
    );
    const getNivelCumplimientoHallazgoIndicadorSerieDetalle = useCallback(
        function (values) {
            postNivelCumplimientoHallazgoIndicadorSerieDetalle(values);
        },
        []
    );
		const getNivelCumplimientoHallazgoIndicadorSerieDetalleDatamart = useCallback(
			function (values) {
				postNivelCumplimientoHallazgoIndicadorSerieDetalleDatamart(values);
			},
			[]
	);

    return {
        getNivelCumplimientoIndicadorSerieDetalle
        , data
        , pageCount
        , getNivelCumplimientoHallazgoIndicadorSerieDetalle
				, getNivelCumplimientoHallazgoIndicadorSerieDetalleDatamart
        , dataHallazgoIndicador
        , pageCountHallazgoIndicador
    }
}
