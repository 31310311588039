import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import InputTextField from "../../../Components/InputTextField/InputTextField";
import InputCheckField from "../../../Components/InputCheckField/InputCheckField";
import brandGovernment from "../../../Assets/images/brand-government.png";
import Button from "../../../Components/Button/Button";
import brandIsa from "../../../Assets/images/brand-isa.svg";
import { format } from "rut.js";
import validations from "../../../Utils/Validations/Validations";
import useLogin from "../Hooks/useLogin";
import ModalSelectPerfil from "../../../Components/ModalSelectPerfil/ModalSelectPerfil";
import {
	EnumPathRutas,
	EnumPerfil,
} from "../../../Utils/Enumeradores/ComunEnum";
import { useComun } from "../../../Hooks/useComun";
import { EnumParametro } from "../../../Utils/Enumeradores/ComunEnum";
import { useRegistroTDR } from "../../../Hooks/useRegistroTDR";

const Login = () => {
	const [show, setShow] = useState(false);
	const { crearRegistroTDR } = useRegistroTDR();
	//const { obtenerIpTdr, ParametroObtenerPorId } = useComun();
	const handleClose = () => setShow(false);
	const {
		signin,
		user,
		jwt,
		recuerdame,
		getUsuario,
		LimpiarRecuerdame,
		respaldo,
		usuario,
	} = useLogin();
	const navigate = useNavigate();
	const [submitted, setSubmitted] = useState(false);
	const [valoresIniciales, setValoresIniciales] = useState({
		rut: "",
		password: "",
		recuerdame: false,
	});
	const [tieneRecuerdame, setTieneRecuerdame] = useState(false);
	const formikRef = useRef();
	const [obtieneIp, setObtieneIp] = useState(false);
	const [startTime, setStartTime] = useState(null);
	const [endTime, setEndTime] = useState(null);
	const [perfiles, setPerfiles] = useState([]);

	const Ingresar = async (values) => {
		let formateoRut = values.rut
			? format(values.rut, { dots: false })
			: recuerdame && tieneRecuerdame
			? recuerdame.rut
			: "";
		signin(
			formateoRut,
			values.password,
			values.recuerdame,
			() => {
				setSubmitted(true);
			},
			setStartTime,
			setEndTime
		);
	};

	useEffect(() => {
		if (recuerdame && !submitted) {
			setValoresIniciales({ password: "", recuerdame: true });
			setTieneRecuerdame(true);
		}
	}, []);

	useEffect(() => {
		if (user !== null && jwt !== null && submitted) {
			setPerfiles(user.listaPerfil);
			/* Esto se deja comentado para que a Santiago no se le caiga la app */
			if (user.listaPerfil.length === 1 && user.listaRespaldos.length === 0) {
				if (
					user.listaPerfil[0].id === EnumPerfil.Resolutor ||
					user.listaPerfil[0].id === EnumPerfil.AsesoriaIF
				) {
					navigate(EnumPathRutas.Hallazgos, { replace: true });
				} else {
					navigate(EnumPathRutas.Inicio, { replace: true });
				}
			} else if (
				user.listaPerfil.length >= 1 &&
				user.listaRespaldos.length >= 1
			) {
				setShow(true);
			} else if (user.listaPerfil.length > 1) {
				setShow(true);
			}
		}
	}, [user, jwt, submitted]);

	function changeRecuerdame(checked) {
		if (formikRef.current) {
			formikRef.current.setFieldValue("recuerdame", checked);
		}
		if (!checked && recuerdame) {
			LimpiarRecuerdame();
			setTieneRecuerdame(false);
			setValoresIniciales({ rut: "", password: "", recuerdame: false });
		}
	}
	return (
		<div className="container min-vh-100">
			<div className="row align-items-center justify-content-center min-vh-100">
				<div className="col-12 col-md-6 col-lg-5 col-xl-4">
					<div className="card card--login">
						<div className="card-body p-4 p-md-5">
							<div className="d-flex align-items-center justify-content-between gap-2 mb-4">
								<a
									href="https://www.mop.cl/Paginas/default.aspx"
									rel="noreferrer"
									target="_blank"
								>
									<img
										height="100px"
										src={brandGovernment}
										alt="Ministerio de Obras Públicas, gobierno de Chile"
									/>
								</a>
								<img src={brandIsa} height="100px" alt="ruta loa" />
							</div>
							<h5 className="text-pewter-blue mb-4 d-block text-center">
								Ingresa los datos
							</h5>
							<Formik
								innerRef={formikRef}
								enableReinitialize
								initialValues={valoresIniciales}
								validate={validations}
								onSubmit={async (values, { setSubmitting, resetForm }) => {
									setSubmitting(false);
									Ingresar(values);
								}}
							>
								{({ values, handleChange, setFieldValue }) => (
									<Form className="form mb-0">
										{!tieneRecuerdame ? (
											<InputTextField
												name="rut"
												label="Rut"
												type="text"
												placeholder="1"
												autoComplete="true"
												maxLength="12"
												value={values.rut || ""}
												onChange={(event) => {
													const formatted =
														event.target.value === "-"
															? ""
															: event.target.value === "" ||
															  event.target.value.length === 1
															? event.target.value
															: format(event.target.value);
													setFieldValue("rut", formatted);

													handleChange(formatted);
												}}
											/>
										) : (
											<span className="text-pewter-blue d-block">
												{recuerdame.nombre}
											</span>
										)}

										<InputTextField
											name="password"
											label="Contraseña"
											type="password"
											placeholder="Password"
											autoComplete="true"
											maxLength="20"
										/>
										<div className="d-grid mb-4">
											<Button type="submit" design="btn-primary">
												Ingresar
											</Button>
										</div>
										<div className="d-flex flex-column flex-xl-row gap-3 justify-content-between">
											<InputCheckField
												name="recuerdame"
												label="Recuérdame"
												onChange={(e) => {
													changeRecuerdame(e.target.checked);
												}}
											/>
											<Link
												className="link-pewter-blue fs-6"
												to="/recuperar_clave"
											>
												¿Olvidó su contraseña?
											</Link>
										</div>
										{}
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
			</div>
			<ModalSelectPerfil show={show} onHide={handleClose} />
		</div>
	);
};

export default Login;
