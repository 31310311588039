import instance from "../../../Utils/Interceptors/Interceptor";


async function ActualizaAlertaPreviaEstadoService(values) {

    try {
        return await instance.Backend.post("/Indicador/ActualizaAlertaPreviaEstado", {
            idUsuario: values.idUsuario,
            esNivelServicio: values.esNivelServicio,           
            id: values.id,
            estado:values.estado
        });

    } catch (error) {
        console.log(error);
    }

}
export { ActualizaAlertaPreviaEstadoService };