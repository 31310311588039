import { useState, useContext } from 'react';
import IndicadorSimple from "./IndicadorSimple";
import { PonderacionContext } from '../Context/PonderacionContext';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';
import useLogin from '../../Autenticacion/Hooks/useLogin';

const IndicadorCuarto = (props) => {
	const { crearRegistroTDR } = useRegistroTDR();
	const {usuario} = useLogin();
	const indicador = props.item;
	const grupoPerteneciente = props.grupo;
	const [toggle, setToggle] = useState(true);
	const { toggleModal, getDataIndicator } = useContext(PonderacionContext);
	const HandleExpand = (action) => {
		setToggle(action)
	}
	const handleModal = (data,esNivelServicio, grupo) => {
		const startTime = new Date();
		getDataIndicator(data,esNivelServicio, grupo);

			setTimeout(() => {
				const endTime = new Date();
				const tdrId = 64;
				crearRegistroTDR(startTime, endTime, usuario, tdrId);
			}, 0);
		return toggleModal(true);
	}
	return (
		<>
			<tr className="skeleton-level-4-ponderacion">
				<td>
					<div className="d-flex align-items-center gap-2">
						<button className={toggle ? 'btn-collapse rotate' : 'btn-collapse'} onClick={() => HandleExpand(!toggle)}>
							<i className="ico-chevron-down"></i>
						</button>
						<i className="indicator ico-enter"></i>
						<span className="badge badge--monochromatic">{indicador.detalle.sigla}</span>
						<span className="name">{indicador.detalle.descripcion}</span>
					</div>
				</td>
				<td colSpan="2">
					<span className={`badge badge--monochromatic`}>{`${indicador.detalle.ponderacion}%`}</span>
				</td>
				<td className="">
					<button className="link link--border text-pewter-blue fs-5" onClick={() => handleModal(indicador.detalle, true, grupoPerteneciente)}>
						<i className="ico-doc-edit"></i>
					</button>
				</td>
			</tr>
			{indicador.detalle.indicadores ?

				<tr className='border-top'>
					<td colSpan='4' className="p-0">
						<div className={toggle ? 'children expanded' : 'children'}>
							<table>
								<tbody>
									{indicador.detalle.indicadores.map((item, index) =>
										<tr key={indicador.detalle.sigla + index + "_ind"} className="skeleton-level-6-indicador border-bottom">
											<td>
												<div className="d-flex align-items-center gap-2">
													<i className="indicator ico-enter"></i>
													<span className="badge badge--monochromatic">{item.sigla}</span>
													<span className="name">{item.descripcion}</span>
												</div>
											</td>
											<td className="text-center px-3">
												<span className={`badge badge--monochromatic`}>{`${item.ponderacion}%`}</span>
											</td>
											<td className="">
												{item.editable &&
													<button className="link link--border text-pewter-blue fs-5" onClick={() => handleModal(item, false,indicador.detalle.indicadores)}>
														<i className="ico-doc-edit"></i>
													</button>
												}

											</td>
										</tr>

									)}
								</tbody>
							</table>
						</div>
					</td>
				</tr>
				: null
			}
			{indicador.hijos ?

				<tr className='border-top'>
					<td colSpan='4' className="p-0">
						<div className={toggle ? 'children expanded' : 'children'}>
							<table>
								<tbody>
									{indicador.hijos.map((item, index) =>
										<IndicadorSimple key={index} item={item} grupo={indicador.hijos}/>
									)}
								</tbody>
							</table>
						</div>
					</td>
				</tr>
				: null
			}
		</>
	)
}
export default IndicadorCuarto;
