import SelloTiempoBitacora from "../Components/SelloTiempoBitacora";
import { EnumEstadoCumplimientoEventoConcesion, EnumEstadoEventoConcesionDesc } from "../../../Utils/Enumeradores/ComunEnum";
import StateInCell from "../Components/StateInCell";

export const COLUMNS = [
	{
		Header: 'Área de negocio',
		accessor: 'areaNegocio',
	},	
	{
		Header: 'Descripción',
		accessor: 'descripcion',
	},
	{
		Header: 'Medición',
		accessor: 'medicion',
		Cell: ({ row }) => <div>
			{
				<b>{row.original.medicion}</b>
			}
		</div>
	},
	{
		Header: 'Valor Exigido / U. Medida',
		accessor: 'valor',
	},
	{
		Header: 'Fecha creación',
		accessor: 'fechaCreacion',
		Cell: ({ row }) => <SelloTiempoBitacora data={row.original.fechaCreacion} />
	},
	{
		Header: 'Fecha/Hora medición',
		accessor: 'fechaHoraMedicion',
		Cell: ({ row }) => <SelloTiempoBitacora data={row.original.fechaHoraMedicion} />
	},
	{
		Header: 'Tipo ingreso',
		accessor: 'tipoIngreso',
	},
	{
		Header: 'Estado Autorización IF',
		accessor: 'estado',
		className: 'text-center',
		disabledFilters: true,
		Cell: ({ row }) => <span
			className={`badge badge--monochromatic
			${row.original.estado === EnumEstadoEventoConcesionDesc.Autorizada ? 'bg-success' : ''}
			${row.original.estado === EnumEstadoEventoConcesionDesc.Pendiente ? 'bg-primary' : ''}
			${row.original.estado === EnumEstadoEventoConcesionDesc.Rechazada ? 'bg-danger' : ''}
		`}
		>
			{row.original.estado === EnumEstadoEventoConcesionDesc.Pendiente ? "Pendiente" : row.original.estado === EnumEstadoEventoConcesionDesc.Autorizada ? "Autorizada" : row.original.estado === EnumEstadoEventoConcesionDesc.Rechazada ? "Rechazada" : ""}
		</span>
	},
	{
		Header: 'Estado Evento',
		accessor: 'estadoEvento',
		Cell: ({ row }) => <span>{row.original.estadoEvento === true ? EnumEstadoCumplimientoEventoConcesion.Cumpliendo : EnumEstadoCumplimientoEventoConcesion.Incumplieno}</span>
	}
];
