import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ListadoMacroUbicacion = async (indicadorId, usuarioId) => {
    try {
        const resultado = await instance.Backend.post("/Hallazgo/ListadoMacroUbicacion", {
            indicadorId: indicadorId,
            idUsuario: usuarioId
        }, customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export { ListadoMacroUbicacion };