import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../../../Components/Button/Button';
import { useToast } from '../../../Hooks/useToast';
import { SolicitudModificarEstadoAutorizacionService } from '../Services/SolicitudModificarEstadoAutorizacionService';
import { EnumEstadoAutorizacion } from "../../../Utils/Enumeradores/ComunEnum";
import useLogin from '../../Autenticacion/Hooks/useLogin';

const ModalEstadoAutorizacion = (props) => {
	const { usuario, autorizado, renderizar, ...rest } = props;
	const [mensajeModal, setMensajeModal] = useState({ titulo: "", texto: "" });
	const addToast = useToast();
	const { Aprobado } = EnumEstadoAutorizacion;
	const { user } = useLogin();

	const btnModificarEstadoAutorizacion = async () => {
		const values = {
			usuarioContextoId: user.id,
			id: usuario.usuarioId,
			estadoAutorizacion: autorizado,
			movimiento: usuario.movimiento
		}
		const resp = await SolicitudModificarEstadoAutorizacionService(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					renderizar();
					addToast({ mostrar: true, mensaje: 'Se actualizó el estado de autorización del usuario ' + usuario.nombres + ' ' + usuario.apellidoPaterno + ' ' + usuario.apellidoMaterno, titulo: 'Confirmación', tipo: 'Success' });
				}
			}
		}

		rest.onHide();
	}
	useEffect(() => {
		if (usuario.movimiento === true) {
			if (autorizado === Aprobado) {
				setMensajeModal({ titulo: "¿Está seguro que desea aprobar el alta del usuario " + usuario.nombres + " " + usuario.apellidoPaterno + " " + usuario.apellidoMaterno + "?" });
			} else {
				setMensajeModal({ titulo: "¿Está seguro que desea rechazar el alta del usuario " + usuario.nombres + " " + usuario.apellidoPaterno + " " + usuario.apellidoMaterno + "?" });
			}
		} else {
			if (autorizado === Aprobado) {
				setMensajeModal({ titulo: "¿Está seguro que desea aprobar la baja del usuario " + usuario.nombres + " " + usuario.apellidoPaterno + " " + usuario.apellidoMaterno + "?" });
			} else {
				setMensajeModal({ titulo: "¿Está seguro que desea rechazar la baja del usuario " + usuario.nombres + " " + usuario.apellidoPaterno + " " + usuario.apellidoMaterno + "?" });
			}
		}
	}, [props]);
	return (
		<Modal
			{...rest}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				<div className='info my-4'>
					<span className='info__icon'>
						<i className='ico-user-delete'></i>
					</span>
					<h6 className='info__title'>{mensajeModal.titulo}</h6>
					<p className='info__text'>{mensajeModal.texto}</p>
				</div>
				<div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4">
					<Button type="button" design="btn-outline-pewter-blue btn--small" onClick={rest.onHide}>Cancelar</Button>
					<Button type="button" design="btn-primary btn--small" onClick={btnModificarEstadoAutorizacion}>Aceptar</Button>
				</div>
			</Modal.Body>
		</Modal>
	)
}
export default ModalEstadoAutorizacion;
