import instance, { customConfig }  from "../../../Utils/Interceptors/Interceptor";

const CrearUsuarioRespaldoService = async (values, idUsuarioContexto) => {
    try {
        
        const resultado = await instance.Backend.post("/Usuario/CrearUsuarioRespaldo",{
            IdUsuarioContexto: idUsuarioContexto,
            idUsuario: values.idUsuario,
            idRespaldo: values.idRespaldo,
            fechaInicio: values.fechaInicio,
            fechaFin: values.fechaFin
        }, customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export {CrearUsuarioRespaldoService};