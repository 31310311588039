const SearchField = ({filter, setFilter}) => {
	return(
		<div className="form form--search">
			<div className="form__field">
				<input
					type="text"
					className="form-control"
					id="search"
					value={filter|| ''}
					placeholder="Buscar"
					onChange={(e)=> setFilter(e.target.value)}
				/>
			</div>
		</div>
	)
}

export default SearchField;
