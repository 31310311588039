import React, { useMemo, useState } from 'react';
import Card from "../../../Components/Card/Card";
import BitacoraUsuariosTable from "../Components/BitacoraUsuariosTable";
import { COLUMNS } from '../Utils/ColumnsUser';
import { EnumPathRutas, EnumTablaPaginacion } from '../../../Utils/Enumeradores/ComunEnum';
import ModalBitacoraUser from '../Components/ModalBitacoraUser';
import { useComun } from '../../../Hooks/useComun';
import { EnumModuloDesc } from "../../../Utils/Enumeradores/ComunEnum";
import { ObtenerListadoActividadesUsuario } from '../Services/ObtenerListadoActividadesUsuario';
import { Link } from 'react-router-dom';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const BitacoraUsuariosPage = () => {
	const { downloadCSV } = useComun();
	const { user, usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const [exportar, setExportar] = useState({ exportacion: false });
	const columns = useMemo(() => COLUMNS, []);
	const [data, setData] = React.useState([]);
	const [pageCount, setPageCount] = React.useState(0);
	const [{ pageIndex, pageSize, sortBy }, setPagination] =
		React.useState({
			pageIndex: 0,
			pageSize: EnumTablaPaginacion.CantidadPaginacion,
			sortBy: []
		});
	const [modalIsCrear, setModalIsCrear] = useState(true);
	const [editUser, setEditUser] = useState({});

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	function handlerModal(user) {
		setEditUser(user);
		setShow(true);
	}
	async function postObtenerListadoActividadUsuarioTabla(values) {
		const startTime = new Date();
		const resp = await ObtenerListadoActividadesUsuario(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					if (values.datosBusqueda.exportacion) {
						const fetchData = async () => {
							await Promise.all([
								downloadCSV(resp.data, "Bitacora Usuario", EnumModuloDesc.DescargaBitacoraUsuario)
							]);
							const endTime = new Date();
							const tdrId = 25;
							crearRegistroTDR(startTime, endTime, usuario, tdrId);
						};

						fetchData();
					} else {
						setPageCount(resp.data.cantidadPaginas);
						setData(resp.data.listadoActividadesUsuarios);
					}
				}
			}
		}
	}
	const fetchData = React.useCallback(({ formulario, pageIndex, sortBy = [], exportacion = false }) => {
		const startTime = new Date();
		formulario.paginacion.numeroPagina = pageIndex + 1;
		formulario.paginacion.ordenadoPor = sortBy.length > 0 ? sortBy[0].id : formulario.paginacion.ordenadoPor;
		formulario.paginacion.orderByAscending = sortBy.length > 0 ? (sortBy[0].desc ? false : true) : formulario.paginacion.orderByAscending;
		formulario.datosBusqueda.exportacion = exportacion;
		const fetchData = async () => {
			await Promise.all([
				postObtenerListadoActividadUsuarioTabla(formulario)
			]);
			const endTime = new Date();
			const tdrId = 23;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
	}, [])


	return (
		<>
			<div className="container-fluid">
				<div className="row mb-4">
					<div className="col-12">
						<div className="breadcrumbs">
							<ul>
								<li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
								<li><a href="" onClick={(e) => { e.preventDefault(); window.location.reload(false); }}>Bitácora</a></li>
								<li><a href="">Actividades de usuario</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<Card className="p-4">
							<BitacoraUsuariosTable
								columns={columns}
								data={data}
								fetchData={fetchData}
								pageCount={pageCount}
								currentpage={pageIndex}
								pageSize={pageSize}
								sortBy={sortBy}
								setPagination={setPagination}
								handlerModal={handlerModal}
							/>
						</Card>
					</div>
				</div>
			</div>
			<ModalBitacoraUser
				show={show}
				onHide={handleClose}
				dataDetalle={editUser}
			/>
		</>
	)
}

export default BitacoraUsuariosPage;
