import IndicadorPrincipal from "./IndicadorPrincipal";

const EsquemaDePonderacion = (props) => {
	const {data} = props;
	return (
		<div className="table-responsive">
			<table className="skeleton">
				<thead>
					<tr>
						<th>Indicadores</th>
						<th colSpan="2">Porcentaje</th>
						<th>Editar</th>
					</tr>
				</thead>
				<tbody>
					{data.map((item, index) =>
						<IndicadorPrincipal key={index} item={item} grupo={data}/>
					)}

				</tbody>
			</table>
		</div>
	)
}
export default EsquemaDePonderacion;
