import { useState } from 'react';
import { Link } from 'react-router-dom';
import Badge from '../../../Components/Badge/Badge';
import ChartsRadialBar from '../../../Components/ChartsRadialBar/ChartsRadialBar';
import moment from "moment";
import { EnumPathRutas } from '../../../Utils/Enumeradores/ComunEnum';

const CardIndicador = (props) => {
	const { descripcionSerie, nivelCumplimientoId, colorCumplimiento, valorCumplimiento, valorIndicador, valor, valor1, fecha, idSerie } = props.item;
	const { siglaIndicador, descripcionIndicador } = props.datosIndicador;
	const [indicators] = useState({ serie: valorCumplimiento, color: colorCumplimiento });
	return (
		<div className="border p-3 rounded h-100 d-flex flex-column justify-content-between">
			<Link to={EnumPathRutas.DatamartIndicador} state={{ nivelCumplimiento: nivelCumplimientoId, sigla: siglaIndicador, descripcion: descripcionIndicador, descripcionSerie: descripcionSerie }} className="link--indicator align-self-end">
				<i className="ico-chevron-right"></i>
			</Link>
			<ChartsRadialBar
				size="165"
				bezel="60"
				dataChart={indicators}
			/>
			{descripcionSerie &&
				<div className="text-center d-flex flex-column align-items-center gap-2 mb-3">
					<Badge design="gray" className="mb-0 white-space-normal">
						{descripcionSerie}
					</Badge>
					<span className='d-flex align-items-center gap-1'>
						<svg width="10" height="10" viewBox="0 0 10 10" fill="none">
							<path d="M7.5 1H9.5C9.63261 1 9.75979 1.05268 9.85355 1.14645C9.94732 1.24021 10 1.36739 10 1.5V9.5C10 9.63261 9.94732 9.75979 9.85355 9.85355C9.75979 9.94732 9.63261 10 9.5 10H0.5C0.367392 10 0.240215 9.94732 0.146447 9.85355C0.0526784 9.75979 0 9.63261 0 9.5V1.5C0 1.36739 0.0526784 1.24021 0.146447 1.14645C0.240215 1.05268 0.367392 1 0.5 1H2.5V0H3.5V1H6.5V0H7.5V1ZM6.5 2H3.5V3H2.5V2H1V4H9V2H7.5V3H6.5V2ZM9 5H1V9H9V5Z" fill="#999999" />
						</svg>
						<small style={{ color: '#999', lineHeight: '1' }}>{moment(fecha).format('DD-MM-YYYY HH:mm:ss')}</small>
					</span>
				</div>
			}
			<div className="text-center">
				<div className={`legend`}>
					<div className={`legend__item`}>
						{
							idSerie>5 &&(siglaIndicador === "AT" || siglaIndicador === "ATO")? 
							(		
								<span className="legend__label text-wrap">
									Valor cumplimiento: {valorCumplimiento}
								</span>
							)
							: siglaIndicador === "VIA" ? 
							(
								<span className="legend__label text-wrap">
									VIAk: {valor} VIAtot: {valor1}
								</span>
							)
							: 
							(
								<span className="legend__label text-wrap">
									Valor indicador: {valor}
								</span>
							)
						}
					</div>
				</div>
			</div>
			{
				idSerie>5 && (siglaIndicador === "AT" || siglaIndicador === "ATO")?
					null
				:
					<div className="text-center">
						<div className={`legend `}>
							<div className={`legend__item align-items-start gap-0`}>
								<span className="legend__dot" style={{ minWidth: '.75rem', width: '.75rem', height: '.75rem', marginTop: '.125rem', marginRight: '.25rem', backgroundColor: colorCumplimiento, borderColor: 'rgba(255, 255, 255, 0.8)' }}></span>
								<span className="legend__label fs-6 text-wrap" style={{ lineHeight: '120%' }}>{valorIndicador}</span>
							</div>
						</div>
					</div>
			}
		</div>
	)
}

export default CardIndicador;
