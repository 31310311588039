import { useState } from "react"
import ModalEstadoHallazgo from "../../../../Features/Hallazgo/Components/ModalEstadoHallazgo";
import { EnumEstadoHallazgo } from "../../../../Utils/Enumeradores/ComunEnum";
import { useRegistroTDR } from "../../../../Hooks/useRegistroTDR";
import useLogin from "../../../../Features/Autenticacion/Hooks/useLogin";

const StateInCell = ({ ...props }) => {
	const { usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => {
		const startTime = new Date();
		setShow(true);

		setTimeout(() => {
			const endTime = new Date();
			const tdrId = 41;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		}, 0);
	};
	return (
		<>
			<button
				className={`badge badge--monochromatic
					${props.estado === EnumEstadoHallazgo.Nuevo ? 'bg-secondary' : ''}
					${props.estado === EnumEstadoHallazgo.EnProceso ? 'bg-primary' : ''}
					${props.estado === EnumEstadoHallazgo.Rechazado ? 'bg-danger' : ''}
					${props.estado === EnumEstadoHallazgo.Aprobado ? 'bg-success' : ''}
					${props.estado === EnumEstadoHallazgo.Preingreso ? 'bg-primary' : ''}
					${props.estado === EnumEstadoHallazgo.Cerrado ? 'bg-disabled' : ''}
					${props.estado === EnumEstadoHallazgo.Resuelto ? 'bg-success' : ''}
					${props.estado === EnumEstadoHallazgo.Objetado ? 'bg-danger' : ''}
					${props.estado === EnumEstadoHallazgo.Validacion ? 'bg-primary' : ''}
				`}
				onClick={handleShow}
			>
				{props.cantidadIteraciones === 0 && <>{props.estadoDescripcion}</>}
				{props.cantidadIteraciones > 0 && <>{props.estadoDescripcion} <svg style={{width: '14px', height: '14px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path fill="currentColor" d="M18.537 19.567A9.961 9.961 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10c0 2.136-.67 4.116-1.81 5.74L17 12h3a8 8 0 1 0-2.46 5.772l.997 1.795z"/></svg></>}
			</button>
			<ModalEstadoHallazgo
				datosModal={{ hallazgoId: props.hallazgoId }}
				show={show}
				onHide={handleClose}
			/>
		</>
	)
}
export default StateInCell;
