import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ContadorNotificacionNoLeidasService = async (idUsuario, idPerfil) => {
    try {
        const resultado = await instance.Backend.post("/Notificacion/ContadorNotificacionesNoleidasPorIdUsuario",
            { idUsuario: idUsuario, idPerfil: idPerfil }, customConfig
        );
        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { ContadorNotificacionNoLeidasService };