import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import IcoRefresh from '../../../Assets/images/Refresh_icon.png';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import InputFileField from '../../../Components/InputFileField/InputFileField';
import validations, { TamanoArchivo, sinExtension } from '../../../Utils/Validations/Validations';
import { EnumMensajeErrorArchivos } from '../../../Utils/Enumeradores/ComunEnum';
import { Formik } from 'formik';
import { ActualizaObjetarEvento } from '../Services/ActualizaObjetarEvento';
import { useComun } from '../../../Hooks/useComun';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Avatar from '../../../Components/Avatar/Avatar';
import { useToast } from '../../../Hooks/useToast';

export const ModalObjetarIndicador = ({ show, handleClose, data, test }) => {
  const [observation, setObservation] = useState('');
  const { usuario } = useLogin();
  const { formatoBytes, downloadXUrl } = useComun();
  const [indisponibilidad, setIndisponibilidad] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const formikRef = useRef();
  const maxLength = 200;
  const addToast = useToast();
  const [archivos, setArchivos] = useState([]);
  const [botonModal, setBotonModal] = useState("");
  const remainingCharacters = maxLength - observation.length;
  const [historial, setHistorial] = useState(data.historialEventoObjectar || []);
  const { eventoConcesionId, estado, factorIndisponibilidadActual, factorIndisponibilidadSolicitado, factorIndisponibilidadCmbDisponibles, historialEventoObjectar, factorIndisponibilidadOtorgado } = data;

  const [initialValues, setInitialValues] = useState({
    idEventoConcesion: eventoConcesionId,
    idUsuario: usuario.id,
    idPerfilContexto: 2,
    fileEventoConcesion: []
  });

  useEffect(() => {
    if (!show) {
      setObservation('');
      setInitialValues({
        idEventoConcesion: eventoConcesionId,
        idUsuario: usuario.id,
        idPerfilContexto: 2,
        fileEventoConcesion: []
      });
      setArchivos([]);
    }
  }, [show, eventoConcesionId, usuario.id, ]);

  function handlerFiles(event) {
    const respuestaExtension = sinExtension(event.target.files[0]);
		const respuestaTamano = TamanoArchivo(event.target.files[0]);
    let archivExist = archivos.some(file => file.name === event.target.files[0].name);

    if(archivExist) {
      addToast({ mostrar: true, mensaje: "Archivo ya seleccionado", titulo: 'Error', tipo: 'Danger' });
    } else {
		if (formikRef.current.values.fileEventoConcesion.length < 2) {
			if (respuestaExtension === "" && respuestaTamano === "") {
				let nuevaLista = formikRef.current.values.fileEventoConcesion !== '' ? formikRef.current.values.fileEventoConcesion : [];
				nuevaLista.push(...Array.prototype.slice.call(event.target.files));
				formikRef.current.setFieldValue("fileEventoConcesion", nuevaLista);
        setArchivos(nuevaLista);
			}
			else {
				formikRef.current.setFieldError("fileEventoConcesion", respuestaExtension !== "" ? respuestaExtension : respuestaTamano);
			}
		} else if (formikRef.current.values.fileEventoConcesion.length === 2) {
			formikRef.current.setFieldError("fileEventoConcesion", EnumMensajeErrorArchivos.CantidadAdjunto);
		}}
	};

  const eliminarFileEventoConcesion = (posicionFila) => {
    const nuevaLista = formikRef.current.values.fileEventoConcesion.filter((x, index) => index !== posicionFila);
    formikRef.current.setFieldValue("fileEventoConcesion", nuevaLista);
    setArchivos(nuevaLista);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmationModal(false);
  };

  const solicitarObjecion = () => {
    setShowConfirmationModal(true);
    setBotonModal("SOLICITADO");
  };

  const aprobarObjecion = () => {
    setShowConfirmationModal(true);
    setBotonModal("APROBADO");
  };

  const rechazarObjecion = () => {
    setShowConfirmationModal(true);
    setBotonModal("RECHAZADO");
  };

  async function postActualizaObjetarEvento(values) {
		const resp = await ActualizaObjetarEvento(values);
		if (resp) {
			if (resp.status === 200) {
        test(true)
			}
		}
	}

	function ObjectarEvento(values) {
		let formData = new FormData();
		formData.append('EventoConcesionId', eventoConcesionId);
		formData.append('UsuarioId', usuario.id);
		formData.append('PerfilId', usuario.perfilContexto.id);
		formData.append('Observacion', observation);
		formData.append('FactorIndisponibilidadActual', factorIndisponibilidadActual);
		formData.append('FactorIndisponibilidadSolicitado', indisponibilidad);
    for (let i = 0; i < archivos.length; i++) {
      formData.append('ListaArchivos', archivos[i]);
    }

    if (botonModal === "APROBADO")
      {
        formData.append('FactorIndisponibilidadOtorgado', indisponibilidad);
      } 
      else 
      {
        formData.append('FactorIndisponibilidadOtorgado', factorIndisponibilidadActual);
      }
  
      formData.append('Accion', botonModal);

    const nuevoHistorial = [
      ...historial,
      {
        eventoConcesionId: eventoConcesionId,
        fecha: new Date().toISOString(),
        observacion: observation,
        idUsuario: usuario.id,
        perfilId: usuario.perfilContexto.id,
        usuario: usuario.nombre,
        factorIndispActual: parseInt(factorIndisponibilidadActual),
        factorIndispSolicitado: indisponibilidad,
        factorIndispOtorgado: factorIndisponibilidadOtorgado || null,
        documentosUsuario: values.fileEventoConcesion
      }
    ];

    setHistorial(nuevoHistorial);
		setArchivos([]);
    setShowConfirmationModal(false);
    handleClose();
		postActualizaObjetarEvento(formData);
	}

  const procesarHistorial = (historial) => {
    const historialModificado = [];
    
    if (historial) {
    historial.forEach(item => {
      historialModificado.push(item);
      
      if (item.estado === 'aprobado' || item.estado === 'rechazado') {
        historialModificado.push({
          ...item,
          fecha: item.fechaAprobacion || item.fechaRechazo,
          observacion: item.observacionAprobacion || item.observacionRechazo,
          usuario: item.usuarioAprobacion || item.usuarioRechazo,
          
        });
      }
    });
  }
  
    return historialModificado;
  };
  
  const historialModificado = procesarHistorial(historialEventoObjectar);

  const obtenerFormatoFecha = (fecha) => {
    const date = new Date(fecha);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  };

  useEffect(() => {
    if (data.estado === 'Solicitado' && data.historialEventoObjectar) {
      setHistorial(data.historialEventoObjectar);
    }
  }, [data.estado, data.historialEventoObjectar]);

  useEffect(() => {
    if (estado === 'Solicitado' && historialEventoObjectar) {
      setHistorial(historialEventoObjectar);
    }
  }, [estado, historialEventoObjectar]);

  return (
    <>
      <Modal show={show && !showConfirmationModal} onHide={handleClose} backdrop={showConfirmationModal ? 'static' : true} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            innerRef={formikRef}
            validate={validations}
            onSubmit={(values) => {
              ObjectarEvento(values);
            }}
          >
            {({ values }) => (
              <Form>
                <Modal.Title style={{ fontSize: '1.4rem' }} className="text-center">
                  Objetar evento {eventoConcesionId}
                </Modal.Title>
                <p className='text-center'>
                  Factor de indisponibilidad actual {estado === 'Aprobado'? factorIndisponibilidadOtorgado : factorIndisponibilidadActual}%
                </p>
                {estado === 'Sin Solicitud' && (
                  <>
                    <Form.Group controlId="indisponibilidad">
                      <Form.Label>Seleccione el factor de indisponibilidad</Form.Label>
                        <Form.Control 
                        as="select" 
                        value={indisponibilidad} 
                        onChange={(e) => setIndisponibilidad(parseInt(e.target.value))}
                        required
                        >
                          {factorIndisponibilidadCmbDisponibles.map(option => (
                            <option key={option.id} value={option.id}>
                              {option.descripcion}%
                            </option>
                          ))}
                        </Form.Control>
                    </Form.Group>
                    <br />
                    <InputFileField 
                      placeholder="Subir Archivos" 
                      multiple 
                      name="fileEventoConcesion" 
                      onChange={(event) => handlerFiles(event)}
                      onClick={(event) => { event.target.value = null }} 
                    />
                    {values.fileEventoConcesion !== '' && values.fileEventoConcesion.length > 0 &&
                      <div className='align-self-start d-flex gap-3 flex-wrap mt-2'>
                        {values.fileEventoConcesion.map((x, i) => {
                          return (
                            <div key={i} className='doc bg-white-smoke rounded p-2'>
                              <div className='doc-icon'>
                                <i className="ico-doc-empty fs-3"></i>
                              </div>
                              <div className='doc-info'>
                                <b className='doc-title'>{x.name}</b>
                                <span className='doc-time'>{formatoBytes(x.size)}</span>
                              </div>
                              <i className="ico ico-close" onClick={() => eliminarFileEventoConcesion(i)}></i>
                            </div>
                          );
                        })}
                      </div>
                    }
                    <Form.Group controlId="observation">
                      <br />
                      <Form.Control
                        placeholder="Escribe opcionalmente tu observación aquí..."
                        as="textarea"
                        rows={3}
                        value={observation}
                        onChange={(e) => setObservation(e.target.value)}
                      />
                    </Form.Group>
                    <small className="text-muted">
                      Quedan {remainingCharacters} caracteres disponibles
                    </small>
                  </>
                )}
                {estado === 'Solicitado' && (usuario.perfilContexto.id === 3) ? (
                  <>
                  <p style={{ fontSize: '0.9rem', display: 'flex', alignItems: 'center' }}>
                  <strong style={{ paddingRight: 10 }}>Factor de indisponibilidad solicitado:</strong> {factorIndisponibilidadSolicitado}%
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">
                                Si no estás de acuerdo con el valor solicitado prefieres mantener el actual, simplemente selecciona "Rechazar". Si deseas otorgar un nuevo valor, ingrésalo y haz click en "Aceptar"
                              </Tooltip>}
                    >
                      <i className="ico-info-circle" style={{ marginLeft: 140 }}></i>
                    </OverlayTrigger>
                  </p>
                    <Form.Group controlId="indisponibilidad">
                      <Form.Label>Seleccione el factor de indisponibilidad</Form.Label>
                        <Form.Control as="select" value={indisponibilidad} onChange={(e) => setIndisponibilidad(parseInt(e.target.value))}>
                          {factorIndisponibilidadCmbDisponibles.map(option => (
                            <option key={option.id} value={option.id}>
                              {option.descripcion}%
                            </option>
                          ))}
                        </Form.Control>
                        <div style={{ display: 'flex', alignItems: 'center'}}>
                          <div style={{marginRight:20}}>
                        <i className="ico-alarms text-pewter-blue fs-2"></i>
                        </div>
                          <small className='text-pewter-blue' style={{ fontSize: '1rem',  display: 'block', marginTop: '1rem', lineHeight: '1.4' }}>
                          Ingresa un nuevo valor o rechaza el valor solicitado. Al rechazar se otorgara el valor del factor de indisponibilidad actual.
                          </small>
                        </div>
                    </Form.Group>
                    <br />
                    <InputFileField 
                      placeholder="Subir Archivos" 
                      multiple 
                      name="fileEventoConcesion" 
                      onChange={handlerFiles}
                      onClick={(event) => { event.target.value = null }} 
                    />
                      {values.fileEventoConcesion !== '' && values.fileEventoConcesion.length > 0 &&
                      <div className='align-self-start d-flex gap-3 flex-wrap mt-2'>
                        {values.fileEventoConcesion.map((x, i) => {
                          return (
                            <div key={i} className='doc bg-white-smoke rounded p-2'>
                              <div className='doc-icon'>
                                <i className="ico-doc-empty fs-3"></i>
                              </div>
                              <div className='doc-info'>
                                <b className='doc-title'>{x.name}</b>
                                <span className='doc-time'>{formatoBytes(x.size)}</span>
                              </div>
                              <i className="ico ico-close" onClick={() => eliminarFileEventoConcesion(i)}></i>
                            </div>
                          );
                        })}
                      </div>
                    }
                    <Form.Group controlId="observation">
                      <br />
                      <Form.Control
                        placeholder="Escribe opcionalmente tu observación aquí..."
                        as="textarea"
                        rows={3}
                        value={observation}
                        onChange={(e) => setObservation(e.target.value)}
                      />
                      <small className="text-muted">
                      Quedan {remainingCharacters} caracteres disponibles
                    </small>
                    </Form.Group>
                    <div>
                    <h2 className='my-4' style={{ fontSize: '1.2rem', margin: '1rem 0' }}>Historial del factor de indisponibilidad</h2>
                    <ul className='d-flex flex-column gap-4 my-4'>
                      {historialModificado.length === 0 ? (
                        <p>No hay historial disponible.</p>
                      ) : (
                        historialModificado.map((item, i) => (
                          <li key={i} className='d-flex justify-content-between gap-3'>
                            <Avatar
                              data={{
                                rutaImagen: item.urlAvatar,
                                nombreCompleto: item.usuarioNombres,
                                apellidoPaterno: item.usuarioApellidoPaterno
                              }}
                            />
                            <div className='d-flex flex-column ms-0 me-auto' style={{ fontSize: '1rem' }}>
                              <div className='d-flex align-items-center'>
                                <span className='me-2'><b>{item.usuario}</b></span>
                                <span style={{ fontSize: '0.9rem' }}>{item.perfil}</span>
                              </div>
                              <small className='text-pewter-blue text-break' style={{ fontSize: '0.8rem' }}>
                                <b>Factor de indisponibilidad Solicitado: {item.factorIndispSolicitado}%</b> 
                              </small>
                              {item.observacion && (
                                <>
                                  <span className='me-2'><b>Observación:</b></span>
                                  <small className='text-pewter-blue text-break' style={{ fontSize: '0.8rem' }}>
                                    <span>{item.observacion}</span>
                                  </small>
                                </>
                              )}
                              {item.documentosUsuario && (
                                <div className='mt-2'>
                                  {item.documentosUsuario.map((x, index) => (
                                    <div key={i} className='doc bg-white-smoke rounded p-2' style={{'flexBasis':'45%'}} onClick={() => downloadXUrl(x.ruta, x.mime, x.nombre, x.extension)}>
                                      <div className='doc-icon'>
                                        <i className="ico-doc-empty fs-3"></i>
                                      </div>
                                      <div className='doc-info'>
                                        <b className='doc-title'>{x.nombre}</b>
                                        <span className='doc-time'>{formatoBytes(x.tamano)}</span>
                                      </div>
                                    </div>
                                    ))}
                                  </div>
                              )}
                            </div>
                            <div>
                              <small className='text-pewter-blue text-nowrap' style={{ fontSize: '0.6rem' }}>
                                {obtenerFormatoFecha(item.fecha)}
                              </small>
                            </div>
                          </li>
                        ))
                      )}
                    </ul>
                    </div>
                  </>
                ) : estado === 'Solicitado' && (usuario.perfilContexto.id === 2 || usuario.perfilContexto.id === 1) ? (
                <div>
                <h2 className='my-4' style={{ fontSize: '1.2rem', margin: '1rem 0' }}>Historial del factor de indisponibilidad</h2>
                <ul className='d-flex flex-column gap-4 my-4'>
                  {historialModificado.length === 0 ? (
                    <p>No hay historial disponible.</p>
                  ) : (
                    historialModificado.map((item, i) => (
                      <li key={i} className='d-flex justify-content-between gap-3'>
                        <Avatar
                          data={{
                            rutaImagen: item.urlAvatar,
                            nombreCompleto: item.usuarioNombres,
                            apellidoPaterno: item.usuarioApellidoPaterno
                          }}
                        />
                        <div className='d-flex flex-column ms-0 me-auto' style={{ fontSize: '1rem' }}>
                          <div className='d-flex align-items-center'>
                            <span className='me-2'><b>{item.usuario}</b></span>
                            <span style={{ fontSize: '0.9rem' }}>{item.perfil}</span>
                          </div>
                          <small className='text-pewter-blue text-break' style={{ fontSize: '0.8rem' }}>
                            <b>Factor de indisponibilidad Solicitado: {item.factorIndispSolicitado}%</b> 
                          </small>
                          {item.observacion && (
                            <>
                              <span className='me-2'><b>Observación:</b></span>
                              <small className='text-pewter-blue text-break' style={{ fontSize: '0.8rem' }}>
                                <span>{item.observacion}</span>
                              </small>
                            </>
                          )}
                          {item.documentosUsuario && (
                            <div className='mt-2'>
                              {item.documentosUsuario.map((x, index) => (
                                <div key={i} className='doc bg-white-smoke rounded p-2' style={{'flexBasis':'45%'}} onClick={() => downloadXUrl(x.ruta, x.mime, x.nombre, x.extension)}>
                                  <div className='doc-icon'>
                                    <i className="ico-doc-empty fs-3"></i>
                                  </div>
                                  <div className='doc-info'>
                                    <b className='doc-title'>{x.nombre}</b>
                                    <span className='doc-time'>{formatoBytes(x.tamano)}</span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div>
                          <small className='text-pewter-blue text-nowrap' style={{ fontSize: '0.6rem' }}>
                            {obtenerFormatoFecha(item.fecha)}
                          </small>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
                </div>
                ) : estado === 'Sin respuesta DGOP' &&
                <div>
                <h2 className='my-4' style={{ fontSize: '1.2rem', margin: '1rem 0' }}>Historial del factor de indisponibilidad</h2>
                <ul className='d-flex flex-column gap-4 my-4'>
                  {historialModificado.length === 0 ? (
                    <p>No hay historial disponible.</p>
                  ) : (
                    historialModificado.map((item, i) => (
                      <li key={i} className='d-flex justify-content-between gap-3'>
                        <Avatar
                          data={{
                            rutaImagen: item.urlAvatar,
                            nombreCompleto: item.usuarioNombres,
                            apellidoPaterno: item.usuarioApellidoPaterno
                          }}
                        />
                        <div className='d-flex flex-column ms-0 me-auto' style={{ fontSize: '1rem' }}>
                          <div className='d-flex align-items-center'>
                            <span className='me-2'><b>{item.usuario}</b></span>
                            <span style={{ fontSize: '0.9rem' }}>{item.perfil}</span>
                          </div>
                          <small className='text-pewter-blue text-break' style={{ fontSize: '0.8rem' }}>
                            <b>Factor de indisponibilidad Solicitado: {item.factorIndispSolicitado}%</b> 
                          </small>
                          {item.observacion && (
                            <>
                              <span className='me-2'><b>Observación:</b></span>
                              <small className='text-pewter-blue text-break' style={{ fontSize: '0.8rem' }}>
                                <span>{item.observacion}</span>
                              </small>
                            </>
                          )}
                          {item.documentosUsuario && (
                            <div className='mt-2'>
                              {item.documentosUsuario.map((x, index) => (
                                <div key={i} className='doc bg-white-smoke rounded p-2' style={{'flexBasis':'45%'}} onClick={() => downloadXUrl(x.ruta, x.mime, x.nombre, x.extension)}>
                                  <div className='doc-icon'>
                                    <i className="ico-doc-empty fs-3"></i>
                                  </div>
                                  <div className='doc-info'>
                                    <b className='doc-title'>{x.nombre}</b>
                                    <span className='doc-time'>{formatoBytes(x.tamano)}</span>
                                  </div>
                                </div>
                                ))}
                              </div>
                          )}
                        </div>
                        <div>
                          <small className='text-pewter-blue text-nowrap' style={{ fontSize: '0.6rem' }}>
                            {obtenerFormatoFecha(item.fecha)}
                          </small>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
                </div>
                }
                {(estado === 'Rechazado') ? (
                  <>
                  <div>
                    <h2 className='my-4' style={{ fontSize: '1.2rem', margin: '1rem 0' }}>Historial del factor de indisponibilidad</h2>
                    <ul className='d-flex flex-column gap-4 my-4'>
                      {historialModificado.length === 0 ? (
                        <p>No hay historial disponible.</p>
                      ) : (
                        historialModificado.map((item, i) => (
                          <li key={i} className='d-flex justify-content-between gap-3'>
                            <Avatar
                              data={{
                                rutaImagen: item.urlAvatar,
                                nombreCompleto: item.usuarioNombres,
                                apellidoPaterno: item.usuarioApellidoPaterno
                              }}
                            />
                            <div className='d-flex flex-column ms-0 me-auto' style={{ fontSize: '1rem' }}>
                              <div className='d-flex align-items-center'>
                                <span className='me-2'><b>{item.usuario}</b></span>
                                <span style={{ fontSize: '0.9rem' }}>{item.perfil}</span>
                              </div>
                              <small className='text-pewter-blue text-break' style={{ fontSize: '0.8rem' }}>
                                <b>
                                    {i === 0 ? 
                                        `Factor de indisponibilidad Solicitado: ${item.factorIndispSolicitado}%` :
                                        `Factor de indisponibilidad Otorgado: ${item.factorIndispActual}%`}
                                </b>
                              </small>
                              {item.observacion && (
                                <>
                                  <span className='me-2'><b>Observación:</b></span>
                                  <small className='text-pewter-blue text-break' style={{ fontSize: '0.8rem' }}>
                                    <span>{item.observacion}</span>
                                  </small>
                                </>
                              )}
                              {item.documentosUsuario && (
                                <div className='mt-2'>
                                  {item.documentosUsuario.map((x, index) => (
                                   <div key={i} className='doc bg-white-smoke rounded p-2' style={{'flexBasis':'45%'}} onClick={() => downloadXUrl(x.ruta, x.mime, x.nombre, x.extension)}>
                                      <div className='doc-icon'>
                                        <i className="ico-doc-empty fs-3"></i>
                                      </div>
                                      <div className='doc-info'>
                                        <b className='doc-title'>{x.nombre}</b>
                                        <span className='doc-time'>{formatoBytes(x.tamano)}</span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                            <div>
                              <small className='text-pewter-blue text-nowrap' style={{ fontSize: '0.6rem'}}>
                                {obtenerFormatoFecha(item.fecha)}
                              </small>
                            </div>
                          </li>
                        ))
                      )}
                    </ul>
                  </div>
                </>
                ) : estado === 'Aprobado' && (
                  <>
                  <div>
                    <h2 className='my-4' style={{ fontSize: '1.2rem', margin: '1rem 0' }}>Historial del factor de indisponibilidad</h2>
                    <ul className='d-flex flex-column gap-4 my-4'>
                      {historialModificado.length === 0 ? (
                        <p>No hay historial disponible.</p>
                      ) : (
                        historialModificado.map((item, i) => (
                          <li key={i} className='d-flex justify-content-between gap-3'>
                            <Avatar
                              data={{
                                rutaImagen: item.urlAvatar,
                                nombreCompleto: item.usuarioNombres,
                                apellidoPaterno: item.usuarioApellidoPaterno
                              }}
                            />
                            <div className='d-flex flex-column ms-0 me-auto' style={{ fontSize: '1rem' }}>
                              <div className='d-flex align-items-center'>
                                <span className='me-2'><b>{item.usuario}</b></span>
                                <span style={{ fontSize: '0.9rem' }}>{item.perfil}</span>
                              </div>
                              <small className='text-pewter-blue text-break' style={{ fontSize: '0.8rem' }}>
                                <b>
                                    {i === 0 ? 
                                        `Factor de indisponibilidad Solicitado: ${item.factorIndispSolicitado}%` :
                                        `Factor de indisponibilidad Otorgado: ${item.factorIndispOtorgado}%`}
                                </b>
                              </small>
                              {item.observacion && (
                                <>
                                  <span className='me-2'><b>Observación:</b></span>
                                  <small className='text-pewter-blue text-break' style={{ fontSize: '0.8rem' }}>
                                    <span>{item.observacion}</span>
                                  </small>
                                </>
                              )}
                              {item.documentosUsuario && (
                                <div className='mt-2'>
                                  {item.documentosUsuario.map((x, index) => (
                                    <div key={i} className='doc bg-white-smoke rounded p-2' style={{'flexBasis':'45%'}} onClick={() => downloadXUrl(x.ruta, x.mime, x.nombre, x.extension)}>
                                      <div className='doc-icon'>
                                        <i className="ico-doc-empty fs-3"></i>
                                      </div>
                                      <div className='doc-info'>
                                        <b className='doc-title'>{x.nombre}</b>
                                        <span className='doc-time'>{formatoBytes(x.tamano)}</span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                            <div>
                              <small className='text-pewter-blue text-nowrap' style={{ fontSize: '0.6rem' }}>
                                {obtenerFormatoFecha(item.fecha)}
                              </small>
                            </div>
                          </li>
                        ))
                      )}
                    </ul>
                  </div>
                </>
              )}
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          {estado === 'Sin Solicitud' ? (
            <>
              <Button variant="light" onClick={handleClose} style={{ borderColor: '#043087' }}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={solicitarObjecion}>
                Objetar
              </Button>
            </>
          ) : estado === 'Solicitado' && (usuario.perfilContexto.id === 3) ? (
            <>
              <Button variant="danger" onClick={rechazarObjecion}>
                Rechazado
              </Button>
              <Button variant="success" onClick={aprobarObjecion}>
                Aprobado
              </Button>
            </>
          ) : null}
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmationModal} onHide={handleCloseConfirmation}>
        <Modal.Header closeButton></Modal.Header>
        <img src={IcoRefresh} alt="icon" style={{ width: '40px', height: '40px', display: 'block', margin: '0 auto', opacity: '0.7' }} />
        <br />
        <Modal.Title style={{ fontSize: '1.3rem', color: '#99B2C6' }} className="w-100 text-center">
          ¿Estás Seguro <br /> que deseas asignar este estado?
        </Modal.Title>
        <Modal.Body style={{ fontSize: '0.9rem', color: '#99B2C6' }} className="w-100 text-center">
          Al asignar el estado ya no podrás volver a modificar este
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseConfirmation} style={{ borderColor: '#043087' }}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={ObjectarEvento}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalObjetarIndicador;