import { Routes, Route } from "react-router-dom";
import LoginLayout from "./Layouts/LoginLayout";
import AppLayout from "./Layouts/AppLayout";
import Login from "./Features/Autenticacion/Pages/AutenticacionPage";
import RecuperarClave from "./Features/Autenticacion/Pages/RecuperarClavePage";
import CambioClave from "./Features/Autenticacion/Pages/CambioClavePage";
import InicioPage from "./Features/Inicio/Pages/InicioPage";
import ConfiguracionPage from "./Features/Configuracion/Pages/ConfiguracionPage";
import UsuarioPage from "./Features/Usuario/Pages/UsuarioPage";
import ValidacionAutenticacion from "./Features/Autenticacion/PrivateRoute/ValidacionAutenticacion";
import { LoginContextProvider } from "./Features/Autenticacion/Context/LoginContext";
import HallazgoPage from "./Features/Hallazgo/Pages/HallazgoPage";
import { ToastContextProvider } from "./Context/ToastContext";
import { LoadingContextProvider } from "./Context/LoadingContext";
import { AxiosInterceptor } from "./Utils/Interceptors/Interceptor";
import ActivarCuenta from "./Features/Autenticacion/Pages/ActivarCuentaPage";
import AutorizacionesPage from "./Features/Autorizaciones/Pages/AutorizacionesPage";
import AsignacionBackupPage from "./Features/AsignacionBackup/Pages/AsignacionBackupPage";
import AdministracionAlarmasPage from "./Features/AdministracionAlarmas/Pages/AdministracionAlarmasPage";
import AdministracionDePonderacionPage from "./Features/AdministracionDePonderacion/Pages/AdministracionDePonderacionPage";
import AdministracionDeExigenciasPage from "./Features/AdministracionDeExigencias/Pages/AdministracionDeExigenciasPage";
import AlertasPage from "./Features/Alertas/Page/AlertasPage";
import NotificacionesPage from "./Features/Notificaciones/Pages/NotificacionesPage";
import AlarmasPage from "./Features/Alarmas/Pages/AlarmasPage";
import PersonalizacionAlarmasPage from "./Features/Alarmas/Pages/PersonalizacionAlarmasPage";
import PrivateRouteModulo from "./Features/Autenticacion/PrivateRoute/ValidarModulo";
import ValidateUrlBase64 from "./Features/Autenticacion/PrivateRoute/ValidacionUrlBase64";
import { EnumModulo, EnumPathRutas } from "./Utils/Enumeradores/ComunEnum";
import IndicadoresPage from "./Features/Indicadores/Pages/IndicadoresPage";
import IndicadoresIspPage from "./Features/Indicadores/Pages/IndicadoresIspPage";
import IndicadoresCuartoNivelPage from "./Features/Indicadores/Pages/IndicadoresCuartoNivelPage";
import DatamartPage from "./Features/Datamart/Pages/DatamartPage";
import { PonderacionContextProvider } from "./Features/AdministracionDePonderacion/Context/PonderacionContext";
import DatamartIndicadoresPage from "./Features/Datamart/Pages/DatamartIndicadoresPage";
import DatamartIndicadorPage from "./Features/Datamart/Pages/DatamartIndicadorPage";
import BitacoraEventosConcesionPage from "./Features/Bitacora/Pages/BitacoraEventosConcesionPage";
import BitacoraUsuariosPage from "./Features/Bitacora/Pages/BitacoraUsuariosPage";
import { AlertasContextProvider } from "./Features/Alertas/Context/ContextAlertas";
import BitacoraBimPage from "./Features/Bitacora/Pages/BitacoraBimPage";
import BitacoraObjetarEventoPage from "./Features/Bitacora/Pages/BitacoraObjetarEventoPage";
import { ModalPerfilContextProvider } from "./Context/ModalPerfilContext";
import { DatamartContextProvider } from "./Features/Datamart/Context/DatamartContext";
import InformesPages from "./Features/Informes/Pages/InformesPages";
import EstadisticasDeHallazgosPage from "./Features/Informes/Pages/EstadisticasDeHallazgosPage";
import EstadisticasTdrPage from "./Features/Informes/Pages/EstadisticasTdrPage";
import NivelesCumplimientosPage from "./Features/Informes/Pages/NivelesCumplimientosPage";
import DisponibilidadPages from "./Features/Informes/Pages/DisponibilidadPages";
import { ContadoresContextProvider } from "./Context/ContadoresContext";
import InterceptorNavigateRoute from "./Utils/Interceptors/InterceptorNavigateRoute";
import { NombreModuloContextProvider } from "./Context/NombreModuloContext";

function App() {
	return (
		<ToastContextProvider>
			<LoadingContextProvider>
				<LoginContextProvider>
					<AxiosInterceptor>
						<NombreModuloContextProvider>
							<InterceptorNavigateRoute>
								<ModalPerfilContextProvider>
									<ContadoresContextProvider>
										<Routes>
											<Route path="/" element={<LoginLayout />}>
												<Route index element={<Login />} />
												<Route path="/recuperar_clave" element={<RecuperarClave />} />
												<Route element={<ValidateUrlBase64></ValidateUrlBase64>}>
													<Route path="/recuperar_clave/:id" element={<CambioClave />} />
													<Route path="/activar_cuenta/:id/:token" element={<ActivarCuenta />} />
												</Route>
											</Route>
											<Route element={<ValidacionAutenticacion></ValidacionAutenticacion>}>
												<Route element={<AppLayout />}>
													<Route path={EnumPathRutas.Inicio} element={<PrivateRouteModulo id={EnumModulo.Inicio}><InicioPage /></PrivateRouteModulo>} />
													<Route path={EnumPathRutas.Notificaciones} element={<PrivateRouteModulo id={EnumModulo.Notificaciones}><NotificacionesPage /></PrivateRouteModulo>} />
													<Route path={EnumPathRutas.Datamart} element={<PrivateRouteModulo id={EnumModulo.Datamart}></PrivateRouteModulo>}>
														<Route index element={<DatamartContextProvider><DatamartPage /></DatamartContextProvider>} />
														<Route path={EnumPathRutas.DatamartIndicadores} element={<DatamartIndicadoresPage />} />
														<Route path={EnumPathRutas.DatamartIndicadores + "/" + EnumPathRutas.DatamartIndicador} element={<DatamartIndicadorPage />} />
													</Route>
													<Route path={EnumPathRutas.Informes} element={<PrivateRouteModulo id={EnumModulo.Informes}></PrivateRouteModulo>}>
														<Route index element={<InformesPages />} />
														<Route path={EnumPathRutas.InformesNivelCumplimiento} element={<NivelesCumplimientosPage />} />
														<Route path={EnumPathRutas.InformesEstadisticasHallazgo} element={<EstadisticasDeHallazgosPage />} />
														<Route path={EnumPathRutas.InformesEstadisticasTdr} element={<EstadisticasTdrPage />} />
														<Route path={EnumPathRutas.InformeDisponibilidadSICNS} element={<DisponibilidadPages />} />
													</Route>
													<Route path={EnumPathRutas.Configuracion} element={<PrivateRouteModulo id={EnumModulo.Configuracion}></PrivateRouteModulo>}>
														<Route index element={<ConfiguracionPage />} />
														<Route path={EnumPathRutas.ConfiguracionUsuario} element={<UsuarioPage />} />
														<Route path={EnumPathRutas.ConfiguracionExigencias} element={<AdministracionDeExigenciasPage />} />
														<Route path={EnumPathRutas.ConfiguracionPonderacion} element={<PonderacionContextProvider><AdministracionDePonderacionPage /></PonderacionContextProvider>} />
														<Route path={EnumPathRutas.ConfiguracionBackup} element={<AsignacionBackupPage />} />
														<Route path={EnumPathRutas.ConfiguracionAlarmas} element={<AdministracionAlarmasPage />} />
														<Route path={EnumPathRutas.ConfiguracionAlarmasPrevias} element={<AlertasContextProvider><AlertasPage /></AlertasContextProvider>} />
													</Route>
													<Route path={EnumPathRutas.Autorizaciones} element={<PrivateRouteModulo id={EnumModulo.Autorizaciones}></PrivateRouteModulo>}>
														<Route index element={<AutorizacionesPage />} />
													</Route>
													<Route path={EnumPathRutas.Hallazgos} element={<PrivateRouteModulo id={EnumModulo.GestionHallazgos}></PrivateRouteModulo>}>
														<Route index element={<HallazgoPage />} />
													</Route>
													<Route path={EnumPathRutas.Alarmas} element={<PrivateRouteModulo id={EnumModulo.Alarmas}></PrivateRouteModulo>}>
														<Route index element={<AlarmasPage />} />
														<Route path={EnumPathRutas.AlarmasPersonalizacion} element={<PersonalizacionAlarmasPage />} />
													</Route>
													<Route path={EnumPathRutas.Indicadores} element={<PrivateRouteModulo id={EnumModulo.Indicadores}></PrivateRouteModulo>}>
														<Route index element={<IndicadoresPage />} />
														<Route path={EnumPathRutas.IndicadoresCuartoNivel} element={<IndicadoresCuartoNivelPage />} />
														<Route path={EnumPathRutas.IndicadoresISP} element={<IndicadoresIspPage />} />
													</Route>
													<Route path={EnumPathRutas.Bitacora} element={<PrivateRouteModulo id={EnumModulo.Bitacora}></PrivateRouteModulo>}>
														<Route path={EnumPathRutas.BitacoraEventosConcesion} element={<BitacoraEventosConcesionPage />} />
														<Route path={EnumPathRutas.BitacoraObjetar} element={<BitacoraObjetarEventoPage />} />
														<Route path={EnumPathRutas.BitacoraActividadUsuario} element={<BitacoraUsuariosPage />} />
														<Route path={EnumPathRutas.BitacoraBIM} element={<BitacoraBimPage />} />
													</Route>
												</Route>
											</Route>
										</Routes>
									</ContadoresContextProvider>
								</ModalPerfilContextProvider>
							</InterceptorNavigateRoute>
						</NombreModuloContextProvider>
					</AxiosInterceptor>
				</LoginContextProvider>
			</LoadingContextProvider>
		</ToastContextProvider>
	);
}

export default App;
