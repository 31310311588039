import { useState } from "react";
import Pivote from "../Components/Pivote/Pivote";

const Main = () => {
	const [access] = useState([
		{
			id:0,
			link:"configuracion/ponderacion",
			title:'Administración Ponderación ',
			text:'Edite la ponderación del porcentaje para los Indicadores de NSB y Especiales Obligatorios',
			icon:"ico-progress",
			color:"#043087",
		},
		{
			id:1,
			link:"configuracion/usuarios",
			title:'Administración Usuario',
			text:'Edite, cree y asigne perfiles a los diferentes usuarios del SIC-NS',
			icon:"ico-user",
			color:"#809FB8",
		},
		{
			id:2,
			link:"configuracion/alarmas",
			title:'Administración Alarmas',
			text:'Gestione las alarmas según el perfil, administre listas de distribución para los usuarios no registrados en la plataforma.',
			icon:"ico-alarms",
			color:"#FC0000",
		},
		{
			id:3,
			link:"configuracion/backup",
			title:'Asignar Reemplazo',
			text:'Administre los reemplazos de cada uno de los usuarios.',
			icon:"ico-user-next",
			color:"#FC5000",
		},
		{
			id:4,
			link:"configuracion/alarmas_previas",
			title:'Administración de Alertas de previas',
			text:'Configure alertas previas para cada uno de los indicadores, de manera de alertar antes de salir de los parámetros de cumplimiento.',
			icon:"ico-bell",
			color:"#289CFB",
		},
		{
			id:5,
			link:"configuracion/exigencias",
			title:'Administración de exigencias',
			text:'Edite la descripción, valor y medida de cada una de las exigencias.',
			icon:"ico-graphics",
			color:"#52B568",
		},
	])
	return (
		<div className="container-fluid p-0">
		<div className="row">
			{	access.map( item =>
				<div key={item.id} className="col-12 col-md-6 col-lg-4 mb-4">
					<Pivote
						link={item.link}
						title={item.title}
						text={item.text}
						icon={item.icon}
						color={item.color}
					/>
				</div>
			)}
		</div>
	</div>
	)
}
export default Main;
