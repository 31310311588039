import { useNavigate } from "react-router-dom";

const Acceso = ({...props}) => {
	const navigate = useNavigate();
	const accessTo = (link) => {
		navigate(`/${link}`, { replace: true });
	}
	return(
		<div className={`access access--${props.design}`} onClick={()=> accessTo(props.link)}>
			<div className="access__info">
				<b>{props.title}</b>
				<small>{props.subtitle}</small>
			</div>
			<div className="access__figure">
				<i className={props.icon}></i>
			</div>
		</div>
	)
}

export default Acceso;
