import { ListadoExigencia } from "../Services/ListadoExigenciaService";
import { useCallback, useState } from 'react'
import useLogin from "../../Autenticacion/Hooks/useLogin";
import { ListaIndicadoresIngresoHallazgoService } from '../Services/ListaIndicadoresIngresoHallazgoService';

export function useIndicadorHallazgo(props) {
    const [listaIndicadoresHallazgo, setListaIndicadoresHallazgo] = useState([]);
    const { user, usuario } = useLogin();
    async function postListaIndicadoresHallazgo() {
        const resp = await ListaIndicadoresIngresoHallazgoService(usuario.id, usuario.perfilContexto.id);
        if (resp) {
            if (resp.status === 200) {
                let listado = [];
                resp.data.listaIndicadores.map((value) => { listado.push({ valor: value.esServicio }) });
                setListaIndicadoresHallazgo(listado);
            }
        }
    }

    const getListaIndicadoresHallazgo = useCallback(
        function (values) {
            postListaIndicadoresHallazgo(values);
        },
        []
    );

    return { listaIndicadoresHallazgo, getListaIndicadoresHallazgo }
}