import SwitchInCell from "../Components/SwitchInCell"
import moment from 'moment';
import { EnumEstadoAutorizacion } from "../../../Utils/Enumeradores/ComunEnum";
export function useColumns(props) {
	const COLUMNS = [
		{
			Header: 'Nombre',
			accessor: 'nombres',
			id: 'nombres',
			className: '',
			Cell: ({ row }) =>
				<div className="user">
					<span className={`avatar rounded-circle border overflow-hidden ${row.original.rutaImagen === "" || row.original.rutaImagen === null ? 'avatarIniciales' : 'text-white'}`}>
						{row.original.rutaImagen === "" || row.original.rutaImagen === null ?
							row.original.nombres.charAt(0).toUpperCase() + row.original.apellidoPaterno.charAt(0).toUpperCase() :
							<img src={row.original.rutaImagen} alt="" />
						}
					</span>
					<div className="user__ide">
						<span className="text-nowrap">{row.original.nombres + " " + row.original.apellidoPaterno + " " + row.original.apellidoMaterno}</span>
						{row.original.listaPerfil.map((perfil, i) => {
							return (<small key={i} className="text-pewter-blue">{perfil.descripcion}</small>)
						})}

					</div>
				</div>
		},
		{
			Header: 'Área de negocio',
			accessor: data => {
				let output = [];
				data.listaAreaNegocio.map((area) => {
					output.push(area.descripcion);
				});
				return output.join(', ');
			},
			className: '',
		},

		{ Header: 'Correo electrónico', accessor: 'email', className: '', },
		{
			Header: 'Fecha de registro',
			accessor: 'fechaCreacion',
			className: 'text-center',
			Cell: ({ row }) => {return moment(row.original.fechaCreacion).format('DD-MM-YYYY')}
		},
		{
			Header: 'Movimiento',
			accessor: 'movimiento',
			className: 'text-center',
			disabledFilters: true,
			Cell: ({ row }) => <a
				className={`fs-4 text-decoration-none
				${row.original.movimiento && row.original.estadoAutorizacion === EnumEstadoAutorizacion.Pendiente ? 'text-primary' : ''}
				${row.original.estadoAutorizacion === EnumEstadoAutorizacion.Aprobado || row.original.estadoAutorizacion === EnumEstadoAutorizacion.Rechazado ? 'text-disabled' : ''}
				${!row.original.movimiento && row.original.estadoAutorizacion === EnumEstadoAutorizacion.Pendiente ? 'text-danger' : ''}


			`}
			//gris cuando el estado autorizacion es 2 o 3
			>
				{!row.original.movimiento  ? <i className="ico-user-delete"></i> : <i className="ico-user-add"></i>}
			</a>
		},
		{
			Header: 'Autorización',
			accessor: 'estadoAutorizacion',
			className: 'text-center',
			disabledFilters: true,
			Cell: ({ row }) => <span
				className={`badge badge--monochromatic
				${row.original.estadoAutorizacion === EnumEstadoAutorizacion.Aprobado ? 'bg-success' : ''}
				${row.original.estadoAutorizacion === EnumEstadoAutorizacion.Pendiente ? 'bg-primary' : ''}
				${row.original.estadoAutorizacion === EnumEstadoAutorizacion.Rechazado ? 'bg-danger' : ''}
			`}
			>
				{row.original.estadoAutorizacion === EnumEstadoAutorizacion.Pendiente ? "Pendiente" : row.original.estadoAutorizacion === EnumEstadoAutorizacion.Aprobado ? "Aprobado" : row.original.estadoAutorizacion === EnumEstadoAutorizacion.Rechazado ? "Rechazado" : ""}
			</span>
		},
		{
			Header: 'Estado',
			accessor: 'estado',
			className: 'text-center',
			disabledFilters: true,
			Cell: ({ row }) => <SwitchInCell usuario={row.original} renderizar={props}></SwitchInCell>,
		},
	]
	return { COLUMNS }
}
