import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ListadoProblemas = async (indicadorId, usuarioId, idMacroubicacion) => {
    try {
        const resultado = await instance.Backend.post("/Hallazgo/ListadoProblemas", {
            indicadorId: indicadorId,
            idMacroubicacion: idMacroubicacion,
            idUsuario: usuarioId
        }, customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export { ListadoProblemas };