import React, { useState, useEffect, useRef } from 'react'
import { Formik, Form, } from "formik";
import InputTextField from "../../../Components/InputTextField/InputTextField"
import Button from "../../../Components/Button/Button"
import TextAreaField from "../../../Components/TextAreaField/TextAreaField"
import SelectCheckField from "../../../Components/SelectCheckField/SelectCheckField";
import useLogin from '../../Autenticacion/Hooks/useLogin';
import moment from "moment";
import { useToast } from '../../../Hooks/useToast';
import InputFileField from '../../../Components/InputFileField/InputFileField';
import { useComun } from '../../../Hooks/useComun';
import validations, { soloDecimal, soloNumerico, onBlurDecimal, onBlurComentario, sinDobleEspacio, sinExtension, TamanoArchivo } from '../../../Utils/Validations/Validations';
import RangeDatePicker from '../../../Components/RangeDatePicker/RangeDatePicker';
import { IngresoHallazgoService } from '../Services/IngresoHallazgoService';
import { EnumMensajeErrorArchivos, EnumSiglaIndicador } from '../../../Utils/Enumeradores/ComunEnum';

const FormAsesoriaFiscal = (props) => {
	const { indicadorId, indicadorSeleccionadoSigla, esServicio, setEsServicio, esLimpiarFormulario, setEsLimpiarFormularo, renderizar, ...rest } = props;
	const addToast = useToast();
	const { user, usuario } = useLogin();
	const [problemaSeleccionado, setProblemaSeleccionado] = useState(0);
	const [macroubicacionSeleccionado, setMacroubicacionSeleccionado] = useState(0);
	const [macro, setMacro] = useState({ id: null, texto: '' });
	const { formatoBytes
		, getListaTipoFalla, listaTipoFalla
		, getListaProblemaHallazgo, listaProblemaHallazgo, setListaProblemaHallazgo
		, getListaMacroubicacionHallazgo, listaMacroubicacionHallazgo
		, getListaGravedadHallazgo, listaGravedadHallazgo
		, getListaElementoHallazgo, listaElementoHallazgo, setListaElementoHallazgo } = useComun();

	const formikRef = useRef();

	const listaFiaFim = [
		{
			value: 0,
			label: '0%'
		},
		{
			value: 3,
			label: '3%'
		},
		{
			value: 5,
			label: '5%'
		}
	];

	useEffect(() => {
		getListaGravedadHallazgo();
		getListaTipoFalla();
	}, []);

	useEffect(() => {
		getListaMacroubicacionHallazgo(indicadorId);
		setProblemaSeleccionado(0);
		setMacroubicacionSeleccionado(0);
	}, [indicadorId]);

	useEffect(() => {
		if (problemaSeleccionado !== 0) {
			getListaElementoHallazgo(problemaSeleccionado, macroubicacionSeleccionado);
		} else if (problemaSeleccionado === 0) {
			setListaElementoHallazgo([]);
		}
		formikRef.current.setFieldValue("elemento", '');
		if (!formikRef.current.errors.hasOwnProperty('elemento')) {
			formikRef.current.setTouched(values => ({ ...values, elemento: false }));
		}
		setMacro({ id: null, texto: '' });

	}, [problemaSeleccionado]);


	useEffect(() => {
		if (macroubicacionSeleccionado !== 0) {
			getListaProblemaHallazgo(indicadorId, macroubicacionSeleccionado);
			setListaElementoHallazgo([]);
		} else if (macroubicacionSeleccionado === 0) {
			setListaElementoHallazgo([]);
			setListaProblemaHallazgo([]);
		}
		formikRef.current.setFieldValue("elemento", '');
		formikRef.current.setFieldValue("problema", '');
		if (!formikRef.current.errors.hasOwnProperty('elemento')) {
			formikRef.current.setTouched(values => ({ ...values, elemento: false }));
		}
		if (!formikRef.current.errors.hasOwnProperty('problema')) {
			formikRef.current.setTouched(values => ({ ...values, problema: false }));
		}
		setMacro({ id: null, texto: '' });

	}, [macroubicacionSeleccionado]);

	const [initialValues, setInitialValues] = useState({
		problema: '',
		elemento: '',
		periodo: '',
		gravedad: '',
		kmInicial: '',
		kmFinal: '',
		macroubicacion: '',
		comentarioHallazgo: '',
		fileHallazgo: ''
	});

	function changeProblema(e) {
		setProblemaSeleccionado(e);
	};

	function changeMacroubicacion(e) {
		setMacroubicacionSeleccionado(e);
	};

	useEffect(() => {
		if (indicadorSeleccionadoSigla === EnumSiglaIndicador.VIA) {
			setInitialValues({
				problema: '',
				elemento: '',
				periodo: '',
				gravedad: '',
				kmInicial: '',
				kmFinal: '',
				macroubicacion: '',
				comentarioHallazgo: '',
				fileHallazgo: '',
				fiaFim: ''
			});
		} else if (indicadorSeleccionadoSigla === EnumSiglaIndicador.VAR2) {
			setInitialValues({
				problema: '',
				elemento: '',
				periodo: '',
				gravedad: '',
				kmInicial: '',
				kmFinal: '',
				macroubicacion: '',
				comentarioHallazgo: '',
				fileHallazgo: '',
				minutos: '',
				tipoFalla: ''
			})
		} else if (indicadorSeleccionadoSigla) {
			setInitialValues({
				problema: '',
				elemento: '',
				periodo: '',
				gravedad: '',
				kmInicial: '',
				kmFinal: '',
				macroubicacion: '',
				comentarioHallazgo: '',
				fileHallazgo: ''
			})
		}
	}, [indicadorSeleccionadoSigla]);

	useEffect(() => {
		if (esLimpiarFormulario) {
			formikRef.current.resetForm();
			setProblemaSeleccionado(0);
			setListaElementoHallazgo([]);
			setMacro({ id: null, texto: '' });
			setEsLimpiarFormularo(false);
		}
	}, [esLimpiarFormulario]);

	function changeElemento(e) {
		const objElemento = listaElementoHallazgo.filter(res => res.value === e);
		if (objElemento.length > 0) {
			setMacro({ id: objElemento[0].idMacro, texto: objElemento[0].macro });

		}
	}

	function changeCancel() {
		setEsServicio(null);
		rest.onHide();
	}

	function handlerFiles(event) {
		const respuestaExtension = sinExtension(event.target.files[0]);
		const respuestaTamano = TamanoArchivo(event.target.files[0]);
		if (formikRef.current.values.fileHallazgo.length < 2) {
			if (respuestaExtension === "" && respuestaTamano === "") {
				let nuevaLista = formikRef.current.values.fileHallazgo !== '' ? formikRef.current.values.fileHallazgo : [];
				nuevaLista.push(...Array.prototype.slice.call(event.target.files));
				formikRef.current.setFieldValue("fileHallazgo", nuevaLista);
			}
			else {
				formikRef.current.setFieldError("fileHallazgo", respuestaExtension !== "" ? respuestaExtension : respuestaTamano);
			}
		} else if (formikRef.current.values.fileHallazgo.length === 2) {
			formikRef.current.setFieldError("fileHallazgo", EnumMensajeErrorArchivos.CantidadAdjunto);
		}
	}

	function eliminarFile(posicionFila) {
		const nuevaLista = formikRef.current.values.fileHallazgo.filter((x, index) => index !== posicionFila);
		formikRef.current.setFieldValue("fileHallazgo", nuevaLista);
	}

	async function postIngresoHallazgo(values) {
		const resp = await IngresoHallazgoService(values);
		if (resp) {
			if (resp.status === 200) {
				addToast({ mostrar: true, mensaje: 'Se ha ingresado el hallazgo correctamente.', titulo: 'Confirmación', tipo: 'Success' });
				rest.onHide();
				renderizar();
			}
		}
	}

	function IngresoHallazgo(values) {
		const formateoKmInicial = values.kmInicial.replace(".", ",");
		const formateoKmFinal = values.kmFinal.replace(".", ",");
		let formData = new FormData();
		formData.append('IndicadorId', indicadorId);
		formData.append('ElementoId', values.elemento);
		formData.append('ProblemaId', problemaSeleccionado);
		formData.append('MacroubicacionId', macro.id);
		formData.append('PorcentajeFiaFim', values.hasOwnProperty('fiaFim') && indicadorSeleccionadoSigla === EnumSiglaIndicador.VIA ? values.fiaFim : '');
		formData.append('KMInicio', formateoKmInicial);
		formData.append('KMFinal', formateoKmFinal);
		formData.append('FechaDeteccion', moment(values.periodo[0]).utc().toISOString());
		formData.append('FechaLimiteCorreccion', moment(values.periodo[1]).utc().toISOString());
		formData.append('Gravedad', values.gravedad);
		formData.append('Observacion', values.comentarioHallazgo);
		formData.append('TipoFalla', values.hasOwnProperty('tipoFalla') && indicadorSeleccionadoSigla === EnumSiglaIndicador.VAR2 ? values.tipoFalla : '');
		formData.append('CantidadMinutos', values.hasOwnProperty('minutos') && indicadorSeleccionadoSigla === EnumSiglaIndicador.VAR2 ? values.minutos : '');
		if (values.fileHallazgo !== '') {
			values.fileHallazgo.map((value, index) => {
				formData.append("ListaArchivos", value);
			});
		}
		formData.append('UsuarioId', user.id);
		formData.append('PerfilId', usuario.perfilContexto.id);
		formData.append('EsServicio', esServicio);
		postIngresoHallazgo(formData);
	}

	return (
		<Formik
			enableReinitialize
			innerRef={formikRef}
			initialValues={initialValues}
			validate={validations}
			onSubmit={values => {
				IngresoHallazgo(values);
			}
			}
		>{({ setFieldValue, setFieldTouched, values }) => (
			<Form className="form mb-0">
				<div className='simple-select'>
					<SelectCheckField
						name="macroubicacion"
						label='Macroubicación'
						options={listaMacroubicacionHallazgo}
						onBlur={setFieldTouched}
						onChange={(name, value) => { setFieldValue("macroubicacion", value); changeMacroubicacion(value); }}
						isMulti={false}
						fijo={false}
					/>
				</div>
				<div className='simple-select'>
					<SelectCheckField
						name="problema"
						label='Problema'
						options={listaProblemaHallazgo}
						onBlur={setFieldTouched}
						onChange={(name, value) => { setFieldValue("problema", value); changeProblema(value); }}
						isMulti={false}
						fijo={false}
					/>
				</div>
				<div className='simple-select'>
					<SelectCheckField
						name="elemento"
						label='Elemento'
						options={listaElementoHallazgo}
						onBlur={setFieldTouched}
						onChange={(name, value) => { changeElemento(value); setFieldValue("elemento", value) }}
						isMulti={false}
						fijo={false}
					/>
				</div>
				{
					values.hasOwnProperty('fiaFim') && indicadorSeleccionadoSigla === EnumSiglaIndicador.VIA &&
					<SelectCheckField
						name="fiaFim"
						label='Porcentaje Fia/Fim'
						options={listaFiaFim}
						onBlur={setFieldTouched}
						onChange={setFieldValue}
						isMulti={false}
					/>

				}
				{
					values.hasOwnProperty('minutos') && values.hasOwnProperty('tipoFalla') && indicadorSeleccionadoSigla === EnumSiglaIndicador.VAR2 &&
					<><InputTextField
						name="minutos"
						label="Cantidad Minutos"
						maxLength="7"
						type="text"
						onInput={(e) => { soloNumerico(e); }}
						placeholder="Cantidad Minutos"
						autoComplete="false" />

						<SelectCheckField
							name="tipoFalla"
							options={listaTipoFalla}
							onChange={setFieldValue}
							onBlur={setFieldTouched}
							label="Tipo Falla"
							isMulti={false} />
					</>

				}

				<div className='d-flex justify-content-between flex-fill gap-3 mb-2'>
					<InputTextField
						name="kmInicial"
						label="KM Inicial"
						type="text"
						placeholder="Km Inicial"
						autoComplete="false"
						maxLength="9"
						onInput={(e) => { soloDecimal(e) }}
						onBlur={(e) => { onBlurDecimal(e); setFieldValue("kmInicial", e.target.value); }}
						onPaste={(e) => {
							e.preventDefault()
							return false;
						}}
						onCopy={(e) => {
							e.preventDefault()
							return false;
						}}
						onDrop={(e) => {
							e.preventDefault()
							return false;
						}}

					/>
					<InputTextField
						name="kmFinal"
						label="KM Final"
						type="text"
						placeholder="Km Final"
						autoComplete="false"
						maxLength="9"
						onInput={(e) => { soloDecimal(e) }}
						onBlur={(e) => { onBlurDecimal(e); setFieldValue("kmFinal", e.target.value); }}
						onPaste={(e) => {
							e.preventDefault()
							return false;
						}}
						onCopy={(e) => {
							e.preventDefault()
							return false;
						}}
						onDrop={(e) => {
							e.preventDefault()
							return false;
						}}
					/>
				</div>
				<div className='d-flex justify-content-between flex-fill gap-3'>
					<RangeDatePicker
						startMaxDate={new Date()}
						startMinDate={null}
						endMaxDate={null}
						endMinDate={new Date()}
						name="periodo"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						labels={['Fecha Detección', 'Fecha Límite']}
					/>
				</div>
				<SelectCheckField
					name="gravedad"
					label='Gravedad'
					onChange={setFieldValue}
					onBlur={setFieldTouched}
					options={listaGravedadHallazgo}
					isMulti={false}
					fijo={false}
				/>
				<TextAreaField
					maxLength="200"
					name="comentarioHallazgo"
					placeholder="Escribe tu mensaje aquí…"
					label='Escribe tu mensaje aquí…'
					onBlur={(e) => { onBlurComentario(e); setFieldValue("comentarioHallazgo", e.target.value); }}
					onInput={(e) => { sinDobleEspacio(e) }}
				/>
				<div className='mb-2'>
					<small className='text-pewter-blue mb-2'>Quedan {200 - values.comentarioHallazgo.length} caracteres disponibles</small>
				</div>
				<div className="form-floating mb-3">
					<InputFileField placeholder="Subir Archivos" multiple name="fileHallazgo" onChange={(event) => handlerFiles(event)}
						onClick={(event) => { event.target.value = null }} />
				</div>

				{values.fileHallazgo !== '' && values.fileHallazgo.length > 0 &&
					<div className='rounded p-2 align-self-start d-flex gap-3'>
						{values.fileHallazgo.map((x, i) => {
							return (
								<div key={i} className='doc bg-white-smoke rounded p-2'>
									<div className='doc-icon'>
										<i className="ico-doc-empty fs-3"></i>
									</div>
									<div className='doc-info'>
										<b className='doc-title'>{x.name}</b>
										<span className='doc-time'>{formatoBytes(x.size)}</span>
									</div>
									<i className="ico ico-close" onClick={() => eliminarFile(i)}></i>
								</div>
							);
						})}
					</div>
				}
				<div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4">
					<Button type="button" design="btn-outline-pewter-blue" onClick={() => changeCancel()}>Cancelar</Button>
					<Button type="submit" design="btn-primary">Enviar</Button>
				</div>
			</Form>
		)}
		</Formik>
	)
}

export default FormAsesoriaFiscal;
