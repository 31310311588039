import moment from "moment";
import SelectColumnFilter from "../../../Components/Table/Filters/Components/SelectColumnFilter";
import { EnumEstadoHallazgo } from "../../../Utils/Enumeradores/ComunEnum";
export const ColumnasHallazgoIndicador = [
    {
        Header: 'Id',
        accessor: 'hallazgoId',
    },
    {
        Header: 'Descripción',
        accessor: 'descripcion',
    },
    {
        Header: 'Área de negocio',
        accessor: 'areaNegocio',
    },
    {
        Header: 'Fecha registro',
        className: 'text-nowrap',
        accessor: 'fechaRegistro',        
        Cell: ({ row }) => moment(row.original.fechaRegistro).format('DD-MM-YYYY')
    },
    {
        Header: 'Fecha evento',
        className: 'text-nowrap',
        accessor: 'fechaEvento',
        Cell: ({ row }) => moment(row.original.fechaEvento).format('DD-MM-YYYY')
    },
    {
        Header: 'Fecha Límite',
        className: 'text-nowrap',
        accessor: 'FECHALIMITE',
        Cell: ({ row }) => moment(row.original.fechaLimiteCorrección).format('DD-MM-YYYY')
    },
    {
        Header: 'Etapa',
        accessor: 'etapa',
        Cell: ({ row }) =>
            <div className="state">
                <span className="state__indice">{row.original.etapaId}</span>
                <span>{row.original.etapa}</span>
            </div>
    },
    {
        Header: 'Estado',
        accessor: 'estado',
        className: 'text-center',
        Filter: SelectColumnFilter,
        filter: 'includes',
        Cell: ({ row }) =>
            <button
                className={`badge badge--monochromatic
					${row.original.estadoId === EnumEstadoHallazgo.Nuevo ? 'bg-secondary' : ''}
					${row.original.estadoId === EnumEstadoHallazgo.EnProceso ? 'bg-primary' : ''}
					${row.original.estadoId === EnumEstadoHallazgo.Rechazado ? 'bg-danger' : ''}
					${row.original.estadoId === EnumEstadoHallazgo.Aprobado ? 'bg-success' : ''}
					${row.original.estadoId === EnumEstadoHallazgo.Preingreso ? 'bg-primary' : ''}
					${row.original.estadoId === EnumEstadoHallazgo.Cerrado ? 'bg-disabled' : ''}
                    ${row.original.estadoId === EnumEstadoHallazgo.Resuelto ? 'bg-success' : ''}
					${row.original.estadoId === EnumEstadoHallazgo.Objetado ? 'bg-danger' : ''}
                    ${row.original.estadoId === EnumEstadoHallazgo.Validacion ? 'bg-primary' : ''}
				`}
            >
                {row.original.estado}
            </button>
    },
    {
        Header: 'Origen',
        accessor: 'origen'

    },
];
