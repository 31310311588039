import React, { useMemo, useState, useEffect } from 'react';
import Chip from "../../../Components/Chip/Chip";
import Card from "../../../Components/Card/Card";
import ChartsDonut from "../../../Components/ChartsDonut/ChartsDonut";
import Tabs from 'react-bootstrap/Tabs'
import { EnumFiltroTabBusquedaHallazgo, EnumHallazgoEstatusEstadisticas, EnumModuloDesc, EnumParametro, EnumPathRutas, EnumTablaPaginacion } from '../../../Utils/Enumeradores/ComunEnum'
import Tab from 'react-bootstrap/Tab'
import { useComun } from '../../../Hooks/useComun';
import { COLUMNS } from '../Utils/Columns';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import ModalDetalleHallazgo from '../Components/ModalDetalleHallazgo';
import ModalAgregarHallazgo from '../Components/ModalAgregarHallazgo';
import { ObtenerEstadisticasHallazgoService } from '../../Inicio/Services/ObtenerEstadisticasHallazgoService';
import { BuscadorHallazgosService } from '../Services/BuscadorHallazgosService';
import { BuscadorHallazgosFiltroService } from '../Services/BuscadorHallazgosFiltroService';
import Button from '../../../Components/Button/Button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { EnumPerfil } from '../../../Utils/Enumeradores/ComunEnum';
import TablaHallazgo from '../Components/TablaHallazgo';
import TablaHallazgoFiltro from '../Components/TablaHallazgoFiltro';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const HallazgoPage = () => {
    const location = useLocation();
    const { usuario } = useLogin();
    const [hallazgo, setHallazgo] = useState({ alDia: 0, cerrados: 0, porVencer: 0, vencidos: 0, resueltos: 0 });
    const [chartFindings, setChartFindings] = useState([0, 0, 0, 0]);
    const { downloadCSV, listadoEstado, obtieneEstadoHallazgo, obtenerListaTipoHallazgo, listadoTipoHallazgo, ParametroObtenerPorId, numeroContrato } = useComun();
    const [datosModal, setDatosModal] = React.useState({});
    const [showModalAgregarHallazgo, setshowModalAgregarHallazgo] = useState(false);
    const [show, setShow] = useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const [pageCountFiltro, setPageCountFiltro] = React.useState(0);
    const handleCloseIngresoHallazgo = () => setshowModalAgregarHallazgo(false);
    const columns = useMemo(() => COLUMNS, []);
    const [data, setData] = React.useState([]);
    const [tabVencidos, setTabVencidos] = useState(false);
    const [tabPorVencer, setTabPorVencer] = useState(false);
    const [tabAlDia, setTabAlDia] = useState(false);
    const [tabCerrados, setTabCerrados] = useState(false);
    const [tabResueltos, setTabResueltos] = useState(false);
    const [key, setKey] = useState(usuario.perfilContexto.id === EnumPerfil.Administrador || usuario.perfilContexto.id === EnumPerfil.AsesoriaIF ? 'todos' : 'pendientes');
    const [esRenderizarTablaHallazgo, setEsRenderizarTablaHallazgo] = useState(false);
    const [cambioTab, setCambioTab] = useState(false);
		const { crearRegistroTDR } = useRegistroTDR();
    const [{ pageIndex, pageSize, sortBy }, setPagination] =
        React.useState({
            pageIndex: 0,
            pageSize: EnumTablaPaginacion.CantidadPaginacion,
            sortBy: []
        });
    const [{ pageIndexFiltro, pageSizeFiltro, sortByFiltro }, setPaginationFiltro] =
        React.useState({
            pageIndexFiltro: 0,
            pageSizeFiltro: EnumTablaPaginacion.CantidadPaginacion,
            sortByFiltro: []
        });
    async function postBuscadorHallazgosFiltro(values) {
        const resp = await BuscadorHallazgosFiltroService(values);
        if (resp) {
            if (resp.status === 200) {
                if (resp.data) {
                    if (values.datosBusqueda.exportacion) {
                        downloadCSV(resp.data, "Hallazgos", EnumModuloDesc.DescargaGestionHallazgos);
                    } else {
                        setPageCountFiltro(resp.data.cantidadPaginas);
                        setData(resp.data.listadoHallazgos);
                    }
                }
            } else {
                setData([]);
            }
        }
    }
    const handleShowVencidos = () => {
        setKey('vencidos');
        setTabVencidos(true);
        setTabPorVencer(false);
        setTabAlDia(false);
        setTabCerrados(false);
        setTabResueltos(false);
    };
    const handleShowPorVencer = () => {
        setKey('porVencer');
        setTabPorVencer(true);
        setTabVencidos(false);
        setTabAlDia(false);
        setTabCerrados(false);
        setTabResueltos(false);
    };
    const handleShowAlDia = () => {
        setKey('alDia');
        setTabAlDia(true);
        setTabPorVencer(false);
        setTabVencidos(false);
        setTabCerrados(false);
        setTabResueltos(false);
    };
    const handleShowCerrados = () => {
        setKey('cerrados');
        setTabCerrados(true);
        setTabVencidos(false);
        setTabPorVencer(false);
        setTabAlDia(false);
        setTabResueltos(false);
    };
    const handleShowResueltos = () => {
        setKey('resueltos');
        setTabResueltos(true);
        setTabCerrados(false);
        setTabVencidos(false);
        setTabPorVencer(false);
        setTabAlDia(false);
    };

    const history = useNavigate()

    useEffect(() => {
			const startTime = new Date();
				const fetchData = async () => {
					await Promise.all([
						ParametroObtenerPorId(EnumParametro.NumeroContrato),
						obtieneEstadoHallazgo(),
						obtenerListaTipoHallazgo(),
						getEstadisticasHallazgo(usuario.id, usuario.perfilContexto.id),
					]);
					const endTime = new Date();
					const tdrId = 32;
					crearRegistroTDR(startTime, endTime, usuario, tdrId);
				};

				fetchData();
        if (location.state) {
            if (location.state.hallazgoId) {
                handlerModal({ hallazgoId: location.state.hallazgoId });
            }
        }
        history(location.pathname, { replace: true }); //ESTO ES PARA LIMPIAR EL STATE DE LA NAVEGACIÓN
    }, []);

    async function postBuscadorHallazgos(values) {
			const startTime = new Date();
        const resp = await BuscadorHallazgosService(values);
        if (resp) {
            if (resp.status === 200) {
                if (resp.data) {
                    if (values.datosBusqueda.exportacion) {
												const fetchData = async () => {
													await Promise.all([
														downloadCSV(resp.data, "Hallazgos", EnumModuloDesc.DescargaGestionHallazgos)
													]);
													const endTime = new Date();
													const tdrId = 39;
													crearRegistroTDR(startTime, endTime, usuario, tdrId);
												};

												fetchData();
                    } else {
                        setPageCount(resp.data.cantidadPaginas);
                        setData(resp.data.listadoHallazgos);
                    }
                }
            } else if (resp.status === 206) {
                setPageCount(0);
                setData([]);
            }
        }
    }

    async function getEstadisticasHallazgo(id, idPerfilContexto) {
        const resp = await ObtenerEstadisticasHallazgoService(id, idPerfilContexto);
        if (resp) {
            if (resp.status === 200) {
                setHallazgo(resp.data);
                setChartFindings([resp.data.alDia, resp.data.porVencer, resp.data.vencidos, resp.data.resueltos]);
            }
        }
    }

    const renderizarListadoHallazgos = () => {
        handleCloseFiltros();
        setKey(usuario.perfilContexto.id === EnumPerfil.AsesoriaIF || usuario.perfilContexto.id === EnumPerfil.Administrador ? 'todos' : 'pendientes');
        setEsRenderizarTablaHallazgo(true);
				getEstadisticasHallazgo(usuario.id, usuario.perfilContexto.id);
    }

    const fetchData = React.useCallback(({ formulario, pageIndex, sortBy = [], exportacion = false }) => {
        formulario.paginacion.numeroPagina = pageIndex + 1;
        formulario.paginacion.ordenadoPor = sortBy.length > 0 ? sortBy[0].id : formulario.paginacion.ordenadoPor;
        formulario.paginacion.orderByAscending = sortBy.length > 0 ? (sortBy[0].desc ? false : true) : formulario.paginacion.orderByAscending;
        formulario.datosBusqueda.exportacion = exportacion;
        postBuscadorHallazgos(formulario);
    }, []);

    const fetchDataFiltro = React.useCallback(({ formulario, pageIndex, sortBy = [], exportacion = false }) => {
			const startTime = new Date();
        formulario.paginacion.numeroPagina = pageIndex + 1;
        formulario.paginacion.ordenadoPor = sortBy.length > 0 ? sortBy[0].id : formulario.paginacion.ordenadoPor;
        formulario.paginacion.orderByAscending = sortBy.length > 0 ? (sortBy[0].desc ? false : true) : formulario.paginacion.orderByAscending;
        formulario.datosBusqueda.exportacion = exportacion;
				const fetchData = async () => {
					await Promise.all([
						postBuscadorHallazgosFiltro(formulario)
					]);					
					if(formulario.datosBusqueda.idFiltroHallazgo == 1){
						const tdrId = 35;
						const endTime = new Date();
						crearRegistroTDR(startTime, endTime, usuario, tdrId);
					}
					else if(formulario.datosBusqueda.idFiltroHallazgo == 2){
						const tdrId = 34;
						const endTime = new Date();
						crearRegistroTDR(startTime, endTime, usuario, tdrId);
					}
					else if(formulario.datosBusqueda.idFiltroHallazgo == 3){
						const tdrId = 33;
						const endTime = new Date();
						crearRegistroTDR(startTime, endTime, usuario, tdrId);
					}
					else if(formulario.datosBusqueda.idFiltroHallazgo == 4){
						const tdrId = 37;
						const endTime = new Date();
						crearRegistroTDR(startTime, endTime, usuario, tdrId);
					}
					else if(formulario.datosBusqueda.idFiltroHallazgo == 5){
						const tdrId = 36;
						const endTime = new Date();
						crearRegistroTDR(startTime, endTime, usuario, tdrId);
					}
				};

				fetchData();
    }, []);

    function handlerModal(item) {
				const startTime = new Date();
				const fetchData = async () => {
					await Promise.all([
						setDatosModal(item),
						setShow(true),
					]);
					const endTime = new Date();
					const tdrId = 42;
					crearRegistroTDR(startTime, endTime, usuario, tdrId);
				};
				fetchData();
    }
    function handleShowIngresoHallazgo() {
        setshowModalAgregarHallazgo(true);
    }
    const handleClose = () => setShow(false);
    //const handleCloseFiltros = () => setTabVencidos(false);

    function handleCloseFiltros() {
        setTabVencidos(false);
        setTabPorVencer(false);
        setTabAlDia(false);
        setTabCerrados(false);
        setTabResueltos(false);
    }

    return (
        <div className="container-fluid">
            <div className="row mb-4">
                <div className="col-12 col-md-6 d-flex align-items-center">
                    <div className="breadcrumbs">
                        <ul>
                            <li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
                            <li><a href="">Gestión de Hallazgos</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-end">
                    {(usuario.perfilContexto.id === EnumPerfil.AsesoriaIF || usuario.perfilContexto.id === EnumPerfil.InspectorFiscal) &&
                        <Button design="btn-secondary btn--small px-3 py-2 rounded-2" onClick={() => handleShowIngresoHallazgo()}>
                            <i className="ico-plus me-2"></i>
                            <span>Agregar Hallazgo</span>
                        </Button>
                    }

                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="row h-100">
                        <div className="col-12 mb-3 col-sm-6 mb-md-3 col-lg-12 mb-lg-3 col-xl-6 mb-xl-3">
                            <Chip
                                label="Al día"
                                value={hallazgo.alDia}
                                icon="ico-play"
                                state="success"
                                design={tabAlDia ? 'handle text-start flex-row active' : 'handle text-start flex-row'}
                                handleClick={handleShowAlDia}
                            />
                        </div>
                        <div className="col-12 mb-3 col-sm-6 mb-md-3 col-lg-12 mb-lg-3 col-xl-6 mb-xl-3">
                            <Chip
                                label="Por vencer"
                                value={hallazgo.porVencer}
                                icon="ico-face"
                                state="warning"
                                design={tabPorVencer ? 'handle text-start flex-row active' : 'handle text-start flex-row'}
                                handleClick={handleShowPorVencer}
                            />
                        </div>
                        <div className="col-12 mb-3 col-sm-6 mb-md-3 col-lg-12 mb-lg-3 col-xl-6 mb-xl-3">
                            <Chip
                                label="Vencidos"
                                value={hallazgo.vencidos}
                                icon="ico-warning"
                                state="danger"
                                design={tabVencidos ? 'handle text-start flex-row active' : 'handle text-start flex-row'}
                                handleClick={handleShowVencidos}
                            />
                        </div>

                        <div className="col-12 mb-3 mb-md-3 col-sm-6 col-lg-12 col-xl-6 mb-lg-3">
                            <Chip
                                label="Resueltos"
                                value={hallazgo.resueltos}
                                icon="ico-check"
                                state="default"
                                design={tabResueltos ? 'handle text-start flex-row active' : 'handle text-start flex-row'}
                                handleClick={handleShowResueltos}
                            />
                        </div>
                        <div className="col-12 mb-3 mb-md-3 col-lg-12 mb-lg-0">
                            <Chip
                                label="Cerrados"
                                value={hallazgo.cerrados}
                                icon="ico-block"
                                state="info"
                                design={tabCerrados ? 'handle text-start flex-row active' : 'handle text-start flex-row'}
                                handleClick={handleShowCerrados}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <Card className="p-3">
                        <ChartsDonut dataChart={chartFindings} />
                    </Card>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12'>
                    <Card
                        className="p-4">
                        {data &&
                            <Tabs
                                defaultActiveKey="pendientes"
                                activeKey={key}
                                transition={false}
                                id="findings"
                                className="mb-3"
                                onSelect={(k) => { setKey(k); setCambioTab(true) }}
                            >
                                {!(usuario.perfilContexto.id === EnumPerfil.Administrador || usuario.perfilContexto.id === EnumPerfil.AsesoriaIF) &&
                                    <Tab eventKey="pendientes" title="Pendientes">
                                        {
                                            key === 'pendientes' &&
                                            <TablaHallazgo
                                                listadoEstado={listadoEstado}
                                                listadoTipoHallazgo={listadoTipoHallazgo}
                                                columns={columns}
                                                data={data}
                                                fetchData={fetchData}
                                                pageCount={pageCount}
                                                currentpage={pageIndex}
                                                pageSize={pageSize}
                                                sortBy={sortBy}
                                                setPagination={setPagination}
                                                handlerModal={handlerModal}
                                                handleCloseFiltros={handleCloseFiltros}
                                                tab={EnumFiltroTabBusquedaHallazgo.Pendientes}
                                                esRenderizar={esRenderizarTablaHallazgo}
                                                setEsRenderizarTablaHallazgo={setEsRenderizarTablaHallazgo}
                                                cambioTab={cambioTab}
                                                setCambioTab={setCambioTab}
                                            />
                                        }
                                    </Tab>}
                                <Tab eventKey="todos" title="Todos">
                                    {
                                        key === 'todos' &&
                                        <TablaHallazgo
                                            listadoEstado={listadoEstado}
                                            listadoTipoHallazgo={listadoTipoHallazgo}
                                            columns={columns}
                                            data={data}
                                            fetchData={fetchData}
                                            pageCount={pageCount}
                                            currentpage={pageIndex}
                                            pageSize={pageSize}
                                            sortBy={sortBy}
                                            setPagination={setPagination}
                                            handlerModal={handlerModal}
                                            handleCloseFiltros={handleCloseFiltros}
                                            tab={EnumFiltroTabBusquedaHallazgo.Todos}
                                            esRenderizar={esRenderizarTablaHallazgo}
                                            setEsRenderizarTablaHallazgo={setEsRenderizarTablaHallazgo}
                                            cambioTab={cambioTab}
                                            setCambioTab={setCambioTab}
                                        />
                                    }
                                </Tab>
                                {tabVencidos ?
                                    <Tab eventKey="vencidos" title="Vencidos">
                                        {
                                            key === 'vencidos' &&
                                            <TablaHallazgoFiltro
                                                columns={columns}
                                                data={data}
                                                fetchData={fetchDataFiltro}
                                                pageCount={pageCountFiltro}
                                                currentpage={pageIndexFiltro}
                                                pageSize={pageSizeFiltro}
                                                sortBy={sortByFiltro}
                                                setPagination={setPaginationFiltro}
                                                handlerModal={handlerModal}
                                                tipoFiltro={EnumHallazgoEstatusEstadisticas.Vencidos}
                                            />}
                                    </Tab>
                                    : null}
                                {tabPorVencer ?
                                    <Tab eventKey="porVencer" title="Por Vencer">
                                        {
                                            key === 'porVencer' &&
                                            <TablaHallazgoFiltro
                                                columns={columns}
                                                data={data}
                                                fetchData={fetchDataFiltro}
                                                pageCount={pageCountFiltro}
                                                currentpage={pageIndexFiltro}
                                                pageSize={pageSizeFiltro}
                                                sortBy={sortByFiltro}
                                                setPagination={setPaginationFiltro}
                                                handlerModal={handlerModal}
                                                tipoFiltro={EnumHallazgoEstatusEstadisticas.PorVencer}
                                            />}
                                    </Tab>
                                    : null}
                                {tabAlDia ?
                                    <Tab eventKey="alDia" title="Al Día">
                                        {
                                            key === 'alDia' &&
                                            <TablaHallazgoFiltro
                                                columns={columns}
                                                data={data}
                                                fetchData={fetchDataFiltro}
                                                pageCount={pageCountFiltro}
                                                currentpage={pageIndexFiltro}
                                                pageSize={pageSizeFiltro}
                                                sortBy={sortByFiltro}
                                                setPagination={setPaginationFiltro}
                                                handlerModal={handlerModal}
                                                tipoFiltro={EnumHallazgoEstatusEstadisticas.AlDia}
                                            />}
                                    </Tab>
                                    : null}
                                {tabCerrados ?
                                    <Tab eventKey="cerrados" title="Cerrados">
                                        {
                                            key === 'cerrados' &&
                                            <TablaHallazgoFiltro
                                                columns={columns}
                                                data={data}
                                                fetchData={fetchDataFiltro}
                                                pageCount={pageCountFiltro}
                                                currentpage={pageIndexFiltro}
                                                pageSize={pageSizeFiltro}
                                                sortBy={sortByFiltro}
                                                setPagination={setPaginationFiltro}
                                                handlerModal={handlerModal}
                                                tipoFiltro={EnumHallazgoEstatusEstadisticas.Cerrados}
                                            />}
                                    </Tab>
                                    : null}
                                {tabResueltos ?
                                    <Tab eventKey="resueltos" title="Resueltos">
                                        {
                                            key === 'resueltos' &&
                                            <TablaHallazgoFiltro
                                                columns={columns}
                                                data={data}
                                                fetchData={fetchDataFiltro}
                                                pageCount={pageCountFiltro}
                                                currentpage={pageIndexFiltro}
                                                pageSize={pageSizeFiltro}
                                                sortBy={sortByFiltro}
                                                setPagination={setPaginationFiltro}
                                                handlerModal={handlerModal}
                                                tipoFiltro={EnumHallazgoEstatusEstadisticas.Resueltos}
                                            />}
                                    </Tab>
                                    : null}

                            </Tabs>
                        }
                    </Card>
                </div>
            </div>
            <ModalDetalleHallazgo
                show={show}
                datosModal={datosModal}
                onHide={handleClose}
                renderizar={renderizarListadoHallazgos}
            />
            <ModalAgregarHallazgo
                show={showModalAgregarHallazgo}
                onHide={handleCloseIngresoHallazgo}
                renderizar={renderizarListadoHallazgos}
                numeroContrato={numeroContrato}
            />
        </div>
    )
}

export default HallazgoPage;
