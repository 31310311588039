import React, { useState, useRef, useEffect } from 'react';
import Select, { components } from 'react-select';
import { useField } from 'formik';

const CustomSelectComponents = {
  ValueContainer: ({ children, ...props }) => {
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;

    return (
      <components.ValueContainer {...props}>
        <div className={`${hasValue ? props.isMulti && nbValues > 1 ? 'ocultarItems' : 'seleccionItems' : ""}`}>{children}</div>
        {hasValue && props.isMulti && nbValues > 1 &&
          `${nbValues} Items seleccionados`
        }
      </components.ValueContainer>
    );
  },

  Option: (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            id={`checkbox-${props.name}${props.value}`}
            type="checkbox"
            className="form-check-input"
            checked={props.isSelected}
            onChange={() => null}
          />{' '}
          <label htmlFor={`checkbox-${props.name}${props.value}`} className="fs-6">{props.label}</label>
        </components.Option>
      </div>
    );
  },
};

const SelectCheckField = ({ label, ...props }) => {
  const selectRef = useRef(null);
  const { options, value, name, isMulti, disabled, helpers = '', fijo = null } = props;
  const [field, meta] = useField(props);
  
  const handleChange = (val) => {
    props.onChange(name, Array.isArray(val) ? ((val.length === 0) ? '' : val.map((item) => item.value)) : val.value);
  };

  const handleBlur = () => {
    props.onBlur(name, true);
  };

  const defaultValue = (options) => {
    return options ? options.filter(option => Array.isArray(field.value) ? field.value.indexOf(option.value) >= 0 : option.value === field.value) : '';
  };

  return (
    <div className="form-floating">
      <div ref={selectRef} className={`simple-select ${helpers} ${meta.touched && meta.error ? 'is-invalid' : ''} ${fijo === true || fijo === null ? 'isMultiple' : ''}`}>
        {label && (
          <label htmlFor={`field-${name}`}>{label}</label>
        )}
        <Select
          {...props}
          {...field}
          classNamePrefix="selector"
          inputId={`field-${name}`}
          name={name}
          options={options}
          value={defaultValue(options)}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          hideSelectedOptions={false}
          components={CustomSelectComponents}
          onChange={handleChange}
          onBlur={handleBlur}
          isClearable={false}
          allowSelectAll={true}
          placeholder="Seleccione"
          isDisabled={disabled}
          menuPosition="absolute"
          isSearchable={false}
          noOptionsMessage={() => 'Sin opciones'}
        />
      </div>
      {meta.touched && meta.error &&
        <small className="message">{meta.error}</small>
      }
    </div>
  );
};

export default SelectCheckField;
