import { useEffect, useState, useRef } from "react";
import ReactApexChart from "react-apexcharts";
 
const ChartsDonut = ({ dataChart, ...props }) => {
    const [series, setSeries] = useState(dataChart);
    const chartId = useRef(0);
 
    useEffect(() => {
        setSeries(dataChart);
        chartId.current++;
    }, [dataChart]);
 
    const options = {
        chart: {
            id: chartId.current,
            type: 'donut',
            height: 200,
        },
        colors: ['#49B461', '#FD8D0E', '#FC3D3D', '#7F9FB8'],
        legend: {
            position: 'left',
            fontFamily: 'Poppins, sans-serif',
            height: 150,
            offsetY: 40,
            markers: {
                width: 13,
                height: 13,
                radius: 4,
                offsetX: -8,
                offsetY: 2,
            },
            formatter: function (seriesName, opts) {
                if (opts.w.globals.series[opts.seriesIndex] === 0) {
                    return [seriesName, `<b style="color:#809FB8">${opts.w.globals.series[opts.seriesIndex]}%</b>`]
                }
                return [seriesName, `<b style="color:#809FB8">${parseFloat(opts.w.globals.seriesPercent[opts.seriesIndex]).toFixed(1)}%</b>`]
            },
        },
        stroke: {
            show: false,
        },
        fill: {
            opacity: 1,
        },
        states: {
            active: {
                filter: 'none'
            }
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    size: '75%',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '22px',
                            fontFamily: 'Poppins, sans-serif',
                            color: '#707070',
                            offsetY: 20,
                        },
                        value: {
                            show: true,
                            fontSize: '28px',
                            fontFamily: 'Poppins, sans-serif',
                            color: '#110F24',
                            offsetY: -16,
                        },
                        total: {
                            fontFamily: 'Poppins, sans-serif',
                            label: 'Hallazgos',
                            fontSize: '12px',
                            color: '#707070',
                            fontWeight: 400,
                            show: true,
                            showAlways: true,
                        }
                    }
                }
            }
        },
        labels: ['Al día', 'Por vencer', 'Vencidos', 'Resueltos'],
        title: {
            text: 'Total',
            style: {
                fontSize: '20px',
                fontWeight: 'bold',
                fontFamily: 'Poppins, sans-serif',
                color: '#262626'
            },
        },
        grid: {
            padding: {
                left: 0,
                right: 0
            }
        },
        responsive: [
            {
                breakpoint: 1250,
                options: {
                    chart: {
                        type: 'donut',
                    },
                    legend: {
                        position: 'bottom',
                        offsetY: 0,
                    },
                },
            }
        ],
    };
 
    return (
        <div id="chart" className="min-h-charts-mobile-400">
            <ReactApexChart
                options={options}
                series={series}
                height="100%"
                type="donut" />
        </div>
    )
};
 
export default ChartsDonut;