import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../../../Components/Button/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ModalAsignado = (props) => {
	const {...rest } = props;

	const Logout =  () => {
		window.location.replace("");
		}

	return (
		<Modal
			{...props}
			size="md"
			centered
			backdropClassName="backdrop-dark"
			className='modal--bg-gray'
			backdrop="static"
			keyboard="false"
		>
			<Modal.Header>
				<h5 className="modal-title">CDO Asignado</h5>
				<Button type="button" design="btn-outline-pewter-blue p-2 lh-1 fs-3" onClick={() => {Logout();}}><i className='ico-close'></i></Button>
			</Modal.Header>
			<Modal.Body>
			<div className='bg-white rounded p-3 mb-3'>
					<ul className='list list--def'>
						<li className='list__item'>
							<span>Trimestre</span>
							<strong>{rest.cdoasignado ? rest.cdoasignado.trimestre:""}</strong>
						</li>
						<li className='list__item'>
							<span>ISP</span>
							<strong>{rest.cdoasignado ? rest.cdoasignado.isp:""}</strong>
						</li>
						<li className='list__item'>
							<span>Rango CDO</span>
							<strong>{rest.cdoasignado ? rest.cdoasignado.rangoCDO:""}</strong>
						</li>
						<li className='list__item'>
							<span className='d-flex align-items-center gap-2'>
								Nota final
								<OverlayTrigger
									key='top'
									placement='top'
									overlay={
										<Tooltip id={`tooltip-top`}>
											<div className='text-start p-3 w-100'>
												<b className='d-flex mb-2'>Conducta diligente del Concesionario.</b>
												<span className='d-block mb-2'>Haber adoptado las medidas de mitigación o reparación necesarias respecto al desempeño operacional insuficiente.</span>
												<span>Desempeño operacional insuficiente reiterado.</span>
											</div>
										</Tooltip>
									}
								>
									<span className='ico ico-info-circle'></span>
								</OverlayTrigger>
							</span>
							<strong>{rest.cdoasignado ? rest.cdoasignado.notaFinal:""}</strong>
						</li>
						<li className='list__item ps-0 pe-0 mt-3'>
							<div className='bg-pewter-blue bg-opacity-25 d-flex justify-cotent-between align-items-center w-100 p-3 rounded'>
								<span>CDO</span>
								<strong className="ms-auto me-0">{rest.cdoasignado ? rest.cdoasignado.cdo:""}</strong>
							</div>
						</li>
					</ul>
					<div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4 mt-4">
						<Button type="button" design="btn-primary" onClick={() => {Logout();}}>Aceptar</Button>
					</div>
			</div>
			</Modal.Body>
		</Modal>
	)
}

export default ModalAsignado
