import { useEffect } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

const Galeria = (props) => {

	useEffect(() => {
		let lightbox = new PhotoSwipeLightbox({
			gallery: '#' + props.galleryID,
			children: 'a',
			pswpModule: () => import('photoswipe'),
		});
		lightbox.init();

		return () => {
			lightbox.destroy();
			lightbox = null;
		};
	}, []);

	return (
		<div className="galeria" id={props.galleryID}>
			{props.images.map((image, index) => (
				<div key={props.galleryID + "__" + index}>
					{index < 4 &&
						<a
							href={image.largeURL}
							data-pswp-width={image.width}
							data-pswp-height={image.height}
							key={props.galleryID + '-' + index}
							target="_blank"
							rel="noreferrer"
							className={index >= 4 ? 'd-none' : ''}
						>
							<img src={image.thumbnailURL} alt="" />
						</a>
					}
					{index === 4 ?
						<a
							href={props.images[0].largeURL}
							data-pswp-width={image.width}
							data-pswp-height={image.height}
							key={props.galleryID + '-' + props.images.length + 1}
							target="_blank"
							rel="noreferrer"
							className='all'
						>Ver más</a>
						: null}
				</div>
			))}
		</div>
	)
}

export default Galeria;
