import { useState } from "react";
import Pivote from "../../Configuracion/Components/Pivote/Pivote";

const InformesPages = () => {
	const [access] = useState([
		{
			id:0,
			link:"informes/nivel_cumplimiento",
			title:'Nivel de cumplimiento',
			text:'Descargue aquí los Informes relacionados con los niveles de cumplimiento de los indicadores en un período determinado. ',
			icon:"ico-progress",
			color:"#043087",
		},
		{
			id:1,
			link:"informes/estadistica_hallazgos",
			title:'Estadística Hallazgos',
			text:'Descargue aquí las Estadísticas de Hallazgos en sus diferentes estados. ',
			icon:"ico-metrics",
			color:"#7794AC",
		},
		{
			id:2,
			link:"informes/estadistica_Tdr",
			title:'Estadística TDR',
			text:'Descargue aquí las Estadísticas de TDR en sus diferentes estados. ',
			icon:"ico-alarm",
			color:"#7794AC",
		},
		{
			id:3,
			link:"informes/disponibilidad_sicns",
			title:'Disponibilidad SIC-NS',
			text:'Disponibilidad de los sistemas. ',
			icon:"ico-analytics",
			color:"#7794AC",
		},

	])
	return (
		<div className="container-fluid p-0">
			<div className="row">
				{	access.map( item =>
					<div key={item.id} className="col-12 col-md-6 col-lg-4 mb-4">
						<Pivote
							link={item.link}
							title={item.title}
							text={item.text}
							icon={item.icon}
							color={item.color}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default InformesPages;
