import React, { useCallback, useEffect, useState } from 'react'
import { ObtenerUsuarioPorId } from '../../Usuario/Services/ObtenerUsuario';
import { autenticarUsuario } from '../Services/AutenticacionService';
import { EnumModuloDesc } from "../../../Utils/Enumeradores/ComunEnum";
import { RegistraBitacoraDetalleService } from '../../../Services/RegistraBitacoraDetalleService';
import { useNavigate } from 'react-router-dom';
import { RegistraBitacoraMovimientoUsuario } from '../../../Services/RegistraBitacoraMovimientoUsuario';
import { CrearRegistroTDRService } from '../../../Services/CrearRegistroTDRService';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';
import { useComun } from "../../../Hooks/useComun";
import { EnumParametro } from "../../../Utils/Enumeradores/ComunEnum";
import { ParametroObtenerPorIdService } from '../../../Services/ParametroObtenerPorIdService';

const Context = React.createContext(null)

export function LoginContextProvider({ children }) {
	const [jwt, setJWT] = useState(
		() => window.sessionStorage.getItem('jwt')
	)
	const { crearRegistroTDR } = useRegistroTDR();
	const [user, setUser] = useState(() => JSON.parse(window.sessionStorage.getItem('usuario')));
	const [usuario, setUsuario] = useState(() => JSON.parse(window.sessionStorage.getItem('usuariomodulo')));
	const [Reemplazo, setReemplazo] = useState(() => JSON.parse(window.sessionStorage.getItem('Reemplazo')));
	const [IdUsuarioPrincipal, setIdUsuarioPrincipal] = useState(() => JSON.parse(window.sessionStorage.getItem('IdUsuarioPrincipal')));
	const [recuerdame, setRecuerdame] = useState(() => JSON.parse(window.sessionStorage.getItem('recuerdame')));
	const [bitacoraDetalle, setBitacoraDetalle] = useState(() => JSON.parse(window.sessionStorage.getItem('bitacoraDetalle')));
	const navigate = useNavigate();
	//const { obtenerIpTdr, ParametroObtenerPorId } = useComun();


	async function Autenticar(usuario, clave, recuerdame, callback) {
		const startTime = new Date();
		const resp = await autenticarUsuario(usuario, clave);
		if (resp) {
			if (resp.status === 200) {
				if (recuerdame) {
					window.sessionStorage.setItem('recuerdame', JSON.stringify({ nombre: resp.data.usuario.nombres + " " + resp.data.usuario.apellidoPaterno + " " + resp.data.usuario.apellidoMaterno, rut: resp.data.usuario.rut }));
					setRecuerdame({ nombre: resp.data.usuario.nombres + " " + resp.data.usuario.apellidoPaterno + " " + resp.data.usuario.apellidoMaterno, rut: resp.data.usuario.rut });
				}
				resp.data.usuario.listaRespaldos = resp.data.listaRespaldos;
				window.sessionStorage.setItem('usuario', JSON.stringify(resp.data.usuario));
				window.sessionStorage.setItem('jwt', resp.data.tokenAutenticacion);
				setJWT(resp.data.tokenAutenticacion);
				setUser(resp.data.usuario);
				await postObtenerIp(EnumParametro.IpTdr);
				if (resp.data.usuario.listaPerfil.length === 1 && resp.data.listaRespaldos.length === 0) {
					const values = {
						idUsuario: resp.data.usuario.id,
						idPerfil: resp.data.usuario.listaPerfil[0].id
					};
					RegistroRemplazo(false, 0);
					postObtenerUsuario(values, callback);
					
				}
				
				else {
					callback();
				}
			}
		}
		const tdrId = 1;
		const endTime = new Date();
		crearRegistroTDR(startTime, endTime, resp.data.usuario, tdrId);
	}

	async function postObtenerUsuario(values, callback) {
		const resp = await ObtenerUsuarioPorId(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					setUsuario(resp.data);
					window.sessionStorage.setItem('usuariomodulo', JSON.stringify(resp.data));
					postRegistraBitacoraDetalle({ idBitacora: 2, idEvento: null, idUsuario: values.idUsuario, perfilId: values.idPerfil }, callback);
				}
			}
		}
	}

	async function postObtenerIp(param) {
		const resp = await ParametroObtenerPorIdService(param);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					window.sessionStorage.setItem('ip', JSON.stringify(resp.data));
				}
			}
		}
	}

	async function postRegistraBitacoraDetalle(values, callback) {
		const resp = await RegistraBitacoraDetalleService(values);
		if (resp) {
			if (resp.status === 200) {
				setBitacoraDetalle(resp.data);
				window.sessionStorage.setItem('bitacoraDetalle', JSON.stringify(resp.data));
				registraBitacoraMovimientoUsuario({ idUsuario: values.idUsuario, idMovimientoSistema: EnumModuloDesc.IngresoSistema, idBitacoraDetalle: resp.data }, callback);
			}
		}
	}

	async function postRegistraBitacoraMovimientoUsuario(values, callback) {
		await RegistraBitacoraMovimientoUsuario(values);
		callback();
	}

	const registraBitacoraMovimientoUsuario = useCallback(
		function (values, callback) {
			postRegistraBitacoraMovimientoUsuario(values, callback);
		},
		[]
	);

	let RegistroRemplazo = (esRemplazo, IdUsuarioPrincipal) => {
		setReemplazo(esRemplazo);
		setIdUsuarioPrincipal(IdUsuarioPrincipal);
		window.sessionStorage.setItem('Reemplazo', esRemplazo);
		window.sessionStorage.setItem('IdUsuarioPrincipal', IdUsuarioPrincipal);
	}

	let getUsuario = (idUsuario, idPerfil, callback) => {
		const values = {
			idUsuario: idUsuario,
			idPerfil: idPerfil
		};
		postObtenerUsuario(values, callback);
	};

	let signin = (newUser, clave, recuerdame, callback) => {
		const fetchData = async () => {
			await Promise.all([
				Autenticar(newUser, clave, recuerdame, callback)
			]);
		};
		fetchData();

	};

	let RemoveSessionStorage = () => {
		window.sessionStorage.removeItem('usuario');
		window.sessionStorage.removeItem('usuariomodulo');
		window.sessionStorage.removeItem('jwt');
		window.sessionStorage.removeItem('Reemplazo');
		window.sessionStorage.removeItem('IdUsuarioPrincipal');
		window.sessionStorage.removeItem('bitacoraDetalle');
		window.sessionStorage.removeItem('ip');
		setJWT('');
		setUser(null);
		setUsuario(null);
		setReemplazo(null);
		setIdUsuarioPrincipal(null);
		setBitacoraDetalle(null);
	};

	let signout = () => {
		if (usuario === null) {
			window.location.reload();
		}
		else {
			RemoveSessionStorage();
			navigate('/');
		}
	}

	let signoutRegistroBD = () => { registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.SalidaSistema, idBitacoraDetalle: bitacoraDetalle }, () => { RemoveSessionStorage(); navigate('/'); }) };

	const ChangeImagenAvatarUsuario = useCallback(
		function (nuevaRutaImagen) {
			setUser({ ...user, rutaImagen: nuevaRutaImagen });
			setUsuario({ ...usuario, rutaImagen: nuevaRutaImagen });
		},
		[]
	);

	useEffect(() => {
		if (user !== null && usuario !== null) {
			window.sessionStorage.setItem('usuario', JSON.stringify(user));
			window.sessionStorage.setItem('usuariomodulo', JSON.stringify(usuario));
		}
	}, [user, usuario]);

	function LimpiarRecuerdame() {
		setRecuerdame({});
		window.sessionStorage.removeItem('recuerdame');
	}

	return <Context.Provider value={{
		user
		, jwt
		, usuario
		, recuerdame
		, bitacoraDetalle
		, getUsuario
		, setJWT
		, setUser
		, RegistroRemplazo
		, setUsuario
		, signin
		, signout
		, signoutRegistroBD
		, ChangeImagenAvatarUsuario
		, LimpiarRecuerdame
		, registraBitacoraMovimientoUsuario
	}}>
		{children}
	</Context.Provider>
}

export default Context
