import { useNavigate } from "react-router-dom";

const Progreso = ({...props}) => {
	const {title, percent, mes, anio} = props;
	const navigate = useNavigate();
	const accessTo = (link) => {
		navigate(`/${link}`, { replace: true });
	}

	return (
		<div className="access access--gradient" onClick={()=> accessTo(props.link)}>
			<div className="access__info flex-row justify-content-between w-100 flex-wrap gap-2 flex-xxl-nowrap">
				<div className="d-flex flex-column">
					<span className="fs-6 text-wrap">{title}</span>
					<small className="fs-7">{`${mes} ${anio}`}</small>
				</div>
				<strong className="fs-3">{percent}%</strong>
			</div>
			<div className="access__progress">
				<div className="meter">
					<span className="meter__bar" style={{width: percent + '%'}} data-percentage={percent}></span>
				</div>
			</div>
		</div>
	)
}
export default Progreso;
