import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ObtenerListadoUsuarioConPerfilAreaNegocio = async (descargar, idUsuario) => {
    try {
        const resultado = await instance.Backend.post("/Usuario/ObtenerListadoUsuarioConPerfilAreaNegocio",
        {
            exportacion: descargar.exportacion,
            idUsuario: idUsuario
        }, customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export {ObtenerListadoUsuarioConPerfilAreaNegocio};