import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ObtenerTiposNotificacionesService = async () => {
    try {
        const resultado = await instance.Backend.post("/Notificacion/ObtenerTipoNotificaciones",
            { }, customConfig
        );
        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { ObtenerTiposNotificacionesService };