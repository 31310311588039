import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const BuscarIndiceServicioPrestado = async (values) => {
    try {
				const resultado = await instance.Backend.post("/Indicador/IndiceServicioPrestado",values, customConfig);

			return resultado


    } catch (error) {
        console.log(error);
    }
}
export { BuscarIndiceServicioPrestado };
