import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import avatar from '../../Assets/images/avatar.jpg';
import iconFoto from '../../Assets/images/icon-photo.svg';
import useLogin from '../../Features/Autenticacion/Hooks/useLogin';
import { useToast } from '../../Hooks/useToast';
import { useUsuario } from '../../Hooks/useUsuario';
import { validarAvatar } from '../../Utils/Validations/Validations';

const ModalPerfil = (props) => {
	const inputFileRef = useRef(null);
	const { user, usuario } = useLogin();
	const { guardarImagen } = useUsuario();
	const addToast = useToast();
	const perfilLogeado = usuario ? usuario.perfilContexto : "";
	const infromacionAdicionalUsuario = user ? user.propiedadesAdicionales ? user.propiedadesAdicionales.fechaUltimoInicioSesion : "" : "";
	const listaUsuarioRespaldo = usuario.listaUsuarioRespaldo ? usuario.listaUsuarioRespaldo.filter(x => x.estado) : [];
	const onFileChange = (e) => {
		const respuesta = validarAvatar(e.target.files[0]);
		if (respuesta === "") {
			let formData = new FormData();
			formData.append('UsuarioId', user.id);
			formData.append('Archivo', e.target.files[0]);
			guardarImagen(formData);
		} else {
			addToast({ mostrar: true, mensaje: respuesta, titulo: 'Información', tipo: 'Info' });
		}

	}
	const handleInputFile = () => {
		inputFileRef.current.click();
	}
	return (
		<Modal
			{...props}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<div className="profile">
					<button className='profile__btn' onClick={handleInputFile}>
						<span className='ico-photo'>
							<img src={iconFoto} alt="" />
						</span>
						<span className={`avatar ${usuario.rutaImagen === "" || usuario.rutaImagen === null ? 'avatarIniciales border overflow-hidden' : ''}`}>
							{
								usuario.rutaImagen === "" || usuario.rutaImagen === null ?
									usuario.nombres.charAt(0).toUpperCase() + usuario.apellidoPaterno.charAt(0).toUpperCase() :
									<img src={usuario.rutaImagen + "?t=" + new Date().getTime()} alt="" /> /* "?t="+ new Date().getTime() prevención de caché a la URL */
							}
						</span>

					</button>
					<input className='d-none' type='file' ref={inputFileRef} onChange={onFileChange} />
					<div className="profile__ide">
						<b>{usuario.nombres + " " + usuario.apellidoPaterno}</b>
						{perfilLogeado ? <span>{perfilLogeado.descripcion}</span> : <span>Superadmin</span>}
					</div>
				</div>
			</Modal.Header>
			<Modal.Body>
				<dl className='definition'>
					<span>
						<dt>Correo electrónico</dt>
						<dd>{usuario.email}</dd>
					</span>
					<span>
						<dt>Perfil</dt>
						<dd>
							<ul className='text-nowrap'>
								{usuario.listaPerfil ? usuario.listaPerfil.map((resp, i) => {
									return (
										<li key={i}>{resp.descripcion}</li>
									)
								}) : []}
							</ul>
						</dd>
					</span>
					<span>
						<dt>Área</dt>
						<dd>
							<ul className='text-nowrap'>
								{usuario.listaAreaNegocio ? usuario.listaAreaNegocio.map((resp, i) => {
									return (
										<li key={i}>{resp.descripcion}</li>
									)
								}) : []}
							</ul>
						</dd>
					</span>
					<span>
						<dt>Ingreso</dt>
						<dd>{moment(usuario.fechaCreacion).format('DD-MM-YYYY')}</dd>
					</span>
					<span>
						<dt>Último Ingreso</dt>
						{infromacionAdicionalUsuario &&
							<dd>{moment(infromacionAdicionalUsuario).format('DD-MM-YYYY HH:mm:ss')}</dd>
						}

					</span>
					<span>
						<dt>Usuario(s) Respaldo</dt>
						{listaUsuarioRespaldo.length > 0 ?
							<dd>
								<ul className='text-nowrap'>
									{
										listaUsuarioRespaldo.map((resp, i) => {
											return (
												<div key={i+"_div"} className={i > 0 ? "mt-3" : ""} role="alert">
													<li key={i+"_nombres"}>{resp.nombres + " " + resp.apellidoPaterno + " " + resp.apellidoMaterno}</li>
													<li key={i+"_fechas"}>{"Desde: " + moment(resp.fechaInicio).format('DD-MM-YYYY') + " Hasta: " + moment(resp.fechaFin).format('DD-MM-YYYY')}</li>
												</div>
											)
										})
									}
								</ul>
							</dd>
							:
							<dd>No tiene usuario respaldo activo</dd>
						}

					</span>
				</dl>
			</Modal.Body>
		</Modal>
	)
}

export default ModalPerfil;
