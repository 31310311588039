import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import { useColumns } from '../Utils/Columns';
import SearchField from '../../../Components/SearchField/SearchField';
import Button from '../../../Components/Button/Button';
import Exports from '../../../Components/Exports/Exports';
import TablaBackup from '../Components/TablaBackup';
import ModalBackup from '../Components/ModalBackup';
import { EnumPathRutas, EnumTablaPaginacion } from '../../../Utils/Enumeradores/ComunEnum'
import { useBackup } from '../Hooks/useBackup';
import { Link } from 'react-router-dom';
import PaginationComponent from '../../../Components/PaginationTableServer/PaginationTableServer';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';


const AsignacionBackupPage = () => {
	const { listadoUsuario, getUsuariosBackup } = useBackup();
	useEffect(() => {
		getUsuariosBackup({ exportacion: false });
	}, [])
	const renderizarListadoUsuario = () => {
		getUsuariosBackup({ exportacion: false });
	}

	const { COLUMNS } = useColumns(renderizarListadoUsuario);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => listadoUsuario, [listadoUsuario]);
	const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: EnumTablaPaginacion.CantidadPaginacion } }, useGlobalFilter, useSortBy, usePagination);
	const { state, setGlobalFilter } = tableInstance;
	const { globalFilter } = state;
	const [editUser, setEditUser] = useState({});
	const [modalIsCrear, setModalIsCrear] = useState(true);
	const { user, usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();

	function handleShow(isCrear) {	
		if(isCrear === true ){
			const startTime = new Date();
			const fetchData = async () => {
				await Promise.all([
					setShow(true),
					setModalIsCrear(isCrear),
				]);
				const endTime = new Date();
				const tdrId = 86;
				crearRegistroTDR(startTime, endTime, usuario, tdrId);
			};

			fetchData();

		}
		else if(isCrear === false){
			const startTime = new Date();
			const fetchData = async () => {
				await Promise.all([
					setShow(true),
					setModalIsCrear(isCrear),
				]);
				const endTime = new Date();
				const tdrId = 88;
				crearRegistroTDR(startTime, endTime, usuario, tdrId);
			};

			fetchData();
		}
	}

	function handlerModal(user) {

				setEditUser(user);
				handleShow(false);

	}

	const descargar = (tipo) => {
		getUsuariosBackup({ exportacion: true });
	};
	const [{ pageIndex, pageSize, sortBy }, setPagination] =
	React.useState({
		pageIndex: 0,
		pageSize: EnumTablaPaginacion.CantidadPaginacion,
		sortBy: []
	});

	return (
		<div className="container-fluid">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
							<li><Link to={EnumPathRutas.Configuracion}>Configuración</Link></li>
							<li><a href="#">Crear Reemplazo</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="bg-white rounded p-4">
						<div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-5 gap-3">
							<SearchField
								className="order-1 order-lg-0"
								filter={globalFilter}
								setFilter={setGlobalFilter}
							/>
							<div className="d-flex order-0 order-lg-1 gap-3">
								<Button design="btn-primary px-4 me-0 ms-auto" onClick={() => handleShow(true)}>
									<i className="ico-user-next me-2"></i>
									<span>Crear reemplazo</span>
								</Button>
								<div className="exports">
									<Exports
										descargar={(tipo) => descargar(tipo)}
									/>
								</div>
							</div>
						</div>
						<div className="table-responsive">
							<TablaBackup
								tableInstance={tableInstance}
								handlerModal={handlerModal}
							/>
						</div>
						<PaginationComponent
							pagesCountServer={tableInstance.pageCount}
							currentPage={tableInstance.state.pageIndex}
							setCurrentPage={tableInstance.gotoPage}
							alwaysShown={false}
							setPagination={setPagination}
						/>
					</div>
				</div>
			</div>
			<ModalBackup
				show={show}
				onHide={handleClose}
				isCrear={modalIsCrear}
				editUser={editUser}
				renderizar={renderizarListadoUsuario}
			/>
		</div>
	)
}
export default AsignacionBackupPage;
