
import { useContext } from "react";
import Context from "../Context/LoginContext";
export default function useLogin() {
    const {
      user
    , jwt
    , usuario
    , recuerdame
    , bitacoraDetalle
    , getUsuario
    , setJWT
    , setUser
    , RegistroRemplazo
    , setUsuario
    , signin
    , signout
    , signoutRegistroBD
    , ChangeImagenAvatarUsuario
    , LimpiarRecuerdame
    , registraBitacoraMovimientoUsuario
    } = useContext(Context);
    return {
      user
      , jwt
      , usuario
      , recuerdame
      , bitacoraDetalle
      , getUsuario
      , setJWT
      , setUser
      , RegistroRemplazo
      , setUsuario
      , signin
      , signout
      , signoutRegistroBD
      , ChangeImagenAvatarUsuario
      , LimpiarRecuerdame
      , registraBitacoraMovimientoUsuario
    }
}
