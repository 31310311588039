import React, { useMemo } from 'react';
import Card from "../../../Components/Card/Card";
import { ColumnasTabla } from '../Utils/ColumnasTabla';
import TablaAlarmas from '../Components/TablaAlarmas';
import { BuscadorAlarmasService } from '../Services/BuscadorAlarmasService';
import { EnumModuloDesc, EnumPathRutas, EnumTablaPaginacion } from '../../../Utils/Enumeradores/ComunEnum';
import { useComun } from '../../../Hooks/useComun';
import { Link } from 'react-router-dom';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';
import useLogin from '../../Autenticacion/Hooks/useLogin';

const AlarmasPage = () => {
	const { downloadCSV } = useComun();
	const { user, usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const columns = useMemo(() => ColumnasTabla, [ColumnasTabla]);
	const [data, setData] = React.useState([]);
	const [pageCount, setPageCount] = React.useState(0);
	const [{ pageIndex, pageSize, sortBy }, setPagination] =
		React.useState({
			pageIndex: 0,
			pageSize: EnumTablaPaginacion.CantidadPaginacion,
			sortBy: []
		});

	async function postObtenerListadoTablaAlarmas(values) {
		const startTime = new Date();
		const resp = await BuscadorAlarmasService(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					if (values.datosBusqueda.exportacion) {
						const fetchData = async () => {
							await Promise.all([
								downloadCSV(resp.data, "Alarmas", EnumModuloDesc.DescargaAlarmas)
							]);
							const endTime = new Date();
							const tdrId = 48;
							crearRegistroTDR(startTime, endTime, usuario, tdrId);
						};
						fetchData();
					} else {
						setPageCount(resp.data.cantidadPaginas);
						setData(resp.data.listadoAlarmas);
					}
				}
			} else if (resp.status === 206) {
				setPageCount(0);
				setData([]);
			}
		}
	}

	const fetchData = React.useCallback(({ formulario, pageIndex, sortBy = [], exportacion = false }) => {
		const startTime = new Date();
		formulario.paginacion.numeroPagina = pageIndex + 1;
		formulario.paginacion.ordenadoPor = sortBy.length > 0 ? sortBy[0].id : formulario.paginacion.ordenadoPor;
		formulario.paginacion.orderByAscending = sortBy.length > 0 ? (sortBy[0].desc ? false : true) : formulario.paginacion.orderByAscending;
		formulario.datosBusqueda.exportacion = exportacion;
		const fetchData = async () => {
			await Promise.all([
				postObtenerListadoTablaAlarmas(formulario)
			]);
			const endTime = new Date();
			const tdrId = 46;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
	}, [])

	return (
		<div className="container-fluid">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li><Link to={EnumPathRutas.Inicio}>Inicio</Link></li>
							<li><a href="#">Alarmas</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<Card className="p-4">
						<TablaAlarmas
							columns={columns}
							data={data}
							fetchData={fetchData}
							pageCount={pageCount}
							currentpage={pageIndex}
							pageSize={pageSize}
							sortBy={sortBy}
							setPagination={setPagination}
						/>
					</Card>
				</div>
			</div>
		</div>
	)
}
export default AlarmasPage;
