import { useState, useContext, useEffect } from 'react';
import { AlertasContext } from '../Context/ContextAlertas';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';
import useLogin from '../../Autenticacion/Hooks/useLogin';

const IndicadorSimple = (props) => {
	const { crearRegistroTDR } = useRegistroTDR();
	const {usuario} = useLogin();
	const indicador = props.item;
	const grupoPerteneciente = props.grupo;
	const [toggle, setToggle] = useState(true);
	const [isActive, setStatus] = useState(indicador.detalle.estadoAlertaPrevia);
	const { toggleModal, getDataIndicator,toggleModalConfirmar } = useContext(AlertasContext);
	const handleModalConfirmar =  (data, esNivelServicio, grupo) => {
		getDataIndicator(data, esNivelServicio, grupo);
		return toggleModalConfirmar(true);
	}
	useEffect(() => {
		setStatus(indicador.detalle.estadoAlertaPrevia)
	}, [indicador]);
	const HandleExpand = (action) => {
		setToggle(action)
	}

	const handleModal = (data, esNivelServicio, grupo) => {

		const startTime = new Date();
		getDataIndicator(data, esNivelServicio, grupo);

			setTimeout(() => {
				const endTime = new Date();
				const tdrId = 92;
				crearRegistroTDR(startTime, endTime, usuario, tdrId);
			}, 0);
		return toggleModal(true);
	}
	return (
		<>
			<tr className="skeleton-level-5">
				<td>
					<div className="d-flex align-items-center gap-2">
						<button className={toggle ? 'btn-collapse rotate' : 'btn-collapse'} onClick={() => HandleExpand(!toggle)}>
							<i className="ico-chevron-down"></i>
						</button>
						<i className="indicator ico-enter"></i>
						<span className="badge badge--monochromatic">{indicador.detalle.sigla}</span>
						<span className="name">{indicador.detalle.descripcion}</span>
					</div>
				</td>
				<td colSpan="2">
					<span className={`badge badge--monochromatic`}>{`${indicador.detalle.porcentajeAlertaPrevia}%`}</span>
				</td>
				<td>
					<div className="form-check form-switch d-flex justify-content-center align-items-center p-0">
						<input
							className="form-check-input mx-0"
							type="checkbox"
							role="switch"
							id={`auth-${indicador.detalle.sigla}`}
							checked={isActive}
							onChange={() => handleModalConfirmar(indicador.detalle, true, grupoPerteneciente)}
						/>
					</div>
				</td>
				<td>
					<button className="link link--border text-pewter-blue fs-5" onClick={() => handleModal(indicador.detalle, true, grupoPerteneciente)}>
						<i className="ico-doc-edit"></i>
					</button>
				</td>
			</tr>

			{indicador.detalle.indicadores ?

				<tr className='border-top'>
					<td colSpan='5' className="p-0">
						<div className={toggle ? 'children expanded' : 'children'}>
							<table>
								<tbody>
									{indicador.detalle.indicadores.map((item, index) =>
										<tr key={indicador.detalle.sigla + index + "_ind"} className="skeleton-level-7 border-bottom">
											<td>
												<div className="d-flex align-items-center gap-2">
													<i className="indicator ico-enter"></i>
													<span className="badge badge--monochromatic">{item.sigla}</span>
													<span className="name">{item.descripcion}</span>
												</div>
											</td>
											<td colSpan="2">
												<span className={`badge badge--monochromatic`}>{`${item.porcentajeAlertaPrevia}%`}</span>
											</td>
											<td>
												<div className="form-check form-switch d-flex justify-content-center align-items-center p-0">
													<input
														className="form-check-input mx-0"
														type="checkbox"
														role="switch"
														id={`auth-${item.indicador_Id}`}
														checked={item.estadoAlertaPrevia}
														onChange={() => handleModalConfirmar(item, false, indicador.detalle.indicadores)}
														/>
												</div>
											</td>
											<td className="">
												<button className="link link--border text-pewter-blue fs-5" onClick={() => handleModal(item, false, indicador.detalle.indicadores)}>
													<i className="ico-doc-edit"></i>
												</button>


											</td>

										</tr>

									)}
								</tbody>
							</table>
						</div>
					</td>
				</tr>
				: null
			}

		</>
	)
}
export default IndicadorSimple;
