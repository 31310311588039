import React, { useMemo, useState, useEffect } from 'react';
import Card from "../../../Components/Card/Card";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TablaExigencias from '../Components/TablaExigencias';
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import { useColumns } from '../Utils/Columns';
import ModalEdicionExigencia from '../Components/ModalEdicionExigencia';
import { EnumPathRutas, EnumTablaPaginacion } from '../../../Utils/Enumeradores/ComunEnum'
import { useExigencia } from '../Hooks/useExigencia';
import { Link, useNavigate } from 'react-router-dom';
import ModalEdicionVia from '../Components/ModalEdicionVia';
import PaginationComponent from '../../../Components/PaginationTableServer/PaginationTableServer';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';
const AdministracionDeExigenciasPage = () => {
	const { listadoExigencia, getListadoExigencia } = useExigencia();
	const [listado, setListado] = useState([]);
	const [key, setKey] = useState("PAV");
	const navigate = useNavigate();

	useEffect(() => {
		getListadoExigencia();
	}, [])
	const renderizarListadoExigencia = () => {
		getListadoExigencia();
	}
	useEffect(() => {
		if (listadoExigencia.length > 0) {
			let pav = listadoExigencia.filter(y => y.sigla === key);
			const { listaParametrosIndicador } = pav[0];
			const listaAux = listaParametrosIndicador.map(x => {
				return {
					descripcionParametro: x.descripcionParametro,
					descripcionUnidadMedida: x.descripcionUnidadMedida,
					estado: x.estado,
					fiaFim: x.fiaFim,
					parametroIndicadorExigenciaId: x.parametroIndicadorExigenciaId,
					reglaTO: x.reglaTO,
					unidadMedidaId: x.unidadMedidaId,
					valor1: x.valor1,
					valor2: x.valor2,
					tab: key
				};
			});
			setListado(listaAux);

		}
	}, [key, listadoExigencia])

	useEffect(() => {
		listado.map(resp => {
			if (resp.descripcionParametro.includes('\n')) {
				resp.descripcionParametro = resp.descripcionParametro.replaceAll('\n', '<br />');
			}
		});
	}, [listado])
	const { COLUMNS } = useColumns({ renderizar: renderizarListadoExigencia });
	const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => listado, [listado]);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const { user, usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();

	const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: EnumTablaPaginacion.CantidadPaginacion } }, useGlobalFilter, useSortBy, usePagination);
	const [exigencia, setEditExigencia] = useState({});

	const handleShow = (state) => {
		setShow(state);
	}
	const handlerModal = (exigencia) => {
		const startTime = new Date();
		setEditExigencia(exigencia);
		if (!exigencia.estado) {
			handleShow(false);
		} else {
			const fetchData = async () => {
				await Promise.all([
					handleShow(true),
				]);
				const endTime = new Date();
				const tdrId = 96;
				crearRegistroTDR(startTime, endTime, usuario, tdrId);
			};

			fetchData();
		}

	}

	const [showModalVia, setShowModalVia] = useState(false);
	const handleCloseVia = () => setShowModalVia(false);

	const handlerModalVia = (exigencia) => {
		setEditExigencia(exigencia);
		if (!exigencia.estado) {
			handleShow(false);
		} else {
			handleShow(true);
		}
	}

	const IrAFormula = (sigla) => {
		localStorage.setItem('sigla', sigla);
		navigate(EnumPathRutas.Datamart)
	}
	const [{ pageIndex, pageSize, sortBy }, setPagination] =
        React.useState({
            pageIndex: 0,
            pageSize: EnumTablaPaginacion.CantidadPaginacion,
            sortBy: []
        });

	return (
		<div className="container-fluid">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
							<li><Link to={EnumPathRutas.Configuracion}>Configuración</Link></li>
							<li><a href="#">Administración de exigencias</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<Card className="p-4">
						<Tabs
							defaultActiveKey="PAV"
							activeKey={key}
							transition={false}
							id="exigencias"
							className="mb-3 tab-scrolling"

							onSelect={(k) => setKey(k)}
						>
							{listadoExigencia.map(tab => (
								<Tab key={tab.indicadorId} eventKey={tab.sigla} title={tab.sigla}>
									<div className='py-2 d-flex justify-content-end'>
										<button className='link link-primary gap-2 d-flex align-items-center' onClick={() => { IrAFormula(tab.sigla) }}>
											<span>Ver fórmula {tab.sigla}</span>
											<i className='ico-info-circle'></i>
										</button>
									</div>
									<TablaExigencias
										tableInstance={tableInstance}
										handlerModal={handlerModal}
										handlerModalVia={handlerModalVia}
										valorTab={key}
									/>
									<PaginationComponent
										pagesCountServer={tableInstance.pageCount}
										currentPage={tableInstance.state.pageIndex}
										setCurrentPage={tableInstance.gotoPage}
										alwaysShown={false}
										setPagination={setPagination}
									/>
								</Tab>

							))}
						</Tabs>
					</Card>
				</div>
			</div>
			<ModalEdicionExigencia
				valorTab={key}
				show={show}
				onHide={handleClose}
				exigencia={exigencia}
				renderizar={renderizarListadoExigencia}
				readOnly={!exigencia.estado}
			/>
			<ModalEdicionVia
				valorTab={key}
				show={showModalVia}
				onHide={handleCloseVia}
				exigencia={exigencia}
				renderizar={renderizarListadoExigencia}
			/>
		</div>
	)
}
export default AdministracionDeExigenciasPage;
