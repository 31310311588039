import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const DescargaListaRegistrosTdrService = async (values) => {
    try {
        const resultado = await instance.Backend.post("/Informe/DescargaListaRegistrosTdr",
            values, customConfig
        );
        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { DescargaListaRegistrosTdrService };
