import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ModificarListaAlarmaService = async (values) => {
    try {
        const resultado = await instance.Backend.post("/Alarma/ModificarEmailAlarmaLista",
         		values, customConfig
				);
        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { ModificarListaAlarmaService };
