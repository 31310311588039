import instance, { customConfig } from "../Utils/Interceptors/Interceptor";

const BusquedaIpUsuarioService = async () => {
    try {
        const resultado = await instance.Backend.get("/Parametro/BusquedaIpUsuario", customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export { BusquedaIpUsuarioService };
