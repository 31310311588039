import moment from "moment";
export const COLUMNS = [
	{
		Header: 'Descripción',
		accessor: 'descripcion',
		className:'text-bold',
	},
	{
		Header: 'Valor / U. Medida',
		accessor: 'valor',
		Cell: ({ row }) =>
			row.original.valor + " / " + row.original.unidadMedida
	},
	{
		Header: 'Fecha',
		accessor: 'fechaHoraCreacion',
		Cell: ({ row }) =>
			moment(row.original.fechaHoraCreacion).format('DD-MM-YYYY HH:mm:ss')

	},
	{
		Header: 'Estado',
		accessor: 'estadoEvento',
    Cell : ({row}) => <span
			className={`point mx-auto
				${row.original.estadoEvento ? 'bg-success' : 'bg-danger'}
			`}
    >
    </span>
	}
];
