import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";
const ObtenerListaAnos = async (idUsuario) => {
    try {
        const resultado = await instance.Backend.post("/Indicador/ListaAnos",
        {
            IdUsuario: idUsuario
        }, customConfig);

        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { ObtenerListaAnos };
