
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom'
import useLogin from '../Hooks/useLogin';

const useAuth = () => {
  const { user, usuario, jwt } = useLogin();
  if (user && usuario) {
    return true
  } else {
    return false
  }
}

const ValidateLogin = (props) => {

  const auth = useAuth()

  return auth ? <Outlet /> : <Navigate to="/" />
}

export default ValidateLogin;
