import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ObtenerListadoPerfilAreaNegocio = async (idUsuario) => {
    try {
        const resultado = await instance.Backend.post("/Usuario/ObtenerPerfilesAreasNegocio",
        {
            IdUsuario: idUsuario
        }, customConfig);
        
        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { ObtenerListadoPerfilAreaNegocio };