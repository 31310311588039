import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ContadorUsuariosPendienteAutorizacionService = async (value) => {
    try {
        const resultado = await instance.Backend.post("/Autorizacion/ContadorUsuariosPendienteAutorizacion"
        , {usuarioId: value}
        , customConfig);
        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { ContadorUsuariosPendienteAutorizacionService };