import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ObtenerListadoActividadesUsuario = async (values) => {
    try {
        const resultado = await instance.Backend.post("/Bitacora/BusquedaBitacoraActividadUsuario",values, customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export { ObtenerListadoActividadesUsuario };