import { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, } from "formik";
import { PonderacionContext } from '../Context/PonderacionContext';
import InputTextField from '../../../Components/InputTextField/InputTextField';
import Button from '../../../Components/Button/Button';
import { ActualizarPonderacionService } from '../Services/ActualizarPonderacionService';
import { useToast } from '../../../Hooks/useToast';
import validations from '../../../Utils/Validations/Validations';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const ModalEditarPonderacion = () => {
	const { visible, indicator, toggleModal, toggleRenderizar } = useContext(PonderacionContext);
	const grupo = indicator.grupo;
	const addToast = useToast();
	const handleClose = () => toggleModal(false);
	const { crearRegistroTDR } = useRegistroTDR();
	const {usuario} = useLogin();
	async function postActualizarPonderacion(values) {
		const startTime = new Date();
		const resp = await ActualizarPonderacionService(values, usuario.id);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
			const fetchData = async () => {
				await Promise.all([
					addToast({ mostrar: true, mensaje: 'Porcentaje actualizado correctamente.', titulo: 'Confirmación', tipo: 'Success' }),
					toggleRenderizar(true),
					handleClose(),
				]);
				const endTime = new Date();
				const tdrId = 65;
				crearRegistroTDR(startTime, endTime, usuario, tdrId);
			};

			fetchData();

				}
			}
		}
	}
	function validarIngresoPonderacion(ingresoPonderacion) {
		const valorTotalGrupoPonderaciones =
		grupo.filter(x => indicator.esNivelServicio ? x.detalle.nivelServicioId !== indicator.nivelServicioId : x.indicadorId !== indicator.indicadorId)
		.map(resp => resp.detalle ? resp.detalle.ponderacion : resp.ponderacion)
		.reduce((prev, curr) => prev + curr, 0);

		const valorTotalPonderaciones = valorTotalGrupoPonderaciones + ingresoPonderacion;
		if (valorTotalPonderaciones > 100) {
			addToast({ mostrar: true, mensaje: 'El porcentaje no debe superar el 100%', titulo: 'Información', tipo: 'Info' });
			return false;
		}
		return true;
	}
	return (
		<Modal
			show={visible}
			onHide={handleClose}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Editar ponderación</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Formik
					enableReinitialize
					initialValues={{ indicador: `${indicator.sigla}: ${indicator.descripcion}`, porcentaje: `${indicator.ponderacion}` }}
					validate={validations}
					onSubmit={values => {
						const valuesForm = {
							esNivelServicio: indicator.esNivelServicio,
							id: (indicator.esNivelServicio) ? indicator.nivelServicioId : indicator.indicadorId,
							ponderacion: Number(values.porcentaje)
						}
						if(validarIngresoPonderacion(Number(values.porcentaje))){
							postActualizarPonderacion(valuesForm);
						}
					}}
				>{({
					values,
					setFieldValue,
				}) => (
					<Form className="form mb-0">
						<InputTextField
							name="indicador"
							label="Indicador"
							type="text"
							placeholder="Indicador"
							autoComplete="false"
							readOnly
						/>
						<InputTextField
							name="porcentaje"
							label="Porcentaje"
							type="text"
							placeholder="Porcentaje"
							autoComplete="false"
							maxLength="3"
							onInput={(e) => { e.target.value = e.target.value.replace(/\D/g, ''); setFieldValue("porcentaje", e.target.value.replace(/\D/g, '')); }}
						/>
						<div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4">
							<Button type="button" design="btn-outline-pewter-blue" onClick={() => handleClose()}>Cancelar</Button>
							<Button type="submit" design="btn-primary">Guardar</Button>
						</div>
					</Form>
				)}
				</Formik>
			</Modal.Body>
		</Modal>
	)
}
export default ModalEditarPonderacion;
