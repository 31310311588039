import { useCallback, useState, createContext } from "react";
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
const ToastContext = createContext();

export default ToastContext;
let id = 0;
export function ToastContextProvider({ children }) {
    const [toasts, setToasts] = useState([]);
    const addToast = useCallback(
        function (toast) {
            setToasts((toasts) => [...toasts, { id: id++, mostrar: toast.mostrar, titulo: toast.titulo, tipo: toast.tipo, mensaje: toast.mensaje }]);
        },
        [setToasts]
    );

    return (
        <ToastContext.Provider value={addToast}>
            {children}
            <ToastContainer position="top-end" className="p-3">
                {toasts.map((toast) => (
                    <Toast
                        onClose={() => setToasts(toasts => toasts.filter(t => t.id !== toast.id))}
                        autohide
                        show={toast.mostrar}
                        bg={toast.tipo.toLowerCase()}
                        delay={5000}
                        key={toast.id}
                    >
                        <Toast.Body className={toast.tipo} style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)'}}>
													{toast.mensaje}
													<button className="toast__button" onClick={() => setToasts(toasts => toasts.filter(t => t.id !== toast.id))}>
														<i className="ico ico-close"></i>
													</button>
												</Toast.Body>
                    </Toast>
                ))}
            </ToastContainer>
        </ToastContext.Provider>
    );
}
