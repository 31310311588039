import React, { useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import useLogin from "../../Features/Autenticacion/Hooks/useLogin";
import { DetalleEventoConcesionService } from "../../Features/Datamart/Services/DetalleEventoConcesionService";
import { useComun } from "../../Hooks/useComun";
import Galeria from "../Galeria/Galeria";

const ModalDetalleEventoConcesion = (props) => {
	const { datosModal, ...rest } = props;
	const { usuario } = useLogin();
	const { downloadXUrl, obtenerFormatoFecha } = useComun();
	const [data, setData] = React.useState({ detalleEventosConcesion: [], documentos: [], imagenes: [] });
	const [listaImagenes, setListaImgenes] = React.useState([]);

	
	async function postDetalleEventoConcesion(values) {
		const resp = await DetalleEventoConcesionService(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					setData(resp.data);
				}
			}
		}
	}

	useEffect(() => {
		if (rest.show) {
			setData({ detalleEventosConcesion: [], documentos: [], imagenes: [] });
			setListaImgenes([]);
			const formEnvio = {
				idUsuario: usuario.id,
				eventoConcesionId: datosModal.eventoConcesionId
			}
			postDetalleEventoConcesion(formEnvio);
		}
	}, [rest.show])
	useEffect(() => {
		if (data.imagenes.length > 0) {
			setListaImgenes(data.imagenes.map(x => {
				return ({
					largeURL: x.ruta,
					thumbnailURL: x.ruta,
					width: 567,
					height: 567,
				})
			}))
		}
	}, [data.imagenes])

	
	return (
		<Modal
			{...rest}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton className='pb-0'>
				<h5 className="modal-title" id="modalUserLabel">
					<span>{datosModal.descripcion}</span>
				</h5>
			</Modal.Header>
			<Modal.Body className='pt-4'>
				<div className='d-flex flex-column gap-4'>
					<ul className='text-pewter-blue d-flex flex-column gap-1 fs-6'>
						{data.detalleEventosConcesion.map((x, i) => {
							return (
								<li key={i}><b>{x.nombreCampo}</b>: {x.valorCampo}</li>
							)
						})}
					</ul>
					<hr />
					<Galeria
						galleryID="fotografias"
						images={listaImagenes
						}
					/>
					{data.documentos.length>0?
						<hr />
						:null}
					<div className='d-flex gap-4 flex-wrap justify-content-between'>
						{data.documentos.map((x, i) => {
							return (
								<div key={i} className='doc' style={{'flexBasis':'45%'}} onClick={() => downloadXUrl(x.ruta, x.mime, x.nombre, x.extension)}>
									<div className='doc-icon'>
										<i className="ico-doc-empty fs-3"></i>
									</div>
									<div className='doc-info'>
										<b className='doc-title'>{x.nombre}</b>
										<span className='doc-time'>Agregado por <b>{x.usuario}</b> {obtenerFormatoFecha(x.fechaCreacion)}</span>
									</div>
								</div>
							);
						})}
						
						{}
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default ModalDetalleEventoConcesion;
