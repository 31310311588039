import Modal from 'react-bootstrap/Modal';
import { Formik, Form, } from "formik";
import Button from '../../../Components/Button/Button';
import SelectCheckField from '../../../Components/SelectCheckField/SelectCheckField';
import InputTextField from '../../../Components/InputTextField/InputTextField';
import { useContext, useEffect, useRef, useState } from 'react';
import { useModalAlarma } from '../../AdministracionAlarmas/Hooks/useModalAlarma';
import validations from '../../../Utils/Validations/Validations';
import { AgregarListaAlarmaService } from '../Services/AgregarListaAlarmaService';
import { ModificarListaAlarmaService } from '../Services/ModificarListaAlarmaService';
import { useToast } from '../../../Hooks/useToast';
import Context from '../../Autenticacion/Context/LoginContext';
import { useComun } from '../../../Hooks/useComun';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';
import useLogin from '../../Autenticacion/Hooks/useLogin';

const ModalAgregarListaDeDistribucion = (props) => {
	const { renderizar, listadoAlarmasLista, ...rest } = props;
	const { optionsBusiness, getListaPerfilesAreasNegocio, setOptionsBusiness } = useComun();
	const { listadoTipoAlarma, getTipoAlarma, setListadoTipoAlarma } = useModalAlarma();
	const [idtipoalarma, setidtipoalarma] = useState(0);
	const [listaDesplegableFaltantes, setListaDesplegableFaltantes] = useState([]);
	const addToast = useToast();
	const formikref = useRef();
	const { user } = useContext(Context);
	const { usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const [initialValues, setInitialValues] = useState({
		email: '',
		business: '',
		typeAlarm: '',
	});
	const [listaAreasNegocio, setListaAreasNegocio] = useState([]);//estas listas se crean por logica para listado tipos de alarmas y listado areas de negocio que faltan por agregar al listado de distribucion
	const [listaAuxiliarTipoAlarma, setListaAuxiliarTipoAlarma] = useState([]);//estas listas se crean por logica para listado tipos de alarmas y listado areas de negocio que faltan por agregar al listado de distribucion

	useEffect(() => {
		if (props.modificar === "true") {
			setidtipoalarma(props.datos.alarmaListaId);
			setInitialValues({ ...initialValues, email: props.datos.email, business: props.datos.areaNegocio, typeAlarm: props.datos.tipoAlarma });
		} else {
			setInitialValues({ ...initialValues, email: '' });
		}
		getListaPerfilesAreasNegocio();
		getTipoAlarma();
	}, [props.datos.alarmaListaId, props.modificar, props.datos.email])

	useEffect(() => {
		if (rest.show && props.modificar !== "true") {
			//logica para listado tipos de alarmas y listado areas de negocio que faltan por agregar al listado de distribucion
			//estas listas se van a mostrar siempre y cuando no esten agregadas
			let listaTiposAlarmaYAreasNegocio = [];
			if(listadoAlarmasLista.listaAlarmaDistribucion.length > 0){
				listadoTipoAlarma.map(x => {
					optionsBusiness.map(y => {
						const listaAreasdeNegocio = listadoAlarmasLista.listaAlarmaDistribucion.map(item => {
							if (item.tipoAlarma === x.label) {
								return item.areaNegocio
							}
						});
						if (listaAreasdeNegocio.length > 0) {
							const listaAreasAusentes = listaAreasdeNegocio.includes(y.label)
							if (!listaAreasAusentes) {
								listaTiposAlarmaYAreasNegocio.push({ x, y });
							}
						}
					});

				})
				const key = 'value';
				//el "ListaUnicaTiposAlarma" se hace para obtener las lista de alarmas NO repetidas
				const ListaUnicaTiposAlarma = [...new Map(listaTiposAlarmaYAreasNegocio.map(item =>
					[item.x[key], item.x])).values()];
				setListaDesplegableFaltantes(listaTiposAlarmaYAreasNegocio);
				setListaAuxiliarTipoAlarma(ListaUnicaTiposAlarma);
			}
			else{
				setListaAuxiliarTipoAlarma(listadoTipoAlarma);
				listadoTipoAlarma.map(x => {
					optionsBusiness.map(y => {
						listaTiposAlarmaYAreasNegocio.push({ x, y });	
					});

				})
				setListaDesplegableFaltantes(listaTiposAlarmaYAreasNegocio);
				setListaAreasNegocio(optionsBusiness);
			}
		}
	}, [rest.show])


	async function postAgregarListaAlarma(values) {
		const startTime = new Date();
		const data = {
			alarmaId: values.typeAlarm,
			areaNegocio: Number(values.business),
			email: values.email,
			usuarioId: user.id
		};

		const resp = await AgregarListaAlarmaService(data);
		if (resp) {
			if (resp.status === 200) {
		const fetchData = async () => {
			await Promise.all([
				addToast({ mostrar: true, mensaje: 'Alarma distribución agregada correctamente.', titulo: 'Confirmación', tipo: 'Success' }),
				renderizar(),
				rest.onHide(),
			]);
			const endTime = new Date();
			const tdrId = 79;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
			}
		}
	}

	async function postModificarListaAlarma(values) {
		const startTime = new Date();
		const data = {
			alarmaListaId: idtipoalarma,
			email: values.email,
			usuarioId: user.id
		};

		const resp = await ModificarListaAlarmaService(data);
		if (resp) {
			if (resp.status === 200) {
				const fetchData = async () => {
					await Promise.all([
						addToast({ mostrar: true, mensaje: 'Alarma distribución modificada correctamente.', titulo: 'Confirmación', tipo: 'Success' }),
						renderizar(),
						rest.onHide(),
					]);
					const endTime = new Date();
					const tdrId = 82;
					crearRegistroTDR(startTime, endTime, usuario, tdrId);
				};

				fetchData();

			}
		}
	}
	function ChangeListadoAlarma(e) {
		const listadoAreasNegocio = listaDesplegableFaltantes.filter(x => x.x.value === e);
		setListaAreasNegocio(listadoAreasNegocio.map(x => {
			return x.y;
		})
		);
		formikref.current.setFieldValue('typeAlarm', e);
		formikref.current.setFieldValue('business', '');
	}
	return (
		<Modal
			{...rest}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<h5 className="modal-title" id="modalUserLabel">
					<i className="ico-add-alarm me-2"></i>
					<span>{props.modificar === "true" ? "Modificar" : "Agregar"} lista</span>
				</h5>
			</Modal.Header>
			<Modal.Body>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validate={validations}
					innerRef={formikref}
					onSubmit={values => {
						if (props.modificar === "true") {
							postModificarListaAlarma(values);
						} else {
							postAgregarListaAlarma(values);
						}
					}}
				>
					{({ values, setFieldValue, errors, setFieldTouched, touched }) => (
						<Form className="form mb-0">
							{props.modificar === "true" ?
								<span>
									<InputTextField
										name="email"
										label="Lista de distribución"
										type="email"
										placeholder="Lista de distribución"
										autoComplete="false"
										value={values.email}
										errors={errors.email}
									/></span>
								:
								<InputTextField
									name="email"
									label="Lista de distribución"
									type="email"
									placeholder="Lista de distribución"
									autoComplete="false"
									value={values.email}
									errors={errors.email}
									maxLength={100}

								/>}

							{props.modificar === "true" ?
								<span>
									<InputTextField
										name="typeAlarm"
										label='Tipo de alarma'
										type="text"
										placeholder="Tipo de alarma"
										autoComplete="false"
										value={values.typeAlarm}
										options={listadoTipoAlarma}
										disabled

									/></span>

								:
								<SelectCheckField
									name="typeAlarm"
									label='Tipo de alarma'
									options={listaAuxiliarTipoAlarma}
									onChange={(e, value) => ChangeListadoAlarma(value)}
									onBlur={setFieldTouched}
									isMulti={false}
									fijo={false}
								/>
							}
							{props.modificar === "true" ?
								<span>
									<InputTextField
										name="business"
										label='Área de negocio'
										type="text"
										placeholder="Área de negocio"
										autoComplete="false"
										options={optionsBusiness}
										value={values.business}
										disabled
									/>
								</span>
								:
								<SelectCheckField
									name="business"
									label='Área de negocio'
									options={listaAreasNegocio}
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									isMulti={false}
									fijo={false}
								/>
							}
							<div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4">
								<Button type="button" design="btn-outline-pewter-blue" onClick={() => rest.onHide()}>Cancelar</Button>
								<Button type="submit" design="btn-primary">Guardar</Button>
							</div>
						</Form>
					)}
				</Formik>
			</Modal.Body>
		</Modal>
	)
}
export default ModalAgregarListaDeDistribucion;
