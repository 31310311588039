import moment from "moment";
import { useEffect, useState } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import PaginationComponent from "../../../Components/PaginationTableServer/PaginationTableServer";
import useLogin from "../../Autenticacion/Hooks/useLogin";
import notfound from "../../../Assets/images/il-empty.svg";

const TablaDisponibilidad = ({ columns, data, fetchData, pageCount: controlledPageCount, currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy, setPagination, datosBusqueda, esDescargar, setEsDescargar }) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		pageCount,
		gotoPage,
		setSortBy,
		state: { pageIndex, pageSize, sortBy },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy },
			manualPagination: true,
			pageCount: controlledPageCount,
			autoResetPage: false,
			autoResetSortBy: false,
			manualSortBy: true,
		},
		useSortBy,
		usePagination
	);
	const { usuario } = useLogin();
	const [valoresFormularioEnvio, setValoresFormularioEnvio] = useState({
		datosBusqueda: {
			mes: datosBusqueda.mes,
			ano: datosBusqueda.ano,
			tipoMonitoreo: datosBusqueda.tipoMonitoreo,
			idUsuario: usuario.id,
			idPerfilContexto: usuario.perfilContexto.id
		},
		paginacion: {
			numeroPagina: pageIndex,
			cantidadRegistros: pageSize,
			orderByAscending: true,
			ordenadoPor: "nombre",
		},
	});
	
	useEffect(() => {
		const handleSearchChange = () => {
			if (
				valoresFormularioEnvio.datosBusqueda.mes !== datosBusqueda.mes ||
				valoresFormularioEnvio.datosBusqueda.ano !== datosBusqueda.ano ||
				valoresFormularioEnvio.datosBusqueda.tipoMonitoreo !== datosBusqueda.tipoMonitoreo
			) {
				const newFormulario = {
					...valoresFormularioEnvio,
					datosBusqueda: {
						...valoresFormularioEnvio.datosBusqueda,
						mes: datosBusqueda.mes,
						ano: datosBusqueda.ano,
						tipoMonitoreo: datosBusqueda.tipoMonitoreo
					},
					paginacion: {
						...valoresFormularioEnvio.paginacion,
						numeroPagina: 0,
						ordenadoPor: sortBy.length === 0 ? "nombre" : sortBy[0].id,
					},
				};
				setValoresFormularioEnvio(newFormulario);
				setPagination({
					pageIndex: 0,
					pageSize: pageSize,
					sortBy: [],
				});
				setSortBy([]);
				gotoPage(0);
			} else if (datosBusqueda.mes && datosBusqueda.ano) {
				fetchData({ formulario: valoresFormularioEnvio, pageIndex, sortBy});
				setPagination((values) => ({
					...values,
					sortBy: sortBy,
				}));
			}
		};
	
		handleSearchChange();
	}, [datosBusqueda, valoresFormularioEnvio, pageIndex, sortBy]);
	
	useEffect(() => {
		if (esDescargar) {
			const newFormulario = {
				...valoresFormularioEnvio,
				datosBusqueda: {
					...valoresFormularioEnvio.datosBusqueda,
					exportacion: true,
				},
			};
	
			fetchData({ formulario: newFormulario, pageIndex, sortBy });
			
			setEsDescargar(false);
		}
	}, [esDescargar]);

	return (
		<>
			<div className="table-responsive">
				<table className="table" {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) =>
									data.length > 0 ? (
										<th {...column.getHeaderProps(column.getSortByToggleProps(), { className: column.classNameSpanTh })}>
											<span className="d-flex align-items-center gap-1">
												{column.render('Header')}
												<>
													{column.isSorted ?
														(column.isSortedDesc ?
															<i className="ico-chevron-down"></i>
															:
															<i className="ico-chevron-up"></i>
														) : !column.disableSortBy ? (
															<>
																<i className="ico-sort fs-7"></i>
															</>
														) : null}
												</>
											</span>
										</th>
									) : (
										<th {...column.getHeaderProps({})}>
											<span className="d-flex align-items-center gap-1">
												{column.render("Header")}
											</span>
										</th>
									)
								)}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{(page.length > 0 &&
							page.map((row) => {
								prepareRow(row);
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return (
												<td
													{...cell.getCellProps({
														className: cell.column.classNameCell,
													})}
												>
													{cell.render("Cell")}
												</td>
											);
										})}
									</tr>
								);
							})) || (
								<tr>
									<td colSpan={headerGroups[0].headers.length + 1}>
										<div className="d-flex justify-content-center align-items-center flex-column gap-2 py-5">
											<img className="mb-3" src={notfound} alt="" />
											<p className="mb-0 text-primary">
												No se encuentran resultados de tu búsqueda.
											</p>
										</div>
									</td>
								</tr>
							)}
					</tbody>
				</table>
			</div>
			{data.length > 0 && (
				<PaginationComponent
					pagesCountServer={pageCount}
					currentPage={pageIndex}
					setCurrentPage={gotoPage}
					alwaysShown={true}
					setPagination={setPagination}
				/>
			)}
		</>
	);
};
export default TablaDisponibilidad;