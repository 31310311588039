
import Modal from 'react-bootstrap/Modal';
import { useDatamart } from '../Hooks/useDatamart';

const ModalFormula = () => {
    const { visible, indicator, toggleModal } = useDatamart();
    const handleClose = () => toggleModal();
    const myHTML = '<iframe src="' + indicator.formula + '""></iframe>'
    return (
        <Modal
            show={visible}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className='fs-4'>Fórmula</Modal.Title>
            </Modal.Header>
            <Modal.Body>
							<div className='ratio ratio-1x1' dangerouslySetInnerHTML={{ __html: myHTML }} />
            </Modal.Body>
        </Modal>
    )
}
export default ModalFormula;
