import { useState,useEffect } from 'react';
import ModalConfirmacion from './ModalConfirmacion';

const EstadoEnCelda = ({ ...props }) => {
    const{data,renderizar} =props;
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    const [estado, setStatus] = useState(props.estado);
    const handleChange = async () => {
        setShow(true);
    }
    useEffect(() => {
        setStatus(props.estado);
    }, [props]);

    const [inputId] = useState(`campo_${Math.random().toString(36).slice(2, 9)}`);

    return (
        <>
            <div className="form-check form-switch d-flex justify-content-center align-items-center p-0">
                <input
                    className="form-check-input mx-0"
                    type="checkbox"
                    role="switch"                    
                    id={inputId}
                    checked={estado}
                    onChange={handleChange}
                />
                  <ModalConfirmacion
				    show={show}
                    parametroIndicadorId={data.parametroIndicadorExigenciaId}
				    onHide={handleClose}
				    estado={estado}
				    renderizar={renderizar}
			/>
            </div>
        </>
    )
}

export default EstadoEnCelda;
