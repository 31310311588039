import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

async function ObtenerIndicadoresService(values, idUsuario){

    try {

        const resultado = await instance.Backend.post("/Indicador/IndicadoresInicio",{
            idIndicador: values.idIndicadores,
            idSerie: values.idSerie,
            idUsuario: idUsuario
        }, customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export {ObtenerIndicadoresService};