import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";


const SeguimientoHallazgoService = async (values) => {
    try {
        return await instance.Backend.post("/Hallazgo/SeguimientoHallazgo",values, customConfig);
    } catch (error) {
        console.log(error);
    }
}
export { SeguimientoHallazgoService };