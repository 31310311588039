import { useCallback, useState } from 'react'
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { ObtenerTipoAlarmaServices } from '../Services/ObtenerTipoAlarmaService';


export function useModalAlarma(props) {

    const [listadoTipoAlarma, setListadoTipoAlarma] = useState([]);
    const {usuario} = useLogin();

    async function postObtenerListadoUsuarioTabla() {

        const resp = await ObtenerTipoAlarmaServices(usuario.id);
        if (resp) {
            if (resp.status === 200) {
                if (resp.data) {
                    let tipoAlarmas = [];
                    resp.data.listaAlarmas.map((value)=>{tipoAlarmas.push({ value:value.alarmaId, label:value.descripcion})});
                    setListadoTipoAlarma(tipoAlarmas);
                }
            }
        }
    }


    const getTipoAlarma = useCallback(
        function () {
            postObtenerListadoUsuarioTabla();
        },
        []
    );

    return { listadoTipoAlarma, getTipoAlarma, setListadoTipoAlarma }
}
