import { Form, Formik } from "formik";
import Button from "../../../Components/Button/Button";
import validations from "../../../Utils/Validations/Validations";
import { usePagination, useSortBy, useTable } from "react-table";
import useLogin from "../../Autenticacion/Hooks/useLogin";
import { useEffect, useState } from "react";
import PaginationComponent from "../../../Components/PaginationTableServer/PaginationTableServer";
import Exports from "../../../Components/Exports/Exports";
import SelectCheckField from "../../../Components/SelectCheckField/SelectCheckField";
import notfound from '../../../Assets/images/il-empty.svg';
import { useRegistroTDR } from "../../../Hooks/useRegistroTDR";


const TableUser = ({ columns, data, fetchData, pageCount: controlledPageCount, currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy, setPagination, esRenderizar, setEsRenderizar }) => {
	const {
		getTableProps
		, getTableBodyProps
		, headerGroups
		, prepareRow
		, page
		, pageCount
		, gotoPage
		, setSortBy
		, state: { pageIndex, pageSize, sortBy }
	} = useTable(
		{
			columns
			, data
			, initialState: { pageIndex: currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy }
			, manualPagination: true
			, pageCount: controlledPageCount
			, autoResetPage: false
			, autoResetSortBy: false
			, manualSortBy: true
		},
		useSortBy
		, usePagination
	)

	const { usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const [valoresFormularioEnvio, setValoresFormularioEnvio] = useState({
		paginacion: {
			numeroPagina: pageIndex
			, cantidadRegistros: pageSize
			, orderByAscending: false
			, ordenadoPor: "NombreCompleto"
		},
		datosBusqueda: {
			idUsuario: usuario.id
			, idEstadoAutorizacion: 1
			, exportacion: false
		}
	});
	useEffect(() => {
		fetchData({ formulario: valoresFormularioEnvio, pageIndex, sortBy });
		/* GUARDAMOS EL SORTBY EN EL USESTATE DE PAGINATION QUE SE ENCUENTRA EN ALARMASPAGE PARA QUE NO SE PIERDA EL ORDENAMIENTO */
		setPagination(values => ({
			...values
			, sortBy: sortBy
		}));
		if(esRenderizar){
			setEsRenderizar(false)
		}
	}, [pageIndex, sortBy, esRenderizar])

	const descargar = () => {
		fetchData({ formulario: valoresFormularioEnvio, pageIndex: pageIndex, sortBy, exportacion: true });
	};
	function BuscarAutorizaciones(values) {
		const startTime = new Date();
		let formEnvio = valoresFormularioEnvio;
		/* Se setean valores del paginado para la primera busqueda (resetea la tabla) */
		formEnvio.paginacion.numeroPagina = 0;
		formEnvio.paginacion.orderByAscending = false;
		formEnvio.paginacion.ordenadoPor = "NombreCompleto";
		/* Se setean los valores de los campos que ingresa el usuario */
		formEnvio.datosBusqueda.idEstadoAutorizacion = Number(values.estado_autorizacion);
		const fetchData = async () => {
			await Promise.all([
				setValoresFormularioEnvio(formEnvio)
			]);
			const endTime = new Date();
			const tdrId = 52;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
		setPagination({
			pageIndex: formEnvio.paginacion.numeroPagina,
			pageSize: formEnvio.paginacion.cantidadRegistros,
			sortBy: []
		});
		setSortBy([]);
		gotoPage(0);
	}
	const listaEstadoAutorizacion = [{ value: 1, label: "Pendiente" }, { value: 2, label: "Aprobado" }, { value: 3, label: "Rechazado" }, { value: 0, label: "Todos" }]
	return (
		<>
			<div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-5 gap-3">
				<Formik
					enableReinitialize
					initialValues={{ estado_autorizacion: 1 }}
					validate={validations}
					onSubmit={values => {
						BuscarAutorizaciones(values);
					}}
				>{({ setFieldTouched, setFieldValue
				}) => (
					<Form className="form mb-0">
						<div className="tools order-1 order-lg-0 align-items-start">
							{}
							<SelectCheckField
							name="estado_autorizacion" label="Estado"
							options={listaEstadoAutorizacion}
							onBlur={setFieldTouched}
							onChange={setFieldValue}
							isMulti={false}
						/>
							<Button type="submit" design="btn-primary">Buscar</Button>
						</div>
					</Form>
				)}
				</Formik>
				{data.length > 0 &&
					<div className="exports order-0 order-lg-1">
						<Exports descargar={() => descargar()} />
					</div>
				}
			</div>
			<div className="table-responsive">
				<table className="table" {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									data.length > 0 ?
									<th {...column.getHeaderProps(column.getSortByToggleProps(), { className: column.className })}
									>
										<span className="d-flex align-items-center gap-1">
											{column.render('Header')}
											<>
											{!column.disabledSortBy ?
												column.isSorted && data.length > 0 ?
													(column.isSortedDesc ?
														<i className="ico-chevron-down"></i>
														:
														<i className="ico-chevron-up"></i>
													) :
													<>
														<i className="ico-sort fs-7"></i>
													</>
												:null
											}
											</>
										</span>
									</th>
									:
									<th {...column.getHeaderProps()}
									>
										<span className="d-flex align-items-center gap-1">
											{column.render('Header')}
										</span>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{
							page.length > 0 && page.map( (row) => {
								prepareRow(row)
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps({ className: cell.column.className })}>
												{cell.render('Cell')}
											</td>
										})
										}
									</tr>
								)
							}) || <tr><td colSpan={headerGroups[0].headers.length}>
							<div className="d-flex justify-content-center align-items-center flex-column gap-2 py-5">
								<img className="mb-3" src={notfound} alt=""/>
								<p className='mb-0 text-primary'>No se encuentran resultados de tu búsqueda.</p>
							</div>
						</td></tr>
						}
					</tbody>
				</table>
			</div>
			{
				data.length > 0 &&
				<PaginationComponent
					pagesCountServer={pageCount}
					currentPage={pageIndex}
					setCurrentPage={gotoPage}
					alwaysShown={false}
					setPagination={setPagination}
				/>
			}
		</>
	)
}
export default TableUser;
