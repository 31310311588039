import instance from "../../../Utils/Interceptors/Interceptor";


async function ActualizaAlertaPreviaService(values) {

    try {
        return await instance.Backend.post("/Indicador/ActualizaAlertaPrevia", {
            idUsuario: values.idUsuario,
            esNivelServicio: values.esNivelServicio,           
            id: values.id,
            porcentajeAlertaPrevia: values.porcentajeAlertaPrevia
           
        });

    } catch (error) {
        console.log(error);
    }

}
export { ActualizaAlertaPreviaService };