import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ListadoTipoFallaService = async () => {
    try {
        const resultado = await instance.Backend.post("/Hallazgo/tipoFalla", customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export { ListadoTipoFallaService };