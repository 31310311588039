import { useMemo, useState } from 'react';
import Select from 'react-select'

const SelectColumnFilter = ({column}) => {
	const {filterValue, setFilter, preFilteredRows, id, Header} = column;
	const [selected , setSelected] = useState(null)
	const options = useMemo(() => {
		const options = new Set();
		preFilteredRows.forEach((row) => {
			options.add(row.values[id]);
		});
		return [...options.values()];
	}, [id, preFilteredRows]);

	const items = [];
	options.forEach((option) =>{
		items.push({value:option, label:option});
	})
	const handleChangeSelect = (option) => {
		setSelected(option || undefined)
		if(option){
			setFilter(option.value || undefined)
		}else{
			setFilter('')
		}
	}

	return (
		<div className='simple-select'>
		<Select
			options={items}
			classNamePrefix="selector"
			name={id}
			id={id}
			isClearable
			value={selected}
			onChange={handleChangeSelect}
			placeholder={Header}
		/>
		</div>
	)
}

export default SelectColumnFilter;
