import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import Button from '../../../Components/Button/Button';
import { DescargaListaRegistrosTdrService } from '../Services/DescargaListaRegistroTdrService';

const ModalSolicitudDescargaTdr = (props) => {
    const { datosModal, datosBusqueda,tiempoIndicadoresTdr, ...rest } = props;
    const [mensajeModal, setMensajeModal] = useState('');

    const postDescargaListaRegistrosTdr = async (values) => {
			const resp = await DescargaListaRegistrosTdrService(values);
			if (resp) {
				if (resp.status === 200) {
						if (resp.data) {
								setMensajeModal(resp.data);
						}
					}
	}
}

    useEffect(() => {
        if (props.show) {
            setMensajeModal('');
						const values = {
							datosBusqueda: datosModal.datosBusqueda

						};
                postDescargaListaRegistrosTdr(values);
							}
    }, [props.show]);
    return (
        <Modal
            {...rest}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className='info my-4'>
                    <h6 className='info__title'>Solicitud</h6>
                    <p className='info__text'>{mensajeModal}</p>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4">
                    <Button type="button" design="btn-primary btn--small" onClick={rest.onHide}>Aceptar</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default ModalSolicitudDescargaTdr;
