import React, { useState } from 'react';
import Exports from "../../../Components/Exports/Exports";
import SearchField from "../../../Components/SearchField/SearchField";
import Button from "../../../Components/Button/Button";
import ModalAgregarListaDeDistribucion from './ModalAgregarListaDeDistribucion';
import { useAlarmaLista } from '../Hooks/UseAlarmaLista';
import notfound from '../../../Assets/images/il-empty.svg';
import { EnumTablaPaginacion } from '../../../Utils/Enumeradores/ComunEnum';
import PaginationComponent from '../../../Components/PaginationTableServer/PaginationTableServer';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const TablaListaDeDistribucion = (props) => {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, state, setGlobalFilter, pageOptions, previousPage, canPreviousPage, nextPage, canNextPage, gotoPage, pageCount } = props.tableInstance;
	const { globalFilter } = state;
	const [modificar, setModificar] = useState(false);
	const [datos, setDatos] = useState({});
	const { getListadoAlarmaLista, listadoAlarmasLista } = props;
	function handleShow(state) {
		setShow(state);
	}
	const { usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();

	const reload = () => window.location.reload();
	const descargar = (tipo) => {
		getListadoAlarmaLista({ exportacion: true });
	};
	const modalAgregar = () => {
		const startTime = new Date();
		const fetchData = async () => {
			await Promise.all([
				setModificar(false),
				handleShow(true),
			]);
			const endTime = new Date();
			const tdrId = 78;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
	}
	const modalModificar = (datosModificar) => {
		const startTime = new Date();
		if (datosModificar.estado === "Activo") {
			const fetchData = async () => {
				await Promise.all([
					setModificar(true),
					setDatos(datosModificar),
					handleShow(true),
				]);
				const endTime = new Date();
				const tdrId = 81;
				crearRegistroTDR(startTime, endTime, usuario, tdrId);
			};

			fetchData();
		}
		else {
			handleShow(false);
		}
	}
	const [{ pageIndex, pageSize, sortBy }, setPagination] =
		React.useState({
			pageIndex: 0,
			pageSize: EnumTablaPaginacion.CantidadPaginacion,
			sortBy: []
		});

	return (
		<>
			<div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-5 gap-3">
				<div className="tools order-1 order-lg-0 align-items-start w-100">
					<SearchField
						filter={globalFilter}
						setFilter={setGlobalFilter}
					/>
					{
						listadoAlarmasLista.agregarLista ?
							<Button design="btn-primary px-4 ms-md-auto" onClick={() => modalAgregar()}>
								<i className="ico-plus me-2"></i>
								<span>Agregar Lista</span>
							</Button>
							:
							<Button design="btn-primary px-4 ms-md-auto" disabled={true}>
								<i className="ico-plus me-2"></i>
								<span>Agregar Lista</span>
							</Button>
					}

				</div>
				<div className="exports order-0 order-lg-1">
					<Exports
						descargar={(tipo) => descargar(tipo)}
					/>
				</div>
			</div>
			<div className="table-responsive brd-top">
				<table className="table" {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps(column.getSortByToggleProps(), { className: column.className })}>
										<span className="d-flex align-items-center gap-1">
											{column.render('Header')}
											<>
												{column.isSorted ?
													(column.isSortedDesc ?
														<i className="ico-chevron-down"></i>
														:
														<i className="ico-chevron-up"></i>
													) :
													<>
														<i className="ico-sort fs-7"></i>
													</>
												}
											</>
										</span>
									</th>
								))}
								<th>Editar</th>
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{
							page.length > 0 && page.map((row) => {
								prepareRow(row)
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps({
												className: cell.column.className
											})}>
												{cell.render('Cell')}
											</td>
										})}
										<td>
											<button className="link link--border text-pewter-blue fs-5" onClick={() => modalModificar(row.original)}>
												<i className="ico-doc-edit"></i>
											</button>
										</td>
									</tr>
								)
							}) || <tr><td colSpan={headerGroups[0].headers.length}>
								<div className="d-flex justify-content-center align-items-center flex-column gap-2 py-5">
									<img className="mb-3" src={notfound} alt="" />
									<p className='mb-0 text-primary'>No se encuentran resultados de tu búsqueda.</p>
								</div>
							</td></tr>
						}
					</tbody>
				</table>
			</div>
			<PaginationComponent
				pagesCountServer={pageCount}
				currentPage={state.pageIndex}
				setCurrentPage={gotoPage}
				alwaysShown={false}
				setPagination={setPagination}
			/>
			<ModalAgregarListaDeDistribucion
				show={show}
				onHide={handleClose}
				renderizar={props.renderizar}
				modificar={modificar.toString()}
				datos={datos}
				listadoAlarmasLista = {listadoAlarmasLista}
			/>
		</>
	)
}
export default TablaListaDeDistribucion;
