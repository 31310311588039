import { createContext, useState } from "react";

const DatamartContext = createContext();

const DatamartContextProvider = ({children}) => {
    const [visible, setVisible] = useState(false);
    const [indicator, setIndicator] = useState({});

    const value = {
		visible,
		indicator,
		getDataIndicator: (data, esNivelServicio) => {
			return setIndicator({...data, esNivelServicio: esNivelServicio});
		},
		toggleModal: () => {
			return setVisible(!visible)
		}
	}
	return (
		<DatamartContext.Provider value={value}>
			{children}
		</DatamartContext.Provider>
	)
}
export {DatamartContext, DatamartContextProvider};