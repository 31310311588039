import React, { useEffect, useMemo } from 'react';
import { ColumnasTabla } from '../Utils/ColumnasTabla';
import Card from "../../../Components/Card/Card";
import TablaNotificacion from '../Components/TablaNotificacion';
import { Link } from 'react-router-dom';
import { BuscadorNotificacionesService } from '../Services/BuscadorNotificacionesService';
import { useComun } from '../../../Hooks/useComun';
import { EnumModuloDesc, EnumPathRutas, EnumTablaPaginacion } from '../../../Utils/Enumeradores/ComunEnum';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';
const NotificacionesPage = () => {
	const columns = useMemo(() => ColumnasTabla, []);
	const { user, usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const [data, setData] = React.useState([]);
	const [pageCount, setPageCount] = React.useState(0);
	const [{ pageIndex, pageSize, sortBy }, setPagination] =
		React.useState({
			pageIndex: 0,
			pageSize: EnumTablaPaginacion.CantidadPaginacion,
			sortBy: []
		});
	const { downloadCSV, listadoNotificacionesSeleccionable, obtenerTiposNotificaciones } = useComun();
	async function postBuscadorNotificaciones(values) {
		const startTime = new Date();
		const resp = await BuscadorNotificacionesService(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					if (values.datosBusqueda.exportacion) {
						const fetchData = async () => {
							await Promise.all([
								downloadCSV(resp.data, "Notificaciones", EnumModuloDesc.DescargaNotificaciones)
							]);
							const endTime = new Date();
							const tdrId = 45;
							crearRegistroTDR(startTime, endTime, usuario, tdrId);
						};

						fetchData();
					} else {
						setPageCount(resp.data.cantidadPaginas);
						setData(resp.data.listadoNotificaciones);
					}
				}
			}
		}
	}
	const fetchData = React.useCallback(({ formulario, pageIndex, sortBy = [], exportacion = false }) => {
		const startTime = new Date();
		formulario.paginacion.numeroPagina = pageIndex + 1;
		formulario.paginacion.ordenadoPor = sortBy.length > 0 ? sortBy[0].id : formulario.paginacion.ordenadoPor;
		formulario.paginacion.orderByAscending = sortBy.length > 0 ? (sortBy[0].desc ? false : true) : formulario.paginacion.orderByAscending;
		formulario.datosBusqueda.exportacion = exportacion;
		const fetchData = async () => {
			await Promise.all([
				postBuscadorNotificaciones(formulario)
			]);
			const endTime = new Date();
			const tdrId = 43;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
	}, [])

	useEffect(() => {
		obtenerTiposNotificaciones();
	}, [])

	return (
		<div className="container-fluid h-100">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li><Link to={EnumPathRutas.Inicio}>Inicio</Link></li>
							<li><a href="#">Notificaciones</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<Card className="p-4">
						<TablaNotificacion
							columns={columns}
							data={data}
							fetchData={fetchData}
							pageCount={pageCount}
							currentpage={pageIndex}
							pageSize={pageSize}
							sortBy={sortBy}
							setPagination={setPagination}
							listadoNotificacionesSeleccionable={listadoNotificacionesSeleccionable}
						/>
					</Card>
				</div>
			</div>
		</div>
	)
}
export default NotificacionesPage;
