import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useLogin from '../../Features/Autenticacion/Hooks/useLogin';
import { useLoading } from '../../Hooks/useLoading';
import { useToast } from '../../Hooks/useToast';


const instance = {
    Backend: axios.create({ baseURL: process.env.REACT_APP_API_BACKEND_URL }),
    Atenticacion: axios.create({ baseURL: process.env.REACT_APP_API_INFRAESTRUCTURA_URL }),
}

const customConfig = {
    headers: {
        'Content-Type': 'application/json'
    }
}
const customConfigFormData = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

const URLSApisIgnorarLoading = [
    "/Alarma/ContadorAlarmasNoLeidas"
    , "/Notificacion/ContadorNotificacionesNoleidasPorIdUsuario"
    , "/Autorizacion/ContadorTotalPendientesAutorizacion"
    , "/Autorizacion/ContadorUsuariosPendienteAutorizacion"
    , "/Autorizacion/ContadorMedicionesManuales"
    , "/Indicador/ListaIndicadores"
    , "/Usuario/ObtenerPerfilesAreasNegocio"
    , "/Indicador/ListaSerie"
    , "/Indicador/ListaIndicadorDt"
    , "/Hallazgo/EstadoHallazgo"
    , "/Hallazgo/ObtenerEstadisticasHallazgo"
    , "/Bitacora/RegistraBitacoraMovimientoUsuario"
    , "/Usuario/ObtenerAreasNegocioIdUsuario"
    , "/Indicador/ListaIndicadoresAreaNegocio"
    , "/Hallazgo/AccionActualizaHallazgo"
    , "/Usuario/ObtenerUsuariosPorPerfilAreaNegocio"
    , "/Indicador/ListaIndicadoresPorAreaNegocio"
    , "/Hallazgo/TipoHallazgo"
    , "/Parametro/ObtenerPorId"
]
const URLSApisIgnorarFechaActualizacionToken = [
    "/Alarma/ContadorAlarmasNoLeidas"
    , "/Notificacion/ContadorNotificacionesNoleidasPorIdUsuario"
    , "/Autorizacion/ContadorTotalPendientesAutorizacion"
]

const AxiosInterceptor = ({ children }) => {
    const addLoading = useLoading();
    const addToast = useToast();
    const { signout, jwt, setJWT } = useLogin();
    const [isSet, setIsSet] = useState(false);

    let erroresLogout = [];

    useEffect(() => {

        const reqInterceptor = (request) => {
            if (JSON.parse(window.sessionStorage.getItem('usuario')) && window.sessionStorage.getItem('jwt')) {
                request.headers.Authorization = `Bearer ${window.sessionStorage.getItem('jwt')}`;
                request.headers.Usuario = JSON.parse(window.sessionStorage.getItem('usuario')).rut;
            }
            if (window.sessionStorage.getItem('IdUsuarioPrincipal') && window.sessionStorage.getItem('Reemplazo')) {
                request.headers.Reemplazo = window.sessionStorage.getItem('Reemplazo');
                request.headers.IdUsuarioPrincipal = window.sessionStorage.getItem('IdUsuarioPrincipal');
            }
            //esto es para que en el apim ignore la actualizacion de fecha para el token para las apis que se ejecutan cada ciertos segundos en la app.
            if (URLSApisIgnorarFechaActualizacionToken.includes(request.url)) {
                request.headers.ActualizaFechaExpiracion = false;
            } else {
                request.headers.ActualizaFechaExpiracion = true;
            }
            if (!URLSApisIgnorarLoading.includes(request.url)) {
                addLoading(true);
            }
            return request;
        }

        const resInterceptor = response => {

            if (response.headers['x-token']) {
                setJWT(response.headers['x-token']);
                window.sessionStorage.setItem('jwt', response.headers['x-token']);
            }
            if (response.data) {
                if (response.data.codigo) {
                    if (response.data.codigo === 206) {
                        addToast({ mostrar: true, mensaje: response.data.mensaje, titulo: 'Información', tipo: 'Info' });
                    }
                }
            }
            if (!URLSApisIgnorarLoading.includes(response.config.url)) {
                addLoading(false);
            }
            return response;
        }

        const errInterceptor = error => {
            if (!URLSApisIgnorarLoading.includes(error.config.url)) {
                addLoading(false);
            }
            let mensajeError = "Ha ocurrido un error, favor intente más tarde.";
            let mensajeErrorReemplazado = "Reemplazado";
            let mensajeErrorExpirado = "Su sesión ha expirado";
            let mensajeErrorOrigenNoValido = "OrigenNoValido";
            if (error.response.data) {
                if (error.response.data.mensaje) {
                    mensajeError = error.response.data.mensaje
                } else {
                    mensajeError = error.response.data
                }
            }
            if (error.response.status === 401) {
                if (error.response.data) {
                    if (error.response.data.mensaje) {
                        if (error.response.data.mensaje === mensajeErrorReemplazado || error.response.data.mensaje === mensajeErrorExpirado
                        || error.response.data.mensaje === mensajeErrorOrigenNoValido) {
                            if (erroresLogout.length === 0) {
                                erroresLogout.push(true);
                                addToast({ mostrar: true, mensaje: mensajeError, titulo: 'Error', tipo: 'Danger' });
                                signout();
                            }
                        } else {
                            addToast({ mostrar: true, mensaje: mensajeError, titulo: 'Error', tipo: 'Danger' });
                        }
                    } else {
                        signout();
                    }
                } else {
                    addToast({ mostrar: true, mensaje: mensajeError, titulo: 'Error', tipo: 'Danger' });
                }
            } else if (error.request.status === 400 || error.request.status === 500 || error.request.status === 504 || error.request.status === 404) {
                addToast({ mostrar: true, mensaje: "Ha ocurrido un error, favor intente más tarde.", titulo: 'Error', tipo: 'Danger' });
            }  else if (error.request.status === 401) {
                signout();
            } else {
                addToast({ mostrar: true, mensaje: mensajeError, titulo: 'Error', tipo: 'Danger' });
            }
            return Promise.reject(error);
        }


        const interceptorReq = instance.Backend.interceptors.request.use(reqInterceptor, errInterceptor);
        const interceptorResp = instance.Backend.interceptors.response.use(resInterceptor, errInterceptor);

        let interceptorReq2;
        let interceptorResp2;

        if (process.env.REACT_APP_ESLOCAL) {
            interceptorReq2 = instance.Atenticacion.interceptors.request.use(reqInterceptor, errInterceptor);
            interceptorResp2 = instance.Atenticacion.interceptors.response.use(resInterceptor, errInterceptor);
        }

        setIsSet(true); // Esto es para saber cuando ya ha finalizado la configuración del interceptor y asi validarlo en el return y luego devolver los children
        return () => {
            instance.Backend.interceptors.request.eject(interceptorReq);
            instance.Backend.interceptors.response.eject(interceptorResp);

            if (process.env.REACT_APP_ESLOCAL) {
                instance.Atenticacion.interceptors.request.eject(interceptorReq2);
                instance.Atenticacion.interceptors.response.eject(interceptorResp2);
            }
        }
    }, [jwt])

    return isSet && children;
}


export default instance;
export { AxiosInterceptor, customConfig, customConfigFormData }
