import moment from "moment";
import { EnumEstadoAutorizacion } from "../../../Utils/Enumeradores/ComunEnum";
import AccionesEnCelda from "../Components/AccionesEnCelda";

export function useColumnsAlerta(props) {
	const COLUMNS = [
		{
			Header: 'Nombre',
			accessor: 'nombreCompleto',
			className: '',
			Cell: ({ row }) =>
				<div className="user">
					<span className={`avatar rounded-circle border overflow-hidden ${row.original.rutaImagen === "" || row.original.rutaImagen === null ? 'avatarIniciales' : 'text-white'}`}>
						{row.original.rutaImagen === "" || row.original.rutaImagen === null ?
							row.original.nombres.charAt(0).toUpperCase() + row.original.apellidoPaterno.charAt(0).toUpperCase() :
							<img src={row.original.rutaImagen} alt="" />
						}
					</span>
					<div className="user__ide">
						<span className="text-nowrap">{row.original.nombres + " " + row.original.apellidoPaterno + " " + row.original.apellidoMaterno}</span>
							{row.original.pefiles.map((perfil, i) => {
							return (<small key={i} className="text-pewter-blue">{perfil.descripcion}</small>)
						})}

					</div>
				</div>
		},
		{
			Header: 'Área de negocio',
			accessor: 'areaNegocio',
			className: '',
		},
		{ Header: 'Correo electrónico', accessor: 'email', className: '', },
		{
			Header: 'Fecha de registro',
			accessor: 'fechaRegistro',
			className: 'text-center',
			Cell: ({ row }) => moment(row.original.fechaRegistro).format('DD-MM-YYYY')
		},
		{
			Header: 'Movimiento',
			accessor: 'movimiento',
			className: 'text-center',
			disabledFilters: true,
			Cell: ({ row }) => <a
				className={`fs-4 text-decoration-none
				${row.original.movimiento && row.original.autorizacion === EnumEstadoAutorizacion.Pendiente ? 'text-primary' : ''}
				${row.original.autorizacion === EnumEstadoAutorizacion.Aprobado || row.original.autorizacion === EnumEstadoAutorizacion.Rechazado ? 'text-disabled' : ''}
				${!row.original.movimiento && row.original.autorizacion === EnumEstadoAutorizacion.Pendiente ? 'text-danger' : ''}


			`}
			//gris cuando el estado autorizacion es 2 o 3
			>

				{!row.original.movimiento ? <i className="ico-user-delete"></i> : <i className="ico-user-add"></i>}

			</a>
		},
		{
			Header: 'Autorización',
			accessor: 'autorizacion',
			className: 'text-center',
			disabledFilters: true,
			Cell: ({ row }) => <span
				className={`badge badge--monochromatic
				${row.original.autorizacion === EnumEstadoAutorizacion.Aprobado ? 'bg-success' : ''}
				${row.original.autorizacion === EnumEstadoAutorizacion.Pendiente ? 'bg-primary' : ''}
				${row.original.autorizacion === EnumEstadoAutorizacion.Rechazado ? 'bg-danger' : ''}
			`}
			>
				{row.original.autorizacion === EnumEstadoAutorizacion.Pendiente ? "Pendiente" : row.original.autorizacion === EnumEstadoAutorizacion.Aprobado ? "Aprobado" : row.original.autorizacion === EnumEstadoAutorizacion.Rechazado ? "Rechazado" : ""}
			</span>
		},
		{
			Header: 'Autorizar',
			className: 'text-center',
			disabledFilters: true,
			disabledSortBy: true,
			Cell: ({ row }) => <AccionesEnCelda usuario={row.original} renderizar={props.renderizar} modal={props.modal} />,

		},
	];
	return { COLUMNS }
}
