import { useState, useContext,useEffect } from 'react';
import IndicadorSecundario from "./IndicadorSecundario";
import { AlertasContext } from '../Context/ContextAlertas';
import ModalConfirmacion from './ModalConfirmacion';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';
import useLogin from '../../Autenticacion/Hooks/useLogin';

const IndicadorPrincipal = (props) => {
	const { crearRegistroTDR } = useRegistroTDR();
	const {usuario} = useLogin();
	const indicador = props.item;
	const grupoPerteneciente = props.grupo;
	const [toggle, setToggle] = useState(true);
	const [isActive, setStatus] = useState(indicador.detalle.estadoAlertaPrevia);
	const { toggleModal, getDataIndicator,toggleModalConfirmar } = useContext(AlertasContext);
	const handleModalConfirmar =  (data, esNivelServicio, grupo) => {
		getDataIndicator(data, esNivelServicio, grupo);
		return toggleModalConfirmar(true);
	}
	useEffect(() => {
		setStatus(indicador.detalle.estadoAlertaPrevia)
	}, [indicador]);
	const HandleExpand = (action) => {
		setToggle(action)
	}
	const handleModal = (data, esNivelServicio, grupo) => {
		const startTime = new Date();
		getDataIndicator(data, esNivelServicio, grupo);

			setTimeout(() => {
				const endTime = new Date();
				const tdrId = 92;
				crearRegistroTDR(startTime, endTime, usuario, tdrId);
			}, 0);
		return toggleModal(true);
	}

	return (
		<>
			<tr>
				<td>
					<div className="d-flex align-items-center gap-2">
						<button className={toggle ? 'btn-collapse rotate' : 'btn-collapse'} onClick={() => HandleExpand(!toggle)}>
							<i className="ico-chevron-down"></i>
						</button>
						<span className="badge badge--monochromatic bg-primary">{indicador.detalle.sigla}</span>
						<strong>{indicador.detalle.descripcion}</strong>
					</div>
				</td>
				<td colSpan="2">
					<span className={`badge badge--monochromatic bg-primary`}>{`${indicador.detalle.porcentajeAlertaPrevia}%`}</span>
				</td>
				<td>
					<div className="form-check form-switch d-flex justify-content-center align-items-center p-0">
						<input
							className="form-check-input mx-0"
							type="checkbox"
							role="switch"
							id={`auth-${indicador.detalle.sigla}`}
							checked={isActive}
							onChange={() => handleModalConfirmar(indicador.detalle, true, grupoPerteneciente)}
						/>

					</div>
				</td>
				<td>
					<button className="link link--border text-pewter-blue fs-5" onClick={() => handleModal(indicador.detalle, true, grupoPerteneciente)}>
						<i className="ico-doc-edit"></i>
					</button>
				</td>
			</tr>
			{indicador.hijos ?
				<tr className='border-top'>
					<td colSpan='5' className="p-0">
						<div className={toggle ? 'children expanded' : 'children'}>
							<table>
								<tbody>
									{indicador.hijos.map((item, index) =>
										<IndicadorSecundario key={index} item={item} grupo={indicador.hijos} />
									)}
								</tbody>
							</table>
						</div>
					</td>
				</tr>
				: null
			}
		</>
	)
}
export default IndicadorPrincipal;
