import React, { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../Components/Button/Button';
import Exports from '../../../Components/Exports/Exports';
import { useColumnasHallazgo } from '../Utils/ColumnsHallazgos';
import { Form, Formik } from 'formik';
import RangeDatePicker from '../../../Components/RangeDatePicker/RangeDatePicker';
import { ResumenEstadisticasHallazgosService } from '../Services/ResumenEstadisticasHallazgosService';
import moment from 'moment';
import { EnumPathRutas, EnumTablaPaginacion, EnumTipoArchivos } from '../../../Utils/Enumeradores/ComunEnum';
import validations from '../../../Utils/Validations/Validations';
import TablaEstadiscticasHallazgos from '../Components/TablaEstadiscticasHallazgos';
import ModalSolicitudDescarga from '../Components/ModalSolicitudDescarga';
import { useComun } from '../../../Hooks/useComun';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { EnumModuloDesc } from "../../../Utils/Enumeradores/ComunEnum";
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const EstadisticasDeHallazgosPage = () => {
	const { downloadCSV } = useComun();
	const [datosBusqueda, setDatosBusqueda] = React.useState({ fechaInicio: moment().subtract(1, 'M').format(), fechaTermino: moment().format() });
	const [show, setShow] = React.useState(false);
	const handleClose = () => setShow(false);
	const [datosModal, setDatosModal] = React.useState({});
	function handlerMostrarModal(tipo, data) {
		const tipoArchivo = tipo === EnumTipoArchivos.PDF ? false : EnumTipoArchivos.XLS ? true : false;
		const areaNegocioId = data ? data.areaNegocioId : 0; //este se manda en 0 por logica de backend (este es el descargar de toda la tabla)
		const formEnvio = {
			areaNegocioId: areaNegocioId,
			formatoEcxel: tipoArchivo // el false indica que se descarge en excel
		}
		setDatosModal(formEnvio);
		setShow(true);
	}
	const { COLUMNS } = useColumnasHallazgo({ mostrarModal: handlerMostrarModal });
	const columns = useMemo(() => COLUMNS, []);
	const { user, usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const [data, setData] = React.useState([]);
	const [pageCount, setPageCount] = React.useState(0);
	const [{ pageIndex, pageSize, sortBy }, setPagination] =
		React.useState({
			pageIndex: 0,
			pageSize: EnumTablaPaginacion.CantidadPaginacion,
			sortBy: []
		});
	const [initialValues, setInitialValues] = useState({
		periodo2: [moment().subtract(1, 'M').toDate(), new Date()]
	})
	const [esDescargar, setEsDescargar] = React.useState();

	async function postResumenEstadisticasHallazgos(values) {
		const startTime = new Date();
		const resp = await ResumenEstadisticasHallazgosService(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					if (values.datosBusqueda.exportacion) {
						const fetchData = async () => {
							await Promise.all([
								downloadCSV(resp.data, "Informe Estadisticas Hallazgos", EnumModuloDesc.DescargaInformeEstadisticasHallazgos)
							]);
							const endTime = new Date();
							const tdrId = 60;
							crearRegistroTDR(startTime, endTime, usuario, tdrId);
						};

						fetchData();
					} else {
						setPageCount(resp.data.cantidadPaginas);
						setData(resp.data.listadoResumen);
					}
				}
			} else if (resp.status === 206) {
				setPageCount(0);
				setData([]);
			}
		}
	}

	const fetchData = React.useCallback(({ formulario, pageIndex, sortBy = [], exportacion = false }) => {
		const startTime = new Date();
		formulario.paginacion.numeroPagina = pageIndex + 1;
		formulario.paginacion.ordenadoPor = sortBy.length > 0 ? sortBy[0].id : formulario.paginacion.ordenadoPor;
		formulario.paginacion.orderByAscending = sortBy.length > 0 ? (sortBy[0].desc ? false : true) : formulario.paginacion.orderByAscending;
		formulario.datosBusqueda.exportacion = exportacion;
		const fetchData = async () => {
			await Promise.all([
				postResumenEstadisticasHallazgos(formulario)
			]);
			const endTime = new Date();
			const tdrId = 58;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
	}, []);

	const descargar = () => {
		setEsDescargar(true);
	};

	function BuscarEstadisticas(values) {
		const startTime = new Date();
		const fetchData = async () => {
			await Promise.all([
				setDatosBusqueda({
					fechaInicio: values.periodo2[0]
					, fechaTermino: values.periodo2[1]
				})
			]);
			const endTime = new Date();
			const tdrId = 59;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();

	}

	return (
		<div className="container-fluid">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
							<li><Link to={EnumPathRutas.Informes}>Informes</Link></li>
							<li><a href="#">Estadística Hallazgos</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="bg-white rounded p-4">
						<div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-5 gap-3">
							<Formik
								enableReinitialize
								initialValues={initialValues}
								validate={validations}
								onSubmit={(values) => {
									BuscarEstadisticas(values);
								}}
							>
								{({ values, setFieldValue, setFieldTouched, }) => (
									<Form className="form mb-0">
										<div className="tools order-1 order-lg-0 align-items-start">
											<RangeDatePicker
												startMaxDate={new Date()}
												startMinDate={null}
												endMaxDate={new Date()}
												endMinDate={null}
												name="periodo2"
												onChange={setFieldValue}
												onBlur={setFieldTouched}
											/>
											<Button type="submit" design="btn-primary">Buscar</Button>
										</div>
									</Form>
								)}
							</Formik>
							<div className="exports order-0 order-lg-1">
								<Exports descargar={() => descargar()}
								/>
							</div>
						</div>
						<TablaEstadiscticasHallazgos
							columns={columns}
							data={data}
							fetchData={fetchData}
							pageCount={pageCount}
							currentpage={pageIndex}
							pageSize={pageSize}
							sortBy={sortBy}
							setPagination={setPagination}
							datosBusqueda={datosBusqueda}
							esDescargar={esDescargar}
							setEsDescargar={setEsDescargar}
						/>
					</div>
				</div>
			</div>
			<ModalSolicitudDescarga
				show={show}
				datosModal={datosModal}
				datosBusqueda={datosBusqueda}
				onHide={handleClose}
			/>
		</div>
	)
}

export default EstadisticasDeHallazgosPage;
