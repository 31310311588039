import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ListaTipoHallazgoService = async (usuario, perfil) => {
    try {
        const resultado = await instance.Backend.post("/Hallazgo/TipoHallazgo", customConfig);

        return resultado;

    } catch (error) {

        console.log(error);

    }

}
export { ListaTipoHallazgoService };