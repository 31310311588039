import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ObtenerAreasNegocioIdUsuario = async (idUsuario, idPerfil) => {
    try {
        const resultado = await instance.Backend.post("/Usuario/ObtenerAreasNegocioIdUsuario",
        {
            usuarioId: idUsuario,
            perfilId: idPerfil
        }, customConfig);
        
        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { ObtenerAreasNegocioIdUsuario };