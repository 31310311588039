import { useEffect, useMemo, useState } from 'react';
import DATA from '../Mock/mock_data_datamart.json';
import Card from "../../../Components/Card/Card";
import EsquemaDataMart from '../Components/EsquemaDataMart';
import { useDatamart } from '../Hooks/useDatamart';
import ModalFormula from '../Components/ModalFormula';
import { Link } from 'react-router-dom';
import useLogin from '../../Autenticacion/Hooks/useLogin'
import { EnumPathRutas } from '../../../Utils/Enumeradores/ComunEnum';

const DatamartPage = () => {
	const { user, usuario } = useLogin();
	const { listadoNivelServicio, ObtenerListadoNivelServicio } = useDatamart();

	useEffect(() => {
		ObtenerListadoNivelServicio(user.id, usuario.perfilContexto.id);
	}, [])
	return (
		<div className="container-fluid">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
							<li><a href="#">Datamart</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<Card className="p-4">
						<EsquemaDataMart
							data={listadoNivelServicio}
						/>
					</Card>
				</div>
			</div>
			<ModalFormula />
		</div>
	)
}
export default DatamartPage;
