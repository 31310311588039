import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const EstadoHallazgoService = async () => {
    try {
        const resultado = await instance.Backend.post("/Hallazgo/EstadoHallazgo", customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export { EstadoHallazgoService };