import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ListaIndicadoresIngresoHallazgoService = async (usuario, perfil) => {
    try {
        const resultado = await instance.Backend.post("/Indicador/ListaIndicadoresIngresoHallazgo", {
            usuarioId: usuario,
            perfilId: perfil
        }, customConfig);

        return resultado;

    } catch (error) {

        console.log(error);

    }

}
export { ListaIndicadoresIngresoHallazgoService };