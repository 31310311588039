import { useEffect, useRef, useState } from 'react';
import Button from "../../../Components/Button/Button";
import { Form, Formik } from 'formik';
import { usePagination, useSortBy, useTable } from 'react-table';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import RangeDatePicker from '../../../Components/RangeDatePicker/RangeDatePicker';
import SelectAutocompleteField from '../../../Components/SelectAutocompleteField/SelectAutocompleteField';
import moment from 'moment';
import PaginationComponent from '../../../Components/PaginationTableServer/PaginationTableServer';
import { EnumCategorias } from '../../../Utils/Enumeradores/ComunEnum';
import { useNavigate } from 'react-router-dom';
import { useModalPerfil } from '../../../Hooks/useModalPerfil';
import { ActualizarNotificacionLeidaService } from '../Services/ActualizarNotificacionLeidaService';
import { useToast } from '../../../Hooks/useToast';
import Exports from '../../../Components/Exports/Exports';
import SelectCheckField from '../../../Components/SelectCheckField/SelectCheckField';
import notfound from '../../../Assets/images/il-empty.svg';
import validations from '../../../Utils/Validations/Validations';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const TablaNotificacion = ({ columns, data, fetchData, pageCount: controlledPageCount, currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy, setPagination, listadoNotificacionesSeleccionable }) => {
	const {
		getTableProps
		, getTableBodyProps
		, headerGroups
		, prepareRow
		, page
		, pageCount
		, gotoPage
		, setSortBy
		, state: { pageIndex, pageSize, sortBy }
	} = useTable(
		{
			columns
			, data
			, initialState: { pageIndex: currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy }
			, manualPagination: true
			, pageCount: controlledPageCount
			, autoResetPage: false
			, autoResetSortBy: false
			, manualSortBy: true
		},
		useSortBy
		, usePagination
	)

	const { usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const navigate = useNavigate();
	const { addShow } = useModalPerfil();
	const addToast = useToast();
	const [initialValues, setInitialValues] = useState({
		notificaciones: listadoNotificacionesSeleccionable.map(x => { return x.value.toString() }),
		periodo2: [moment().subtract(1, 'M').toDate(), new Date()],
		leido: false
	});
	const formikRef = useRef();

	const [valoresFormularioEnvio, setValoresFormularioEnvio] = useState({
		paginacion: {
			numeroPagina: pageIndex
			, cantidadRegistros: pageSize
			, orderByAscending: false
			, ordenadoPor: ""
		},
		datosBusqueda: {
			usuarioId: usuario.id
			, perfilContextoId: usuario.perfilContexto.id
			, tipoNotificacionId: listadoNotificacionesSeleccionable.map(x => { return Number(x.value) })
			, fechaInicio: moment().subtract(1, 'M').format()
			, fechaTermino: moment().format()
			, exportacion: false
			, leido: false
		}
	});

	useEffect(() => {
		fetchData({ formulario: valoresFormularioEnvio, pageIndex, sortBy });
		/* GUARDAMOS EL SORTBY EN EL USESTATE DE PAGINATION QUE SE ENCUENTRA EN ALARMASPAGE PARA QUE NO SE PIERDA EL ORDENAMIENTO */
		setPagination(values => ({
			...values
			, sortBy: sortBy
		}));
	}, [pageIndex, sortBy])

	useEffect(() => {
		setInitialValues(values => ({
			...values
			, notificaciones: listadoNotificacionesSeleccionable.map(x => { return x.value.toString() })
		}));
	}, [listadoNotificacionesSeleccionable])

	function BuscarNotificaciones(values) {
		const startTime = new Date();
		let formEnvio = valoresFormularioEnvio;
		/* Se setean valores del paginado para la primera busqueda (resetea la tabla) */
		formEnvio.paginacion.numeroPagina = 0;
		formEnvio.paginacion.orderByAscending = false;
		formEnvio.paginacion.ordenadoPor = "";
		/* Se setean los valores de los campos que ingresa el usuario */
		formEnvio.datosBusqueda.tipoNotificacionId = values.notificaciones.map(Number);
		formEnvio.datosBusqueda.fechaInicio = values.periodo2[0];
		formEnvio.datosBusqueda.fechaTermino = values.periodo2[1];
		formEnvio.datosBusqueda.leido = values.leido;
		const fetchData = async () => {
			await Promise.all([
				setValoresFormularioEnvio(formEnvio)
			]);
			const endTime = new Date();
			const tdrId = 44;
			const tiempoTranscurrido = endTime.getTime() - startTime.getTime();
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
		setPagination({
			pageIndex: formEnvio.paginacion.numeroPagina,
			pageSize: formEnvio.paginacion.cantidadRegistros,
			sortBy: []
		});
		setSortBy([]);
		gotoPage(0);
	}

	function Enrutamiento(item) {
		const { categoria, usuarioId } = item;
		switch (categoria) {
			case EnumCategorias.Hallazgos:
				navigate('/hallazgos', { state: { hallazgoId: item.hallazgoId } }); //PENDIENTE A REALIZAR MODULO
				return;
			case EnumCategorias.Mediciones:
				navigate('/bitacora/eventos_concesion'); //PENDIENTE A REALIZAR MODULO
				return;
			case EnumCategorias.Usuario:
				//logica de modal perfil usuario
				addShow(true);
				return;
			case EnumCategorias.Autorizaciones:
				navigate('/autorizaciones');
				return;
			default:
				return;
		}
	}
	async function postActualizarNotificacionLeida(values) {
		const resp = await ActualizarNotificacionLeidaService(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					addToast({ mostrar: true, mensaje: 'Se ha leído la notificación correctamente.', titulo: 'Confirmación', tipo: 'Success' });
					fetchData({ formulario: valoresFormularioEnvio, pageIndex, sortBy });
				}
			}
		}
	}
	function ChangeNotificacionLeida(item) {
		if (!item.leido) {
			const formEnvio = {
				usuarioId: usuario.id,
				notificacionId: [item.notificacionId]
			}
			postActualizarNotificacionLeida(formEnvio);
		}
	}
	const descargar = () => {
		fetchData({ formulario: valoresFormularioEnvio, pageIndex, sortBy, exportacion: true });
	};
	const onChangeleido = event => {
		setInitialValues({ ...formikRef.current.values, leido: event.target.checked });
	};
	return (
		<>
			<div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-5 gap-3">
				<Formik
					innerRef={formikRef}
					enableReinitialize
					initialValues={initialValues}
					validate={validations}
					onSubmit={values => {
						BuscarNotificaciones(values);
					}}
				>
					{({ setFieldValue, setFieldTouched, }) => (
						<Form className="form mb-0">
							<div className="tools order-1 order-lg-0 align-items-start">
								<div className='simple-select'>
									<SelectCheckField name="notificaciones" label="Tipo de notificación"
										options={listadoNotificacionesSeleccionable}
										onChange={setFieldValue}
										onBlur={setFieldTouched}
										isMulti={true}
									/>
								</div>
								<RangeDatePicker
									startMaxDate={new Date()}
									startMinDate={null}
									endMaxDate={new Date()}
									endMinDate={null}
									name="periodo2"
									onChange={setFieldValue}
									onBlur={setFieldTouched}
								/>
								<div className="form-floating">
									<div className="form-check form-switch d-flex justify-content-center align-items-center p-1 pt-3 gap-2">
										<input
											name="leido"
											className="form-check-input mx-0"
											type="checkbox"
											role="switch"
											checked={initialValues.leido}
											onChange={onChangeleido}
										/>
										<label className="lh-2">Leído</label>
									</div>
								</div>
								<Button type="submit" design="btn-primary">Buscar</Button>
							</div>
						</Form>
					)}
				</Formik>
				{
					data.length > 0 &&
					<div className="exports order-0 order-lg-1">
						<Exports descargar={() => descargar()} />
					</div>
				}
			</div>
			<div className="table-responsive brd-top">
				<table className="table" {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									data.length > 0 ?
										<th {...column.getHeaderProps(column.getSortByToggleProps(), { className: column.className })}>
											<span className="d-flex align-items-center gap-1">
												{column.render('Header')}
												<>
													{column.isSorted ?
														(column.isSortedDesc ?
															<i className="ico-chevron-down"></i>
															:
															<i className="ico-chevron-up"></i>
														) :
														<>
															<i className="ico-sort fs-7"></i>
														</>
													}
												</>
											</span>
										</th>
										:
										<th {...column.getHeaderProps()}
										>
											<span className="d-flex align-items-center gap-1">
												{column.render('Header')}
											</span>
										</th>
								))
								}
								<th>&nbsp;</th>
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{
							page.length > 0 && page.map((row) => {
								prepareRow(row)
								return (
									<tr className={`${row.original.leido ? '' : 'active'}`}
										{...row.getRowProps()} onClick={() => { ChangeNotificacionLeida(row.original) }}
									>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps({
												className: cell.column.className
											})}>
												{cell.render('Cell')}
											</td>
										})
										}
										<td className="text-center">
											{row.original.categoria !== EnumCategorias.Indicadores ?
												<button className="link text-pewter-blue fs-4" onClick={() => { Enrutamiento(row.original) }}>
													<i className="ico-chevron-right"></i>
												</button>
												: null}

										</td>
									</tr>
								)
							}) || <tr><td colSpan={headerGroups[0].headers.length + 1}>
								<div className="d-flex justify-content-center align-items-center flex-column gap-2 py-5">
									<img className="mb-3" src={notfound} alt="" />
									<p className='mb-0 text-primary'>No se encuentran resultados de tu búsqueda.</p>
								</div>
							</td></tr>
						}
					</tbody>
				</table>
			</div>
			{ }
			{
				data.length > 0 &&
				<PaginationComponent
					pagesCountServer={pageCount}
					currentPage={pageIndex}
					setCurrentPage={gotoPage}
					alwaysShown={false}
					setPagination={setPagination}
				/>
			}
		</>
	)
}

export default TablaNotificacion;
