import { EnumEstadoEventoConcesion } from "../../../Utils/Enumeradores/ComunEnum";

export const AccionesCelda = (props) => {
	const { data, handleShowModal } = props;
	const { Autorizado, Rechazado, Pendiente } = EnumEstadoEventoConcesion;

	const Modificar = async (value) => {
		const esAutorizado = value === 1 ? 1 : 2;
		handleShowModal([data.eventoConcesionId], false, esAutorizado);
	}

	return (
		<div className='d-flex justify-content-end gap-2 ms-auto me-0'>
			<button className='btn btn--outline btn--pewter-blue btn--xs' disabled={data.estado !== Pendiente} onClick={() => Modificar(Autorizado)}>
				<i className='ico-check'></i>
			</button>
			<button className='btn btn--outline btn--pewter-blue btn--xs' disabled={data.estado !== Pendiente} onClick={() => Modificar(Rechazado)}>
				<i className='ico-close'></i>
			</button>
		</div>
	)
}
