import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ObtenerAlarmasPersonalizadasService = async (idUsuario,idPerfilContexto) => {
    try {
        const resultado = await instance.Backend.post("/Alarma/ObtenerAlarmasPersonalizadas",
            { 
                idUsuario: idUsuario, 
                IdPerfilContexto: idPerfilContexto
            }, customConfig
        );
        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { ObtenerAlarmasPersonalizadasService };