import instance from "../../../Utils/Interceptors/Interceptor";
 async function autenticarUsuario(usuario, clave) {

  try {
    if(process.env.REACT_APP_ESLOCAL){
      return await instance.Atenticacion.post(process.env.REACT_APP_URL_LOGIN, {
        usuario: usuario,
        clave: clave
      });
    }else{
      return await instance.Backend.post(process.env.REACT_APP_URL_LOGIN, {
        usuario: usuario,
        clave: clave
      });
    }
    

  } catch (error) {
    console.log(error);
  }

}
export { autenticarUsuario };