import { useState, useContext } from 'react';
import IndicadorTerciario from "./IndicadorTerciario";
import { PonderacionContext } from '../Context/PonderacionContext';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';
import useLogin from '../../Autenticacion/Hooks/useLogin';

const IndicadorSecundario = (props) => {
	const { crearRegistroTDR } = useRegistroTDR();
	const {usuario} = useLogin();
	const indicador = props.item;
	const grupoPerteneciente = props.grupo;
	const [toggle, setToggle] = useState(true);
	const { toggleModal, getDataIndicator } = useContext(PonderacionContext);
	const HandleExpand = (action) => {
		setToggle(action)
	}
	const handleModal = (data, grupo) => {
		const startTime = new Date();
		getDataIndicator(data, true, grupo);

			setTimeout(() => {
				const endTime = new Date();
				const tdrId = 64;
				crearRegistroTDR(startTime, endTime, usuario, tdrId);
			}, 0);
		return toggleModal(true);
	}
	return (
		<>
			<tr className="skeleton-level-2">
				<td>
					<div className="d-flex align-items-center gap-2">
						<button className={toggle ? 'btn-collapse rotate' : 'btn-collapse'} onClick={() => HandleExpand(!toggle)}>
							<i className="ico-chevron-down"></i>
						</button>
						<i className="indicator ico-enter"></i>
						<span className="badge badge--monochromatic">{indicador.detalle.sigla}</span>
						<span className="name">{indicador.detalle.descripcion}</span>
					</div>
				</td>
				<td colSpan="2">
					<span className={`badge badge--monochromatic`}>{`${indicador.detalle.ponderacion}%`}</span>
				</td>
				<td className="">
					{indicador.detalle.editable &&
						<button className="link link--border text-pewter-blue fs-5" onClick={() => handleModal(indicador.detalle, grupoPerteneciente)}>
							<i className="ico-doc-edit"></i>
						</button>
					}

				</td>
			</tr>
			{indicador.hijos ?
				<tr className='border-top'>
					<td colSpan='4' className="p-0">
						<div className={toggle ? 'children expanded' : 'children'}>
							<table>
								<tbody>
									{indicador.hijos.map((item, index) =>
										<IndicadorTerciario key={index} item={item} grupo={indicador.hijos} />
									)}
								</tbody>
							</table>
						</div>
					</td>
				</tr>
				: null
			}
		</>
	)
}
export default IndicadorSecundario;
