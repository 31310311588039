import Select from 'react-select';
import { useField } from "formik";

const SelectAutocompleteField = ({ label, ...props }) => {
	const { options, value, name } = props;
	const [field, meta] = useField(props);

	const handleChange = data => {
		props.onChange(name, Array.isArray(data) ? ((data.length === 0) ? '' : data.map((item) => item.value)) : data.value);
	};

	const handleBlur = () => {
		props.onBlur(name, true);
	};

	const defaultValue = (options) => {
		return options ? options.filter(option => Array.isArray(field.value) ? field.value.indexOf(option.value) >= 0 : option.value === field.value) : '';
	}

	return (
		<div className="form-floating">
			<div className={`${props.helpers} simple-select`}>
				<label htmlFor={`field-${props.name}`}>{label}</label>
				<Select
					{...props}
					{...field}
					classNamePrefix="selector"
					className={`${meta.touched && meta.error ? 'form-control is-invalid' : ''}`}
					id={`field-${props.name}`}
					name={name}
					options={options}
					value={defaultValue(options)}
					onChange={handleChange}
					onBlur={handleBlur}
					placeholder="Seleccionar"
					noOptionsMessage={() => "Sin opciones"}
				/>
			</div>
			{meta.touched && meta.error ?
				<small className="message">{meta.error}</small> : null}
		</div>
	)
}
export default SelectAutocompleteField;
