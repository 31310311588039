import React, { Fragment, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import range from "lodash/range";
import { getMonth, getYear } from 'date-fns';
import es from 'date-fns/locale/es';
import { useField } from "formik";
import { Portal } from 'react-overlays'
registerLocale('es', es);

const CalendarContainer = ({ children }) => {
    const el = document.getElementById('calendar-portal')

    return (
        <Portal container={el}>
            {children}
        </Portal>
    )
}

const RangeDatePicker = ({ startMaxDate, startMinDate, endMaxDate, endMinDate, ...props }) => {
    const { name, labels } = props;
    const [field, meta] = useField(props);
    const { value } = field;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const years = range(getYear(new Date()) - 5, getYear(new Date()) + 1, 1);
    const months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ];
    const datepickerFechaInicioRef = useRef(null);
    const datepickerFechaFinRef = useRef(null);

    const handleSelectStart = (date) => {
        setStartDate(date)
        props.onChange(name, [date, endDate])
    }

    const handleSelectEnd = (date) => {
        setEndDate(date);
        props.onChange(name, [startDate, date])
    }

    useEffect(() => {
        if (value) {
            if (Array.isArray(value)) {
                setStartDate(value[0]);
                setEndDate(value[1]);
            }
        } else {
            setStartDate(null);
            setEndDate(null);
        }
    }, [value])

    useEffect(() => { //esta es lógica para cuando se seleccione una fecha se cierre el calendario.
        if (startDate !== null) {
            if (datepickerFechaInicioRef.current.state.open) {
                datepickerFechaInicioRef.current.setOpen(false);
            }
        }
        if (endDate !== null) {
            if (datepickerFechaFinRef.current.state.open) {
                datepickerFechaFinRef.current.setOpen(false);
            }
        }
    }, [startDate, endDate])

    function handleClickDatepickerFechaInicio() {
        const datepickerElement = datepickerFechaInicioRef.current;
        datepickerElement.setOpen(true);
    }
    function handleClickDatepickerFechaFin() {
        const datepickerElement = datepickerFechaFinRef.current;
        datepickerElement.setOpen(true);
    }

    const CustomInputDesde = React.forwardRef((props, ref) => {
        return (
            <input
                id={`datePcker-${props.name}`}
                className={`form-control ${(meta.touched && meta.error && !Array.isArray(meta.error)) ? 'is-invalid' : Array.isArray(meta.error) && meta.error[0] !== "" ? 'is-invalid' : ""}`}
                value={props.value}
                readOnly={true}
                ref={ref}
                placeholder={props.name}
            />
        )

    })
    const CustomInputHasta = React.forwardRef((props, ref) => {
        return (

            <input
                id={`datePcker-${props.name}`}
                className={`form-control ${(meta.touched && meta.error && !Array.isArray(meta.error)) ? 'is-invalid' : Array.isArray(meta.error) && meta.error[1] !== "" ? 'is-invalid' : ""}`}
                value={props.value}
                readOnly={true}
                ref={ref}
                placeholder={props.name}
            />
        )

    })
    return (
        <Fragment>
            <div className='form-floating' onClick={() => handleClickDatepickerFechaInicio()}>
                <span className="datepicker-label" htmlFor={`datePcker-${labels ? labels[0] : "Desde"}`}>{labels ? labels[0] : "Desde"}</span>
                <DatePicker
                    locale='es'
                    ref={datepickerFechaInicioRef}
                    {...field}
                    {...props}
                    name={labels ? labels[0] : "Desde"}
                    customInput={<CustomInputDesde />}
                    wrapperClassName='rangePicker'
                    dateFormat='dd-MM-yyyy'
                    maxDate={startMaxDate}
                    minDate={startMinDate}
                    placeholderText={labels ? labels[0] : "Desde"}
                    renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div
                            style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                {"<"}
                            </button>
                            <select
                                value={getYear(date)}
                                onChange={({ target: { value } }) => changeYear(value)}
                            >
                                {years.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={months[getMonth(date)]}
                                onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                }
                            >
                                {months.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                {">"}
                            </button>
                        </div>
                    )}
                    selected={startDate}
                    onChange={(date) => { handleSelectStart(date); }}
                />
                {meta.touched && meta.error && !Array.isArray(meta.error) ?
                    <small className="message">{meta.error}</small>
                    : Array.isArray(meta.error) && meta.error[0] ?
                        <small className="message">{meta.error[0]}</small> : null}
            </div>


            <div className='form-floating' onClick={() => handleClickDatepickerFechaFin()}>
                <span className="datepicker-label" htmlFor={`datePcker-${labels ? labels[1] : "Hasta"}`}>{labels ? labels[1] : "Hasta"}</span>
                <DatePicker
                    locale='es'
                    {...field}
                    {...props}
                    ref={datepickerFechaFinRef}
                    name={labels ? labels[1] : "Hasta"}
                    customInput={<CustomInputHasta />}
                    wrapperClassName='rangePicker'
                    dateFormat='dd-MM-yyyy'
                    maxDate={endMaxDate}
                    minDate={endMinDate}
                    placeholderText={labels ? labels[1] : "Hasta"}
                    renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div
                            style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                {"<"}
                            </button>
                            <select
                                value={getYear(date)}
                                onChange={({ target: { value } }) => changeYear(value)}
                            >
                                {years.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={months[getMonth(date)]}
                                onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                }
                            >
                                {months.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                {">"}
                            </button>
                        </div>
                    )}
                    selected={endDate}
                    onChange={(date) => { handleSelectEnd(date); }}
                />
                {meta.touched && meta.error && !Array.isArray(meta.error) ?
                    <small className="message">{meta.error}</small>
                    : Array.isArray(meta.error) && meta.error[1] ?
                        <small className="message">{meta.error[1]}</small> : null}
            </div>

        </Fragment>
    );
}

export default RangeDatePicker;
