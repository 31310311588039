import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";


const ModificarEstadoEventoConcesionServices = async (values) => {
    
    try {

        const resultado = await instance.Backend.post("/Autorizacion/ModificarEstadoEventoConcesion", {
            idUsuario: values.idUsuario,
            eventoConcesionIds: values.eventoConcesionIds,
            estado: values.estado,
        }, customConfig);
        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { ModificarEstadoEventoConcesionServices };