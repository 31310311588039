import { ListadoExigencia } from "../Services/ListadoExigenciaService";
import { useCallback, useState } from 'react'
import useLogin from "../../Autenticacion/Hooks/useLogin";

export function useHallazgo(props) {
    const [listadoExigencia, setListadoExigencia] = useState([]);
    const { user, usuario } = useLogin();
    async function postListaExigencias(values) {
        const resp = await ListadoExigencia(values, usuario.id);
        if (resp) {
            if (resp.status === 200) {
                let listado = [];
                resp.data.map((value) => { listado.push({ value: value.id, label: value.descripcion }) });
                setListadoExigencia(listado);
            }
        }
    };

    const getListadoExigencia = useCallback(
        function (values) {
            postListaExigencias(values);
        },
        []
    );

    return { listadoExigencia, getListadoExigencia }
}