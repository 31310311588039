import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ModificarEstadoAlarmaPerfilService = async (values) => {
    try {
        const resultado = await instance.Backend.post("/Alarma/ModificarEstadoAlarmaPerfil", values, customConfig);
        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { ModificarEstadoAlarmaPerfilService };