import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../../../Components/Button/Button';
import { useToast } from '../../../Hooks/useToast';
import { CambiarEstado } from '../Services/CambiarEstadoService';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const ModalEstadoUsuario = (props) => {
	const { usuario, handlerCambioEstadoUsuario, handlerRenderizarListado, ...rest } = props;
	const [mensajeModal, setMensajeModal] = useState({ titulo: "", texto: "" });
	const addToast = useToast();
	const { crearRegistroTDR } = useRegistroTDR();

	const btnCambiarEstado = async () => {
		const startTime = new Date();
		//API CAMBIO ESTADO
		const values = {
			id: usuario.id,
			movimiento: (usuario.estado === false) ? true : false
		}
		const resp = await CambiarEstado(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					const fetchData = async () => {
						await Promise.all([
							handlerCambioEstadoUsuario(),
							handlerRenderizarListado(),
							addToast({ mostrar: true, mensaje: 'Se ha enviado su solicitud correctamente.', titulo: 'Confirmación', tipo: 'Success' }),
						]);
						const endTime = new Date();
						const tdrId = 68;
						crearRegistroTDR(startTime, endTime, usuario, tdrId);
					};

					fetchData();
				}
			}
		}

		rest.onHide();
	}
	useEffect(() => {
		if (usuario.estado === false && (usuario.estadoAutorizacion === 2 || usuario.estadoAutorizacion === 3)) {
			setMensajeModal({ titulo: "¿Estás seguro que deseas dar de alta al " + usuario.nombres + " " + usuario.apellidoPaterno + " " + usuario.apellidoMaterno + "?", texto: "La alta será efectiva previa autorización Inspector Fiscal" });
		} else
			if (usuario.estado === true && (usuario.estadoAutorizacion === 2 || usuario.estadoAutorizacion === 3)) {
				setMensajeModal({ titulo: "¿Estás seguro que deseas dar de baja al " + usuario.nombres + " " + usuario.apellidoPaterno + " " + usuario.apellidoMaterno + "?", texto: "La baja será efectiva previa autorización Inspector Fiscal" });
			}
	}, [props]);
	return (
		<Modal
			{...rest}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				<div className='info my-4'>
					<span className='info__icon'>
						<i className='ico-user-delete'></i>
					</span>
					<h6 className='info__title'>{mensajeModal.titulo}</h6>
					<p className='info__text'>{mensajeModal.texto}</p>
				</div>
				<div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4">
					<Button type="button" design="btn-outline-pewter-blue btn--small" onClick={rest.onHide}>Cancelar</Button>
					<Button type="button" design="btn-primary btn--small" onClick={btnCambiarEstado}>Aceptar</Button>
				</div>
			</Modal.Body>
		</Modal>
	)
}
export default ModalEstadoUsuario;
