import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ObtenerTipoAlarmaServices = async (idUsuario) => {
    try {
        const resultado = await instance.Backend.post("/Alarma/ObtenerTiposAlarmas",
        {
            UsuarioId: idUsuario
        }, 
        customConfig);
        
        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { ObtenerTipoAlarmaServices };