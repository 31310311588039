import instance, { customConfig } from "../Utils/Interceptors/Interceptor";


async function ObtenerListadoUsuarios(estado, idUsuario){

    try {

        const resultado = await instance.Backend.post("/Usuario/ObtenerUsuarios",{
            estado:estado,
            idUsuario: idUsuario
        }, customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export {ObtenerListadoUsuarios};