import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

async function ObtenerListadoNivelServicioService(id, idPerfilContexto) {
    try {
        return await instance.Backend.post("/Indicador/ListaNivelServicio", {
            idUsuario: id,
            idPerfilContexto: idPerfilContexto
        }, customConfig);
    } catch (error) {
        console.log(error);
    }
}
export { ObtenerListadoNivelServicioService };