import { useField } from "formik";

const InputRadioField = ({label, ...props}) => {
	const [field, meta] = useField({...props, type: 'radio'});
	return (
		<div>
		<div className="d-flex form-check text-pewter-blue mb-0">
			<input
				id={`field-${props.name}-${label.replaceAll(/[^a-zA-Z]+/g,'')}`}
				name={props.name}
				type="radio"
				className="form-check-input me-2"
				{...field}
				{...props}
			/>
			<label className="form-check-label fs-5" role="button" htmlFor={`field-${props.name}-${label.replaceAll(/[^a-zA-Z]+/g,'')}`}>{label}</label>
		</div>
			{props.orden ? props.orden === "1" ?
				<div>
			{meta.touched && meta.error &&
        <small className="message">{meta.error}</small>
      }
			</div>
			:
			null
			:
			null
			}
		</div>
	);
}
export default InputRadioField;
