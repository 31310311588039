import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const recuperarClave = async (email) => {

  try {
    const resultado = await instance.Backend.post("/Usuario/RecuperarContrasenaEmail",
      {email}, customConfig
    );

    return resultado

  } catch (error) {

    console.log(error);

  }

}
export { recuperarClave };