import { useCallback, useContext, useState } from 'react'
import { useComun } from '../../../Hooks/useComun';
import { DatamartContext } from '../Context/DatamartContext';
import { ObtenerListadoNivelServicioService } from '../Services/ObtenerListadoNivelServicioService';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';
import useLogin from '../../Autenticacion/Hooks/useLogin';


export function useDatamart() {
	const [listadoNivelServicio, setListadoNivelServicio] = useState([]);
	const { visible, indicator, toggleModal, getDataIndicator } = useContext(DatamartContext);
	const { ValidarListaMetodoRecursivoXNivelServicioPadreId } = useComun();
	const { user, usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();

	async function postObtenerListadoNivelServicio(id, idPerfilContexto) {
		const startTime = new Date();
		const resp = await ObtenerListadoNivelServicioService(id, idPerfilContexto);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					let listaAux = resp.data.nivelServicio;
					let resultadoLista = [];
					if (listaAux.filter(y => y.nivelServicioPadreId === null).length > 0) {
						resultadoLista = ValidarListaMetodoRecursivoXNivelServicioPadreId(resp.data.nivelServicio, null, 0, []);
					} else {
						const numeroMin = listaAux.reduce((n,m) => Math.min(n,m.nivelServicioPadreId), Number.POSITIVE_INFINITY)
						resultadoLista = ValidarListaMetodoRecursivoXNivelServicioPadreId(resp.data.nivelServicio, numeroMin, 0, []);
					}
					const fetchData = async () => {
						await Promise.all([
							setListadoNivelServicio(resultadoLista)
						]);
						const endTime = new Date();
						const tdrId = 14;
						crearRegistroTDR(startTime, endTime, usuario, tdrId);
					};

					fetchData();
				}
			}
		}
	}

	const ObtenerListadoNivelServicio = useCallback(
		function (id, idPerfilContexto) {
			postObtenerListadoNivelServicio(id, idPerfilContexto);
		},
		[]
	);

	const handleModal = (data, esNivelServicio) => {
		getDataIndicator(data, esNivelServicio);
		toggleModal();
	}

	return {
		listadoNivelServicio
		, visible
		, indicator
		, ObtenerListadoNivelServicio
		, handleModal
		, toggleModal
	}
}
