import { useCallback, useState } from 'react'
import { useComun } from '../../../Hooks/useComun';
import { EnumModuloDesc } from '../../../Utils/Enumeradores/ComunEnum';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { ObtenerAlarmasPerfilService } from '../Services/ObtenerAlarmasPerfilService';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

export function useAlarma() {

    const [listadoAlarmasPerfil, setListadoAlarmasPerfil] = useState([]);
    const {downloadCSV} = useComun();
    const {usuario} = useLogin();
		const { crearRegistroTDR } = useRegistroTDR();

    async function postObtenerListadoAlarmasPerfil(exportacion) {
			const startTime = new Date();
        const resp = await ObtenerAlarmasPerfilService(exportacion, usuario.id);
        if (resp) {
            if (resp.status === 200) {
                if (!exportacion.exportacion) {
                    if (resp.data) {
												const fetchData = async () => {
													await Promise.all([
														setListadoAlarmasPerfil(resp.data),
													]);
													const endTime = new Date();
													const tdrId = 72;
													crearRegistroTDR(startTime, endTime, usuario, tdrId);
												};

												fetchData();
                    }
                } else {
                    if (resp.data) {
												const fetchData = async () => {
													await Promise.all([
														downloadCSV(resp.data, 'AsociarAlarmas', EnumModuloDesc.DescargaAlarmasPerfil),
													]);
													const endTime = new Date();
													const tdrId = 75;
													crearRegistroTDR(startTime, endTime, usuario, tdrId);
												};

												fetchData();
                    }
                }
            }
        }
    }

    const getListadoAlarmasPerfil = useCallback(
        function (exportacion) {
            postObtenerListadoAlarmasPerfil(exportacion);
        },
        []
    );

    return { listadoAlarmasPerfil, getListadoAlarmasPerfil }
}
