import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ListadoElementos = async (problemaSeleccionado, idMacroubicacion, usuarioId) => {
    try {
        const resultado = await instance.Backend.post("/Hallazgo/ListadoElementos", {
            idProblema: problemaSeleccionado,
            idMacroubicacion: idMacroubicacion,
            idUsuario: usuarioId
        }, customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export { ListadoElementos };