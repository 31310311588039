import instance, { customConfig } from "../Utils/Interceptors/Interceptor";

const ObtenerUsuariosPorPerfilService = async (values) => {
    try {
        return await instance.Backend.post("/Usuario/ObtenerUsuariosPorPerfilAreaNegocio", values, customConfig);

    } catch (error) {

        console.log(error);

    }

}
export { ObtenerUsuariosPorPerfilService };