import moment from "moment";

export const ColumnasTabla = [
	{
		Header: 'Área de negocio'
		, accessor: 'areaNegocio'

	},
	{
		Header: 'Indicador'
		, accessor: 'indicadorSigla'
		, className: 'text-bold text-dark'
	},
	{
		Header: 'Exigencia'
		, accessor: 'exigenciaDescripcion'
	},
	{
		Header: 'Valor'
		, accessor: 'valor'
		, className: 'text-bold text-dark'
	},
	{
		Header: 'Valor Exigido / U. Medida'
		, accessor: 'referencia'
	},
	{
		Header: 'Fecha/Hora'
		, accessor: 'fechaEvento'
		, className: 'text-nowrap'
		, Cell: ({ row }) => moment(row.original.fechaEvento).format('DD-MM-yyyy HH:mm:ss')
	},
	{
		Header: 'Fecha/Hora de notificación'
		, accessor: 'fechaCreacion'
		, className: 'text-nowrap'
		, Cell: ({ row }) => moment(row.original.fechaCreacion).format('DD-MM-yyyy HH:mm:ss')
	},
]
