const Leyenda = ({ indicators, esLogicaIndicador = false, ...rest }) => {
	function btnAccion(item) {
		if(rest.handlerBtn){
			rest.handlerBtn(item);
		}
	}
	return (
		<div className={`legend ${rest.className}`}>
			{!esLogicaIndicador ?
				indicators.map((item, i) =>
					<div key={i} onClick={() => { btnAccion(item) }} className={`legend__item legend__item--${item.state}`}>
						<span className="legend__dot"></span>
						<span className="legend__label">{item.sigla} ({item.ponderacion}%)</span>
						<span className="legend__value">{item.valor}%</span>
						<span className="legend__indicator">
							{item.state === 'success' ?
								<i className="ico-increase"></i> :
								<i className="ico-decrement"></i>
							}
						</span>
					</div>
				)
				:
				indicators.map((item, i) => {
					return (
						item.esNivelServicio ?
							<div key={i} onClick={() => { btnAccion(item) }} className={`legend__item legend__item--${item.state}`}>
								<span className="legend__dot"></span>
								<span className="legend__label">{item.sigla} ({item.ponderacion}%)</span>
								<span className="legend__value">{item.valor}%</span>
								<span className="legend__indicator">
									{item.state === 'success' ?
										<i className="ico-increase"></i> :
										<i className="ico-decrement"></i>
									}
								</span>
							</div>
							:
							<div key={i} onClick={() => { btnAccion(item) }} className={`legend__item legend__item--`}>
								<span className="legend__dot" style={{ backgroundColor: item.colorCumplimiento, borderColor: 'rgba(255, 255, 255, 0.8)' }}></span>
								<span className="legend__label">{item.sigla} ({item.ponderacion}%)</span>
								<span className="legend__value" style={{ color: item.colorCumplimiento}}>{item.valor}%</span>
								{/* 	ESTE ES  */}
								<span className="legend__indicator" style={{borderColor:'rgba(255, 255, 255, 0.8)', color:item.colorCumplimiento, position:'relative'}}>
									<span style={{ backgroundColor: item.colorCumplimiento, opacity: '0.2', position: 'absolute', top:0, left: 0, width:'100%', height: '100%', borderRadius:'100%'}}></span>
									{item.state === 'success' ?
										<i className="ico-increase"></i> :
										<i className="ico-decrement"></i>
									}
								</span>
							</div>
					)
				}

				)
			}
		</div>
	)
}
export default Leyenda;
