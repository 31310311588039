import { useEffect } from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import { useUsuario } from "../../../Hooks/useUsuario";
import useLogin from "../Hooks/useLogin";

export default function PrivateRouteModulo({ children, id }) {
  const { usuario } = useLogin();

  const modulos = usuario ? (usuario.listaModuloAcceso ? usuario.listaModuloAcceso : []) : [];
  if (modulos.find(x => x.moduloId === id)) {
    return children ? children : <Outlet />;
  } else {
    return <Navigate to="/" />
  }
}