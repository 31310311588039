import moment from "moment";

export const COLUMNS = [
	{
		Header: 'Descripción',
		accessor: 'descripcion'
	},
	{
		Header: 'Valor / U. Medida',
		accessor: 'valor',
		Cell: ({ row }) =>
			row.original.valor + " / " + row.original.unidadMedida
	},
	{
		Header: 'Fecha Registro',
		accessor: 'fechaHoraCreacion',
		Cell: ({ row }) =>
			moment(row.original.fechaHoraCreacion).format('DD-MM-YYYY HH:mm:ss')

	},
]
