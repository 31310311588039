import { useEffect, useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../../../Components/Button/Button';
import { useToast } from '../../../Hooks/useToast';
import { ModificarEstadoAlarmaListaService } from "../Services/ModificarEstadoAlarmaListaService";
import Context from '../../Autenticacion/Context/LoginContext';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';
import useLogin from '../../Autenticacion/Hooks/useLogin';

const ModalConfirmacion = (props) => {
	const { estado, alarmaListaId, renderizar, ...rest } = props;
	const [mensajeModal, setMensajeModal] = useState({ titulo: "", texto: "" });
	const addToast = useToast();
	const { user } = useContext(Context);
	const { usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const btnModificarEstadoAlarmaLista = async () => {
		const startTime = new Date();
		const values = {
			idUsuario: user.id,
			alarmaListaId: alarmaListaId,
			estado: !estado

		}
		const resp = await ModificarEstadoAlarmaListaService(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					const fetchData = async () => {
						await Promise.all([
							renderizar(),
							addToast({ mostrar: true, mensaje: 'Se actualizó el estado de la alarma', titulo: 'Confirmación', tipo: 'Success' }),
						]);
						const endTime = new Date();
						const tdrId = 80;
						crearRegistroTDR(startTime, endTime, usuario, tdrId);
					};

					fetchData();
				}
			}
		}

		rest.onHide();
	}

	useEffect(() => {
		if (estado === false) {
			setMensajeModal({ titulo: "¿Estás seguro que deseas activar esta alarma?" });
		} else
			if (estado === true) {
				setMensajeModal({ titulo: "¿Estás seguro que deseas desactivar esta alarma?" });
			}
	}, [props]);
	return (
		<Modal
			{...rest}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				<div className='info my-4'>
					<span className='info__icon'>
						<i className='ico-user-delete'></i>
					</span>
					<h6 className='info__title'>{mensajeModal.titulo}</h6>
					<p className='info__text'>{mensajeModal.texto}</p>
				</div>
				<div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4">
					<Button type="button" design="btn-outline-pewter-blue btn--small" onClick={rest.onHide}>Cancelar</Button>
					<Button type="button" design="btn-primary btn--small" onClick={btnModificarEstadoAlarmaLista}>Aceptar</Button>
				</div>
			</Modal.Body>
		</Modal>
	)
}
export default ModalConfirmacion;
