const Progreso = ({...props}) => {
	const {title, percent, mes, anio} = props;

	return (
		<div className="access" style={{color:"white", backgroundColor:'mediumblue'}}>
			<div className="access__info flex-row justify-content-between w-100 flex-wrap gap-2 flex-xxl-nowrap">
				<div className="d-flex flex-column">
					<span className="fs-6 text-wrap">{title}</span>
					<small className="fs-7">{`${mes} ${anio}`}</small>
				</div>
				{percent?
					<strong className="fs-3">{percent}%</strong>
					:
				null}
			</div>
			<div className="access__progress">
				<div className="meter">
					<span className="meter__bar" style={{width: percent + '%'}} data-percentage={percent}></span>
				</div>
			</div>
		</div>
	)
}
export default Progreso;