import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Badge from '../../../Components/Badge/Badge';
import Card from '../../../Components/Card/Card';
import Chip from '../../../Components/Chip/Chip';
import Acceso from '../Components/Acceso/Acceso';
import ChipAlarma from '../Components/ChipAlarma/ChipAlarma';
import Progreso from '../Components/Progreso/Progreso';
import ChartsRadialBar from '../../../Components/ChartsRadialBar/ChartsRadialBar';
import ChartsDonut from '../../../Components/ChartsDonut/ChartsDonut';
import { useInicio } from '../Hooks/useInicio';
import moment from 'moment';
import Leyenda from '../../../Components/Leyenda/Leyenda';
import { EnumPathRutas } from '../../../Utils/Enumeradores/ComunEnum';

const InicioPage = () => {
	const { chartIndicators, chartFindings, indicators, alarms, hallazgo, indicadores, disponibilidad } = useInicio();
	return (
		<div className="container-fluid p-0">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li><a>Inicio</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row mb-4">
				<div className="col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
					<Acceso
						link="datamart"
						icon="ico-layout"
						title="Datamart"
						design="primary"
					/>
				</div>
				<div className="col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
					<Acceso
						link="informes"
						icon="ico-doc-word"
						title="Informes"
						design="secondary"
					/>
				</div>
				<div className="col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
					<Acceso
						link="bitacora/eventos_concesion"
						icon="ico-list"
						title="Bitácora"
						subtitle="Eventos concesión"
						design="tertiary"
					/>
				</div>
				<div className="col-12 col-lg-6 col-xl-3 mb-4 mb-xl-0">
					<Progreso
						title="Disponibilidad SIC-NS"
						percent={disponibilidad.value}
						mes = {disponibilidad.mes}
						anio = {disponibilidad.anio}
						link="informes/disponibilidad_sicns"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-12 col-lg mb-3">
					<Card
						title="Indicadores"
						link="indicadores"
						className="p-3"
					>
						<Badge
							design="secondary"
							className="mb-3"
						>Últimos 5 min.</Badge>

						<h3 className="fs-3">NSC</h3>
						<span className="text-pewter-blue mb-4 d-block">Nivel servicio concesionaria
						{
							chartIndicators &&
							<ChartsRadialBar
							dataChart={chartIndicators}
						/>
						}
						<Leyenda indicators={indicadores} />
						</span>
					</Card>
				</div>
				<div className="col-12 col-lg-5 mb-3">
					<Card
						title="Gestión de hallazgos"
						link="hallazgos"
						className="p-3"
					>
						<div className="row pt-3">
						<div className="col-12 col-xl-6 mb-3">
								<Chip
									label="Al día"
									value={hallazgo.alDia}
									icon="ico-play"
									state="success"
									design="text-start flex-row"
								/>
							</div>
							<div className="col-12 col-xl-6 mb-3">
								<Chip
									label="Por vencer"
									value={hallazgo.porVencer}
									icon="ico-face"
									state="warning"
									design="text-start flex-row"
								/>
							</div>
							<div className="col-12 col-xl-6 mb-3">
								<Chip
									label="Vencidos"
									value={hallazgo.vencidos}
									icon="ico-warning"
									state="danger"
									design="text-start flex-row"
								/>
							</div>
							<div className="col-12 col-xl-6 mb-3">
								<Chip
									label="Resueltos"
									value={hallazgo.resueltos}
									icon="ico-check"
									state="default"
									design="text-start flex-row gap-0"
								/>
							</div>
							<div className="col-12 mb-3">
								<Chip
									label="Cerrados"
									value={hallazgo.cerrados}
									icon="ico-block"
									state="info"
									design="text-start flex-row gap-0"
								/>
							</div>
						</div>
						<div className="border p-3 rounded">
							<ChartsDonut
								dataChart={chartFindings}
							/>
						</div>
					</Card>
				</div>
				<div className="col-12 col-lg mb-3">
					<Card
						title="Alarmas"
						link="alarmas"
						className="p-3"
					>
						{alarms.find(a => moment(a.fechaRegistro).format('DD-MM-yyyy') === moment().format('DD-MM-yyyy')) &&
							<Fragment>
								<b className="fs-6 text-pewter-blue mb-2 d-block">HOY</b>
								<div className="d-flex flex-column gap-3">
									{
										alarms.filter(a => moment(a.fechaRegistro).format('DD-MM-yyyy') === moment().format('DD-MM-yyyy')).map((item, i) =>
											<ChipAlarma
												key={"hoy" + i}
												title={item.indicador}
												time={moment(item.fechaRegistro).format('HH:mm:ss')}
												text={item.descripExigencia}
												value={item.valor}
											/>
										)
									}
								</div>
								<hr className="my-4" />
							</Fragment>
						}

						{alarms.find(a => moment(a.fechaRegistro).format('DD-MM-yyyy') === moment().subtract(1, 'days').format('DD-MM-yyyy')) &&
							<Fragment>
								<b className="fs-6 text-pewter-blue mb-2 d-block">AYER</b>
								<div className="d-flex flex-column gap-3">
									{
										alarms.filter(a => moment(a.fechaRegistro).format('DD-MM-yyyy') === moment().subtract(1, 'days').format('DD-MM-yyyy')).map((item, i) =>
											<ChipAlarma
												key={"ayer" + i}
												title={item.indicador}
												time={moment(item.fechaRegistro).format('HH:mm:ss')}
												text={item.descripExigencia}
												value={item.valor}
											/>
										)
									}
								</div>
								<hr className="my-4" />
							</Fragment>
						}

						{alarms.find(a => moment(a.fechaRegistro).format('DD-MM-yyyy') !== moment().format('DD-MM-yyyy') &&
							moment(a.fechaRegistro).format('DD-MM-yyyy') !== moment().subtract(1, 'days').format('DD-MM-yyyy')) &&
							<Fragment>
								<b className="fs-6 text-pewter-blue mb-2 d-block">ANTERIORES</b>
								<div className="d-flex flex-column gap-3">
									{
										alarms.filter(a => moment(a.fechaRegistro).format('DD-MM-yyyy') !== moment().format('DD-MM-yyyy') &&
											moment(a.fechaRegistro).format('DD-MM-yyyy') !== moment().subtract(1, 'days').format('DD-MM-yyyy')).map((item, i) =>
												<ChipAlarma
													key={"anteriores" + i}
													title={item.indicador}
													time={moment(item.fechaRegistro).format('DD-MM-yyyy HH:mm:ss')}
													text={item.descripExigencia}
													value={item.valor}
												/>
											)
									}
								</div>
							</Fragment>
						}


						<Link to={EnumPathRutas.Alarmas} className="link link--icon mt-3">
							<span>Ver todas las alarmas</span>
							<i className="ico-chevron-right"></i>
						</Link>
					</Card>
				</div>
			</div>
		</div>
	);
}

export default InicioPage;
