import ReactApexChart from "react-apexcharts";

const ChartsRadialBar = ({size, bezel, dataChart}) => {
	const series = [dataChart.serie];

	const options = {
		chart: {
			height: `${size ?  size : '300px'}`
		},
		colors: [dataChart.color],
		plotOptions: {
			radialBar: {
				hollow: {
					margin: 0,
					size: `${bezel ?  bezel+'%' : '70%'}`,
				},
				dataLabels: {
					name: {
						show: false
					},
					value: {
						color: "#110F24",
						fontSize: "27px",
						show: true,
						fontFamily: 'Poppins, sans-serif',
					},
				},
			},
		},
		states:{
			hover:{
				filter:'none'
			},
			active:{
				filter:'none'
			}
		},
		stroke: {
			lineCap: "round",
		},
		fill:{
			opacity: 1,
		},
		responsive: [
			{
				breakpoint: 1250,
				options: {
					chart: {
						height: 'auto'
					},
				}
			}
		]
	}
	return (
		<div id="chart">
			<ReactApexChart
				options={options}
				series={series}
				type="radialBar"
				height={options.chart.height}
			/>
		</div>
	)
}

export default ChartsRadialBar;
