import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

const ActualizaIndicadorExigenciaEstadoService = async (values) => {
    try {
        const resultado = await instance.Backend.post("/Indicador/ActualizaIndicadorExigenciaEstado", values, customConfig);
        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { ActualizaIndicadorExigenciaEstadoService};