import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

async function ObtenerAlarmasService(values){

    try {

        const resultado = await instance.Backend.post("/Alarma/ObtenerListadoAlarmas",{
            idUsuario: values.id,
            cantidadAlarma: values.cantidadAlarma,
            idPerfil: values.idPerfil
        }, customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export {ObtenerAlarmasService};