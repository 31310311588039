import { EnumTipoArchivos } from "../../Utils/Enumeradores/ComunEnum";
import Button from "../Button/Button";

const Exports = ({ descargar, helpers, mostrarPdf = false }) => {

	return (
		<>
			<Button design={`btn--outline btn--icon btn--pewter-blue rounded ${helpers}`}
				onClick={() => descargar(EnumTipoArchivos.XLS)}
			>
				<i className="ico-file-excel"></i>
			</Button>
			{
				mostrarPdf &&
				<Button design="btn--outline btn--icon btn--pewter-blue rounded"
					onClick={() => descargar(EnumTipoArchivos.PDF)}
				>
					<i className="ico-doc-pdf"></i>
				</Button>
			}

		</>
	)
}

export default Exports;
