import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

async function DetalleEventoConcesionService (values) {
    try {
        return await instance.Backend.post("/Indicador/DetalleEventoConcesion"
            , values
            , customConfig);
    } catch (error) {
        console.log(error);
    }
}
export { DetalleEventoConcesionService };