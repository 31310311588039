import React, { useEffect, useMemo, useState } from 'react';
import Card from "../../../Components/Card/Card";
import { COLUMNS } from '../Utils/Columns';
import { EnumPathRutas, EnumTablaPaginacion } from '../../../Utils/Enumeradores/ComunEnum'
import { useComun } from '../../../Hooks/useComun';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ModalDetalleEventoConcesion from '../../../Components/ModalDetalleEventoConcesion/ModalDetalleEventoConcesion';
import { EnumModuloDesc } from "../../../Utils/Enumeradores/ComunEnum";
import Badge from '../../../Components/Badge/Badge';
import Exports from '../../../Components/Exports/Exports';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { useTablasIndicadores } from '../../Indicadores/Hooks/useTablasIndicadores';
import { ColumnasHallazgoIndicador } from '../../Indicadores/Utils/ColumnaHallazgoIndicador';
import TablaHallazgoIndicador from '../../Indicadores/Components/TablaHallazgoIndicador';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const DatamartIndicadorPage = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { crearRegistroTDR } = useRegistroTDR();
	const { user, usuario } = useLogin();
	const datosIndicador = {
		siglaIndicador: location.state ? location.state.sigla : ""
		, descripcionIndicador: location.state ? location.state.descripcion : ""
		, nivelCumplimiento: location.state ? location.state.nivelCumplimiento : null
		, descripcionSerie: location.state ? location.state.descripcionSerie : ""
	};
	const [datosModal, setDatosModal] = React.useState({});
	const [esDescargar, setEsDescargar] = React.useState(false);

	const [key, setKey] = useState("indicadores");
	const [show, setShow] = useState(false);
	const columns = useMemo(() => COLUMNS, []);
	const columnasHallazgoIndicador = useMemo(() => ColumnasHallazgoIndicador, []);
	const [{ pageIndex, pageSize, sortBy }, setPagination] =
		React.useState({
			pageIndex: 0,
			pageSize: EnumTablaPaginacion.CantidadPaginacion,
			sortBy: []
		});
	const [{ pageIndexHallazgo, pageSizeHallazgo, sortByHallazgo }, setPaginationHallazgo] =
		React.useState({
			pageIndexHallazgo: 0,
			pageSizeHallazgo: EnumTablaPaginacion.CantidadPaginacion,
			sortByHallazgo: []
		});
		useEffect(() => {
			key === "indicadores" ?
			setPaginationHallazgo({
				pageIndexHallazgo: 0,
				pageSizeHallazgo: EnumTablaPaginacion.CantidadPaginacion,
				sortByHallazgo: []
			})
			:
			setPagination({
				pageIndex: 0,
				pageSize: EnumTablaPaginacion.CantidadPaginacion,
				sortBy: []
			})
		}, [key])
	const {
		getNivelCumplimientoIndicadorSerieDetalle
		, data
		, pageCount
		, getNivelCumplimientoHallazgoIndicadorSerieDetalleDatamart
		, dataHallazgoIndicador
		, pageCountHallazgoIndicador } = useTablasIndicadores(datosIndicador);

	const fetchData = React.useCallback(({ formulario, pageIndex, sortBy = [], exportacion = false }) => {
		formulario.paginacion.numeroPagina = pageIndex + 1;
		formulario.paginacion.ordenadoPor = sortBy.length > 0 ? sortBy[0].id : formulario.paginacion.ordenadoPor;
		formulario.paginacion.orderByAscending = sortBy.length > 0 ? (sortBy[0].desc ? false : true) : formulario.paginacion.orderByAscending;
		formulario.datosBusqueda.exportacion = exportacion;
				getNivelCumplimientoIndicadorSerieDetalle(formulario);

	}, [])

	const fetchDataHallazgoIndicador = React.useCallback(({ formulario, pageIndex, sortBy = [], exportacion = false }) => {
		formulario.paginacion.numeroPagina = pageIndex + 1;
		formulario.paginacion.ordenadoPor = sortBy.length > 0 ? sortBy[0].id : formulario.paginacion.ordenadoPor;
		formulario.paginacion.orderByAscending = sortBy.length > 0 ? (sortBy[0].desc ? false : true) : formulario.paginacion.orderByAscending;
		formulario.datosBusqueda.exportacion = exportacion;
		getNivelCumplimientoHallazgoIndicadorSerieDetalleDatamart(formulario);
	}, [])

	function handlerModal(item) {
		if (key === "indicadores") {
			setDatosModal(item);
			setShow(true);
		} else {
			navigate('/hallazgos', { state: { hallazgoId: item.hallazgoId } })
		}

	}
	const handleClose = () => setShow(false);
	return (
		<div className="container-fluid">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
							<li><Link to={EnumPathRutas.Datamart}> Datamart</Link></li>
							<li><a href="#"></a></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<Card className="p-4">
						<div>
							<div className="d-flex justify-content-between align-items-start mb-4 gap-3">
								<span className='d-flex flex-column align-items-start'>
									<h3 className="fs-3">{datosIndicador.siglaIndicador}</h3>
									<span className="text-pewter-blue d-block mb-3">{datosIndicador.descripcionIndicador}</span>
									<Badge
										design="secondary"
									>{datosIndicador.descripcionSerie}</Badge>
								</span>
								{data.length > 0 &&
									<div className="exports order-0 order-lg-1">
										<Exports descargar={() => setEsDescargar(true)} />
									</div>
								}
							</div>
							<div className="row flex-wrap p-3">
								<Tabs
									defaultActiveKey="indicadores"
									activeKey={key}
									transition={false}
									id="tabs"
									onSelect={(k) => setKey(k)}
								>
									<Tab eventKey="indicadores" title="Eventos" className="p-0">
										{key === "indicadores" &&
											<TablaHallazgoIndicador
												columns={columns}
												data={data}
												fetchData={fetchData}
												pageCount={pageCount}
												currentpage={pageIndex}
												pageSize={pageSize}
												sortBy={sortBy}
												setPagination={setPagination}
												handlerModal={handlerModal}
												datosIndicador={datosIndicador}
												esDescargar={esDescargar}
												setEsDescargar={setEsDescargar}
												tab={key}
											/>
										}
									</Tab>
									<Tab eventKey="hallazgo" title="Hallazgo">
										{key === "hallazgo" &&
											<TablaHallazgoIndicador
												columns={columnasHallazgoIndicador}
												data={dataHallazgoIndicador}
												fetchData={fetchDataHallazgoIndicador}
												pageCount={pageCountHallazgoIndicador}
												currentpage={pageIndexHallazgo}
												pageSize={pageSizeHallazgo}
												sortBy={sortByHallazgo}
												setPagination={setPaginationHallazgo}
												handlerModal={handlerModal}
												datosIndicador={datosIndicador}
												esDescargar={esDescargar}
												setEsDescargar={setEsDescargar}
												tab={key}
											/>
										}
									</Tab>
								</Tabs>
							</div>
						</div>

					</Card>
				</div>
			</div>
			<ModalDetalleEventoConcesion
				show={show}
				onHide={handleClose}
				datosModal={datosModal}
			/>
		</div>
	)
}

export default DatamartIndicadorPage;
