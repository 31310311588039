import { useEffect, useState } from 'react'
import { EnumDMIndicador, EnumDMSerie } from '../../../Utils/Enumeradores/ComunEnum';
import useLogin from '../../Autenticacion/Hooks/useLogin'
import { ObtenerAlarmasService } from '../Services/ObtenerAlarmasService'
import { ObtenerEstadisticasHallazgoService } from '../Services/ObtenerEstadisticasHallazgoService';
import { ObtenerIndicadoresService } from '../Services/ObtenerIndicadoresService';
import { CrearRegistroTDRService } from '../../../Services/CrearRegistroTDRService';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

export function useInicio() {
	const { user, usuario } = useLogin();
	const [home, setHome] = useState([])
	const [hallazgo, setHallazgo] = useState({ alDia: 0, cerrados: 0, porVencer: 0, vencidos: 0 });
	const [chartIndicators, setChartIndicador] = useState(null)
	const [chartFindings, setChartFindings] = useState([0, 0, 0]);
	const [indicators] = useState([
		{ sigla: 'NPSC', ponderacion: 80, valor: 99, state: 'success' },
		{ sigla: 'NPSG', ponderacion: 20, valor: 99, state: 'danger' }
	]);
	const { crearRegistroTDR } = useRegistroTDR();
	const [alarms, setAlarmas] = useState([]);
	const [indicadores, setIndicadores] = useState([]);
	const [disponibilidad, setDisponibilidad] = useState({ value: 0, mes: "", anio: "" });

	async function getAlarmas(values) {
		const resp = await ObtenerAlarmasService(values);
		if (resp) {
			if (resp.status === 200) {
				setAlarmas(resp.data);
			}
		}
	}

	async function getEstadisticasHallazgo(id, idPerfilContexto) {
		const resp = await ObtenerEstadisticasHallazgoService(id, idPerfilContexto);
		if (resp) {
			if (resp.status === 200) {
				setHallazgo(resp.data);
				setChartFindings([resp.data.alDia, resp.data.porVencer, resp.data.vencidos, resp.data.resueltos]);

			}
		}
	}

	async function getIndicadores(values) {
		const resp = await ObtenerIndicadoresService(values, usuario.id);
		if (resp) {
			if (resp.status === 200) {
				let indiAux = [];
				resp.data.seriesIndicador.forEach(y => {
					if (y.idSerie === EnumDMSerie.Ultimos5Minutos) {
						y.indicadores.forEach(x => {
							if (x.siglaIndicador === "NSC") {
								const numeroCota = x.cota.replace(',', '.');
								const color = x.valorNivelServicio >= parseFloat(numeroCota) ? '#49B461' : '#FF3300';
								setChartIndicador({ serie: x.valorNivelServicio, color: color });
							}
							else {

								const color = x.valorNivelServicio >= Number(x.cota) ? 'success' : 'danger';
								indiAux.push({ sigla: x.siglaIndicador, ponderacion: x.ponderacion, valor: x.valorNivelServicio, state: color });
							}
						})
					} else if (y.idSerie === EnumDMSerie.MesAnterior) {
						y.indicadores.forEach(x => {
							if (x.siglaIndicador === "DIS") {
								setDisponibilidad({ value: x.valorNivelServicio, mes: x.tiempo.nombreMes, anio: x.tiempo.ano });
							}
						})
					}
				})
				setIndicadores(indiAux);
			}
		}
	}

	useEffect(() => {
		const startTime = new Date();
		const valuesAlarmas = {
			id: user.id,
			cantidadAlarma: 5,
			idPerfil: usuario.perfilContexto.id
		}
		const valuesIndicadores = {
			idIndicadores: [EnumDMIndicador.NSC, EnumDMIndicador.NPSB, EnumDMIndicador.NPSE, 28],
			idSerie: EnumDMSerie.Ultimos5Minutos
		}
		const fetchData = async () => {
			await Promise.all([
				getAlarmas(valuesAlarmas),
				getEstadisticasHallazgo(usuario.id, usuario.perfilContexto.id),
				getIndicadores(valuesIndicadores)
			]);
			const endTime = new Date();
			const tdrId = 2;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
	}, [])

	return { home, chartIndicators, chartFindings, indicators, alarms, hallazgo, indicadores, disponibilidad }
}
