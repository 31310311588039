import Modal from 'react-bootstrap/Modal';
import { Formik, Form, } from "formik";
import Button from '../../../Components/Button/Button';
import SelectCheckField from '../../../Components/SelectCheckField/SelectCheckField';
import { useUsuario } from '../../../Hooks/useUsuario';
import { useContext, useEffect } from 'react';
import { useModalAlarma } from '../Hooks/useModalAlarma';
import validations from '../../../Utils/Validations/Validations';
import { AgregarPerfilAlarmaService } from '../Services/AgregarPerfilAlarmaService';
import { useToast } from '../../../Hooks/useToast';
import Context from '../../Autenticacion/Context/LoginContext';
import { useAlarma } from '../Hooks/useAlarmas';
import { useComun } from '../../../Hooks/useComun';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';
import useLogin from '../../Autenticacion/Hooks/useLogin';

const initialValues = {
	business: '',
	typeAlarm: '',
	profile: '',
}

const ModalAsociarAlarma = (props) => {

	const { renderizar, ...rest } = props;
	const { optionsBusiness, optionsProfile, getListaPerfilesAreasNegocio } = useComun();
	const { listadoTipoAlarma, getTipoAlarma } = useModalAlarma();
	const { getListadoAlarmasPerfil } = useAlarma();
	const addToast = useToast();
	const { user } = useContext(Context);
	const {usuario} = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();

	useEffect(() => {
		getListaPerfilesAreasNegocio();
		getTipoAlarma();
	}, [])

	async function postAgregarPerfilAlarma(values) {
		const startTime = new Date();
		const data = {
			alarmaId: values.typeAlarm,
			areaNegocioId: Number(values.business),
			perfilId: Number(values.profile),
			usuarioId: user.id
		};
		const resp = await AgregarPerfilAlarmaService(data);
		if (resp) {
			if (resp.status === 200) {
				const fetchData = async () => {
					await Promise.all([
						addToast({ mostrar: true, mensaje: 'Alarma asociada correctamente.', titulo: 'Confirmación', tipo: 'Success' }),
						renderizar(),
						rest.onHide(),
					]);
					const endTime = new Date();
					const tdrId = 74;
					crearRegistroTDR(startTime, endTime, usuario, tdrId);
				};

				fetchData();
			}
		}
	}

	return (
		<Modal
			{...rest}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<h5 className="modal-title" id="modalUserLabel">
					<i className="ico-add-alarm me-2"></i>
					<span>Asociar alarma</span>
				</h5>
			</Modal.Header>
			<Modal.Body>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validate={validations}
					onSubmit={values => {
						postAgregarPerfilAlarma(values);
					}}
				>
					{({ setFieldValue, setFieldTouched }) => (
						<Form className="form mb-0">
							<SelectCheckField
								name="business"
								label='Área de negocio'
								options={optionsBusiness}
								onChange={setFieldValue}
								onBlur={setFieldTouched}
								isMulti={false}
								fijo={false}
							/>
							<SelectCheckField
								name="typeAlarm"
								label='Tipo de alarma'
								options={listadoTipoAlarma}
								onChange={setFieldValue}
								onBlur={setFieldTouched}
								isMulti={false}
								fijo={false}
							/>
							<SelectCheckField
								name="profile"
								label='Perfil'
								options={optionsProfile}
								onChange={setFieldValue}
								onBlur={setFieldTouched}
								isMulti={false}
								closeOnSelect={true}
								fijo={false}
							/>
							<div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4">
								<Button type="button" design="btn-outline-pewter-blue" onClick={() => rest.onHide()}>Cancelar</Button>
								<Button type="submit" design="btn-primary">Guardar</Button>
							</div>
						</Form>
					)}
				</Formik>
			</Modal.Body>
		</Modal>
	)
}
export default ModalAsociarAlarma;
