import React, { useMemo, useState, useCallback } from 'react';
import Card from "../../../Components/Card/Card"
import BitacoraObjetarIndicadorTable from "../Components/BitacoraObjetarIndicadorTable";
import { Columns } from '../Utils/ColumnsObjetarIndicador';
import { EnumPathRutas, EnumTablaPaginacion, EnumModuloDesc } from '../../../Utils/Enumeradores/ComunEnum'
import { BusquedaObjetarEvento } from '../Services/BusquedaObjetarEvento';
import { ObtenerDetalleObjetorEvento } from '../Services/ObtenerDetalleObjetorEvento';
import { useComun } from '../../../Hooks/useComun';
import ModalObjetarIndicador from '../Components/ModalObjetarIndicador';
import { Link } from 'react-router-dom';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const BitacoraObjetarEventoPage = () => {
    const { downloadCSV } = useComun();
    const columns = useMemo(() => Columns, []);
    const [showModal, setShowModal] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [selectedEvento, setSelectedEvento] = useState(null);
    const { usuario } = useLogin();
    const [eventData, setEventData] = useState({});
    const { crearRegistroTDR } = useRegistroTDR();
    const [data, setData] = useState([]);
    const [test, setTest] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleSelectEvento = async (evento) => {
        setSelectedEvento(evento);
        const valores = {
            idUsuario: usuario.id,
            idPerfilContexto: usuario.perfilContexto.id,
            eventoConcesionId: evento.eventoConcesionId
        };
        
            const eventResponse = await ObtenerDetalleObjetorEvento(valores);
            const eventDetails = eventResponse.data;
    
            const combinedEventData = {
                ...evento,
                ...eventDetails,
                fileEventoConcesion: eventDetails.fileEventoConcesion || []
            };
    
            setEventData(combinedEventData);
            handleShowModal();
    };       

    const handleObjection = () => {
        const startTime = new Date();
        const endTime = new Date();
        const tdrId = 19;
        crearRegistroTDR(startTime, endTime, usuario, tdrId);
        setShowModal(false);
    };

    const [{ pageIndex, pageSize, sortBy }, setPagination] = useState({
        pageIndex: 0,
        pageSize: EnumTablaPaginacion.CantidadPaginacion,
        sortBy: []
    });

    const postBusquedaObjetarEvento = async (values) => {
        const startTime = new Date();
        const resp = await BusquedaObjetarEvento(values);
        if (resp) {
            if (resp.status === 200) {
                if (values.datosBusqueda.exportacion) {
                    downloadCSV(resp.data, "Bitácora Objecion Indicador", EnumModuloDesc.DescargaBitacoraObjecion);
                } else {
                    setPageCount(resp.data.cantidadPaginas);
                    setData(resp.data.listadoBitacoraObjetarEvento);
                }
            } else if (resp.status === 206) {
                setPageCount(0);
                setData([]);
            }
        }
        const endTime = new Date();
        const tdrId = 19;
        crearRegistroTDR(startTime, endTime, usuario, tdrId);
    };

    const fetchData = useCallback(({ formulario, pageIndex, sortBy = [], exportacion = false }) => {
        formulario.paginacion.numeroPagina = pageIndex + 1;
        formulario.paginacion.ordenadoPor = sortBy.length > 0 ? sortBy[0].id : formulario.paginacion.ordenadoPor;
        formulario.paginacion.orderByAscending = sortBy.length > 0 ? !sortBy[0].desc : formulario.paginacion.orderByAscending;
        formulario.datosBusqueda.exportacion = exportacion;
        postBusquedaObjetarEvento(formulario);
    }, []);

	return (
		<div className="container-fluid">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li><Link to={EnumPathRutas.Inicio}>Inicio</Link></li>
							<li><a href="" onClick={(e) => { e.preventDefault(); window.location.reload(false); }}>Bitácora</a></li>
							<li><a href="">Excepciones VIA</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<Card className="p-4">
						<BitacoraObjetarIndicadorTable
							columns={columns} 
							data={data} 
							fetchData={fetchData}
							pageCount={pageCount}
							currentpage={pageIndex}
							pageSize={pageSize}
						    sortBy={sortBy}
                            show={showModal}
							setPagination={setPagination}
							handlerModal={handleSelectEvento}
                            test={test}
                            setTest={setTest}
						/>
					</Card>
				</div>
			</div>
			{selectedEvento && eventData && Object.keys(eventData).length > 0 && (
    <ModalObjetarIndicador
        show={showModal}
        fetchData={fetchData}
        handleClose={handleCloseModal}
        handleObjection={handleObjection}
        data={eventData}
        test={setTest}
    />
)}
		</div>
	)
}

export default BitacoraObjetarEventoPage;