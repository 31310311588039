const DetalleCelda = (props) => {
	const MostrarModalDetalle = () => {
		props.handlerShowModalEvento(props.data);
	}
	return (
		<div className='d-flex justify-content-center'>
			<button className='btn btn--outline btn--pewter-blue btn--xs' onClick={() => MostrarModalDetalle()}>
				<i className='ico-search'></i>
			</button>
		</div>
	)
}

export default DetalleCelda;
