import PaginationComponent from "../../../Components/PaginationTableServer/PaginationTableServer";
import React, { useEffect, useState } from 'react';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import moment from 'moment';
import { usePagination, useSortBy, useTable } from 'react-table';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Accordion from 'react-bootstrap/Accordion';
import ExigenciaCelda from "./ExigenciaCelda";
import { EnumEstadoEventoConcesion } from "../../../Utils/Enumeradores/ComunEnum";
import DetalleCelda from "./DetalleCelda";
import { AccionesCelda } from "./AccionesCelda";
import notfound from '../../../Assets/images/il-empty.svg';
import { AccionesEncabezado } from "./AccionesEncabezado";

const CustomToggle = ({ children, eventKey, sigla }) => {
	const decoratedOnClick = useAccordionButton(eventKey, () => null);

	return (
		<button className='accordion-toggle' type="button" onClick={decoratedOnClick}>
			<i className='ico-chevron-down'></i>
			<span className='fw-semibold'>{sigla}</span>
		</button>
	);
}

const TablaExigencias = ({ columns, data, fetchData, pageCount: controlledPageCount, currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy, setPagination, esRenderizar, mediciones, handleShowModal, handlerShowModalEvento }) => {
	const {
		getTableProps
		, getTableBodyProps
		, headerGroups
		, prepareRow
		, page
		, pageCount
		, gotoPage
		, setSortBy
		, state: { pageIndex, pageSize, sortBy }
	} = useTable(
		{
			columns
			, data
			, initialState: { pageIndex: currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy }
			, manualPagination: true
			, pageCount: controlledPageCount
			, autoResetPage: false
			, autoResetSortBy: false
			, manualSortBy: true
		},
		useSortBy
		, usePagination
	)

	const { usuario } = useLogin();

	const Modificar = (item, value) => {
		const esAutorizado = value === 1 ? 1 : 2;
		let datosBusquedaModificacion = {
			datosBusqueda: {
				idUsuario: usuario.id
				, idNegocio: mediciones.idNegocio
				, idIndicador: [item.idIndicador]
				, estado: mediciones.estado
				, tipoIngreso: 1
				, fechaInicio: mediciones.fechaInicio
				, fechaTermino: mediciones.fechaTermino
				, nuevoEstado: esAutorizado
			}
		}
		handleShowModal(item.detalle.map(x => { return Number(x.eventoConcesionId) }), true, esAutorizado, datosBusquedaModificacion);
	
	}
	const { Autorizado, Rechazado, Pendiente } = EnumEstadoEventoConcesion;
	const [valoresFormularioEnvio, setValoresFormularioEnvio] = useState({
		paginacion: {
			numeroPagina: pageIndex
			, cantidadRegistros: pageSize
			, orderByAscending: false
			, ordenadoPor: "fechaHoraCreacion"
		},
		datosBusqueda: {
			idUsuario: usuario.id
			, idNegocio: mediciones.idNegocio
			, idIndicador: mediciones.idIndicador
			, estado: mediciones.estado
			, tipoIngreso: 1
			, fechaInicio: mediciones.fechaInicio
			, fechaTermino: mediciones.fechaTermino
		}
	});

	useEffect(() => {

		if (valoresFormularioEnvio.datosBusqueda.idNegocio !== mediciones.idNegocio ||
			valoresFormularioEnvio.datosBusqueda.idIndicador !== mediciones.idIndicador ||
			valoresFormularioEnvio.datosBusqueda.estado !== mediciones.estado ||
			valoresFormularioEnvio.datosBusqueda.fechaInicio !== mediciones.fechaInicio ||
			valoresFormularioEnvio.datosBusqueda.fechaTermino !== mediciones.fechaTermino) {

			let newFormulario = valoresFormularioEnvio;
			/* Se setean valores del paginado para la primera busqueda (resetea la tabla) */
			newFormulario.paginacion.numeroPagina = 0;
			newFormulario.paginacion.orderByAscending = false;
			newFormulario.paginacion.ordenadoPor = "fechaCreacion";
			/* Se setean los valores de los campos que ingresa el usuario */
			newFormulario.datosBusqueda.idNegocio = mediciones.idNegocio;
			newFormulario.datosBusqueda.idIndicador = mediciones.idIndicador;
			newFormulario.datosBusqueda.estado = mediciones.estado;
			newFormulario.datosBusqueda.fechaInicio = mediciones.fechaInicio;
			newFormulario.datosBusqueda.fechaTermino = mediciones.fechaTermino;
			setValoresFormularioEnvio(newFormulario);
			setPagination({
				pageIndex: 0,
				pageSize: pageSize,
				sortBy: []
			});
			setSortBy([]);
			gotoPage(0);
		} else {
			fetchData({ formulario: valoresFormularioEnvio, pageIndex, sortBy });
			/* GUARDAMOS EL SORTBY EN EL USESTATE DE PAGINATION QUE SE ENCUENTRA EN ALARMASPAGE PARA QUE NO SE PIERDA EL ORDENAMIENTO */
			setPagination(values => ({
				...values
				, sortBy: sortBy
			}));
		}


	}, [pageIndex, sortBy, esRenderizar, mediciones])

	return (
		<>
			{data.length > 0 && data.map((x, i) => {
				return (
					<Accordion key={i} defaultActiveKey={i} className='accordion-table'>
						<div className='accordion-item'>
							<div className='accordion-header gap-4 py-2'>
								<CustomToggle eventKey={i} sigla={x.siglaIndicador} />
								{}
								<div className='d-flex gap-2 ms-auto me-2'>
									<button className='btn btn--outline btn--pewter-blue btn--xs' disabled={x.detalle[0].estado !== Pendiente} onClick={() => Modificar(x, Autorizado)}>
										<i className='ico-check'></i>
									</button>
									<button className='btn btn--outline btn--pewter-blue btn--xs' disabled={x.detalle[0].estado !== Pendiente} onClick={() => Modificar(x, Rechazado)}>
										<i className='ico-close'></i>
									</button>
								</div>
							</div>
							<Accordion.Collapse eventKey={i}>
								<div className='accordion-body p-0'>
									<table className="table table-sm" {...getTableProps()}>
										<thead>
											{headerGroups.map((headerGroup) => (
												<tr {...headerGroup.getHeaderGroupProps()}>
													{headerGroup.headers.map((column) => (
														data.length > 0 ?
															<th style={{ width: '16.6%' }} {...column.getHeaderProps(column.getSortByToggleProps())}
															>
																<span className={`d-flex align-items-center gap-1 ${column.headerClassName}`}>
																	{column.render('Header')}
																	<>
																		{!column.disabledSortBy ?
																			column.isSorted && data.length > 0 ?
																				(column.isSortedDesc ?
																					<i className="ico-chevron-down"></i>
																					:
																					<i className="ico-chevron-up"></i>
																				) :
																				<>
																					<i className="ico-sort fs-7"></i>
																				</>
																			: null
																		}
																	</>
																</span>
															</th>
															:
															<th {...column.getHeaderProps()}
															>
																<span className="d-flex align-items-center gap-1">
																	{column.render('Header')}
																</span>
															</th>
													))}
												</tr>
											))}
										</thead>
										<tbody {...getTableBodyProps()}>
											{
												x.detalle.map((y, index) => {
													return (
														<tr key={index}>

															<td style={{ width: '10%' }} className="">{y.eventoConcesionId}</td>
															<td style={{ width: '25%' }} ><ExigenciaCelda data={y} /></td>
															<td style={{ width: '15%' }} className="text-center">{y.valor}</td>
															<td style={{ width: '15%' }} className="text-center">{moment(y.fechaHoraCreacion).format('DD-MM-yyyy HH:mm:ss')}</td>
															<td style={{ width: '15%' }} className="text-center">
																<span
																	className={`badge badge--monochromatic
																	${y.estado === EnumEstadoEventoConcesion.Autorizado ? 'bg-success' : ''}
																	${y.estado === EnumEstadoEventoConcesion.Pendiente ? 'bg-primary' : ''}
																	${y.estado === EnumEstadoEventoConcesion.Rechazado ? 'bg-danger' : ''}
																`}
																>
																	{y.estado === EnumEstadoEventoConcesion.Pendiente ? "Pendiente" : y.estado === EnumEstadoEventoConcesion.Autorizado ? "Aprobado" : y.estado === EnumEstadoEventoConcesion.Rechazado ? "Rechazado" : ""}
																</span>
															</td>
															<td style={{ width: '10%' }} className="text-center"><DetalleCelda data={y} handlerShowModalEvento={handlerShowModalEvento}></DetalleCelda></td>
															<td style={{ width: '10%' }} className="text-center"><AccionesCelda data={y} handleShowModal={handleShowModal} /></td>
														</tr>
													)
												})
											}
										</tbody>
									</table>
								</div>
							</Accordion.Collapse>
						</div>
					</Accordion>
				)
			}) || <div className="d-flex justify-content-center align-items-center flex-column gap-2 py-5">
					<img className="mb-3" src={notfound} alt="" />
					<p className='mb-0 text-primary'>No se encuentran resultados de tu búsqueda.</p>
				</div>
			}

			{
				data.length > 0 &&
				<PaginationComponent
					pagesCountServer={pageCount}
					currentPage={pageIndex}
					setCurrentPage={gotoPage}
					alwaysShown={false}
					setPagination={setPagination}
				/>
			}
		</>
	)
}
export default TablaExigencias;
