import { useCallback, useState } from 'react'
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { ListaParametroIndicadorExigenciaService } from '../Services/ListaParametroIndicadorExigenciaService'

export function useExigencia(props) {

    const [listadoExigencia, setListadoExigencia] = useState([]);
    const[listadoPav, setListadoPav] = useState([])
    const {usuario} = useLogin();
    async function postObtenerListaParametroIndicadorExigencia() {
        const {data, status} = await ListaParametroIndicadorExigenciaService(usuario.id);
            if (status === 200) {
                if (data) {
                    setListadoExigencia(data);

                }
            }
    }
    const getListadoExigencia = useCallback(
        function () {
            postObtenerListaParametroIndicadorExigencia();
        },
        []
    );

    return { listadoExigencia, getListadoExigencia }
}
