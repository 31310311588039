import { useContext, useEffect, useMemo } from 'react';
import Card from "../../../Components/Card/Card";
import EsquemaDePonderacion from "../Components/EsquemaDePonderacion";
import ModalEditarPonderacion from '../Components/ModalEditarPonderacion';
import { PonderacionContext } from '../Context/PonderacionContext';
import { Link } from 'react-router-dom';
import { EnumPathRutas } from '../../../Utils/Enumeradores/ComunEnum';

const AdministracionDePonderacionPage = () => {
	const {renderizarTabla,getListadoPonderacion, listado} = useContext(PonderacionContext);
	const data = useMemo(() => listado, [listado]);
	useEffect(() => {
		getListadoPonderacion();
	}, [])
	useEffect(() => {
		getListadoPonderacion();
	}, [renderizarTabla])
	return (
			<div className="container-fluid">
				<div className="row mb-4">
					<div className="col-12">
						<div className="breadcrumbs">
							<ul>
								<li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
								<li><Link to={EnumPathRutas.Configuracion}>Configuración</Link></li>
								<li><a href="#">Administración de ponderación</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<Card className="p-4">
							<EsquemaDePonderacion
								data={data}
							/>
						</Card>
					</div>
				</div>
				<ModalEditarPonderacion />
			</div>
	)
}
export default AdministracionDePonderacionPage;
