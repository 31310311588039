import instance, { customConfig } from "../Utils/Interceptors/Interceptor";

const ObtenerListaUnidadMedida = async (values, idUsuario) => {
    try {
        const resultado = await instance.Backend.post("/Indicador/ListaUnidadMedida",
        {
            IdUsuario: idUsuario
        }, customConfig);
        
        return resultado
    } catch (error) {
        console.log(error);
    }
}
export { ObtenerListaUnidadMedida };