import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

async function NivelCumplimientoIndicadorSerieDetalleService (values) {
    try {
        return await instance.Backend.post("/Indicador/NivelCumplimientoIndicadorSerieDetalle"
            , values
            , customConfig);
    } catch (error) {
        console.log(error);
    }
}
export { NivelCumplimientoIndicadorSerieDetalleService };