/* eslint-disable default-case */
import { useNavigate } from 'react-router-dom';
import sicns from '../../Assets/images/sicns.svg';
import useLogin from '../../Features/Autenticacion/Hooks/useLogin';
import { useModalPerfil } from '../../Hooks/useModalPerfil';
import ModalPerfil from '../ModalPerfil/ModalPerfil';
import Dropdown from 'react-bootstrap/Dropdown';
import { EnumPathRutas } from "../../Utils/Enumeradores/ComunEnum";
import { useNombreModulo } from '../../Hooks/useNombreModulo';

const Header = ({ onToggle, contadorAlarmas, contadorNotificaciones, collapse }) => {
	const {show, addShow} = useModalPerfil();
	const handleClose = () => addShow(false);
	const { signoutRegistroBD, usuario } = useLogin();
	const perfilLogeado = usuario ? usuario.perfilContexto : "";
	const navigate = useNavigate();
	const { nombreModulo } = useNombreModulo();
	
	return (
		<>
			<header className="header">
				<button className={collapse ? 'toggle header__btn active' : 'toggle header__btn'} id="toggle" onClick={onToggle} style={{lineHeight:'1'}}>
					{collapse ? <i className="ico-burger"></i> : <i className="ico-chevron-left" style={{fontSize:'1.75rem', lineHeight:'1'}}></i>}
				</button>
				<h1 className="d-none d-md-block">{nombreModulo}</h1>
				<img className="d-block d-md-none ms-0 me-auto" src={sicns} alt="Sicns" />
				<div className="header__tools">
					{usuario.alarmas === true ?
						<button type="button" className="position-relative" onClick={()=>{navigate(EnumPathRutas.Alarmas)}}>
						<i className="ico-alarms"></i>
						{contadorAlarmas > 0 &&
							<span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{contadorAlarmas}</span>
						}
					</button>
					:
					null}
					<button type="button" className="position-relative" onClick={()=>{navigate(EnumPathRutas.Notificaciones)}}>
						<i className="ico-bell"></i>
						{contadorNotificaciones > 0 &&
							<span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">{contadorNotificaciones}</span>
						}
					</button>
					<Dropdown align="end">
						<Dropdown.Toggle variant="perfil" id="dropdown-basic">
							<div className="user">
								<span className={`avatar rounded-circle ${usuario.rutaImagen === "" || usuario.rutaImagen === null ? 'avatarIniciales border overflow-hidden' : 'text-white'}`}>
									{
										usuario.rutaImagen === "" || usuario.rutaImagen === null ?
											usuario.nombres.charAt(0).toUpperCase() + usuario.apellidoPaterno.charAt(0).toUpperCase() :
											<img src={usuario.rutaImagen + "?t=" + new Date().getTime()} alt="" /> /* "?t="+ new Date().getTime() prevención de caché a la URL */
									}
								</span>
								<div className="user__ide d-none d-md-flex">
									<b>{usuario.nombres + " " + usuario.apellidoPaterno}</b>
									{perfilLogeado ? <span className='text-start'>{perfilLogeado.descripcion}</span> : <span className='text-start'>Superadmin</span>}

								</div>
							</div>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item onClick={() => addShow(true)}>Mi perfil</Dropdown.Item>
							<Dropdown.Item onClick={() => signoutRegistroBD()}>Cerrar sesión</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>


				</div>
			</header>
			<ModalPerfil
				show={show}
				onHide={handleClose}
			/>
		</>
	)
}

export default Header;
