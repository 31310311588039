import { useState } from "react";
import { NavLink } from "react-router-dom";
import brandGovernment from "../../Assets/images/brand-government.png";
import brandIsa from "../../Assets/images/brand-isa-white.svg";
import useLogin from "../../Features/Autenticacion/Hooks/useLogin";
import { EnumModulo } from "../../Utils/Enumeradores/ComunEnum";

const Menu = ({ collapse, handleCollapse, contadorAlarmas, contadorNotificaciones, contadorTotalPendientesAutorizacion }) => {
	const devices = () => { return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent); }
	const [submenu, setSubmenu] = useState(false);
	const [submenuIndicadores, setSubmenuIndicadores,] = useState(false);
	const activeStyle = { color: "white" };
	const { signoutRegistroBD, usuario } = useLogin();
	const modulos = usuario ? (usuario.listaModuloAcceso ? usuario.listaModuloAcceso : []) : [];
  const handleClick = () => {

		if(devices() && collapse){
			handleCollapse(false);
		}
  };
	return (
		<aside className={`sidebar ${collapse ? 'collapsed' : ''}`} id="sidebar">
			<div className="sidebar__header">
				<img src={brandIsa} alt="ruta loa" />
				<span>Sistema de Gestión <b>SICNS 1.0</b></span>
			</div>
			<nav className="sidebar__nav">
				<ul className="nav">
					{modulos.map((mod, i) => {
						switch (mod.moduloId) {
							case EnumModulo.Inicio:
								return (
									<li key={i} className="nav__item">
										<NavLink
											className="nav__link"
											to="/inicio"
											style={({ isActive }) =>
												isActive ? activeStyle : undefined
											}
											onClick={handleClick}
										>
											<i className="ico-home"></i>
											<span>Inicio</span>
										</NavLink>
									</li>)
							case EnumModulo.Indicadores:
								return (
									<li key={i} className="nav__item">
									<button className={`nav__link ${submenuIndicadores ? 'active' : ''}`} onClick={() => setSubmenuIndicadores(!submenuIndicadores)}>
										<i className="ico-list"></i>
										<span>Indicadores</span>
									</button>
									<div className={`collapse ${submenuIndicadores ? 'show' : ''}`}>
										<ul className="subnav">
											<li>
												<NavLink
													to="/indicadores"
													style={({ isActive }) =>
														isActive ? activeStyle : undefined
													}
													onClick={handleClick}
												>
													Niveles de servicio
												</NavLink>
											</li>
											<li>
												<NavLink
													to="/indicadores/isp"
													style={({ isActive }) =>
														isActive ? activeStyle : undefined
													}
													onClick={handleClick}
												>
													ISP
												</NavLink>
											</li>
										</ul>
									</div>
								</li>
								);
							case EnumModulo.Datamart:
								return (
									<li key={i} className="nav__item">
										<NavLink
											className="nav__link"
											to="/datamart"
											style={({ isActive }) =>
												isActive ? activeStyle : undefined
											}
											onClick={handleClick}
										>
											<i className="ico-layout"></i>
											<span>Datamart</span>
										</NavLink>
									</li>
								);
							case EnumModulo.Bitacora:
								return (
									<li key={i} className="nav__item">
										<button className={`nav__link ${submenu ? 'active' : ''}`} onClick={() => setSubmenu(!submenu)}>
											<i className="ico-list"></i>
											<span>Bitácora</span>
										</button>
										<div className={`collapse ${submenu ? 'show' : ''}`}>
											<ul className="subnav">
												<li>
													<NavLink
														to="/bitacora/eventos_concesion"
														style={({ isActive }) =>
															isActive ? activeStyle : undefined
														}
														onClick={handleClick}
													>
														Eventos concesión
													</NavLink>
												</li>
												{(usuario.perfilContexto.id === 1 || usuario.perfilContexto.id === 2 || usuario.perfilContexto.id === 3) && (
													<li>
														<NavLink
															to="/bitacora/objectar_indicador"
															style={({ isActive }) =>
																isActive ? activeStyle : undefined
															}
															onClick={handleClick}
														>
															Excepciones VIA
														</NavLink>
													</li>
												)}
												<li>
													<NavLink
														to="/bitacora/bim"
														style={({ isActive }) =>
															isActive ? activeStyle : undefined
														}
														onClick={handleClick}
													>
														BIM
													</NavLink>
												</li>
												<li>
													<NavLink
														to="/bitacora/actividad_de_usuario"
														style={({ isActive }) =>
															isActive ? activeStyle : undefined
														}
														onClick={handleClick}
													>
														Actividad de usuario
													</NavLink>
												</li>
											</ul>
										</div>
									</li>
								);
							case EnumModulo.GestionHallazgos:
								return (
									<li key={i} className="nav__item">
										<NavLink
											className="nav__link"
											to="/hallazgos"
											style={({ isActive }) =>
												isActive ? activeStyle : undefined
											}
											onClick={handleClick}
										>
											<i className="ico-metrics"></i>
											<span>Gestión de Hallazgos</span>
										</NavLink>
									</li>
								);
							case EnumModulo.Notificaciones:
								return (
									<li key={i} className="nav__item">
										<NavLink
											className="nav__link"
											to="/notificaciones"
											style={({ isActive }) =>
												isActive ? activeStyle : undefined
											}
											onClick={handleClick}
										>
											<i className="ico-bell"></i>
											<span>Notificaciones</span>
											{contadorNotificaciones > 0 &&
												<span className="badge rounded-pill px-2 bg-pewter-blue">{contadorNotificaciones}</span>
											}

										</NavLink>
									</li>
								);
							case EnumModulo.Alarmas:
								return (
									<li key={i} className="nav__item">
										<NavLink
											className="nav__link"
											to="/alarmas"
											style={({ isActive }) =>
												isActive ? activeStyle : undefined
											}
											onClick={handleClick}
										>
											<i className="ico-alarms"></i>
											<span>Alarmas</span>
											{contadorAlarmas > 0 &&
												<span className="badge rounded-pill px-2 bg-pewter-blue">{contadorAlarmas}</span>
											}

										</NavLink>
									</li>
								);
							case EnumModulo.Autorizaciones:
								return (
									<li key={i} className="nav__item">
										<NavLink
											className="nav__link"
											to="/autorizaciones"
											style={({ isActive }) =>
												isActive ? activeStyle : undefined
											}
											onClick={handleClick}
										>
											<i className="ico-auth"></i>
											<span>Autorizaciones</span>
											{contadorTotalPendientesAutorizacion > 0 &&
												<span className="badge rounded-pill px-2 bg-pewter-blue">{contadorTotalPendientesAutorizacion}</span>
											}
										</NavLink>
									</li>
								);
							case EnumModulo.Informes:
								return (
									<li key={i} className="nav__item">
										<a href="https://reactjs.org"
											className="nav__link" >
										</a>
										<NavLink
											className="nav__link"
											to="/informes"
											style={({ isActive }) =>
												isActive ? activeStyle : undefined
											}
											onClick={handleClick}
										>
											<i className="ico-doc-word"></i>
											<span>Informes</span>
										</NavLink>
									</li>
								);
							case EnumModulo.Configuracion:
								return (
									<li key={i} className="nav__item">
										<NavLink
											className="nav__link"
											to="/configuracion"
											style={({ isActive }) =>
												isActive ? activeStyle : undefined
											}
											onClick={handleClick}
										>
											<i className="ico-settings"></i>
											<span>Configuración</span>
										</NavLink>
									</li>
								);
							default:
								return null;
						}
					})}
				</ul>
			</nav>
			<div className="sidebar__sponsor mb-0">
				<a href="https://www.mop.cl/Paginas/default.aspx" rel="noreferrer" target="_blank">
					<img src={brandGovernment} alt="Ministerio de Obras Públicas - Gobierno de Chile" />
				</a>
			</div>
			<div className="sidebar__footer">
				<span onClick={() =>signoutRegistroBD()} className="nav__link">
					<i className="ico-exit"></i>
					<span>Salir</span>
				</span>
			</div>
		</aside>
	);
}

export default Menu;
