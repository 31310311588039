import AvatarInCell from "../../../Components/Table/Filters/Components/AvatarInCell";
import PeriodoBackup from "../Components/PeriodoBackup";
import moment from 'moment';
import { data } from "autoprefixer";
export function useColumns(props){
	const COLUMNS = [
		{
			Header: 'Nombre',
			accessor:data=>{return data.nombres.toUpperCase() }	,
			className: '',
			Cell: ({ row }) =>
				<div className="user">
					<span className={`avatar rounded-circle border overflow-hidden ${row.original.rutaImagen === "" || row.original.rutaImagen === null ? 'avatarIniciales' : 'text-white'}`}>
						{row.original.rutaImagen === "" || row.original.rutaImagen === null ?
							row.original.nombres.charAt(0).toUpperCase() + row.original.apellidoPaterno.charAt(0).toUpperCase() :
							<img src={row.original.rutaImagen} alt="" />
						}
					</span>
					<div className="user__ide">
						<span className="text-nowrap">{row.original.nombres + " " + row.original.apellidoPaterno + " " + row.original.apellidoMaterno}</span>
						{}
					</div>
				</div>
		},
		{
			Header: 'Reemplazo',
			//accessor: 'nombresRespaldo',
			accessor:data=>{return data.nombresRespaldo.toUpperCase() }	,
			Cell: ({ row }) =>
				<div className="user">
					<span className={`avatar rounded-circle border overflow-hidden ${row.original.rutaImagenRespaldo === "" || row.original.rutaImagenRespaldo === null ? 'avatarIniciales' : 'text-white'}`}>
						{row.original.rutaImagenRespaldo === "" || row.original.rutaImagenRespaldo === null ?
							row.original.nombresRespaldo.charAt(0).toUpperCase() + row.original.apellidoPaternoRespaldo.charAt(0).toUpperCase() :
							<img src={row.original.rutaImagenRespaldo} alt="" />
						}
					</span>
					<div className="user__ide">
						<span className="text-nowrap">{row.original.nombresRespaldo + " " + row.original.apellidoPaternoRespaldo + " " + row.original.apellidoMaternoRespaldo}</span>

					</div>
				</div>
		},
		{
			Header: 'Fecha reemplazo',
			disabledFilters: true,
			accessor: 'fechaInicio',
			Cell: ({ row }) =>{return moment(row.original.fechaInicio).format('DD-MM-YYYY') + ' ■ ' + moment(row.original.fechaFin).format('DD-MM-YYYY')}
		},
		{
			Header: 'Estado',
			accessor: data=>{return data.estado === true ? "Activo" : data.estado === false ? "Inactivo" : ""},
			disabledFilters: true,
			Cell: ({ row }) => <span
				className={`badge badge--monochromatic
				${row.original.estado === true? 'bg-success' : ''}
				${row.original.estado === false? 'bg-primary' : ''}
			`}
			>
				{row.original.estado === true ? "Activo" : row.original.estado === false ? "Inactivo" : ""}
			</span>
		},
	]
	return { COLUMNS }
}
