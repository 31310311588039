import Exports from "../../../Components/Exports/Exports";

export function useColumnasHallazgo(props) {
	const COLUMNS = [
		{
			Header: "Área de negocio",
			accessor: "areaNegocio",
			className: "text-start",
		},
		{
			Header: "Abierto / Al día",
			accessor: "abiertoDia",
			className: "text-center",
			disableSortBy: true,
		},
		{
			Header: "Abierto / Fuera de plazo",
			accessor: "abiertoFueraPlazo",
			className: "text-center",
			disableSortBy: true,
		},
		{
			Header: "Resuelto / Al día",
			accessor: "resueltoAldia",
			className: "text-center",
			disableSortBy: true,
		},
		{
			Header: "Resuelto / Fuera de plazo",
			accessor: "resueltoFueraPlazo",
			className: "text-center",
			disableSortBy: true,
		},
		{
			Header: "Descargar informe",
			id: "exports",
			className: "text-end",
			disabledFilters: true,
			disableSortBy: true,
			accessor: (row) => row,
			Cell: ({ row }) => (
				<div className="exports order-0 order-lg-1">
					<Exports
						mostrarPdf={true}
						descargar={(tipo) => props.mostrarModal(tipo, row.original)}
					/>
				</div>
			),
		},
	];
	return { COLUMNS };
}
