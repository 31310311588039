import notfound from '../../../Assets/images/il-empty.svg';
import { useComun } from '../../../Hooks/useComun';
import React, { useEffect, useState } from 'react';
import { usePagination, useSortBy, useTable, useGlobalFilter } from 'react-table';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import Button from "../../../Components/Button/Button";
import Avatar from '../../../Components/Avatar/Avatar';
import Exports from '../../../Components/Exports/Exports';
import {EnumTablaPaginacion} from '../../../Utils/Enumeradores/ComunEnum'
import moment from 'moment';
import Rangepicker from '../../../Components/Rangepicker/Rangepicker';
import { Form, Formik } from 'formik';
import SearchField from '../../../Components/SearchField/SearchField';
import PaginationComponent from '../../../Components/PaginationTableServer/PaginationTableServer';

const BitacoraDetailTable = ({ columns, data, avatar, fetchData, pageCount: controlledPageCount, currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy, setPagination, handlerModal }) => {
	const {
		getTableProps
		, getTableBodyProps
		, headerGroups
		, prepareRow
		, page
		, pageCount
		, gotoPage
		, setSortBy
		, state: { pageIndex, pageSize, sortBy }
	} = useTable(
		{
			columns
			, data
			, initialState: { pageIndex: currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy }
			, manualPagination: true
			, pageCount: controlledPageCount
			, autoResetPage: false
			, autoResetSortBy: false
			, manualSortBy: true
		},
		useSortBy
		, usePagination
	);

	const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0, EnumTablaPaginacion }}, useGlobalFilter, useSortBy, usePagination);
	const {state} = tableInstance;
	const {globalFilter} = state;
	const { usuario } = useLogin();
	const [valoresFormularioEnvio, setValoresFormularioEnvio] = useState({
		paginacion: {
			numeroPagina: pageIndex
			, cantidadRegistros: pageSize
			, orderByAscending: false
			, ordenadoPor: "FechaInicio"
		},
		datosBusqueda: {
			idUsuario: avatar.usuarioId
			, idBitacora: avatar.bitacoraDetalleId
			, exportacion: false
		}
	});
	useEffect(() => {
		fetchData({ formulario: valoresFormularioEnvio, pageIndex, sortBy });
		setPagination(values => ({
			...values
			, sortBy: sortBy
		}));
	}, [pageIndex, sortBy]);

	const descargar = () => {
		fetchData({ formulario: valoresFormularioEnvio, pageIndex: pageIndex, sortBy, exportacion: true });
	};

	return (
		<div>
			<header className='d-flex justify-content-between aling-items-center gap-3'>
					<Avatar data={avatar} esMostrarNombreCompletoYperfil={true} esMostrarNombreYperfil={false}/>
					<div className="flex-column d-md-flex ms-0 me-auto">
									<b>{avatar.nombreCompleto}</b>
									{<span className='text-start'>{avatar.listaPerfiles}</span>}
					</div>
					<Exports descargar={() => descargar()} />
				</header>
			<div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-start mb-5 gap-3">
							<div className="exports order-0 order-lg-1">
								{
								data.length > 0 &&
									<div className="exports order-0 order-lg-1">
									</div>
								}
							</div>
						</div>
			<div className="table-responsive brd-top">
				<table className="table" {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps(column.getSortByToggleProps(), { className: column.className })}>
										<span className="d-flex align-items-center gap-1">
											{column.render('Header')}
											<>
												{column.isSorted ?
													(column.isSortedDesc ?
														<i className="ico-chevron-down"></i>
														:
														<i className="ico-chevron-up"></i>
													) :
													<>
														<i className="ico-sort fs-7"></i>
													</>
												}
											</>
										</span>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{
							page.length > 0 && page.map( (row) => {
								prepareRow(row)
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps({
												className: cell.column.className
											})}>
												{cell.render('Cell')}
											</td>
										})}

									</tr>
									)
							}) || <tr><td colSpan={headerGroups[0].headers.length + 1}>
							<div className="d-flex justify-content-center align-items-center flex-column gap-2 py-5">
								<img className="mb-3" src={notfound} alt=""/>
								<p className='mb-0 text-primary'>No se encuentran resultados de tu búsqueda.</p>
							</div>
						</td></tr>
						}
					</tbody>
				</table>
			</div>
			{
				data.length > 0 &&
				<PaginationComponent
					pagesCountServer={pageCount}
					currentPage={pageIndex}
					setCurrentPage={gotoPage}
					alwaysShown={false}
					setPagination={setPagination}
				/>
			}
		</div>
	)
}

export default BitacoraDetailTable;

