import notfound from '../../../Assets/images/il-empty.svg';
import React, { useEffect, useRef, useState } from 'react';
import { usePagination, useSortBy, useTable, useGlobalFilter, useFilters } from 'react-table';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import Button from "../../../Components/Button/Button";
import Exports from '../../../Components/Exports/Exports';
import { EnumEstadoEventoConcesion, EnumEstadoEventoConcesionDesc, EnumTablaPaginacion } from '../../../Utils/Enumeradores/ComunEnum';
import moment from 'moment';
import { useComun } from '../../../Hooks/useComun';
import RangeDatePicker from '../../../Components/RangeDatePicker/RangeDatePicker';
import { Form, Formik } from 'formik';
import SelectCheckField from '../../../Components/SelectCheckField/SelectCheckField';
import PaginationComponent from '../../../Components/PaginationTableServer/PaginationTableServer';
import { ListaIndicadoresPorAreaNegocioService } from '../../../Services/ObtenerListaIndicadoresPorAreaNegocioService';
import validations from '../../../Utils/Validations/Validations';
import { useLoading } from '../../../Hooks/useLoading';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const BitacoraEventosConcesionTable = ({ columns, data, fetchData, pageCount: controlledPageCount, currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy, setPagination, handlerModal }) => {
	const {
		getTableProps
		, getTableBodyProps
		, headerGroups
		, prepareRow
		, page
		, pageCount
		, gotoPage
		, setSortBy
		, state: { pageIndex, pageSize, sortBy }
	} = useTable(
		{
			columns
			, data
			, initialState: { pageIndex: currentpage, pageSize: controlledPageSize, sortBy: controlledSortBy }
			, manualPagination: true
			, pageCount: controlledPageCount
			, autoResetPage: false
			, autoResetSortBy: false
			, manualSortBy: true
		},
		useSortBy,
		usePagination
	);

	const { usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const addLoading = useLoading();
	const [esBuscar, setEsBuscar] = useState(false);
	const [esPeticion, setEsPeticion] = useState(true);
	const formikRef = useRef();
	const { listadoIndicadoresId, setListadoIndicadoresId, getListaIndicadoresId, getListadoAreasNegocioIdUsuario } = useComun();
	const listaEstadoAutorizacion = [{ value: EnumEstadoEventoConcesion.Pendiente, label: EnumEstadoEventoConcesionDesc.Pendiente }, 
									{ value: EnumEstadoEventoConcesion.Autorizado, label: EnumEstadoEventoConcesionDesc.Autorizada }, 
									{ value: EnumEstadoEventoConcesion.Rechazado, label: EnumEstadoEventoConcesionDesc.Rechazada }];
	const listaEstadoEvento = [{ value: 1, label: "Cumpliendo" }
							, { value: 0, label: "Incumpliendo" }];
	const [listaAreaNegocioSeleccion, setListaAreaNegocioSeleccion] = useState(usuario ? usuario.listaAreaNegocio ? usuario.listaAreaNegocio.map(x => { return { value: x.id.toString(), label: x.descripcion } }) : [] : []);
	const [initialValues, setInitialValues] = useState({
		business: listaAreaNegocioSeleccion.map(x => { return x.value.toString() }),
		indicadores: listadoIndicadoresId ? listadoIndicadoresId.map(x => { return x.value }) : '',
		periodo2: [moment().subtract(1, 'M').toDate(), new Date()]
		, estado: listaEstadoAutorizacion.map(x => { return Number(x.value) })
			, estadoEvento: listaEstadoEvento.map(x => { return Number(x.value) })
	});
	useEffect(() => {
		getListadoAreasNegocioIdUsuario();

	}, [])
	useEffect(() => {
		if (esPeticion) {
			if (listadoIndicadoresId.length > 0 && usuario.listaAreaNegocio.length > 0) {
				BuscarEventosConcesion(initialValues);
			}
		}
	}, [usuario.listaAreaNegocio, listadoIndicadoresId, esPeticion])

	const [valoresFormularioEnvio, setValoresFormularioEnvio] = useState({
		paginacion: {
			numeroPagina: pageIndex
			, cantidadRegistros: pageSize
			, orderByAscending: false
			, ordenadoPor: "fechaCreacion"
		},
		datosBusqueda: {
			idUsuario: usuario.id
			, fechaInicio: moment().subtract(1, 'M').format()
			, fechaFin: moment().format()
			, exportacion: false
			, idNegocio: listaAreaNegocioSeleccion.map(x => { return Number(x.value) })
			, idIndicador: listadoIndicadoresId ? listadoIndicadoresId.map(x => { return x.value }) : []
			, estado: listaEstadoAutorizacion.map(x => { return Number(x.value) })
			, estadoEvento: listaEstadoEvento.map(x => { return Number(x.value) })
		}
	});

	useEffect(() => {
		if (listadoIndicadoresId.length > 0) {
			setInitialValues(values => ({
				...values
				, business: formikRef.current.values.business
				, indicadores: listadoIndicadoresId ? listadoIndicadoresId.map(x => { return x.value }) : '',
			}));
			setEsBuscar(true);
		} else {
			setInitialValues(values => ({
				...values
				, business: formikRef.current.values.business
				, indicadores: '',
			}));
		}

	}, [listadoIndicadoresId])

	useEffect(() => {
		let valoresNuevos = valoresFormularioEnvio;
		valoresNuevos.datosBusqueda.idNegocio = listaAreaNegocioSeleccion.map(x => { return Number(x.value) });
		valoresNuevos.datosBusqueda.idIndicador = listadoIndicadoresId ? listadoIndicadoresId.map(x => { return x.value }) : [];
		setValoresFormularioEnvio(valoresNuevos);
		setEsBuscar(false);
	}, [esBuscar])

	useEffect(() => {
		if (valoresFormularioEnvio.datosBusqueda.idIndicador.length > 0) {
			setEsPeticion(false);
			fetchData({ formulario: valoresFormularioEnvio, pageIndex, sortBy });
			/* GUARDAMOS EL SORTBY EN EL USESTATE DE PAGINATION QUE SE ENCUENTRA EN ALARMASPAGE PARA QUE NO SE PIERDA EL ORDENAMIENTO */
			setPagination(values => ({
				...values
				, sortBy: sortBy
			}));
		}
	}, [pageIndex, sortBy])


	const descargar = () => {
		fetchData({ formulario: valoresFormularioEnvio, pageIndex: pageIndex, sortBy, exportacion: true });
	};

	function BuscarEventosConcesion(values) {
		const startTime = new Date();
		let formEnvio = valoresFormularioEnvio;
		/* Se setean valores del paginado para la primera busqueda (resetea la tabla) */
		formEnvio.paginacion.numeroPagina = 0;
		formEnvio.paginacion.orderByAscending = false;
		formEnvio.paginacion.ordenadoPor = "fechaCreacion";
		/* Se setean los valores de los campos que ingresa el usuario */
		formEnvio.datosBusqueda.idNegocio = values.business.map(Number);
		formEnvio.datosBusqueda.fechaInicio = values.periodo2[0];
		formEnvio.datosBusqueda.fechaFin = values.periodo2[1];
		formEnvio.datosBusqueda.idIndicador = values.indicadores !== "" ? values.indicadores.map(x => { return Number(x) }) : []
		formEnvio.datosBusqueda.estado = values.estado !== "" ? values.estado.map(x => { return Number(x) }) : []
		formEnvio.datosBusqueda.estadoEvento = values.estadoEvento !== "" ? values.estadoEvento.map(x => { return Number(x) }) : []

		const fetchData = async () => {
			await Promise.all([
				setValoresFormularioEnvio(formEnvio),
			]);
			const endTime = new Date();
			const tdrId = 20;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
		setPagination({
			pageIndex: formEnvio.paginacion.numeroPagina,
			pageSize: formEnvio.paginacion.cantidadRegistros,
			sortBy: []
		});
		setSortBy([]);
		gotoPage(0);
	};

	useEffect(() => {

		setInitialValues(values => ({
			...values
			, business: listaAreaNegocioSeleccion ? listaAreaNegocioSeleccion.map(x => { return x.value.toString() }) : ''
		}));
		if (listaAreaNegocioSeleccion.length > 0) {
			getListaIndicadoresId(listaAreaNegocioSeleccion.map(x => { return x.value }));
		}
	}, [listaAreaNegocioSeleccion])

	const mostrarModal = (e, row) => {
		e.preventDefault();
		handlerModal(row);
	}

	function changeAreaNegocio(e) {
		formikRef.current.setFieldValue('business', e);
		if (e.length == 0) {
			setListadoIndicadoresId([]);
		}
	}

	const realizarBusqueda = () => {
		addLoading(true);
		if (formikRef.current.values.business.length > 0) {
			getListaIndicadoresId(formikRef.current.values.business.map(x => { return Number(x) }));
		}
		setTimeout(() => {
			addLoading(false);
		}, "400");
	};

	const BusquedaIndicadorBlur = () => {
		realizarBusqueda();
	};


	return (
		<div>
			<div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-start mb-5 gap-3">
				<Formik
					enableReinitialize
					initialValues={initialValues}
					innerRef={formikRef}
					validate={validations}
					onSubmit={(values) => {

						BuscarEventosConcesion(values);
					}}
				>
					{({ values, setFieldValue, setFieldTouched, }) => (
						<Form className="form mb-0">
							<div className="tools order-1 order-lg-0 align-items-start">
								<SelectCheckField
									name="business"
									label='Área de negocio'
									options={listaAreaNegocioSeleccion}
									onChange={(e, value) => { changeAreaNegocio(value) }}
									onBlur={BusquedaIndicadorBlur}
									isMulti={true}
									backdrop="static"
								/>
								<SelectCheckField
									name="indicadores"
									label='Indicadores'
									options={listadoIndicadoresId}
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									isMulti={true}
								/>
								<RangeDatePicker
									startMaxDate={new Date()}
									startMinDate={null}
									endMaxDate={new Date()}
									endMinDate={null}
									name="periodo2"
									onChange={setFieldValue}
									onBlur={setFieldTouched}
								/>
								<SelectCheckField
									name="estadoEvento"
									label='Estado Evento'
									options={listaEstadoEvento}
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									isMulti={true}
								/>
								<SelectCheckField
									name="estado"
									label='Estado Autorización IF'
									options={listaEstadoAutorizacion}
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									isMulti={true}
								/>
								<Button type="submit" design="btn-primary">Buscar</Button>
							</div>
						</Form>
					)}
				</Formik>
				{
					data.length > 0 &&
					<div className="exports order-0 order-lg-1">
						<Exports descargar={() => descargar()} />
					</div>
				}
			</div>
			<div className="table-responsive brd-top">
				<table className="table" {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									data.length > 0 ?
										<th {...column.getHeaderProps(column.getSortByToggleProps(), { className: column.className })}>
											<span className="d-flex align-items-center gap-1">
												{column.render('Header')}
												<>
													{column.isSorted ?
														(column.isSortedDesc && data.length > 0 ?
															<i className="ico-chevron-down"></i>
															:
															<i className="ico-chevron-up"></i>
														) :
														<>
															<i className="ico-sort fs-7"></i>
														</>
													}
												</>
											</span>
										</th>
										:
										<th {...column.getHeaderProps()}
										>
											<span className="d-flex align-items-center gap-1">
												{column.render('Header')}
											</span>
										</th>
								))}
								<th className="text-center">Detalle</th>
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{
							page.length > 0 && page.map((row) => {
								prepareRow(row)
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps({
												className: cell.column.className
											})}>
												{cell.render('Cell')}
											</td>
										})}
										<td className="text-center">
											<button className="link text-pewter-blue fs-4" onClick={(e) => mostrarModal(e, row.original)}>
												<i className="ico-search"></i>
											</button>
										</td>
									</tr>
								)
							}) || <tr><td colSpan={headerGroups[0].headers.length + 1}>
								<div className="d-flex justify-content-center align-items-center flex-column gap-2 py-5">
									<img className="mb-3" src={notfound} alt="" />
									<p className='mb-0 text-primary'>No se encuentran resultados de tu búsqueda.</p>
								</div>
							</td></tr>
						}
					</tbody>
				</table>
			</div>
			{
				data.length > 0 &&
				<PaginationComponent
					pagesCountServer={pageCount}
					currentPage={pageIndex}
					setCurrentPage={gotoPage}
					alwaysShown={false}
					setPagination={setPagination}
				/>
			}
		</div>
	)
}

export default BitacoraEventosConcesionTable;

