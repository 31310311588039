import instance, { customConfigFormData } from "../../../Utils/Interceptors/Interceptor";

const ModificarUsuario = async (values, idUsuario) => {
    try {
        let formData = new FormData();
		formData.append('Id', values.id);
		formData.append('Nombres', values.nombre);
		formData.append('ApellidoPaterno', values.apellido_paterno);
		formData.append('ApellidoMaterno', values.apellido_materno);
		formData.append('Email', values.email);
		formData.append('NumeroMovil', values.celular);
		formData.append('RutaImagen', values.file);
		
		values.profile.map((value,index) => {
			formData.append("ListPerfil["+index+"][Id]", Number(value));
			formData.append("ListPerfil["+index+"][Descripcion]", Number(value));
		});
		values.business.map((value, index) => {
			formData.append("ListAreaNegocio["+index+"][Id]", Number(value));
			formData.append("ListAreaNegocio["+index+"][Descripcion]", Number(value));
		});
		formData.append('IdUsuarioConsulta', idUsuario);

        const resultado = await instance.Backend.post("/Usuario/Modificar",formData, customConfigFormData);

        return resultado;

    } catch (error) {
        console.log(error);
        return error.response;
    }

}
export {ModificarUsuario};