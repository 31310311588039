import moment from 'moment';
import { validate } from 'rut.js';
import { EnumMensajeErrorArchivos, EnumTamanoArchivo } from '../Enumeradores/ComunEnum';
const validations = (values) => {
    const errors = {}

    if (values.rut === '') {
        errors.rut = 'Requerido';
    } else if (values.rut) {
        if (!validate(values.rut)) {
            errors.rut = 'Rut inválido';
        }
    }

    if (values.password === '') {
        errors.password = 'Requerido';
    } else if (values.password) {
        if (values.password.length < 8) {
            errors.password = 'La contraseña debe tener al menos 8 caracteres';
        } else if (values.password.length > 20) {
            errors.password = 'La contraseña debe tener máximo 20 caracteres';
        } else if (!/^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040.,/_{}-¿¡])(?=.*[A-Z])(?=.*[a-z])\S{8,20}$/.test(values.password)) {
            errors.password = 'La contraseña debe tener al menos una mayúscula, ocho caracteres, un número y un carácter especial';
        }
    }

    if (values.verification === '') {
        errors.verification = 'Requerido';
    } else if (values.verification && values.password) {
        if (values.verification !== values.password) {
            errors.verification = 'Contraseñas no coinciden';
        }
    }

    if (values.code === '') {
        errors.code = 'Requerido';
    } else if (values.code) {
        if (values.code === '0') {
            errors.code = 'El código debe ser mayor a 0';
        }
    }

    if (values.nombre === '') {
        errors.nombre = 'Requerido';
    } else if (values.nombre) {
        if (!/^[a-z A-ZáéíóúÁÉÍÓÚÑñ]+$/.test(values.nombre)) {
            errors.nombre = 'El nombre debe tener solo letras';
        } else if (!(values.nombre.length >= 3 && values.nombre.length <= 30)) {
            errors.nombre = 'El nombre debe tener entre 3 y 30 caracteres';
        }
    }

    if (values.apellido_paterno === '') {
        errors.apellido_paterno = 'Requerido';
    } else if (values.apellido_paterno) {
        if (!/^[a-z A-ZáéíóúÁÉÍÓÚÑñ]+$/.test(values.apellido_paterno)) {
            errors.apellido_paterno = 'El apellido debe tener solo letras';
        } else if (!(values.apellido_paterno.length >= 3 && values.apellido_paterno.length <= 30)) {
            errors.apellido_paterno = 'El apellido debe tener entre 3 y 30 caracteres';
        }
    }

    if (values.apellido_materno === '') {
        errors.apellido_materno = 'Requerido';
    } else if (values.apellido_materno) {
        if (!/^[a-z A-ZáéíóúÁÉÍÓÚÑñ]+$/.test(values.apellido_materno)) {
            errors.apellido_materno = 'El apellido debe tener solo letras';
        } else if (!(values.apellido_materno.length >= 3 && values.apellido_materno.length <= 30)) {
            errors.apellido_materno = 'El apellido debe tener entre 3 y 30 caracteres';
        }
    }

    if (values.celular === '') {
        errors.celular = 'Requerido';
    } else if (values.celular) {
        if (values.celular === '+569') {
            errors.celular = 'Requerido';
        } else if (!/^[+569]{4}[0-9]{8}$/.test(values.celular)) {
            errors.celular = 'Celular no tiene el formato correcto.';
        }
    }

    if (values.email === '') {
        errors.email = 'Requerido';

    } else if (values.email) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Email no válido';
        }
    }

    if (values.profile === '') {
        errors.profile = 'Requerido';
    }

    if (values.business === '') {
        errors.business = 'Requerido';
    }

    if (values.indicadores === '') {
        errors.indicadores = 'Requerido';
    }

    if (values.area === '') {
        errors.area = 'Requerido';
    }

    if (values.file) {
        const respuestaTamanoArchivo = TamanoArchivo(values.file);
        const respuestaFormato = validarAvatar(values.file);
        if (respuestaFormato !== "") {
            errors.file = respuestaFormato;
        } else if (respuestaTamanoArchivo !== "") {
            errors.file = respuestaTamanoArchivo;
        }
    }

    if (values.fileISP) {
        const respuestaExtension = sinExtension(values.fileISP);
        const respuestaTamanoArchivo = TamanoArchivo(values.fileISP);
        if (respuestaExtension !== "") {
            errors.fileISP = respuestaExtension;
        } else if (respuestaTamanoArchivo !== "") {
            errors.fileISP = respuestaTamanoArchivo;
        }
    }

    if (values.apellido === '') {
        errors.apellido = 'Requerido';
    }

    if (values.porcentaje === '') {
        errors.porcentaje = 'Requerido';
    } else if (values.porcentaje) {
        if (Number(values.porcentaje) <= 0) {
            errors.porcentaje = 'El porcentaje debe ser mayor a 0';
        } else
            if (Number(values.porcentaje) > 100) {
                errors.porcentaje = 'El porcentaje no debe ser mayor a 100';
            }
    }
    if (values.typeAlarm === '') {
        errors.typeAlarm = 'Requerido';
    }

    if (values.usuario === '') {
        errors.usuario = 'Requerido';
    } else if (values.usuario) {
    }

    if (values.backup === '') {
        errors.backup = 'Requerido';
    } else if (values.backup) {
    }

    if (values.periodo === '') {
        errors.periodo = 'Requerido';
    } else if (values.periodo) {
        if (values.periodo[0] === null) {
            errors.periodo = ['Debe seleccionar fecha detección', ''];
        } else if (values.periodo[1] === null) {
            errors.periodo = ['', 'Debe seleccionar fecha límite'];
        } else if (moment(values.periodo[0]).format() >= moment(values.periodo[1]).format()) {
            errors.periodo = ['La fecha detección debe ser menor a la fecha límite', 'La fecha detección debe ser menor a la fecha límite'];
        }
    }

    if (values.periodo2 === '') {
        errors.periodo2 = 'Requerido';
    } else if (values.periodo2) {
        if (values.periodo2[0] === null) {
            errors.periodo2 = ['Debe seleccionar fecha desde', ''];
        } else if (values.periodo2[1] === null) {
            errors.periodo2 = ['', 'Debe seleccionar fecha hasta'];
        } else if (moment(values.periodo2[0]).format() > moment(values.periodo2[1]).format()) {
            errors.periodo2 = ['La fecha desde debe ser menor a la fecha hasta', 'La fecha desde debe ser menor a la fecha hasta'];
        }
    }

    if (values.unidad === '') {
        errors.unidad = 'Requerido';
    }


    if (values.problema === '') {
        errors.problema = 'Requerido';
    }
    if (values.elemento === '') {
        errors.elemento = 'Requerido';
    }
    if (values.fiaFim === '') {
        errors.fiaFim = 'Requerido';
    }
    if (values.tipoFalla === '') {
        errors.tipoFalla = 'Requerido';
    }
    if (values.kmInicial === '') {
        errors.kmInicial = 'Requerido';
    } else if (values.kmInicial) {
        if (Number(values.kmInicial) < 0) {
            errors.kmInicial = 'El KM inicial debe ser mayor o igual a 0';
        } else if (values.kmFinal) {
            if (Number(values.kmFinal) < Number(values.kmInicial)) {
                errors.kmInicial = 'El KM inicial debe ser menor o igual al KM final';
            }
        }
        else if (values.kmInicial.toString().includes('.') && values.kmInicial.toString().split('.')[1].length > 3) {
            errors.kmInicial = 'Debe tener 3 decimales o menos';
        }
    }
    if (values.kmFinal === '') {
        errors.kmFinal = 'Requerido';
    } else if (values.kmFinal) {
        if (Number(values.kmFinal) === 0) {
            errors.kmFinal = 'El KM final debe ser mayor a 0';
        } else if (values.kmInicial) {
            if (Number(values.kmFinal) < Number(values.kmInicial)) {
                errors.kmFinal = 'El KM inicial debe ser menor o igual al KM final';
            }
        } else if (values.kmFinal.toString().includes('.') && values.kmFinal.toString().split('.')[1].length > 3) {
            errors.kmFinal = 'Debe tener 3 decimales o menos';
        }
    }
    if (values.gravedad === '') {
        errors.gravedad = 'Requerido';
    }
    if (values.valor === '') {
        errors.valor = 'Requerido';
    } else if (values.valor) {
        if (Number(values.valor) <= 0) {
            errors.valor = 'El valor debe ser mayor a 0';
        }
        else if (!/^[0-9]+([.][0-9]+)?$/.test(values.valor)) {
            errors.valor = 'El valor exigido solo debe tener numeros';
        }
    }

    if (values.notificaciones === '') {
        errors.notificaciones = 'Requerido';
    }

    if (values.estado_autorizacion === '') {
        errors.estado_autorizacion = 'Requerido';
    }

    if (values.serie === '') {
        errors.serie = 'Requerido';
    }

    if (values.fileProrroga) {
        let errorFileSize = [];
        values.fileProrroga.map((item) => {
            const respuestaExtension = sinExtension(item);
            const respuestaTamanoArchivo = TamanoArchivo(item);
            if (respuestaExtension !== "") {
                errorFileSize.push(respuestaExtension);
            }
            if (respuestaTamanoArchivo !== "") {
                errorFileSize.push(respuestaTamanoArchivo);
            }
        });
        if (errorFileSize.length > 0) {
            errors.fileProrroga = errorFileSize[0];
        }
    }

    if (values.fileHallazgo) {
        let errorFileSize = [];
        values.fileHallazgo.map((item) => {
            const respuestaExtension = sinExtension(item);
            const respuestaTamanoArchivo = TamanoArchivo(item);
            if (respuestaExtension !== "") {
                errorFileSize.push(respuestaExtension);
            }
            if (respuestaTamanoArchivo !== "") {
                errorFileSize.push(respuestaTamanoArchivo);
            }
        });
        if (errorFileSize.length > 0) {
            errors.fileHallazgo = errorFileSize[0];
        }
    }

    if (values.comentarioProrroga === '') {
        errors.comentarioProrroga = 'Requerido';
    } else if (values.comentarioProrroga) {
        const sinEspacio = values.comentarioProrroga.replace(/\s/g, '');
        const sinCaracteres = sinEspacio.replace(/[^a-zA-Z0-9 ]/g, '');
        const expresionRegular = /^[a-zA-Z0-9 ]+$/
        if (!expresionRegular.test(sinCaracteres)) {
            errors.comentarioProrroga = 'Debe ingresar comentario';
        }
    }

    if (values.fechaProrroga === '') {
        errors.fechaProrroga = 'Requerido';
    }

    if (values.comentarioHallazgo === '') {
        errors.comentarioHallazgo = 'Requerido';
    } else if (values.comentarioHallazgo) {
        const sinEspacio = values.comentarioHallazgo.replace(/\s/g, '');
        const sinCaracteres = sinEspacio.replace(/[^a-zA-Z0-9 ]/g, '');
        const expresionRegular = /^[a-zA-Z0-9 ]+$/
        if (!expresionRegular.test(sinCaracteres)) {
            errors.comentarioHallazgo = 'Debe ingresar comentario';
        }
    }

    if (values.minutos === '') {
        errors.minutos = 'Requerido';
    } else if (values.minutos) {
        if (Number(values.minutos) === 0) {
            errors.minutos = 'Cantidad minutos debe ser mayor a 0';
        } else if (Number(values.minutos) > 2000000) {
            errors.minutos = 'Cantidad minutos supera el máximo (2000000)';
        }
    }
    if (values.afectaIndicador === '') {
        errors.afectaIndicador = 'Requerido';
    }

    if (values.estado === '') {
        errors.estado = 'Requerido';
    }
    if (values.tipoHallazgo === '') {
        errors.tipoHallazgo = 'Requerido';
    }
    if (values.adaptacion === '') {
        errors.adaptacion = 'Requerido';
    }
    if (values.conducta === '') {
        errors.conducta = 'Requerido';
    }
    if (values.desempeno === '') {
        errors.desempeno = 'Requerido';
    }
    if (values.tipo_monitoreo === '') {
        errors.tipo_monitoreo = 'Requerido';
    }
    return errors
}
const esBase64 = (value) => {
    const expresionRegular = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    if (expresionRegular.test(value)) {
        return true;
    }
    return false;
}
const validarAvatar = (value) => {
    if (!value.type.includes("image")) {
        return EnumMensajeErrorArchivos.Formato;
    }
    return "";
}
const sinExtension = (value) => {
    if (value.type === "") {
        return EnumMensajeErrorArchivos.Extension;
    }
    return "";
}

const TamanoArchivo = (item) => {
    var sizeByte = item.size;
    var sizeKiloByte = parseInt(sizeByte / 1024);
    if (sizeKiloByte > EnumTamanoArchivo.DosMB) {
        return EnumMensajeErrorArchivos.Tamano;
    }
    return "";
}

const soloNumerico = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "")
    e.target.value = Math.max(0, (e.target.value === '') ? e.target.value : parseInt(e.target.value)).toString().slice(0, 10).replace(/^(0+)/g, '')
}
const soloDecimal = (e) => {
    e.target.value = e.target.value.replace(/[^0-9.]/g, "")
    if (!e.target.value.includes(".")) {
        if (/^[0-9]{6}/g.test(e.target.value)) {
            e.target.value = e.target.value.slice(0, -1);
            return;
        }
    } else {
        const separador = e.target.value.split(".");
        if (separador.length >= 3) {
            e.target.value = e.target.value.slice(0, -1);
            return;
        }
        if (separador[1]) {
            if (/^[0-9]{4}/g.test(separador[1])) {
                e.target.value = e.target.value.slice(0, -1);
                return;
            }
        }
        if (/^[0-9]{6}/g.test(separador[0])) {
            e.target.value = separador[0].slice(0, -1) + '.' + separador[1];
            return;
        }

    }
    e.target.value =
        e.target.value
            .replace(/[^0-9.]/g, "")
            .replace(/\.{3,}/g, '.')
            .replace(/^0*([^0]\d*\.\d{1,3}).*/g, "$1")
}
const onBlurDecimal = (e) => {
    const separador = e.target.value.split(".");
    if (separador.length === 2) {
        if (Number(separador[0]) === 0) {
            e.target.value = '0.' + separador[1];
            return;
        }
    } else if (separador.length === 1 && separador[0] !== '0') {
        e.target.value = e.target.value.replace(/^(0+)/g, '');
        return;
    }
}
const sinDobleEspacio = (e) => {
    e.target.value =
        e.target.value
            .replace("  ", " ");
}
const onBlurComentario = (e) => {
    e.target.value = e.target.value.trim();
    const arrayTexto = [...e.target.value];
    const arregloLimpio = [];
    arrayTexto.map((resp, index) => {
        if (index > 0) {
            const caracterAnterior = arrayTexto.filter((x, i) => i === (index - 1));
            if (!/\s/.test(caracterAnterior) || !/\s/.test(resp)) {
                arregloLimpio.push(resp);
            }
        } else {
            arregloLimpio.push(resp);
        }
    });
    e.target.value = arregloLimpio.join("");

}

export default validations;
export { esBase64, validarAvatar, soloNumerico, soloDecimal, onBlurDecimal, onBlurComentario, sinDobleEspacio, sinExtension, TamanoArchivo };
