import { EnumTipoMonitoreo } from "../../../Utils/Enumeradores/ComunEnum";

export const Columns = [
    { Header: 'Id Configuración', accessor: 'configuracionId', disableSortBy: true},
    { Header: 'Cliente', accessor: 'cliente', disableSortBy: true },
    { Header: 'Nombre Host', accessor: 'nombreHost', disableSortBy: true},
    { Header: 'Tipo', accessor: 'tipo', disableSortBy: true },
    { Header: 'Nombre Test', accessor: 'nombreTest', disableSortBy: true },
    { Header: <span  >
    Inicio Evento
  </span>, accessor: 'inicioEvento', disableSortBy: true },
    { Header: <span >
    Fin Evento
  </span>, accessor: 'finEvento', disableSortBy: true },
    { Header: <span >
    Estado
  </span>, accessor: 'estado', disableSortBy: true },
    { Header: <span >
    Duración
  </span>, accessor: 'duracion', disableSortBy: true },
    { Header: <span >
    Causa
  </span>, accessor: 'causa', disableSortBy: true }, 
    { 
      Header: <span>Tipo Monitoreo</span>,
      accessor: 'tipoMonitoreoString', 
      classNames: 'text-center',
      disableSortBy: true,
    },
    { Header: <span >
    Mes
  </span>, accessor: 'mes', disableSortBy: true},
    { Header: 'Año', accessor: 'año', disableSortBy: true},
  ];