import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import Card from "../../../Components/Card/Card";
import { useToast } from "../../../Hooks/useToast";
import useLogin from "../../Autenticacion/Hooks/useLogin";
import { ActualizaAlarmasPersonalizadasService } from "../Services/ActualizaAlarmasPersonalizadasService";
import { ObtenerAlarmasPersonalizadasService } from "../Services/ObtenerAlarmasPersonalizadasService";
import { EnumPathRutas } from "../../../Utils/Enumeradores/ComunEnum";
import { useRegistroTDR } from "../../../Hooks/useRegistroTDR";

const PersonalizacionAlarmasPage = () => {
	const [listaAlarmasPersonalizadas, setListaAlarmasPersonalizadas] = useState([]);
	const { usuario } = useLogin();
	const addToast = useToast();
	const { crearRegistroTDR } = useRegistroTDR();

	async function postObtenerAlarmasPersonalizadas(idUsuario, idPerfilContexto) {
		const startTime = new Date();
		const resp = await ObtenerAlarmasPersonalizadasService(idUsuario, idPerfilContexto);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					const fetchData = async () => {
						await Promise.all([
							setListaAlarmasPersonalizadas(resp.data)
						]);
						const endTime = new Date();
						const tdrId = 49;
						crearRegistroTDR(startTime, endTime, usuario, tdrId);
					};

					fetchData();
				}
			}
		}
	}

	async function postActualizaAlarmasPersonalizadas(values) {
		const resp = await ActualizaAlarmasPersonalizadasService(values);
		if (resp) {
			if (resp.status === 200) {
				addToast({ mostrar: true, mensaje: "Se ha actualizado correctamente.", titulo: 'Confirmación', tipo: 'Success' });
			}
		}
	}

	useEffect(() => {
		postObtenerAlarmasPersonalizadas(usuario.id, usuario.perfilContexto.id);
	}, [])

	function guardarAlarmasPersonalizadas() {
		const startTime = new Date();
		const listaCheckeados = [];
		listaAlarmasPersonalizadas.map(x => {
			listaCheckeados.push({
				indicadorId: x.indicadorId
				, exigenciaId: null //cuando llenamos los indicadores la exigencia se debe mandar en null.
				, visualizar: x.visualizar
				, correo: x.correo
				, sms: x.sms
				, previa: x.previa
			});
		});

		listaAlarmasPersonalizadas.map(resp => {
			if (resp.exigencia) {
				resp.exigencia.map(x => {
					if (x.visualizar || x.correo || x.sms) {
						listaCheckeados.push({
							indicadorId: resp.indicadorId
							, exigenciaId: x.exigenciaId
							, visualizar: x.visualizar
							, correo: x.correo
							, sms: x.sms
							, previa: false //en exigencias "previa" siempre es falso.
						});
					}
				})
			}
		});
		const envioFormulario = {
			idUsuario: usuario.id,
			indicadores: listaCheckeados
		}
		const fetchData = async () => {
			await Promise.all([
				postActualizaAlarmasPersonalizadas(envioFormulario)
			]);
			const endTime = new Date();
			const tdrId = 50;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
	}
	return (
		<div className="container-fluid">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li><Link to={EnumPathRutas.Inicio}>Inicio</Link></li>
							<li><Link to={EnumPathRutas.Alarmas}>Alarmas</Link></li>
							<li><a href="#">Personalización de alarmas</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<Card className="p-4">
						<div className="table-responsive">
							<table className="table table-sm">
								<thead>
									<tr>
										<th>Indicador</th>
										<th>Exigencia</th>
										<th className="text-center">Visualizar</th>
										<th className="text-center">Alerta correo</th>
										<th className="text-center">Alerta SMS</th>
										<th className="text-center">Alerta Previa</th>
									</tr>
								</thead>
								<tbody>
									{listaAlarmasPersonalizadas.map((item, index) => {
										return (
											<Fragment key={item.indicadorSigla + index}>
												<tr key={item.indicadorSigla + index} className="parent">
													<td><b>{item.indicadorSigla}</b></td>
													<td>&nbsp;</td>
													<td className="text-center">
														{item.exigencia === null &&
															<input className="check" defaultChecked={item.visualizar} onChange={(e) => item.visualizar = e.target.checked} type="checkbox" />
														}
													</td>
													<td className="text-center">
														{item.exigencia === null &&
															<input className="check" defaultChecked={item.correo} onChange={(e) => item.correo = e.target.checked} type="checkbox" />
														}
													</td>
													<td className="text-center">
														{item.exigencia === null &&
															<input className="check" defaultChecked={item.sms} onChange={(e) => item.sms = e.target.checked} type="checkbox" />
														}
													</td>
													<td className="text-center">
														<input className="check" defaultChecked={item.previa} onChange={(e) => item.previa = e.target.checked} type="checkbox" />
													</td>
												</tr>
												{item.exigencia ? item.exigencia.map((exigencia, i) => {
													return (
														<tr key={i + item.indicadorSigla} className="child">
															<td>&nbsp;</td>
															<td>{exigencia.exigenciaDescripcion}</td>
															<td className="text-center">
																<input className="check" defaultChecked={exigencia.visualizar} onChange={(e) => exigencia.visualizar = e.target.checked} type="checkbox" />
															</td>
															<td className="text-center">
																<input className="check" defaultChecked={exigencia.correo} onChange={(e) => exigencia.correo = e.target.checked} type="checkbox" />
															</td>
															<td className="text-center">
																<input className="check" defaultChecked={exigencia.sms} onChange={(e) => exigencia.sms = e.target.checked} type="checkbox" />
															</td>
															<td className="text-center">&nbsp;</td>
														</tr>
													)
												}
												) : null}

											</Fragment>
										)
									})}
								</tbody>
							</table>
						</div>
						<div className="d-flex justify-content-center py-3">
							<Button type="button" design="btn-primary" onClick={() => { guardarAlarmasPersonalizadas() }}>Guardar Cambios</Button>
						</div>
					</Card>
				</div>
			</div>
		</div>
	)
}
export default PersonalizacionAlarmasPage;
