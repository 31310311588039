import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";


async function ListaParametroIndicadorExigenciaService(idUsuario) {

    try {
        return await instance.Backend.post("/Indicador/ListaParametroIndicadorExigencia", 
        {
            IdUsuario: idUsuario
        }, customConfig);

    } catch (error) {
        console.log(error);
    }

}
export { ListaParametroIndicadorExigenciaService };