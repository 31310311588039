import { useCallback, useState, createContext } from "react";
const NombreModuloContext = createContext();

export default NombreModuloContext;
export function NombreModuloContextProvider({ children }) {
    const [nombreModulo, setNombreModulo] = useState("");
    const addNombre = useCallback(
        function (nombreValue) {
            setNombreModulo(() => nombreValue);
        },
        [setNombreModulo]
    );

    return (
        <NombreModuloContext.Provider value={{ addNombre, nombreModulo }}>
            {children}
        </NombreModuloContext.Provider>
    );
}
