import React, { useMemo, useState, useEffect } from 'react';
import Card from "../../../Components/Card/Card";
import BitacoraBimTable from "../Components/BitacoraBimTable";
import { COLUMNS } from '../Utils/ColumnsBim';
import { EnumPathRutas, EnumTablaPaginacion } from '../../../Utils/Enumeradores/ComunEnum'
import { useComun } from '../../../Hooks/useComun';
import { EnumModuloDesc } from "../../../Utils/Enumeradores/ComunEnum";
import { BusquedaBitacoraBIM } from '../Services/BusquedaBitacoraBIM';
import ModalBimDetalle from '../Components/ModalBimDetalle';
import { ObtenerDetalleBimService } from '../Services/ObtenerDetalleBimService';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { Link } from 'react-router-dom';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const BitacoraBimPage = () => {
	const { downloadCSV } = useComun();
	const { crearRegistroTDR } = useRegistroTDR();
	const columns = useMemo(() => COLUMNS, []);
	const [data, setData] = React.useState([]);
	const [dataDetalle, setDataDetalle] = React.useState([]);
	const { usuario } = useLogin();
	const [editUser, setEditUser] = useState({});
	const [show, setShow] = useState(false);
	const [pageCount, setPageCount] = React.useState(0);
	const handleClose = () => setShow(false);
	const handlerModal = (user) => {
		setEditUser(user);
		setShow(true);
	};
	const [{ pageIndex, pageSize, sortBy }, setPagination] =
		React.useState({
			pageIndex: 0,
			pageSize: EnumTablaPaginacion.CantidadPaginacion,
			sortBy: []
		});

	async function postBusquedaBitacoraBIM(values) {
		const startTime = new Date();
		const resp = await BusquedaBitacoraBIM(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					if (values.datosBusqueda.exportacion) {
						const fetchData = async () => {
							await Promise.all([
								downloadCSV(resp.data, "Bitacora BIM", EnumModuloDesc.DescargaBitacoraBIM)
							]);
							const endTime = new Date();
							const tdrId = 30;
							crearRegistroTDR(startTime, endTime, usuario, tdrId);
						};

						fetchData();
					} else {
						setPageCount(resp.data.cantidadPaginas);
						setData(resp.data.listadoBitacoraBIM);
					}
				}
			}
		}
	};

	async function postObtenerBimDetalle(values) {
		const startTime = new Date();
		const resp = await ObtenerDetalleBimService(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {
					const fetchData = async () => {
						await Promise.all([
							setDataDetalle(resp.data)
						]);
						const endTime = new Date();
						const tdrId = 31;
						crearRegistroTDR(startTime, endTime, usuario, tdrId);
					};

					fetchData();
				}
			}
		}
	};
	useEffect(() => {
		const formulario = {
			elementoId: editUser.elementoId,
			idUsuario: usuario.id
		}
		postObtenerBimDetalle(formulario);
	}, [editUser]);


	const fetchData = React.useCallback(({ formulario, pageIndex, sortBy = [], exportacion = false }) => {
		const startTime = new Date();
		formulario.paginacion.numeroPagina = pageIndex + 1;
		formulario.paginacion.ordenadoPor = sortBy.length > 0 ? sortBy[0].id : formulario.paginacion.ordenadoPor;
		formulario.paginacion.orderByAscending = sortBy.length > 0 ? (sortBy[0].desc ? false : true) : formulario.paginacion.orderByAscending;
		formulario.datosBusqueda.exportacion = exportacion;
		const fetchData = async () => {
			await Promise.all([
				postBusquedaBitacoraBIM(formulario)
			]);
			const endTime = new Date();
			const tdrId = 28;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
	}, []);

	return (
		<>
			<div className="container-fluid">
				<div className="row mb-4">
					<div className="col-12">
						<div className="breadcrumbs">
							<ul>
								<li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
								<li><a href="" onClick={(e) => { e.preventDefault(); window.location.reload(false); }}>Bitácora</a></li>
								<li><a href="">BIM</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<Card className="p-4">
							{data &&
								<BitacoraBimTable
									columns={columns}
									data={data}
									fetchData={fetchData}
									pageCount={pageCount}
									currentpage={pageIndex}
									pageSize={pageSize}
									sortBy={sortBy}
									setPagination={setPagination}
									handlerModal={handlerModal}
								/>
							}
						</Card>
					</div>
				</div>
			</div>
			<ModalBimDetalle
				show={show}
				onHide={handleClose}
				respdatadetalle={dataDetalle}
			/>
		</>
	)
}

export default BitacoraBimPage;
