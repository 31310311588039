import instance, { customConfigFormData } from "../../../Utils/Interceptors/Interceptor";

const AsignarCDO = async (values) => {
	try {
		const resultado = await instance.Backend.post("/Indicador/AsignarCDO",values, customConfigFormData);

		return resultado

} catch (error) {

		console.log(error);

}
}
export { AsignarCDO };
