import instance, { customConfig }  from "../../../Utils/Interceptors/Interceptor";

const CambiarEstado = async (values) => {
    try {
        
        const resultado = await instance.Backend.post("/Usuario/SolicitudModificarEstado",{
            usuarioId: values.id,
            movimiento: values.movimiento, 
        }, customConfig);

        return resultado

    } catch (error) {

        console.log(error);

    }

}
export {CambiarEstado};