import React, { useState, useCallback, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../Components/Header/Header";
import Menu from "../Components/Menu/Menu";
import useLogin from "../Features/Autenticacion/Hooks/useLogin";
import { useComun } from "../Hooks/useComun";
import { useContadores } from "../Hooks/useContadores";
import { EnumIntervaloTiempo, EnumPerfil } from "../Utils/Enumeradores/ComunEnum";

const AppLayout = () => {
	const [collapse, setCollapse] = useState(false);
	const handleToggle = useCallback(() => setCollapse(prevCollapse => !prevCollapse), [])
	const { usuario } = useLogin();
	const { contador } = useContadores();
	const modulos = usuario ? (usuario.listaModuloAcceso ? usuario.listaModuloAcceso : []) : [];
	const {
		contadorAlarmas, obtenerContadorAlarmasNoLeidas
		, contadorNotificaciones, obtenerContadorNotificacionNoLeidas
		, contadorTotalPendientesAutorizacion, obtenerContadorTotalPendientesAutorizacion
	} = useComun();

	const handleCollapse = (value) => {
		setCollapse(value)
	}

	useEffect(() => {
		if (modulos.filter(x => x.moduloId).length > 0) {
			const values = {
				idUsuario: usuario.id,
				idPerfil: usuario.perfilContexto.id
			}
			obtenerContadorNotificacionNoLeidas(usuario.id, usuario.perfilContexto.id);
			if(usuario.perfilContexto.id === EnumPerfil.Administrador || usuario.perfilContexto.id === EnumPerfil.InspectorFiscal){
				obtenerContadorTotalPendientesAutorizacion(usuario.id);
			}
			if(usuario.alarmas === true){
				obtenerContadorAlarmasNoLeidas(values);
			}
			const interval = setInterval(() => {
				obtenerContadorNotificacionNoLeidas(usuario.id, usuario.perfilContexto.id);
				if(usuario.perfilContexto.id === EnumPerfil.Administrador || usuario.perfilContexto.id === EnumPerfil.InspectorFiscal || usuario.perfilContexto.id === EnumPerfil.LiderConcesionaria){
					obtenerContadorTotalPendientesAutorizacion(usuario.id);
				}
				if(usuario.alarmas === true){
					obtenerContadorAlarmasNoLeidas(values);
				}
			}, EnumIntervaloTiempo.VeinteSegundos)
			return () => clearInterval(interval)
		}

	}, []);
	useEffect(() => {
		if(usuario.perfilContexto.id === EnumPerfil.Administrador || usuario.perfilContexto.id === EnumPerfil.InspectorFiscal || usuario.perfilContexto.id === EnumPerfil.LiderConcesionaria){
			obtenerContadorTotalPendientesAutorizacion(usuario.id);
		}
	}, [contador]);

	return (
		<>
			<section className="wrapper">
				<Menu
					collapse={collapse}
					handleCollapse={handleCollapse}
					contadorAlarmas={contadorAlarmas}
					contadorNotificaciones={contadorNotificaciones}
					contadorTotalPendientesAutorizacion={contadorTotalPendientesAutorizacion} />
				<main role="main">
					<Header onToggle={handleToggle}
						collapse={collapse}
						contadorAlarmas={contadorAlarmas}
						contadorNotificaciones={contadorNotificaciones} />
					<section className="content">
						<Outlet />
					</section>
				</main>
			</section>
		</>
	);
};

export default AppLayout;



