import { useEffect, useRef, useState } from 'react';
import Button from "../../../Components/Button/Button";
import { Formik, Form, } from "formik";
import SelectCheckField from "../../../Components/SelectCheckField/SelectCheckField";
import Badge from "../../../Components/Badge/Badge";
import { Link, useNavigate } from "react-router-dom";
import ChartsRadialBar from "../../../Components/ChartsRadialBar/ChartsRadialBar";
import Leyenda from '../../../Components/Leyenda/Leyenda';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { useComun } from '../../../Hooks/useComun';
import validations from '../../../Utils/Validations/Validations';
import { BuscaSerieIndicadorService } from '../Services/BuscaSerieIndicadorService';
import { EnumDMIndicador, EnumPathRutas, EnumPerfil } from '../../../Utils/Enumeradores/ComunEnum';
import ColumnCharts from '../../../Components/ColumnCharts/ColumnCharts';
import notfound from '../../../Assets/images/il-empty.svg';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';


const IndicadoresPage = () => {
	const { user, usuario } = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();
	const perfilLogeado = usuario ? usuario.perfilContexto : "";
	const formikRef = useRef();
	const { listaSerie, listaIndicadorDt, ObtenerListaSerie, ObtenerListaIndicadorDt } = useComun();
	const [indicators] = useState({ serie: 90, color: '#49B461' });
	const [legends] = useState([
		{ indicator: 'NPSC', ponderation: 80, value: 99, state: 'success' },
		{ indicator: 'NPSG', ponderation: 20, value: 99, state: 'danger' }
	]);
	const [initialValues, setInitialValues] = useState({ indicadoresSinValidacion: '', serie: [1] });
	const [dataSerieIndicador, setDataSerieIndicador] = useState([]);
	const [dataSerieNivelServicio, setDataSerieNivelServicio] = useState([]);
	const [dataSerieIndicadorCompleto, setDataSerieIndicadorCompleto] = useState([]);
	const [listaSerieTab, setListaSerieTab] = useState([{ serie: 1, clase: 'secondary' }]);
	const [descripcionNivelServicio, setDescripcionNivelServicio] = useState(null);
	const [mostrarGrafico, setMostrarGrafico] = useState(false);
	const navigate = useNavigate();
	const [conDatos, setConDatos] = useState(false);
	const [tdrIdCounter, setTdrIdCounter] = useState(4);
	const [selectedSerieId, setSelectedSerieId] = useState(null);

	const [datosGrafico, setDatosGrafico] = useState([
		{
			name: 'ultimos 5 minutos',
			data: [96, 100, 97]
		}, {
			name: 'ultima media hora',
			data: [98, 94, 100]
		}, {
			name: '1 hora ',
			data: [97, 100, 98]
		}
	]);
	useEffect(() => {
		//const startTime = new Date();
		if (dataSerieIndicador.length > 0) {
			let listaDatos = [];
			let listaValor = [];
			const listaNombres = dataSerieIndicador.sort((a, b) => a.indicadorId > b.indicadorId ? 1 : -1).map(x => x.sigla);
			dataSerieIndicadorCompleto.map(x => {
				x.indicador.filter(x => listaNombres.some(y => y === x.sigla)).sort((a, b) => a.indicadorId > b.indicadorId ? 1 : -1).map(y => {
					if((y.sigla === 'AT' || y.sigla === 'ATO')){
						listaValor.push(y.valorCumplimiento);
					}
					else{
						listaValor.push(y.valor);
					}
				})
				listaDatos.push({ name: x.serieDescripcion, data: listaValor });
				listaValor = [];
			});
			setDatosGrafico({ listaNombres: listaNombres, listaData: listaDatos })
			setConDatos(true);
		}
	}, [dataSerieIndicador])

	async function postBuscaSerieIndicador(values, esNivelServicio = true, selectedBuscar1 = false) {
    const resp = await BuscaSerieIndicadorService(values);
    if (resp) {
        if (resp.status === 200) {
            if (resp.data) {
                setDescripcionNivelServicio(null);
                setDataSerieIndicadorCompleto(resp.data);
                if (resp.data.length > 0) {
									const serieSeleccionada = selectedBuscar1 == true ? resp.data[0] : resp.data.find(x => x.serieId === selectedSerieId) || resp.data[0];
                    if (esNivelServicio) {
                        setDataSerieNivelServicio(serieSeleccionada.indicador.sort((a, b) => a.indicadorId > b.indicadorId ? 1 : -1));
                        setDataSerieIndicador([]);
                    } else {
                        setDataSerieNivelServicio([]);
                        const tieneHijo = serieSeleccionada.indicador.filter(x => x.indicadorHijo.length > 0);
                        if (tieneHijo.length > 0) {
                            setDescripcionNivelServicio(tieneHijo[0]);
                            setDataSerieIndicador(tieneHijo[0].indicadorHijo.sort((a, b) => a.indicadorId > b.indicadorId ? 1 : -1));
                        } else {
                            setDataSerieIndicador(serieSeleccionada.indicador.sort((a, b) => a.indicadorId > b.indicadorId ? 1 : -1));
                        }
                    }
                }
                setConDatos(true);
            }
        } else if (resp.status === 206) {
            setConDatos(false);
        }
    }
}

	useEffect(() => {
		const startTime = new Date();
		const formEnvio = {
			idUsuario: user.id,
			idPerfilContexto: usuario.perfilContexto.id
		}

		const formEnvioBuscar = {
			idUsuario: user.id,
			indicadorId: [2, 3, 4],
			serieId: initialValues.serie,
			idPerfilContexto: usuario.perfilContexto.id,
			consultaIndicadores: false
		}


		const fetchData = async () => {
			await Promise.all([
				ObtenerListaSerie(formEnvio),
				ObtenerListaIndicadorDt(formEnvio),
				postBuscaSerieIndicador(formEnvioBuscar)
			]);
			const endTime = new Date();
			const tdrId = 3;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
	}, [])

	function SubmitBuscarSerieIndicador(values) {

		const startTime = new Date();
		const formEnvio = {
			idUsuario: usuario.id,
			indicadorId: (values.indicadoresSinValidacion.length === 0) ? [2, 3, 4] : values.indicadoresSinValidacion,
			serieId: values.serie,
			idPerfilContexto: usuario.perfilContexto.id,
			consultaIndicadores: true
		}
		const fetchData = async () => {
			await Promise.all([

				postBuscaSerieIndicador(formEnvio, (values.indicadoresSinValidacion.length === 0) ? true : false, true)
			]);
			const endTime = new Date();
			const tdrId = 10;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};
		fetchData();
		//CUANDO HACE BUSQUEDA PARA LOS NIVELES DE SERVICIO Y ESTABA EN LA VISTA DEL GRAFICO, CIERRA EL GRAFICO Y SE LISTAN NUEVAMENTE LAS SERIES
		if (values.indicadoresSinValidacion.length === 0) {
			setMostrarGrafico(false);
			const numeroMin = values.serie.reduce((n, m) => Math.min(n, m), Number.POSITIVE_INFINITY)
			setListaSerieTab(values.serie.map(x => {
				if (x === numeroMin) {
					return { serie: numeroMin, clase: 'secondary' }
				} else {
					return { serie: x, clase: 'gray' }
				}
			}));
		} else if (mostrarGrafico) { // CUANDO ES MOSTRAR GRAFICO DEJA LAS SERIES EN GRIS SIN BOTON
			setListaSerieTab(values.serie.map(x => {
				return { serie: x, clase: 'gray' }
			}));
		} else {//LISTA LAS SERIES PINTANDO LA SERIE MINIMA
			const numeroMin = values.serie.reduce((n, m) => Math.min(n, m), Number.POSITIVE_INFINITY)
			setListaSerieTab(values.serie.map(x => {
				if (x === numeroMin) {
					return { serie: numeroMin, clase: 'secondary' }
				} else {
					return { serie: x, clase: 'gray' }
				}
			}));
		}

	}
	function changeMostrarGrafico(esMostrar) {
		const startTime = new Date();
		if (esMostrar) {
			const fetchData = async () => {
				await Promise.all([
					setListaSerieTab(
						listaSerieTab.map(x => {
							return { serie: x.serie, clase: 'gray' }
						})
					),
				]);
				const endTime = new Date();
				const tdrId = 11;
				crearRegistroTDR(startTime, endTime, usuario, tdrId);
			};

			fetchData();
		} else {
			const numeroMin = listaSerieTab.reduce((n, m) => Math.min(n, m.serie), Number.POSITIVE_INFINITY)
			changeSerie(numeroMin);
		}
		setMostrarGrafico(esMostrar);
	}
	function changeSerie(serieId) {
		setListaSerieTab(
			listaSerieTab.map(x => {
				if (x.serie === serieId) {
					return { serie: serieId, clase: 'secondary' }
				} else {
					return { serie: x.serie, clase: 'gray' }
				}
			})

		);
		const lista = dataSerieIndicadorCompleto.filter(x => x.serieId === serieId);
		setSelectedSerieId(serieId);
		if (dataSerieNivelServicio.length !== 0) {
			setDataSerieNivelServicio(lista.length > 0 ? lista[0].indicador.sort((a, b) => a.indicadorId > b.indicadorId ? 1 : -1) : []);
		}
		if (dataSerieIndicador.length !== 0) {
			const tieneHijo = lista[0].indicador.filter(x => x.indicadorHijo.length > 0);
			if (tieneHijo.length > 0) {
				setDescripcionNivelServicio(tieneHijo[0]);
				setDataSerieIndicador(tieneHijo[0].indicadorHijo.sort((a, b) => a.indicadorId > b.indicadorId ? 1 : -1));
			} else {
				setDescripcionNivelServicio(null);
				setDataSerieIndicador(lista.length > 0 ? lista[0].indicador.sort((a, b) => a.indicadorId > b.indicadorId ? 1 : -1) : []);
			}
		}
	}



	const maxTdrId = 7; // Termina en 7

	function mostrarIndicadores(x) {
    if (x.esNivelServicio) {
        const formEnvio = {
            idUsuario: usuario.id,
            indicadorId: [x.indicadorId],
            serieId: formikRef.current.values.serie,
            idPerfilContexto: usuario.perfilContexto.id,
            consultaIndicadores: true
        };
        const startTime = new Date(); // Inicia el contador
        const fetchData = async () => {
            await Promise.all([
                postBuscaSerieIndicador(formEnvio, false)
            ]);
            const endTime = new Date();
            crearRegistroTDR(startTime, endTime, usuario, tdrIdCounter);
            // Almacenar el valor actualizado de tdrIdCounter en el almacenamiento local
            localStorage.setItem('tdrIdCounter', tdrIdCounter);
            setTdrIdCounter(prevCounter => {
                const newCounter = prevCounter + 1;
                if (newCounter > maxTdrId) { // Si supera el máximo, reinicia
                    return 3;
                }
                return newCounter;
            });
        };
        fetchData();
    } else {
        navigate("cuarto-nivel", { state: { idNivelCumplimiento: x.nivelCumplimientoId, item: x } });
    }
}




	function redireccion(datos) {
		const formEnvio = {
			idUsuario: usuario.id,
			indicadorId: [datos.indicadorId],
			serieId: formikRef.current.values.serie,
			idPerfilContexto: usuario.perfilContexto.id,
			consultaIndicadores: true
		}
		postBuscaSerieIndicador(formEnvio, false);
	}

	return (
		<div className="container-fluid h-100">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
							<li><a href="" onClick={(e) => { e.preventDefault(); window.location.reload(false); }}>Indicadores</a></li>
							<li><a href="">Nivel de servicio</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="row h-100">
				<div className="col-12">
					<div className="bg-white rounded p-4">
						<div className="d-flex flex-column justify-content-between mb-5 gap-4">
							<div className="d-flex flex-column flex-md-row gap-3 justify-content-between">
								<Formik
									innerRef={formikRef}
									enableReinitialize
									initialValues={initialValues}
									validate={validations}
									onSubmit={values => {
										SubmitBuscarSerieIndicador(values);
									}}
								>
									{({ values, setFieldValue, errors, setFieldTouched, touched }) => (
										<Form className="form flex-wrap flex-row mb-0 order-1 order-md-0">
											<SelectCheckField
												name="indicadoresSinValidacion"
												options={listaIndicadorDt}
												onChange={setFieldValue}
												onBlur={setFieldTouched}
												isMulti={true}
											/>
											<SelectCheckField
												name="serie"
												options={listaSerie}
												onChange={setFieldValue}
												onBlur={setFieldTouched}
												isMulti={true}
											/>
											<div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch gap-4">
												<Button type="submit" design="btn-primary align-self-normal" style={{ maxHeight: '48px' }}>Buscar</Button>
											</div>
										</Form>
									)}
								</Formik>
								{
									dataSerieIndicador.length !== 0 && descripcionNivelServicio === null &&
									<>
										{!mostrarGrafico ?
											<button className='btn btn--icon btn--gray btn--pewter-blue br-sm align-self-end align-self-md-start order-0 order-md-1' onClick={() => changeMostrarGrafico(true)}>
												<i className='ico ico-graph-bar'></i>
											</button>
											:
											<button className='btn btn--icon btn--gray btn--pewter-blue br-sm align-self-end align-self-md-start order-0 order-md-1' onClick={() => changeMostrarGrafico(false)}>
												<i className='ico ico-analytics'></i>
											</button>
										}
									</>
								}

							</div>
							<div className="d-flex order-0 order-lg-1 gap-3 flex-wrap">
								{mostrarGrafico ?
									listaSerieTab.map((x, i) => {
										return (
											<Badge
												key={i}
												design={x.clase}
												className="mb-3"
											>{listaSerie.map(y => {
												if (y.value === x.serie) {
													return y.label
												}
											})}</Badge>
										)
									})
									:
									listaSerieTab.map((x, i) => {
										return (
											<Badge
												key={i}
												design={x.clase}
												className="mb-3"
												onClick={(e) => { changeSerie(x.serie) }}
											>{listaSerie.map(y => {
												if (y.value === x.serie) {
													return y.label
												}
											})}</Badge>
										)
									})
								}

							</div>
						</div>
						{
							conDatos ?
								!mostrarGrafico ?
									<>
										{descripcionNivelServicio !== null &&
											<div className="d-flex align-items-end mb-4 gap-2">
												<span className='d-flex flex-column'>
													<h3 className="fs-3">{descripcionNivelServicio.sigla}</h3>
													<span className="text-pewter-blue d-block">{descripcionNivelServicio.descripcion}</span>
												</span>
												<div className="legend__item legend__item--success fs-1 lh-1">
													<span className="legend__value">{descripcionNivelServicio.valor}%</span>
													<span className="legend__indicator">
														<i className="ico-increase"></i>
													</span>
												</div>
											</div>
										}


										< div className="row">
											{
												dataSerieNivelServicio.map((x, i) => {
													return (
														i === 0 &&
														<div key={i} className="col-12 col-md-6 mb-4 mb-md-0">
															<div className="border p-4 rounded h-100 d-flex flex-column justify-content-between">
																<div className="d-flex justify-content-between align-items-center mb-4">
																	<span>
																		<h3 className="fs-3">{x.sigla}</h3>
																		<span className="text-pewter-blue d-block">{x.descripcion}</span>
																	</span>
																	<span onClick={(e) => mostrarIndicadores(x)} className="btn btn--outline btn--icon btn--pewter-blue align-self-end">
																		<i className="ico-chevron-right"></i>
																	</span>

																</div>
																{
																	x.valorCumplimiento ?
																		<>
																			<ChartsRadialBar
																				size="400"
																				bezel="75"
																				dataChart={{ serie: x.valorCumplimiento ? x.valorCumplimiento : 0, color: x.colorCumplimiento }}
																			/>
																			<Leyenda className="flex-row align-self-center flex-wrap justify-content-center" indicators={x.indicadorHijo.map(x => x = { ...x, state: x.cumple ? "success" : "danger" })} esLogicaIndicador={true} />
																		</>
																		:
																		<div className="d-flex justify-content-center align-items-center flex-column gap-2 py-5">
																			<img className="mb-3" src={notfound} alt="" />
																			<p className='mb-0 text-primary'>Información no disponible.</p>
																		</div>
																}
																<div></div>
																{ }

															</div>
														</div>
													)
												})
											}
											{
												dataSerieNivelServicio.length > 0 &&
												<div className="col-12 col-md-6 mb-4 mb-md-0">
													{
														dataSerieNivelServicio.map((x, i) => {
															return (
																i !== 0 &&
																<div key={i} className={`border p-4 rounded ${i === 1 ? 'mb-4' : ''}`}>
																	<div className="d-flex justify-content-between align-items-center mb-4">
																		<span>
																			<h3 className="fs-3">{x.sigla}</h3>
																			<span className="text-pewter-blue d-block">{x.descripcion}</span>
																		</span>
																		<span onClick={(e) => mostrarIndicadores(x)} className="btn btn--outline btn--icon btn--pewter-blue align-self-end">
																			<i className="ico-chevron-right"></i>
																		</span>

																	</div>
																	<div className='d-flex justify-content-center flex-wrap'>
																		{
																			x.valorCumplimiento ?
																				<div className='align-self-end order-1 order-xl-0'>
																					<Leyenda indicators={x.indicadorHijo.map(x => x = { ...x, state: x.cumple ? "success" : "danger" })} esLogicaIndicador={true} />
																				</div>
																				: null
																		}
																		<div className='order-0 order-xl-1'>
																			{
																				x.valorCumplimiento ?
																					<ChartsRadialBar
																						size="230"
																						dataChart={{ serie: x.valorCumplimiento ? x.valorCumplimiento : 0, color: x.colorCumplimiento }}
																					/>
																					:
																					<div className="d-flex justify-content-center align-items-center flex-column gap-2 py-5">
																						<img className="mb-3" src={notfound} alt="" />
																						<p className='mb-0 text-primary'>Información no disponible.</p>
																					</div>
																			}
																		</div>
																	</div>
																</div>

															)
														})
													}


												</div>
											}


											{
												dataSerieIndicador.map((x, i) => {
													return (
														<div key={i} className="col-12 col-md-4 mb-4">
															<div className="border p-4 rounded d-flex flex-column justify-content-start">
																<div className="d-flex justify-content-between align-items-center mb-4 mb-5">
																	<span>
																		<h3 className="fs-3">{x.sigla}</h3>
																		<span className="text-pewter-blue d-block">{x.descripcion}</span>
																	</span>
																	<span onClick={(e) => mostrarIndicadores(x)} className="btn btn--outline btn--icon btn--pewter-blue align-self-end">
																		<i className="ico-chevron-right"></i>
																	</span>
																</div>
																<div className='d-flex flex-column gap-5'>
																	<ChartsRadialBar
																		size="250"
																		dataChart={{ serie: x.valorCumplimiento, color: x.colorCumplimiento }}
																	/>
																	{listaSerieTab.find(x=>x.clase=='secondary').serie>5 && (x.sigla === "AT" || x.sigla === "ATO")?
																		<div className="text-center">
																		<div className={`legend `}>
																			<div className={`legend__item legend__item--`}>
																				<span className="legend__label"> &nbsp;</span>
																			</div>
																		</div>
																	</div>

																	:
																		<div className="text-center">
																			<div className={`legend `}>
																				<div className={`legend__item legend__item--`}>
																					<span className="legend__dot" style={{ backgroundColor: x.colorCumplimiento, borderColor: 'rgba(255, 255, 255, 0.8)' }}></span>
																					<span className="legend__label">{x.valorIndicador}</span>
																				</div>
																			</div>
																		</div>
																	}
																</div>
															</div>
														</div>
													);
												})
											}


										</div>
									</>
									:
									<div className="row" style={{ height: 'calc(100% - 140px)' }}>
										<div className="col-12">
											<div className="border rounded p-4 h-100">
												<ColumnCharts
													dataChart={datosGrafico}
												/>
											</div>
										</div>
									</div>
								:
								<div className="d-flex justify-content-center align-items-center flex-column gap-2 py-5">
									<img className="mb-3" src={notfound} alt="" />
									<p className='mb-0 text-primary'>Información no disponible.</p>
								</div>
						}
					</div>
				</div>
			</div>
		</div>
	)
}
export default IndicadoresPage;
