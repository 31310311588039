import React, { useEffect, useState } from 'react';
import Exports from "../../../Components/Exports/Exports";
import SearchField from "../../../Components/SearchField/SearchField";
import Button from "../../../Components/Button/Button";
import ModalAsociarAlarma from './ModalAsociarAlarma';
import { useAlarma } from '../Hooks/useAlarmas';
import notfound from '../../../Assets/images/il-empty.svg';
import { EnumTablaPaginacion } from '../../../Utils/Enumeradores/ComunEnum';
import PaginationComponent from '../../../Components/PaginationTableServer/PaginationTableServer';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';
import useLogin from '../../Autenticacion/Hooks/useLogin';

const TablaAlarmas = (props) => {
	const [exportacion, setExportacion] = useState({ exportacion: false })
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, state, setGlobalFilter, pageOptions, previousPage, canPreviousPage, nextPage, canNextPage, gotoPage, pageCount } = props.tableInstance;
	const { globalFilter } = state;
	const [{ pageIndex, pageSize, sortBy }, setPagination] =
        React.useState({
            pageIndex: 0,
            pageSize: EnumTablaPaginacion.CantidadPaginacion,
            sortBy: []
        });
	const { listadoAlarmasPerfil, getListadoAlarmasPerfil } = useAlarma();
	const [actualizar, setActualizar] = useState(props);
	const { crearRegistroTDR } = useRegistroTDR();
	const {usuario} = useLogin();
	function handleShow() {
		const startTime = new Date();
		const fetchData = async () => {
			await Promise.all([
				setShow(true),
			]);
			const endTime = new Date();
			const tdrId = 73;
			crearRegistroTDR(startTime, endTime, usuario, tdrId);
		};

		fetchData();
	}

	const descargar = (tipo) => {
		getListadoAlarmasPerfil({ exportacion: true });
	};

	useEffect(() => {
		setActualizar(props.renderizar)
	}, [])
	return (
		<>
			<div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-5 gap-3">
				<div className="tools order-1 order-lg-0 align-items-start w-100">
					<SearchField
						filter={globalFilter}
						setFilter={setGlobalFilter}
					/>
					<Button design="btn-primary px-4 ms-md-auto" onClick={() => handleShow()}>
						<i className="ico-plus me-2"></i>
						<span>Asociar alarma</span>
					</Button>
				</div>
				<div className="exports order-0 order-lg-1">
					<Exports
						descargar={(tipo) => descargar(tipo)}
					/>
				</div>
			</div>
			<div className="table-responsive brd-top">
				<table className="table" {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps(column.getSortByToggleProps(), { className: column.className })}>
										<span className="d-flex align-items-center gap-1">
											{column.render('Header')}
											<>
												{column.isSorted ?
													(column.isSortedDesc ?
														<i className="ico-chevron-down"></i>
														:
														<i className="ico-chevron-up"></i>
													) :
													<>
														<i className="ico-sort fs-7"></i>
													</>
												}
											</>
										</span>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{
							page.length > 0 && page.map((row) => {
								prepareRow(row)
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps({
												className: cell.column.className
											})}>
												{cell.render('Cell')}
											</td>
										})}
									</tr>
								)
							}) || <tr><td colSpan={headerGroups[0].headers.length}>
								<div className="d-flex justify-content-center align-items-center flex-column gap-2 py-5">
									<img className="mb-3" src={notfound} alt="" />
									<p className='mb-0 text-primary'>No se encuentran resultados de tu búsqueda.</p>
								</div>
							</td></tr>
						}

					</tbody>
				</table>
			</div>
			<PaginationComponent
				pagesCountServer={pageCount}
				currentPage={state.pageIndex}
				setCurrentPage={gotoPage}
				alwaysShown={false}
				setPagination={setPagination}
			/>
			<ModalAsociarAlarma
				show={show}
				onHide={handleClose}
				renderizar={props.renderizar}
			/>
		</>
	)
}

export default TablaAlarmas;
