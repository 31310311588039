import React, { useEffect, useMemo, useState } from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import { Link } from 'react-router-dom';
import TablaNivelesCumplimientos from '../Components/TablaNivelesCumplimientos';
import { InformeListaSerieService } from '../Services/InformeListaSerieService';
import { useColumnasNivelCumplimiento } from '../Utils/ColumnsCumplimineto';
import { EnumPathRutas, EnumTablaPaginacionInforme, EnumTipoArchivos } from '../../../Utils/Enumeradores/ComunEnum';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import ModalSolicitudDescarga from '../Components/ModalSolicitudDescarga';
import PaginationComponent from '../../../Components/PaginationTableServer/PaginationTableServer';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const NivelesCumplimientosPage = () => {
    const { usuario } = useLogin();
		const { crearRegistroTDR } = useRegistroTDR();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [datosModal, setDatosModal] = useState({ serieId: 0, esFormatoEcxel: false });
    function handlerMostrarModal(tipo, item) {
        setDatosModal({ serieId: item.serieId, esFormatoEcxel: (tipo === EnumTipoArchivos.XLS ? true : false) });
        setShow(true);
    }
    const { COLUMNS } = useColumnasNivelCumplimiento({ mostrarModal: handlerMostrarModal });
    const columns = useMemo(() => COLUMNS, []);
    const [listaNivelesCumplimiento, setListaNivelesCumplimiento] = React.useState([]);
    const data = useMemo(() => listaNivelesCumplimiento, [listaNivelesCumplimiento]);
    const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: EnumTablaPaginacionInforme.CantidadPaginacion } }, useGlobalFilter, useSortBy, usePagination);

		async function postInformeListaSerie(values) {
			const startTime = new Date();
        const resp = await InformeListaSerieService(values);
        if (resp) {
            if (resp.status === 200) {
                if (resp.data) {
										const fetchData = async () => {
											await Promise.all([
												setListaNivelesCumplimiento(resp.data)
											]);
											const endTime = new Date();
											const tdrId = 55;
											crearRegistroTDR(startTime, endTime, usuario, tdrId);
										};

										fetchData();
                }
            } else if (resp.status === 206) {
								const fetchData = async () => {
									await Promise.all([
										setListaNivelesCumplimiento([])
									]);
									const endTime = new Date();
									const tdrId = 55;
									crearRegistroTDR(startTime, endTime, usuario, tdrId);
								};

								fetchData();
            }
        }
    }
    useEffect(() => {
        const formEnvio = {
            idUsuario: usuario.id
        }
        postInformeListaSerie(formEnvio);
    }, [])
    const [{ pageIndex, pageSize, sortBy }, setPagination] =
    React.useState({
        pageIndex: 0,
        pageSize: EnumTablaPaginacionInforme.CantidadPaginacion,
        sortBy: []
    });
    return (
        <div className="container-fluid">
            <div className="row mb-4">
                <div className="col-12">
                    <div className="breadcrumbs">
                        <ul>
                            <li><Link to={EnumPathRutas.Inicio}> Inicio</Link></li>
                            <li><Link to={EnumPathRutas.Informes}>Informes</Link></li>
                            <li><a href="#">Nivel de cumplimiento</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="bg-white rounded p-4">
                        <div className="table-responsive">
                            <TablaNivelesCumplimientos
                                tableInstance={tableInstance}
                            />
                        </div>
                        <PaginationComponent
							pagesCountServer={tableInstance.pageCount}
							currentPage={tableInstance.state.pageIndex}
							setCurrentPage={tableInstance.gotoPage}
							alwaysShown={false}
							setPagination={setPagination}
						/>
                    </div>
                </div>
            </div>
            <ModalSolicitudDescarga
                show={show}
                datosModal={datosModal}
                onHide={handleClose}
            />
        </div>
    )
}

export default NivelesCumplimientosPage;
