export const EnumPerfil = {
    Administrador: 1,
    InspectorFiscal: 2,
    LiderConcesionaria: 3,
    LiderArea: 4,
    Resolutor: 5,
    AsesoriaIF: 6,
}

export const EnumAreaNegocio = {
    Conservacion: 1,
    GestionTrafico: 2,
    AsistenciaRuta: 3,
    AtencionUsuarios: 4,
    OperacionPlazaPeaje: 5
}

export const EnumEstadoAutorizacion = {
    Pendiente: 1,
    Aprobado: 2,
    Rechazado: 3
}

export const EnumModulo = {
    Inicio: 1,
    Indicadores: 2,
    Datamart: 3,
    Bitacora: 4,
    GestionHallazgos: 5,
    Notificaciones: 6,
    Alarmas: 7,
    Autorizaciones: 8,
    Informes: 9,
    Configuracion: 10
}

//DATAMART
export const EnumDMSerie = {
    Ultimos5Minutos: 1,
    UltimaMediaHora: 2,
    Ultimas4Horas: 3,
    DiaActual: 4,
    DiaAnterior: 5,
    Ultimos3Dias: 6,
    UltimaSemana: 7,
    MesActual: 8,
    MesAnterior: 9,
    Anioactual: 10,
    AnioAnterior: 11
}

export const EnumDMIndicador = {
    ISP: 1,
    NSC: 2,
    NPSB: 3,
    NPSE: 4,
    NPSC: 5,
    NPSG: 6,
    NPSR: 7,
    NPSU: 8,
    NPSO: 9
}

export const EnumTablaPaginacion = {
    CantidadPaginacion: 10
}
export const EnumTablaPaginacionInforme = {
    CantidadPaginacion: 15
}

export const EnumIntervaloTiempo = {
    UnMinuto: 60000,
    DiezSegundos: 10000,
    VeinteSegundos: 20000,
}

export const EnumCategorias = {
    Hallazgos: 1,
    Mediciones: 2,
    Usuario: 3,
    Indicadores: 4,
    Autorizaciones: 5
}
export const EnumEstadoEventoConcesion = {
    Pendiente: 3,
    Rechazado: 2,
    Autorizado: 1
}

export const EnumEstadoEventoConcesionDesc = {
    Pendiente: "Pendiente",
    Rechazada: "Rechazada",
    Autorizada: "Autorizada"
}

export const EnumEstadoObjetarEventos = {
    0: "Sin Solicitud",
    1: "Vencido",
    2: "Solicitado",
    3: "Aprobado",
    4: "Rechazado",
    5: "Sin respuesta DGOP"
}

export const EnumEstadoObjetarEventosDesc = {
    "Sin solicitud": 0,
    Vencido: 1,
    Solicitado: 2,
    Aprobado: 3,
    Rechazado: 4,
    "Sin respuesta DGOP": 5
}

export const EnumTipoArchivos = {
    PDF: "pdf",
    XLS: "xls"
}

export const EnumModuloDesc = {
    IngresoSistema: 1,
    AccesoInicio: 2,
    AccesoIndicadoresNivelesServicio: 3,
    DescargaIndicadores: 4,
    AccesoDatamart: 5,
    DescargaDatamart: 6,
    AccesoBitacoraEvento: 7,
    DescargaBitacoraEvento: 8,
    AccesoBitacoraUsuario: 9,
    DescargaBitacoraUsuario: 10,
    AccesoBitacoraBIM: 11,
    DescargaBitacoraBIM: 12,
    AccesoGestionHallazgos: 13,
    DescargaGestionHallazgos: 14,
    AccesoNotificaciones: 15,
    DescargaNotificaciones: 16,
    AccesoAlarmas: 17,
    AccesoPersonalizacionAlarmas: 18,
    DescargaAlarmas: 19,
    AccesoAutorizacionesUsuario: 20,
    AccesoAutorizacionesEventosManuales: 21,
    DescargaAutorizacionesUsuario: 22,
    DescargaAutorizacionesEventosManuales: 23,
    AccesoInformeNivelCumplimiento: 24,
    AccesoInformeEstadisticasHallazgos: 25,
    DescargaInformeNivelCumplimiento: 26,
    DescargaInformeEstadisticasHallazgos: 27,
    AccesoAdministracionPonderacion: 28,
    AccesoAdministracionUsuario: 29,
    DescargaAdministracionUsuario: 30,
    AccesoAdministracionAlarmasPerfil: 31,
    AccesoAdministracionAlarmasListas: 32,
    DescargaAlarmasPerfil: 33,
    DescargaAlarmasListas: 34,
    AccesoAsignarBackup: 35,
    DescargaAsignarBackup: 36,
    AccesoAdministracionAlertasPrevias: 37,
    AccesoAdministracionExigencias: 38,
    SalidaSistema: 39,
    AccesoIndicadoresISP: 40,
    DescargaBitacoraObjecion: 41,
    AccesoBitacoraObjecion: 42,
}

export const EnumEstadoProrroga = {
    EnProceso: 1,
    Cerrada: 2,
    Rechazado: 3
}

export const EnumEtapaProrroga = {
    Ingreso: 1,
    ValidacionLC: 2,
    ValidacionIF: 3
}

export const EnumAccionProrroga = {
    Aprobada: "APROBADA",
    Rechazada: "RECHAZADA",
    Cerrada: "CERRADA",
    Reenviada: "REENVIADA"
}

export const EnumEstadoHallazgo = {
    Nuevo: 1,
    EnProceso: 2,
    Rechazado: 3,
    Aprobado: 4,
    Preingreso: 5,
    Cerrado: 6,
    Resuelto: 7,
    Objetado: 8,
    Validacion: 9
}

export const EnumEtapaHallazgo = {
    Ingreso: 1,
    Asignacion: 2,
    Resolutor: 3,
    LiderArea: 4,
    LiderConcesionaria: 5,
    ResolucionIF: 6
}

export const EnumAccionHallazgo = {
    Aprobada: "APROBADA",
    Rechazada: "RECHAZADA",
    Asigna: "ASIGNA",
    ObjetaRechazoLA: "OBJETARECHAZOLA",
    Reasigna: "REASIGNA",
    SubirCorreccion: "SUBIRCORRECCION",
    ActualizaCorreccion: "ACTUALIZACORRECCION",
    Cerrada: "CERRADA",
    EnviarResolutor: "ENVIARRESOLUTOR",
    AprobadaSubirCorrecion: "APROBADASUBIRCORRECION"
}

export const EnumHallazgoEstatusEstadisticas = {
    Vencidos: 1,
    PorVencer: 2,
    AlDia: 3,
    Cerrados: 4,
    Resueltos: 5
}

export const EnumSiglaIndicador = {
    PAV: "PAV",
    ILUE: "ILUE",
    SEV: "SEV",
    DEM: "DEM",
    CON: "CON",
    SOS: "SOS",
    VIA: "VIA",
    VAR2: "VAR2",
    EAO: "EAO",
    AT: "AT",
    ATO: "ATO",
    APO: "APO",
    REO: "REO",
    CIE: "CIE",
    DPW: "DPW",
    DOA: "DOA",
    DCT: "DCT",
    LCV: "LCV"
}
export const EnumHallazgoOrigen = {
    SIC_NS: 1,
    SICE: 2
}

export const EnumFiltroTabBusquedaHallazgo = {
    Pendientes: 1,
    Todos: 2
}
export const EnumParametro = {
    NumeroContrato: "NumContratoMOP",
    UsuarioBimOperacion: "IdUsrBIMOperacion",
		IpTdr: "IP-TDR-IF"
}

export const EnumTamanoArchivo = {
    DosMB : 2000
}

export const EnumPathRutas = {
    Inicio: "/inicio"
    , Configuracion: "/configuracion"
    , ConfiguracionUsuario: "usuarios"
    , ConfiguracionExigencias: "exigencias"
    , ConfiguracionPonderacion: "ponderacion"
    , ConfiguracionBackup: "backup"
    , ConfiguracionAlarmas: "alarmas"
    , ConfiguracionAlarmasPrevias: "alarmas_previas"
    , Indicadores: "/indicadores"
    , IndicadoresCuartoNivel: "cuarto-nivel"
    , IndicadoresISP: "isp"
    , Datamart: "/datamart"
    , DatamartIndicadores: "indicadores"
    , DatamartIndicador: "indicador"
    , Bitacora: "/bitacora"
    , BitacoraEventosConcesion: "eventos_concesion"
    , BitacoraActividadUsuario: "actividad_de_usuario"
    , BitacoraBIM: "bim"
    , BitacoraObjetar: "objectar_indicador"
    , Hallazgos: "/hallazgos"
    , Notificaciones: "/notificaciones"
    , Alarmas: "/alarmas"
    , AlarmasPersonalizacion: "personalizacion"
    , Autorizaciones: "/autorizaciones"
    , Informes: "/informes"
    , InformesNivelCumplimiento: "nivel_cumplimiento"
    , InformesEstadisticasHallazgo: "estadistica_hallazgos"
    , InformesEstadisticasTdr: "estadistica_Tdr"
    , InformeDisponibilidadSICNS: "disponibilidad_sicns"

}

export const EnumMensajeErrorArchivos={
    Extension : 'Se requiere archivo con extensión',
    Tamano: 'Tamaño supera el máximo permitido (2MB)',
    CantidadAdjunto: 'Solo se puede adjuntar hasta 2 archivos',
    Formato : 'Formato inválido'
}

export const EnumEstadoCumplimientoEventoConcesion={
    Cumpliendo : 'Cumpliendo',
    Incumplieno: 'Incumpliendo'
}

export const EnumDisponibilidadMeses={
    1: "Enero",
    2: "Febrero",
    3: "Marzo",
    4: "Abril",
    5: "Mayo",
    6: "Junio",
    7: "Julio",
    8: "Agosto",
    9: "Septiembre",
    10: "Octubre",
    11: "Noviembre",
    12: "Diciembre"
}

export const EnumTipoMonitoreo={
    1: "Mantenimiento Programado Aprobado",
    2: "Monitoreo Normal",
    3: "Mantenimiento Programado No Aprobado"
}