import { useCallback, useState, createContext } from "react";
const ModalPerfilContext = createContext();

export default ModalPerfilContext;
export function ModalPerfilContextProvider({ children }) {
    const [show, setShow] = useState(false);
    const addShow = useCallback(
        function (showValue) {
            setShow(() => showValue);
        },
        [setShow]
    );

    return (
        <ModalPerfilContext.Provider value={{addShow,show}}>
            {children}
        </ModalPerfilContext.Provider>
    );
}
