import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";
const ObtenerIndicadoresTdrService = async (values) => {
    try {
        return await instance.Backend.post("/Informe/ObtenerIndicadoresTdr", values, customConfig);
    } catch (error) {

        console.log(error);
    }

}
export { ObtenerIndicadoresTdrService };
