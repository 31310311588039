import React from "react";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";


const LoginLayout = () => {
  useEffect(() => {
    document.body.classList.add("login");
    return () => {
      document.body.classList.remove("login");
    };
  }, []);

	return <Outlet/>
};

export default LoginLayout;
