import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useLogin from "../../Features/Autenticacion/Hooks/useLogin";
import { EnumModuloDesc, EnumPathRutas } from "../Enumeradores/ComunEnum";
import { useNombreModulo } from "../../Hooks/useNombreModulo";

const InterceptorNavigateRoute = ({ children }) => {
    const { usuario, bitacoraDetalle, registraBitacoraMovimientoUsuario } = useLogin();
    const { addNombre } = useNombreModulo();
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (bitacoraDetalle !== null) {
            const nombreRuta = location.pathname.split("/");
            const pathName = nombreRuta.length > 2 ? nombreRuta[nombreRuta.length-1] : location.pathname;
            switch (pathName) {
                case EnumPathRutas.Inicio:
                    addNombre("Inicio");
                    registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoInicio, idBitacoraDetalle: bitacoraDetalle },()=>{});
                    return;
                case EnumPathRutas.Configuracion:
                    addNombre("Configuración");
                    return;
                case EnumPathRutas.ConfiguracionAlarmas:
                    //La lógica de registraBitacoraMovimientoUsuario esta por tab en la page.
                    addNombre("Configuración");
                    return;
                case EnumPathRutas.ConfiguracionPonderacion:
                    addNombre("Configuración");
                    registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoAdministracionPonderacion, idBitacoraDetalle: bitacoraDetalle },()=>{});
                    return;
                case EnumPathRutas.ConfiguracionUsuario:
                    addNombre("Configuración");
                    registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoAdministracionUsuario, idBitacoraDetalle: bitacoraDetalle },()=>{});
                    return;
                case EnumPathRutas.ConfiguracionBackup:
                    addNombre("Configuración");
                    registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoAsignarBackup, idBitacoraDetalle: bitacoraDetalle },()=>{});
                    return;
                case EnumPathRutas.ConfiguracionAlarmasPrevias:
                    addNombre("Configuración");
                    registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoAdministracionAlertasPrevias, idBitacoraDetalle: bitacoraDetalle },()=>{});
                    return;
                case EnumPathRutas.ConfiguracionExigencias:
                    addNombre("Configuración");
                    registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoAdministracionExigencias, idBitacoraDetalle: bitacoraDetalle },()=>{});
                    return;
                case EnumPathRutas.Indicadores:
                    addNombre("Indicadores");
                    registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoIndicadoresNivelesServicio, idBitacoraDetalle: bitacoraDetalle },()=>{});
                    return;
                case EnumPathRutas.IndicadoresCuartoNivel:
                    addNombre("Indicadores");
                    return;
                case EnumPathRutas.IndicadoresISP:
                    addNombre("Indicadores");
                    registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoIndicadoresISP, idBitacoraDetalle: bitacoraDetalle },()=>{});
                    return;
                case EnumPathRutas.Datamart:
                    addNombre("Datamart");
                    registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoDatamart, idBitacoraDetalle: bitacoraDetalle },()=>{});
                    return;
                case EnumPathRutas.Bitacora:
                    addNombre("Bitácora");
                    return;
                case EnumPathRutas.BitacoraActividadUsuario:
                    addNombre("Bitácora");
                    registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoBitacoraUsuario, idBitacoraDetalle: bitacoraDetalle },()=>{});
                    return;
                case EnumPathRutas.BitacoraEventosConcesion:
                    addNombre("Bitácora");
                    registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoBitacoraEvento, idBitacoraDetalle: bitacoraDetalle },()=>{});
                    return;
                case EnumPathRutas.BitacoraObjetar:
                    addNombre("Bitácora");
                    registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoBitacoraObjecion, idBitacoraDetalle: bitacoraDetalle },()=>{});
                    return;
                case EnumPathRutas.BitacoraBIM:
                    addNombre("Bitácora");
                    registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoBitacoraBIM, idBitacoraDetalle: bitacoraDetalle },()=>{});
                    return;
                case EnumPathRutas.Hallazgos:
                    addNombre("Gestión de Hallazgos");
                    registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoGestionHallazgos, idBitacoraDetalle: bitacoraDetalle },()=>{});
                    return;
                case EnumPathRutas.Notificaciones:
                    addNombre("Notificaciones");
                    registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoNotificaciones, idBitacoraDetalle: bitacoraDetalle },()=>{});
                    return;
                case EnumPathRutas.Alarmas:
                    addNombre("Alarmas");
                    registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoAlarmas, idBitacoraDetalle: bitacoraDetalle },()=>{});
                    return;
                case EnumPathRutas.Autorizaciones:
                    addNombre("Autorizaciones");
                    return;
                case EnumPathRutas.Informes:
                    addNombre("Informes");
                    registraBitacoraMovimientoUsuario({ idUsuario: usuario.id, idMovimientoSistema: EnumModuloDesc.AccesoInformeNivelCumplimiento, idBitacoraDetalle: bitacoraDetalle },()=>{});
                    return;
                default:
                    // 
                    return;
            }
        }
        
        return () => {
        }
    }, [navigate])
    return children;
}
export default InterceptorNavigateRoute;