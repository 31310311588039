import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../Components/Button/Button';
import Exports from '../../../Components/Exports/Exports';
import { COLUMNS, useColumnasEstadisticasTdr } from '../Utils/ColumnsEstadisticasTdr';
import { Form, Formik } from 'formik';
import RangeDatePicker from '../../../Components/RangeDatePicker/RangeDatePicker';
import moment from 'moment';
import { EnumModuloDesc, EnumPathRutas, EnumTablaPaginacion, EnumTipoArchivos } from '../../../Utils/Enumeradores/ComunEnum';
import validations from '../../../Utils/Validations/Validations';
import TablaEstadiscticasTdr from '../Components/TablaEstadisticasTdr';
import ModalSolicitudDescarga from '../Components/ModalSolicitudDescarga';
import { useComun } from '../../../Hooks/useComun';
import { EstadisticasTdrService } from '../Services/EstadisticasTdrService';
import Progreso from '../Components/Progreso';
import SelectCheckField from "../../../Components/SelectCheckField/SelectCheckField";
import { ObtenerIndicadoresTdrService } from '../Services/ObtenerIndicadoresTdrService';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { ObtenerListadoRegistrosTdr } from '../Services/ObtenerListadoRegistrosTdr';
import ModalSolicitudDescargaTdr from '../Components/ModalSolicitudDescargaTdr';


const EstadisticasTdrPage = () => {
	const { user, usuario } = useLogin();
	const { listaIndicadoresTdr, downloadCSV , ObtenerListaIndicadoresTdr , tiempoIndicadoresTdr , listaTdr} = useComun();
	const [datosBusqueda, setDatosBusqueda] = React.useState({ mes: tiempoIndicadoresTdr.mes , anio: tiempoIndicadoresTdr.ano });
	const [show, setShow] = React.useState(false);
	const handleClose = () => setShow(false);
	const [datosModal, setDatosModal] = React.useState({});

	const columns = useMemo(() => COLUMNS, []);
	const [data, setData] = React.useState([]);
	const [pageCount, setPageCount] = React.useState(0);
	const [{ pageIndex, pageSize, sortBy }, setPagination] =
		React.useState({
			pageIndex: 0,
			pageSize: EnumTablaPaginacion.CantidadPaginacion,
			sortBy: []
		});
	const [initialValues, setInitialValues] = useState({
		mes: tiempoIndicadoresTdr.mes,
		anio: tiempoIndicadoresTdr.ano,
	})
	const [esDescargar, setEsDescargar] = React.useState();
	const ref = useRef(null);
	function handlerMostrarModal() {
		if(document.getElementsByName("periodo_tdr")[0].value == ''){
			const formEnvio = {
				datosBusqueda: {
					mes: tiempoIndicadoresTdr.mes,
					nombreMes: tiempoIndicadoresTdr.nombreMes,
					cumplimiento: tiempoIndicadoresTdr.valorCumplimiento,
					anio: tiempoIndicadoresTdr.ano,
					idUsuario: usuario.id,
				}
			}
			setDatosModal(formEnvio);
			setShow(true);
		}else{
			const values = document.getElementsByName("periodo_tdr")[0].value;
			const mes = listaTdr.data.find(x=>x.fecha==values).mes;
			const anio = listaTdr.data.find(x=>x.fecha==values).ano;
			const nombreMes = listaTdr.data.find(x=>x.fecha==values).nombreMes;
			const cumplimiento = listaTdr.data.find(x=>x.fecha==values).valorCumplimiento;
			const formEnvio = {
				datosBusqueda: {
					mes: mes,
					nombreMes: nombreMes,
					cumplimiento: cumplimiento,
					anio: anio,
					idUsuario: usuario.id,
				}
			}
			setDatosModal(formEnvio);
			setShow(true);
		}
	}
	useEffect(() => {
		setDatosBusqueda({
			mes: tiempoIndicadoresTdr.mes,
				anio: tiempoIndicadoresTdr.ano,
		});
		if(listaIndicadoresTdr.length>0){
			var periodoActual = listaIndicadoresTdr.find(x=>x.value.includes(tiempoIndicadoresTdr.ano + (tiempoIndicadoresTdr.mes<10? "-0" : "-") + tiempoIndicadoresTdr.mes));
			if(periodoActual!= null){
				setInitialValues({...initialValues, periodo_tdr : periodoActual.value});
			}
		}
	}, [tiempoIndicadoresTdr])

	useEffect(() => {
		const usuarioId = {
			idUsuario: usuario.id,
		}

		// const datos = {
		// 		mes: 2,
		// 		anio: 2024,
		// 		idUsuario: 2147483647,
		// 		paginacion: {
		// 			numeroPagina: 1,
		// 			cantidadRegistros: 10,
		// 			orderByAscending: true,
		// 			ordenadoPor: "string"
		// 		}
		// }
		ObtenerListaIndicadoresTdr(usuarioId);
		//postObtenerListadoRegistrosTdr(datos);
	}, [])

	async function postObtenerListadoRegistrosTdr(values) {
		const resp = await ObtenerListadoRegistrosTdr(values);
		if (resp) {
			if (resp.status === 200) {
				if (resp.data) {

						setPageCount(resp.data.cantidadPaginas);
						setData(resp.data.listaDetalleRespuesta);

				}
			}
		}
	}

	const fetchData = React.useCallback(({ formulario, pageIndex, sortBy = [] }) => {
		formulario.paginacion.numeroPagina = pageIndex + 1;
		formulario.paginacion.ordenadoPor = sortBy.length > 0 ? sortBy[0].id : formulario.paginacion.ordenadoPor;
		formulario.paginacion.orderByAscending = sortBy.length > 0 ? (sortBy[0].desc ? false : true) : formulario.paginacion.orderByAscending;
		postObtenerListadoRegistrosTdr(formulario);
	}, []);

	const descargar = () => {
		setEsDescargar(true);
	};

	function BuscarListadoRegistrosTdr(values) {
		const mes = listaTdr.data.find(x=>x.fecha==values.periodo_tdr).mes;
		const anio = listaTdr.data.find(x=>x.fecha==values.periodo_tdr).ano;
		setDatosBusqueda({
			mes: mes
			, anio: anio
		});
	}

	return (
		<div className="container-fluid">
			<div className="row mb-4">
				<div className="col-12">
					<div className="breadcrumbs">
						<ul>
							<li>
								<Link to={EnumPathRutas.Inicio}> Inicio</Link>
							</li>
							<li>
								<Link to={EnumPathRutas.Informes}>Informes</Link>
							</li>
							<li>
								<a href="#">Estadística TDR</a>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<div className="bg-white rounded p-4">
						<div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-5 gap-3">
							<Formik
								enableReinitialize
								initialValues={initialValues}
								validate={validations}
								onSubmit={(values) => {
									BuscarListadoRegistrosTdr(values);
								}}
							>
								{({ values, setFieldValue, setFieldTouched }) => (
									<Form className="form mb-0" ref={ref}>
										<div className="tools order-1 order-lg-0 align-items-start">
											<SelectCheckField
												name="periodo_tdr"
												label="Periodo"
												options={listaIndicadoresTdr}
												onBlur={setFieldTouched}
												onChange={setFieldValue}
												isMulti={false}
												value={initialValues.periodo_tdr}
											/>

											<Button type="submit" design="btn-primary">
												Buscar
											</Button>
										</div>
									</Form>
								)}
							</Formik>
							<div className="exports order-0 order-lg-1">
								<Exports descargar={() => handlerMostrarModal()} />
							</div>
						</div>

						<div style={{ width: '100%', borderBottom: '1px solid black', marginBottom: '20px' }}></div>


						<div style={{ width: '100%', marginBottom:'20px' }} className="row d-flex justify-content-center align-items-center">
							<div style={{ width: '400px', height:'100px' }} className="">
								<Progreso
									title="Tiempos de Respuesta(TDR)"
									percent={tiempoIndicadoresTdr.valorCumplimiento}
									mes = {tiempoIndicadoresTdr.nombreMes? tiempoIndicadoresTdr.nombreMes : ""}
									anio = {tiempoIndicadoresTdr.ano ? tiempoIndicadoresTdr.ano : ""}
								/>
							</div>
						</div>

						<div style={{ width: '100%', borderBottom: '1px solid black', marginBottom: '20px' }}></div>

						<TablaEstadiscticasTdr
							columns={columns}
							data={data}
							fetchData={fetchData}
							pageCount={pageCount}
							currentpage={pageIndex}
							pageSize={pageSize}
							sortBy={sortBy}
							setPagination={setPagination}
							datosBusqueda={datosBusqueda}
							esDescargar={esDescargar}
							setEsDescargar={setEsDescargar}
							tiempoIndicadoresTdr={tiempoIndicadoresTdr}
						/>
					</div>
				</div>
			</div>
			<ModalSolicitudDescargaTdr
				show={show}
				datosModal={datosModal}
				datosBusqueda={datosBusqueda}
				tiempoIndicadoresTdr={tiempoIndicadoresTdr}
				onHide={handleClose}
			/>
		</div>
	);
}

export default EstadisticasTdrPage;
