import instance, { customConfig } from "../../../Utils/Interceptors/Interceptor";

async function NivelCumplimientoIndicadorSerieDetalleEstadoService (values) {
    try {
        return await instance.Backend.post("/Indicador/NivelCumplimientoIndicadorSerieDetalleEstado"
            , values
            , customConfig);
    } catch (error) {
        console.log(error);
    }
}
export { NivelCumplimientoIndicadorSerieDetalleEstadoService };