import Modal from 'react-bootstrap/Modal';
import Button from '../../../Components/Button/Button';
import { Formik, Form, } from "formik";
import SelectAutocompleteField from '../../../Components/SelectAutocompleteField/SelectAutocompleteField';
import Rangepicker from '../../../Components/Rangepicker/Rangepicker';
import { useEffect, useState } from 'react';
import { useComun } from '../../../Hooks/useComun';
import validations from '../../../Utils/Validations/Validations';
import { CrearUsuarioRespaldoService } from '../Services/CrearUsuarioRespaldoService';
import { ActualizaUsuarioRespaldoService } from '../Services/ActualizarUsuarioRespaldoService';
import { useToast } from '../../../Hooks/useToast';
import RangeDatePicker from '../../../Components/RangeDatePicker/RangeDatePicker';
import { useModalBackup } from '../Hooks/useModalBackup';
import { useBackup } from '../Hooks/useBackup';
import SelectCheckField from '../../../Components/SelectCheckField/SelectCheckField';
import useLogin from '../../Autenticacion/Hooks/useLogin';
import { useRegistroTDR } from '../../../Hooks/useRegistroTDR';

const ModalBackup = (props) => {
	const { renderizar, ...resto } = props;
	const { listadoUsuarioSeleccionable, getListaUsuarioSeleccionables } = useComun();
	const { listadoUsuarioSeleccionableRespaldo, getListaUsuariosRespaldos,setListadoUsuarioSeleccionableRespaldo } = useBackup();
	const [listadoBackup, setListadoBackup] = useState([]);
	const addToast = useToast();
	const { initialValues, isCrear, rest } = useModalBackup(resto);
	const {usuario} = useLogin();
	const { crearRegistroTDR } = useRegistroTDR();

	async function postCrearUsuarioRespaldo(values) {
		const startTime = new Date();
		const resp = await CrearUsuarioRespaldoService(values, usuario.id);
		if (resp) {
			if (resp.status === 200) {
				const fetchData = async () => {
					await Promise.all([
						addToast({ mostrar: true, mensaje: 'Se ha guardado correctamente.', titulo: 'Confirmación', tipo: 'Success' }),
						rest.onHide(),
						renderizar(),
					]);
					const endTime = new Date();
					const tdrId = 87;
					crearRegistroTDR(startTime, endTime, usuario, tdrId);
				};

				fetchData();
			}
		}
	}

	async function postActualizaUsuarioRespaldo(values) {
		const startTime = new Date();
		const resp = await ActualizaUsuarioRespaldoService(values, usuario.id);
		if (resp) {
			if (resp.status === 200) {
				const fetchData = async () => {
					await Promise.all([
						addToast({ mostrar: true, mensaje: 'Usuario actualizado correctamente.', titulo: 'Confirmación', tipo: 'Success' }),
						rest.onHide(),
						renderizar(),
					]);
					const endTime = new Date();
					const tdrId = 89;
					crearRegistroTDR(startTime, endTime, usuario, tdrId);
				};

				fetchData();
			}
		}
	}

	useEffect(() => {
		if (rest.show && isCrear) {
			getListaUsuarioSeleccionables(true);
			setListadoUsuarioSeleccionableRespaldo([]);
		} else if (rest.show && !isCrear) {
			getListaUsuarioSeleccionables(true)
		}

	}, [rest.show])
	useEffect(() => {
		if (rest.show && !isCrear) {
			setListadoBackup(listadoUsuarioSeleccionable.filter(x => x.value !== Number(initialValues.usuario)));		}
	}, [listadoUsuarioSeleccionable])

	const changeSeleccionUsuario = (idUsuario) => {
		getListaUsuariosRespaldos(idUsuario);
	}
	return (
		<Modal
			{...rest}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					<i className='ico-user-next me-2'></i>
					{isCrear ?
						<span>Agregar reemplazo</span>
						:
						<span>Modificar reemplazo</span>
					}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validate={validations}
					onSubmit={values => {
						const envioForm = {
							idUsuario: values.usuario,
							idRespaldo: values.backup,
							fechaInicio: values.periodo2[0],
							fechaFin: values.periodo2[1]
						}
						const envioFormModificar = {
							usuarioRespaldoId: values.respaldo_id,
							fechaInicio: values.periodo2[0],
							fechaFin: values.periodo2[1]
						}
						if (isCrear) {
							postCrearUsuarioRespaldo(envioForm);
						} else {
							postActualizaUsuarioRespaldo(envioFormModificar)
						}
					}}
				>
					{({ setFieldValue, setFieldTouched }) => (
						<Form className="form mb-0">
							<SelectAutocompleteField
								name="usuario" label="Usuario"
								options={listadoUsuarioSeleccionable}
								onChange={(name, value) => {changeSeleccionUsuario(value); setFieldValue("usuario", value); setFieldValue("backup", ""); }}
								onBlur={setFieldTouched}
								isDisabled={!isCrear}
								helpers="w-100"
							/>
							{isCrear?
								<SelectAutocompleteField
									name="backup" label="Reemplazo"
									options={listadoUsuarioSeleccionableRespaldo}
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									helpers="w-100"
								/>
							:
								<SelectAutocompleteField
									name="backup" label="Reemplazo"
									options={listadoBackup}
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									helpers="w-100"
									isDisabled
								/>
							}
							<RangeDatePicker
								name="periodo2"
								startMinDate={new Date()}
								endMaxDate={null}
								endMinDate={new Date()}
								onChange={setFieldValue}
								onBlur={setFieldTouched}
							/>
							<div className="d-flex flex-column flex-md-row justify-content-center align-items-stretch align-items-md-center gap-4">
								<Button type="button" design="btn-outline-pewter-blue" onClick={rest.onHide}>Cancelar</Button>
								<Button type="submit" design="btn-primary">Guardar</Button>
							</div>
						</Form>
					)}
				</Formik>
			</Modal.Body>
		</Modal>
	)
}
export default ModalBackup;
