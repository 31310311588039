import { useEffect, useState } from "react";
import { useUsuario } from "../../../Hooks/useUsuario";
import { EnumEstadoAutorizacion } from "../../../Utils/Enumeradores/ComunEnum";
import ModalEstadoAutorizacion from './ModalAutorizacion';

const AccionesEnCelda = (props) => {
	const { listadoUsuario, getUsuarios } = useUsuario();
	const {renderizar, usuario, modal} = props; 
	const [show, setShow] = useState(false);
	const [isAutorizado, setIsAutorizado] = useState(3);
	const handleClose = () => setShow(false);
	const { Aprobado, Rechazado } = EnumEstadoAutorizacion;

	const Modificar = async (value) =>{
		value === 2 ? setIsAutorizado(2) : setIsAutorizado(3)		
	 	setShow(true);	
	}	
	return <div className="d-flex gap-2 align-items-center">
		<button className="link text-pewter-blue fs-4" disabled={usuario.autorizacion !== EnumEstadoAutorizacion.Pendiente} onClick={() => Modificar(Rechazado)}>
			<i className="ico-close"></i>
		</button>
		<button className="link text-pewter-blue fs-4" disabled={usuario.autorizacion !== EnumEstadoAutorizacion.Pendiente} onClick={() => Modificar(Aprobado)}>
			<i className="ico-check"></i>
		</button>
		<ModalEstadoAutorizacion
				show={show}
				usuario={usuario}
				onHide={handleClose}
				autorizado={isAutorizado}
				renderizar={renderizar}
			/>
	</div>
}
export default AccionesEnCelda;
