import SelloTiempoBitacora from "../Components/SelloTiempoBitacora";

export const COLUMNS = [
	{
		Header: 'Id BIM',
		accessor: 'idBIM',
	},
	{
		Header: 'Id SICE',
		accessor: 'idSICE',
	},
	{
		Header: 'Descripción',
		accessor: 'descripcion',
	},
	{
		Header: 'Pk Inicial',
		accessor: 'kmInicial',
	},
	{
		Header: 'Pk Final',
		accessor: 'kmFinal',
	},
	{
		Header: 'Calzada',
		accessor: 'calzada',
	},
	{
		Header: 'Acción',
		accessor: 'accion',
	},
	{
		Header: 'Fecha Creación',
		id: 'fechaCreacion',
		accessor: 'fechaCreacion',
		Cell : ({row}) => <SelloTiempoBitacora data={row.original.fechaCreacion}/>
	},
	{
		Header: 'Fecha Modificación',
		id: 'fechaModificacion',
		accessor: 'fechaModificacion',
		Cell : ({row}) => <SelloTiempoBitacora data={row.original.fechaModificacion}/>
	},
]
