import { useEffect, useState } from 'react'
import { format } from 'rut.js';
import { EnumPerfil } from '../../../Utils/Enumeradores/ComunEnum';


export function useModalUser(props, valuesFormulario) {
	const [initialValues, setInitialValues] = useState({
		id: '',
		rut: '',
		nombre: '',
		apellido_paterno: '',
		apellido_materno: '',
		email: '',
		celular: '+569',
		profile: '',
		business: '',
		file: ''
	});
	const { isCrear, editUser, ...rest } = props;
	const [mostrarAreaNegocio, setMostrarAreaNegocio] = useState(false);

	useEffect(() => {
		if (!isCrear) {
			const listaPerfiles = editUser.listaPerfil.filter(x =>
				x.id !== EnumPerfil.Administrador &&
				x.id !== EnumPerfil.InspectorFiscal &&
				x.id !== EnumPerfil.LiderConcesionaria &&
				x.id !== EnumPerfil.AsesoriaIF);
			if (listaPerfiles.length > 0) {
				setMostrarAreaNegocio(true);
				setInitialValues({
					id: editUser.id,
					rut: format(editUser.rut),
					nombre: editUser.nombres,
					apellido_paterno: editUser.apellidoPaterno,
					apellido_materno: editUser.apellidoMaterno,
					email: editUser.email,
					celular: editUser.numeroMovil,
					profile: editUser.listaPerfil.map(x => {
						return x.id.toString()
					}),
					business: editUser.listaAreaNegocio.map(x => {
						return x.id.toString()
					}),
					file: '',
				})
			} else {
				setMostrarAreaNegocio(false);
				setInitialValues({
					id: editUser.id,
					rut: format(editUser.rut),
					nombre: editUser.nombres,
					apellido_paterno: editUser.apellidoPaterno,
					apellido_materno: editUser.apellidoMaterno,
					email: editUser.email,
					celular: editUser.numeroMovil,
					profile: editUser.listaPerfil.map(x => {
						return x.id.toString()
					}),

					file: '',
				})
			}

		} else {
			setMostrarAreaNegocio(false);
			setInitialValues({
				id: '',
				rut: '',
				nombre: '',
				apellido_paterno: '',
				apellido_materno: '',
				email: '',
				celular: '+569',
				profile: '',
				file: '',
			})
		}
	}, [isCrear, editUser, rest.show])

	useEffect(() => {
		const listaPerfiles = valuesFormulario.listaPerfil.filter(x =>
			Number(x) !== EnumPerfil.Administrador &&
			Number(x) !== EnumPerfil.InspectorFiscal &&
			Number(x) !== EnumPerfil.LiderConcesionaria &&
			Number(x) !== EnumPerfil.AsesoriaIF);
		if (isCrear) {
			if (listaPerfiles.length > 0) {
				setMostrarAreaNegocio(true);
				setInitialValues({
					id: valuesFormulario.values.id ? valuesFormulario.values.id : '',
					rut: valuesFormulario.values.rut ? valuesFormulario.values.rut : '',
					nombre: valuesFormulario.values.nombre ? valuesFormulario.values.nombre : '',
					apellido_paterno: valuesFormulario.values.apellido_paterno ? valuesFormulario.values.apellido_paterno : '',
					apellido_materno: valuesFormulario.values.apellido_materno ? valuesFormulario.values.apellido_materno : '',
					email: valuesFormulario.values.email ? valuesFormulario.values.email : '',
					celular: valuesFormulario.values.celular ? valuesFormulario.values.celular : '',
					profile: valuesFormulario.listaPerfil ? valuesFormulario.listaPerfil.map(String) : '',
					business: valuesFormulario.values.business ? valuesFormulario.values.business : '',
					file: valuesFormulario.values.file ? valuesFormulario.values.file : '',
				})

			} else {

				setMostrarAreaNegocio(false);
				setInitialValues({
					id: valuesFormulario.values.id ? valuesFormulario.values.id : '',
					rut: valuesFormulario.values.rut ? valuesFormulario.values.rut : '',
					nombre: valuesFormulario.values.nombre ? valuesFormulario.values.nombre : '',
					apellido_paterno: valuesFormulario.values.apellido_paterno ? valuesFormulario.values.apellido_paterno : '',
					apellido_materno: valuesFormulario.values.apellido_materno ? valuesFormulario.values.apellido_materno : '',
					email: valuesFormulario.values.email ? valuesFormulario.values.email : '',
					celular: valuesFormulario.values.celular ? valuesFormulario.values.celular : '',
					profile: valuesFormulario.listaPerfil ? valuesFormulario.listaPerfil.map(String) : '',
					file: valuesFormulario.values.file ? valuesFormulario.values.file : '',
				})

			}
		}else{
			if(valuesFormulario.values.id){
				if (listaPerfiles.length > 0) {
					setMostrarAreaNegocio(true);
					setInitialValues({
						id: valuesFormulario.values.id ? valuesFormulario.values.id : '',
						rut: valuesFormulario.values.rut ? valuesFormulario.values.rut : '',
						nombre: valuesFormulario.values.nombre ? valuesFormulario.values.nombre : '',
						apellido_paterno: valuesFormulario.values.apellido_paterno ? valuesFormulario.values.apellido_paterno : '',
						apellido_materno: valuesFormulario.values.apellido_materno ? valuesFormulario.values.apellido_materno : '',
						email: valuesFormulario.values.email ? valuesFormulario.values.email : '',
						celular: valuesFormulario.values.celular ? valuesFormulario.values.celular : '',
						profile: valuesFormulario.listaPerfil ? valuesFormulario.listaPerfil.map(String) : '',
						business: valuesFormulario.values.business ? valuesFormulario.values.business : '',
						file: valuesFormulario.values.file ? valuesFormulario.values.file : '',
					})

				} else {
					setMostrarAreaNegocio(false);
					setInitialValues({
						id: valuesFormulario.values.id ? valuesFormulario.values.id : '',
						rut: valuesFormulario.values.rut ? valuesFormulario.values.rut : '',
						nombre: valuesFormulario.values.nombre ? valuesFormulario.values.nombre : '',
						apellido_paterno: valuesFormulario.values.apellido_paterno ? valuesFormulario.values.apellido_paterno : '',
						apellido_materno: valuesFormulario.values.apellido_materno ? valuesFormulario.values.apellido_materno : '',
						email: valuesFormulario.values.email ? valuesFormulario.values.email : '',
						celular: valuesFormulario.values.celular ? valuesFormulario.values.celular : '',
						profile: valuesFormulario.listaPerfil ?  valuesFormulario.listaPerfil.map(String) : '',
						file: valuesFormulario.values.file ? valuesFormulario.values.file : '',
					})

				}
			};
		}

	}, [valuesFormulario])


	return { initialValues, isCrear, rest, mostrarAreaNegocio }
}
