import { useEffect, useState } from 'react';
import notfound from '../../../Assets/images/il-empty.svg';

const TablaExigencias = (props) =>{
	const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = props.tableInstance;
	const { handlerModalVia, valorTab } = props;
	const [actualizar, setActualizar] = useState(props);
	const mostrarModal = (e, row) => {
		e.preventDefault();
		props.handlerModal(row);
	}
	useEffect(() => {
		setActualizar(props.renderizar)
	},[props])

	return (
		<div className="table-responsive">
			<table className="table" {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps(column.getSortByToggleProps(), { className: column.className }
								)}>
									<span className="d-flex align-items-center gap-1">
										{column.render('Header')}
										<>
											{column.isSorted ?
												(column.isSortedDesc ?
													<i className="ico-chevron-down"></i>
													:
													<i className="ico-chevron-up"></i>
												) :
												<>
													<i className="ico-sort fs-7"></i>
												</>
											}
										</>
									</span>
								</th>
							))}
							<th className="text-center">Editar</th>
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{
						page.length > 0 && page.map( (row) => {
							prepareRow(row)
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return <td {...cell.getCellProps({className: cell.column.className})}>
											{cell.render('Cell')}
										</td>
									})
									}
									<td className="text-center">
										<button className="link text-pewter-blue fs-4" onClick={(e) => mostrarModal(e, row.original)}>
											<i className="ico-doc-edit"></i>
										</button>
									</td>
								</tr>
							)
						}) || <tr><td colSpan={headerGroups[0].headers.length + 1}>
							<div className="d-flex justify-content-center align-items-center flex-column gap-2 py-5">
								<img className="mb-3" src={notfound} alt=""/>
								<p className='mb-0 text-primary'>No se encuentran resultados de tu búsqueda.</p>
							</div>
						</td></tr>
						}
				</tbody>
			</table>
		</div>
	)
}
export default TablaExigencias;
